import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from '../../../shared/services/http.service';
import { Select2OptionData, Options } from 'select2';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'kt-envio-sts',
  templateUrl: './envio-sts.component.html',
  styleUrls: ['./envio-sts.component.scss']
})
export class EnvioStsComponent implements OnInit
{
  public selectConcesionarios: Array<Select2OptionData>;
  public selectBuses: Array<Select2OptionData>;
  public selectCodeMesage: Array<Select2OptionData>;
  public options: Options;
  public concesionarioSelected: string = '';
  public busesSelected: string = '';
  public codeMesageSelected: string = '';
  public messageInserted = ""
  listBuses = {}
  dataTableMesageSend = []
  requiredConsecionario = false ;
  requiredBuses =false;
  requiredCodeMesage =false;
  requiredMessageInserted =false;
  conditionalLoader = false;
  numerado = 0;

  contentToShow: number = -1;
  textTareaShow = true;
  configTabla: {} = { status: -1, data: [] };

  paginador: object = {
    count: 10,
    page: 1,
    total: 0,
    maxPages: 5
  };
  numeroRegistrosTabla = 10;

  constructor(private http: HttpService, private cdr: ChangeDetectorRef) {}


  ngOnInit() {
    this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};

    this.getConcesionarios()
    this.getBuses()
    this.getCodeMesage()

    this.generateTable([])
  }

  getConcesionarios()
  {
      this.http.ejecutarServicioGet(environment.endpoints.envioSTS.urlConsecionarios).subscribe(
  			res => {
          if (res.json().length > 0) {
            let data = res.json();
            let selectConcesionario = data.map(i => {
  						return { id: i.id, text: i.name };
  					});
            this.selectConcesionarios=selectConcesionario

          }
          this.cdr.markForCheck();
  			},
  			(error) => {
  				console.log(error)
        });
  }

  getBuses()
  {
      this.http.ejecutarServicioGet2(environment.endpoints.envioSTS.urlListBuses).subscribe(
        res => {

            if (res.json())
            {

              let buses = res.json()
              this.listBuses = buses['buses']
            }
            this.cdr.markForCheck();
        },
        (error) => {
          console.log(error)

        });
  }

  setBusesGroup(ConcesionarioSelected)
  {
        console.log(ConcesionarioSelected)
        let buses_selected  = this.listBuses[ConcesionarioSelected]
        if (buses_selected == undefined) return;
        //MAP BUSES TO GET STRUCTURE FOR NGSELECT
        let buses_opciones = buses_selected.map(i => {
            return {id:i, text:i}
        })
        buses_opciones.push({id: 'Todos', text: 'Todos'})
        this.selectBuses = buses_opciones
        this.cdr.markForCheck();
        if (buses_opciones.length == 0) return
        this.busesSelected = buses_opciones[0].id
  }

  getCodeMesage()
  {
    this.http.ejecutarServicioGet2(environment.endpoints.envioSTS.urlCodeMessage).subscribe(
      res => {
        if (res.json())
        {
          let data = res.json();
          let selectCodeMesage = data.messages.map(i => {
            let id = i.substring(0, i.indexOf(':'))
            return { id: id, text: i };
          });
          this.selectCodeMesage=selectCodeMesage


        }
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error)
      });
  }

  codeSelect(CodigoSeleccionado){
      // let show_text_area = (CodigoSeleccionado == '99' ? false:true)
      // this.textTareaShow = show_text_area

    if (CodigoSeleccionado == '99')
      {
        this.textTareaShow = false
        this.messageInserted = ''
      }
      else if(CodigoSeleccionado == '')
      {
        this.textTareaShow = true
        this.messageInserted = ''
      }
      else
      {
          this.textTareaShow = true
          //this.messageInserted = 'aqui va el mensje del numero'
          let code_message_selected = this.selectCodeMesage[CodigoSeleccionado-1].text
          let startOfText = code_message_selected.indexOf(': ') + 1
          this.messageInserted = code_message_selected.substring(startOfText)

      }

  }

  sendStsMessage() {
      this.requiredConsecionario = (this.concesionarioSelected == '' ? true:false)
      this.requiredBuses = (this.busesSelected == '' ? true:false)
      this.requiredCodeMesage = (this.codeMesageSelected == '' ? true:false)
      this.requiredMessageInserted = (this.messageInserted == '' ? true:false)

      if (this.requiredConsecionario == false && this.requiredBuses == false && this.requiredCodeMesage ==false && this.requiredMessageInserted ==false)
      {
            this.conditionalLoader = true;

            let url= environment.endpoints.envioSTS.urlSendMessage
            url = url.replace('concesionarioSelected',this.concesionarioSelected)
            url = url.replace('busesSelected',this.busesSelected)
            url = url.replace('codeMesageSelected',this.codeMesageSelected)
            url = url.replace('messageInserted', encodeURIComponent(this.messageInserted))

            this.http.ejecutarServicioGet2(url).subscribe(
              res => {
                  this.conditionalLoader = false;
                  this.cdr.markForCheck();

                  this.dataTableMesageSend = res.json().devices
                  this.paginador['page'] = 1
                  this.paginador['total'] = this.dataTableMesageSend.length
                  this.generateTable((this.dataTableMesageSend).slice(0, this.numeroRegistrosTabla))
              },
              (error) => {
                console.log(error)
                this.conditionalLoader = false;
              });
      }
   }

  generateTable(Data) {
    let data_formated = this.procesarJson(Data)
    this.conditionalLoader = false;
      this.contentToShow = 1;
      this.configTabla = {
        origin: 'incidents',
        status: 1,
        header: [
          '',
          '',
          'Concesionario',
          'Bus',
          'ID Dispositivo',
          'Resultado',
          'Fecha/Hora de Envío'
        ],
        typeColumn: [
          ,
          ,
          ,
          ,
          ,
        ],
        showSearch: false,
        searchColumn: [1],
        searchTitle: 'Buscar Incidente',
        hideColumns: [0],
        showPag: true,
        leftsColumns: [1],
        configColumns: {
          id: 0,
        },
        data: data_formated,
        paginador: this.paginador
      };
  }



  procesarJson(Data)
  {
      console.log("ABOUT TO GENERATE TABLE")
      console.log(Data)
      let self = this;
      let data_formated = Data.map(function(busAnalizado, count){
            let page = self.paginador['page']
            console.log("PAGINE ACTUAL " + page)
            let fecha = new Date(busAnalizado.date)
            console.log(fecha)
            fecha.setHours(fecha.getHours() - 5)
            let fecha_formated = fecha.getDate()+'-'+(fecha.getMonth()+1)+'-'+ fecha.getFullYear()+'  '+ fecha.getHours() + ":" + fecha.getMinutes()
            return ['',(((page -1)*10)+count+1).toString(), busAnalizado.registry_id, busAnalizado.bus, busAnalizado.device_id, (busAnalizado.status == 'fall' ? 'Fallido':'Exitoso'), fecha_formated]
      })
      //((self.paginador['page'])*10 + count).toString()
      return data_formated
  }

  buscadorPagina(event) {

      let rangoInicialPaginado =(event -1)*this.numeroRegistrosTabla
      let rangoFinalPaginado =(event*this.numeroRegistrosTabla)

      this.paginador['total'] = this.dataTableMesageSend.length
      this.paginador['page'] = event

      console.log("PAGINA " + this.paginador['page'])

      this.generateTable((this.dataTableMesageSend).slice(rangoInicialPaginado, rangoFinalPaginado))

	}



}
