export class AdminAjustarCasoDto {
    
    estadoCaso: string;
    tipoCaso: string;
    rol: string;
    troncal: string;


    constructor(data?: any) {
        if (data !== undefined) {
            this.estadoCaso = data['estadoCaso'] !== undefined ? data['estadoCaso'] : null;
            this.tipoCaso = data['tipoCaso'] !== undefined ? data['tipoCaso'] : null;
            this.rol = data['rol'] !== undefined ? data['rol'] : null;
            this.troncal = data['troncal'] !== undefined ? data['troncal'] : null;
        }
    }

    static fromJS(data: any): AdminAjustarCasoDto {
        return new AdminAjustarCasoDto(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['estadoCaso'] = this.estadoCaso !== undefined ? this.estadoCaso : null;
        data['tipoCaso'] = this.tipoCaso !== undefined ? this.tipoCaso : null;
        data['rol'] = this.rol !== undefined ? this.rol : null;
        data['troncal'] = this.troncal !== undefined ? this.troncal : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['estadoCaso'] = this.estadoCaso !== undefined ? this.estadoCaso : '';
        data['tipoCaso'] = this.tipoCaso !== undefined ? this.tipoCaso : '';
        data['rol'] = this.rol !== undefined ? this.rol : '';
        data['troncal'] = this.troncal !== undefined ? this.troncal : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminAjustarCasoDto(JSON.parse(json));
    }

}
