import {
	Component,
	OnInit,
	ChangeDetectorRef,
	Input,
	Output,
	EventEmitter
} from '@angular/core';

import { Response } from '@angular/http';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { AdminUserDTO } from '../../../../shared/dto/admin-user-dto';
import { UserDTO } from '../../../../../../app/views/shared/dto/user-dto';
import { AuthService } from '../../../../../core/auth/_services/auth.service';

import { Select2OptionData, Options } from 'select2';
import { UIService } from '../../../_services/UI.service';

@Component({
	selector: 'kt-usuario-crear',
	templateUrl: './usuario-crear.component.html',
	styleUrls: ['./usuario-crear.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class UsuarioCrearComponent implements OnInit {
	// Select2
	public selectFitro1: Array<Select2OptionData>;
	public selectFitroProfiles: Array<Select2OptionData>;
	public selectContratistas: Array<Select2OptionData>;
	public options: Options;
	private generado: boolean = false;
	rutasMenu: object;
	permisos: {}[];
	configDatos: {};
	user: UserDTO;
	selectConcesionario: boolean = true;

	formModelUser: AdminUserDTO = new AdminUserDTO();
	@Input() modificarDatos: AdminUserDTO;

	@Output() actionModificarUser = new EventEmitter();
	// Modales
	contentToShow: number = -1;
	configModal: {} = { status: -1 };
	loading: boolean = false;

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService,
		private authService: AuthService,
	) { }

	ngOnInit() {
		// Si trae parametros para EDITAR
		if (this.modificarDatos) {
			this.formModelUser = this.modificarDatos;
		}
		this.loading = true;
		let ruta = `${environment.endpoints.adminUserProfileSearch}?id=&name=&description=&count=200&page=1`;
		this.http.ejecutarServicioGet(ruta).subscribe((res: Response) => {
			switch (res.status) {
				case 200:
				case 201:
				case 204:
					let perfiles = res.json();
					for (let index = 0; index < perfiles.length; index++) {
						perfiles[index]['text'] = perfiles[index]['name'];
						if (index === perfiles.length - 1) {
							this.selectFitroProfiles = perfiles;
						}
					}
					this.http.ejecutarServicioGet(environment.endpoints.adminRegistries).subscribe((resRegistries: Response) => {
						switch (resRegistries.status) {
							case 200:
							case 201:
							case 204:
								let entities = resRegistries.json();
								if (!this.modificarDatos) {
									this.formModelUser.userProfiles = perfiles[0].id;
								}
								for (let indexEntities = 0; indexEntities < entities.length; indexEntities++) {
									entities[indexEntities]['text'] = entities[indexEntities]['id'];
									if (indexEntities === entities.length - 1) {
										this.selectFitro1 = entities;
									}
								}
								this.loading = false;
								this.cdr.markForCheck();
								break;
							case 401:
								// nothing
								break;
							default:
								this.showError(resRegistries);
								break;
						}
					}, (error: Response) => {
						this.showError(error);
					}); // END INNER GET

					this.http.ejecutarServicioGet(environment.endpoints.adminRegistriesContratista)
						.subscribe((res) => {
							if (res.json().length > 0) {
								let data = res.json();
								this.selectContratistas = data.map(i => ({ id: i.id, text: i.name }));
								this.cdr.markForCheck();
							}
						},
							(error) => {
								console.log(error)
							});
					break;
				case 401:
					// nothing
					break;
				default:
					this.showError(res);
					break;
			}
		}, (error: Response) => {
			this.showError(error);
		}
		); // END GET

		this.rutasMenu = [
			{
				label: 'Buscar Usuario',
				icon: 'smart-icon-search',
				ruta: `${environment.folders.routeAdmin}usuario/buscar`
			},
			{
				label: 'Crear Usuario',
				icon: 'smart-icon-usuario',
				ruta: `${environment.folders.routeAdmin}usuario/crear`
			}
		];

		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	openModalUserCreado() {
		this.contentToShow = 1;
		let nombreUser: string = '';
		if (this.modificarDatos) {
			nombreUser = `<b>${this.formModelUser.name}</b>`;
		}

		this.configModal = {
			status: 1,
			icono: 'smart-icon-usuario',
			titulo: 'Usuario',
			textos: [
				`Se guardó exitosamente la configuración del Usuario ${nombreUser}`
			],
			botones: [
				{
					label: 'Aceptar',
					class: '',
					accion: !this.modificarDatos
						? 'creacionUsuario'
						: 'modificarUsuario'
				}
			]
		};
		this.cdr.markForCheck();
		setTimeout(() => {
			this.contentToShow = -1;
			this.configModal = { status: -1 };
		}, 300);
	}

	crearUser() {
		this.user = this.authService.getUser();
		let formUser = this.formModelUser.toJS();
		let today = new Date();
		let todayDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
		console.log('FECHA ACTUAL:', today);
		console.log('FECHA ACTUAL FORMATO:', todayDate);
		console.log('USER', formUser);
		formUser.creationDate = todayDate;
		formUser.creationUser = this.user.username;
		// console.log(formUser);		
		if (formUser.name === '' && formUser.username === '') {
			return;
		}
		console.log(this.validateEmail(formUser.email))
		if (!this.validateEmail(formUser.email)) {
			this.uiService.showMessage('El formato de correo electrónico no es válido');
			return false;
		}
		this.loading = true;
		delete formUser['id'];
		this.http
			.ejecutarServicioPost(environment.endpoints.adminUser, formUser)
			.subscribe(
				(res: Response) => {
					switch (res.status) {
						case 200:
						case 201:
						case 204:
							// Mostrar Modal
							this.openModalUserCreado();
							this.loading = false;
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							break;
					}
				},
				(error: Response) => {
					this.showError(error);
				}
			); // END POST
	}

	cerrarModal() {
		this.actionModificarUser.emit('cerrar');
	}

	modificarUser() {
		let formUser = this.formModelUser.toJS();
		console.log(this.validateEmail(formUser.email))
		if (!this.validateEmail(formUser.email)) {
			this.uiService.showMessage('El formato de correo electrónico no es válido');
			return false;
		}
		this.loading = true;
		let ruta = `${environment.endpoints.adminUser}${formUser.username}`;
		this.http.ejecutarServicioPut(ruta, formUser).subscribe(
			(res: Response) => {
				switch (res.status) {
					case 200:
					case 201:
					case 204:
						// Mostrar Modal
						this.openModalUserCreado();
						this.loading = false;
						break;
					case 401:
						// nothing
						break;
					default:
						this.showError(res);
						break;
				}
			},
			(error: Response) => {
				this.showError(error);
			}
		); // END POST
	}

	changeStatusModal(event) {
		if (this.modificarDatos) {
			this.actionModificarUser.emit('cerrar');
		} else {
			this.formModelUser = new AdminUserDTO();
		}
	}

	omit_special_char(event) {
		let k;
		k = event.charCode;
		/*
		return (
			(k > 64 && k < 91) ||
			(k > 96 && k < 123) ||
			k === 8 ||
			k === 32 ||
			(k >= 48 && k <= 57) ||
			k === 241 ||
			k === 209
			
		);
		*/
	}
	omit_spaces(event) {
		this.generado = false;
		let k;
		k = event.charCode;
		return k !== 32;
	}
	generate_username() {
		if (!this.modificarDatos) {
			let name = this.formModelUser.name ? this.formModelUser.name.substr(0, 1) : '';
			let lastname = this.formModelUser.lastName ? this.formModelUser.lastName : '';
			lastname = lastname.split(' ')[0];
			this.formModelUser.username = `${name}.${lastname}`;
			this.generado = true;
		}
	}

	validateEmail(email) {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	changeEntity(bol) {
		this.selectConcesionario = bol
	}
}
