import { UIService } from './../../../_services/UI.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Select2OptionData, Options } from 'select2';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { HttpService } from '../../../../shared/services/http.service';
import { Response } from '@angular/http';

@Component({
  selector: 'kt-asociar',
  templateUrl: './asociar.component.html',
  styleUrls: [
    './../dispositivos-grupos/dispositivos-formulario/dispositivos-formulario.component.scss',
    './asociar.component.scss'
  ],
  host: { 'class': 'sths-tb-element' }
})
export class AsociarComponent implements OnInit {
  // Select2
  public selectFitroDispositivos: Array<Select2OptionData>;
  //public selectFitro2: Array<Select2OptionData>;
  public options: Options;

  // Cargador
  conditionalLoader: boolean;
  conditionalLoaderTable: boolean;

  configTable: {};
  rutasMenu: {};

  idRegistry: string;
  idRegistryGroup: string;

  idNumber: number = 1;
  busMetaData;
  metaDatasDefault: {};
  metaDatas: {}[] = [
    {
      key: '',
      value: ''
    }
  ];
  devices: {};

  idDevicesActive: string;

  // Modales
  contentToShowModal: number = -1;
  contentToShowFormModal: number = -1;
  configModal: {} = { status: -1 };

  contentToShowTerminar: number = -1;

  // Paginador
  paginador: {} = {
    count: 30,
    page: 1
  };

  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };
  public operadorStyle: boolean = false;

  canChangeState: boolean = false;
  canEditDelete: boolean = false;

  constructor(private _route: ActivatedRoute,
              private router: Router,
              private http: HttpService,
              private cdr: ChangeDetectorRef,
              private uiService: UIService,
              private route: ActivatedRoute,
              //public operadorStyle: boolean = false,
  ) {
    this.idRegistry = this._route.snapshot.paramMap.get('registryId');
    this.idRegistryGroup = this._route.snapshot.paramMap.get('registryGroup');
    
    this.metaDatasDefault = {
      marca: '',
      referencia: ''
    };
  }

  Admin: boolean = true;

  ngOnInit() {

    this.route.data.subscribe( data => {
      this.Admin = ( data['form'] === 1 );
    });

    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: 'select2-dropdown-forms'
    };
    this.http.ejecutarServicioGet( `${environment.endpoints.adminRegistryDevicesMetadata}/${this.idRegistry}/${this.idRegistryGroup}` , null ).subscribe( (res: Response) => {
      this.busMetaData = res.json();
      console.log('metadata :>> ', this.busMetaData );
      // Combos Select2
      this.cargarCombos();
    });


    // Rutas
    this.rutasMenu = [
      {
        label: 'Asociar dispositivos',
        icon: 'smart-icon-asociar-dispositivos',
        ruta:  `${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}/${this.idRegistryGroup}/asociar`
      },
      {
        label: 'Bus',
        icon: 'smart-icon-editar-cuadro',
        ruta: `${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}/${this.idRegistryGroup}/editar`
      }
    ];

    this.cargarDevicesList();
  }

  cargarCombos() {
    //this.selectFitroDispositivos = [];
     //this.selectFitro2 = [];
    this.http.ejecutarServicioGet( environment.endpoints.adminRegistryDevicesComboDisp , null ).subscribe( (res: Response) => {
      const dispositivos: {}[] = res.json();
      // for (let disp of dispositivos) {
      //   console.log('disp :>> ', disp);
      //  // this.selectFitro2 = [...this.selectFitro2,{ id: disp['id'], text: disp['name'] }];
      //  this.selectFitroDispositivos = [...this.selectFitroDispositivos,{ id: disp['id'], text: disp['name'] }];
      // }
      console.log('item :>> ', dispositivos);
      this.selectFitroDispositivos = dispositivos
      .filter(item => item.hasOwnProperty("tipo_motor"))
      .filter(item => this.eliminarDiacriticos(this.busMetaData.tipo_motor).toLowerCase() ==  this.eliminarDiacriticos(item['tipo_motor']).toLowerCase())
      .map((item : any) => {
        //console.log('item :>> ',  this.eliminarDiacriticos(item.tipo_motor));
      
         // console.log('entre al primer if :>> ', );
          return {
            id: item.id,
            text: item.name
          }
      })
    //  console.log('dispositivos :>> ', dispositivos);
      this.cdr.markForCheck();
    //  console.log('this.selectFitroDispositivos :>> ', this.selectFitroDispositivos);
    });
    
  }

  eliminarDiacriticos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
	}

  cargarDevicesList() {
    this.conditionalLoaderTable = true;
    let ruta = `${environment.endpoints.adminRegistryDevicesList}/${this.idRegistry}/${this.idRegistryGroup}`;
    this.http.ejecutarServicioGet(ruta, null).subscribe((res: Response) => {
      this.conditionalLoaderTable = false;
      this.procesarJson(res.json());
      this.cdr.markForCheck();
    });
  }

  procesarJson(data) {
    /*
      @ID (Referencia)
      @ID,
    */
   console.log(data,'esta es la data');
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      this.canChangeState = (data[index]['canChangeState'] === 'true') ? false : true;
      this.canEditDelete = (data[index]['canEditDelete'] === 'true') ? true : false;
      if (this.canChangeState) {
        dataArray.push([
          data[index]['id'].toString(),
          data[index]['id'].toString(),
          '',
          ''
        ]);  
      } else {
        dataArray.push([
          data[index]['id'].toString(),
          data[index]['id'].toString(),
          '',
          (data[index]['metadata']['blocked'] === 'true') ? false : true
        ]);
      }
      if (index === data.length - 1) {
        this.armarTabla(dataArray);
      }
    }

    if (!data.length) {
      this.armarTabla(dataArray);
    }
  }

  armarTabla( data, hide_action = false ) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header : ['ID', 'ID Dispositivo', 
        (!this.canChangeState?'':'Acciones'),
        (!this.canChangeState?'Estado':'')
      ],
      typeColumn: [
        { tipo: 'ID Dispositivo', acciones:['editar']},
        , 
        { 
          tipo: 'acciones', 
          acciones: (!this.canChangeState?[]:['editar', 'eliminar']),
        }, 
        {tipo: (!this.canChangeState?'estado':'')} 
      ],
      leftsColumns: [1],
      searchColumn: [2],
      hideColumns: [0],
      showPag: false,
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }

  agregarDato() {
    this.idNumber++;
    this.metaDatas.push({
      key: '',
      value: ''
    });
  }

  eliminarMetadata(item) {
    const index: number = this.metaDatas.indexOf(item);
    if (index !== -1) {
      this.metaDatas.splice(index, 1);
    }
  }

  asociarDispositivos() {
    this.conditionalLoader = true;

    let paramsGroup = { ...this.metaDatasDefault };
    paramsGroup['registry'] = this.idRegistry;
    paramsGroup['groupId'] = this.idRegistryGroup;

    paramsGroup['template'] = this.devices;
    // paramsGroup['deviceId'] = `${this.idRegistryGroup}_CANBUS`;

    var url = environment.endpoints.adminRegistryDevicesCreate;
    this.http.ejecutarServicioPost(url, paramsGroup).subscribe((res: Response) => {

      let message = JSON.parse(res['_body']);
      let idDevice = message['id'];
      //console.log(idDevice,'id');

      if (res.ok === true) {
        let paramsMetaData = {
          device: idDevice,
          registry: this.idRegistry,
        };

        for (let key in this.metaDatasDefault) {
          paramsMetaData[key] = this.metaDatasDefault[key];
        }

        for (let index = 0; index < this.metaDatas.length; index++) {
          paramsMetaData[this.metaDatas[index]['key']] = this.metaDatas[index]['value'];
        }

        this.http.ejecutarServicioPost(environment.endpoints.adminRegistryDevicesAddMetadata, paramsMetaData).subscribe((resMeta: Response) => {
          if (res.status === 200) {
            this.uiService.showMessage('Dispositivo asociado');
          } else {
            this.uiService.showMessage('Error asociando Dispositivo');
          }
        }, (e) => {
          this.uiService.showMessage('Error asociando Dispositivo');
        });
        setTimeout(() => {
          this.conditionalLoader = false;
          this.cdr.markForCheck();
          this.openModalDevicesCreado();
        }, 3000);

      } else {
        this.uiService.showMessage(res.json()['message'], -1);
        this.conditionalLoader = false;
        this.cdr.markForCheck();
      }
    }, (error: Response) => {
      this.conditionalLoader = false;
      this.uiService.showMessage(error);
      this.cdr.markForCheck();
    });
  }

  eventCredencialesClick(id, type?: string) {
    this.idDevicesActive = id;
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-nombre-categoria',
      titulo: 'Credenciales',
      textos: [
        ''
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Descargar',
          class: '',
          accion: 'descargarCredencial'
        }
      ]
    };

    if (type === 'regenerado') {
      this.configModal['textos'].push(' Se ha regenerado correctamente...');
    } else {
      this.configModal['botones'].push({
        label: 'Regenerar',
        class: '',
        accion: 'regenerarCredencial'
      });
    }

    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModal = -1;
      this.configModal = { status: -1 };
    }, 300);
  }

  openModalDevicesCreado() {
    this.contentToShowModal = 1;
    let nombreUser: string = '';
    // if( this.modificarDatos ){
    //   nombreUser = `<b>${this.formModelUser.name}</b>`;
    // }

    this.configModal = {
      status: 1,
      icono: 'smart-icon-usuario',
      titulo: 'Asociar Dispositivos',
      textos: [
        `Se guardó exitosamente los dispositivos ${nombreUser}`
      ],
      botones: [
        {
          label: 'Aceptar',
          class: '',
          accion: 'creacionDispositivos'
          // accion: !this.modificarDatos ? 'creacionUsuario' : 'modificarUsuario'
        }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModal = -1;
      this.configModal = { status: -1 };
    }, 300);
  }

  changeStatusModal(event) {
    let ruta;
    switch (event) {
      case 'descargarCredencial':
        this.conditionalLoaderTable = true;
        this.cdr.markForCheck();
        ruta = `${environment.endpoints.adminRegistryDevicePK}/${this.idRegistry}/${this.idDevicesActive}`;
        this.http.ejecutarServicioGet(ruta, null).subscribe(
          data => this.uiService.downloadFile(data['_body'], 'pem', `${this.idDevicesActive}.rsa.pem`),
          (e) => {
            this.uiService.showMessage('Error descagando las Credenciales');
          });
        this.conditionalLoaderTable = false;
        this.cdr.markForCheck();
        break;
      case 'regenerarCredencial':
        this.conditionalLoaderTable = true;
        this.cdr.markForCheck();
        ruta = `${environment.endpoints.adminRegistryDeviceUpdatePK}/${this.idRegistry}/${this.idDevicesActive}`;
        this.http.ejecutarServicioPost(ruta, null).subscribe((res: Response) => {
          this.conditionalLoaderTable = false;
          if (res.status === 200) {
            this.uiService.showMessage('Credenciales listas');
            this.eventCredencialesClick(this.idDevicesActive, 'regenerado');
          } else {
            this.uiService.showMessage('Error generando las Credenciales');
          }
          this.cdr.markForCheck();
        },
          (e) => {
            this.uiService.showMessage('Error generando las Credenciales');
          });
        break;
      case 'eliminarDispositivo':
        this.conditionalLoaderTable = true;
        this.cdr.markForCheck();
        const params = {
          registry: this.idRegistry,
          groupId: this.idRegistryGroup,
          deviceId: this.idDevicesActive
        };
        this.http.ejecutarServicioPost(environment.endpoints.adminRegistryDeviceDelete, params).subscribe((res: Response) => {
          this.conditionalLoaderTable = false;
          if (res.status === 200) {
            this.uiService.showMessage('Dispositivo Eliminado');
            this.cargarDevicesList();
          } else {
            this.uiService.showMessage('Error al eliminar el Dispositivo');
          }
          this.cdr.markForCheck();
        }, (error) => {
          this.uiService.showMessage('Error al eliminar el Dispositivo');
        });

        this.idDevicesActive = '';
        break;
      case 'editarDispositivo': {
        this.conditionalLoaderTable = true;
        this.configModal = {};
        this.contentToShowFormModal = 11;
        this.cdr.markForCheck();
        setTimeout(() => {
          this.contentToShowFormModal = -1;
          this.configModal = { status: -1 };
          this.conditionalLoaderTable = false;
          this.cdr.markForCheck();
        }, 300);
        break;
      }
      case 'crearPlantilla':
        const paramsI = {
          registry: this.idRegistry,
          name: this.idRegistryGroup
        };
        this.conditionalLoaderTable = true;
        this.http.ejecutarServicioPost(environment.endpoints.adminRegistryTemplateGroupCreate, paramsI).subscribe((res: Response) => {
          this.conditionalLoaderTable = false;
          if (res.status === 200) {
            this.uiService.showMessage('Plantilla creada con éxito');
          } else {
            this.uiService.showMessage('Error al crear Plantilla');
          }
          this.cdr.markForCheck();
        }, (error) => {
          this.uiService.showMessage('Error al crear Plantilla');
        });
        break;

      default:
        this.conditionalLoaderTable = false;
        this.cargarDevicesList();
        this.cdr.markForCheck();
        break;
    }

  }

  validateForm(event) {
    setTimeout(() => {
      for (let metadata in this.metaDatas) {
        this.metaDatas[metadata]['key'] = this.metaDatas[metadata]['key'].replace(/[^a-zA-Z0-9_-]/g, ' ').replace(' ', '').trim();
        //removed regex: .replace(/[^a-zA-Z0-9_.]/g, ' ').replace(' ', '')
        this.metaDatas[metadata]['value'] = this.metaDatas[metadata]['value'].trim();
      }
      this.cdr.markForCheck();
    }, 100);
  }

  eventEstadoClick(item) {
    // Los links e inputs serán deshabilitados
    let inputs = document.getElementsByTagName("input"); 
    let links = document.getElementsByTagName("a"); 
    for (let i = 0; i < inputs.length; i++) { 
        inputs[i].disabled = true;
    }
    for (let i = 0; i < links.length; i++) { 
        links[i].setAttribute("style", "pointer-events: none;cursor: default;");
    }

    let ruta = environment.endpoints.adminRegistryDeviceBlock;
    let params = {
      registry: this.idRegistry,
      deviceId: item['id'],
      groupId: this.idRegistryGroup
      //,
      //template: '1007'
    };

    if (item['value'] === true) {
      ruta = environment.endpoints.adminRegistryDeviceUnblock;
    }
    this.conditionalLoaderTable = true;
    this.http.ejecutarServicioPost(ruta, params).subscribe((res: Response) => {
      if (res.status === 200) {
        this.uiService.showMessage('Dispositivo actualizado');

        for (let i = 0; i < inputs.length; i++) { 
            inputs[i].disabled = false;
        }
        for (let i = 0; i < links.length; i++) { 
            links[i].setAttribute("style", "");
        }    
      } else {
        this.uiService.showMessage('Error actualizando Dispositivo');
      }
      this.conditionalLoaderTable = false;
      this.cdr.markForCheck();
    }, (e) => {
      this.uiService.showMessage('Error actualizando Dispositivo');
      this.conditionalLoaderTable = false;
      this.cdr.markForCheck();
    });
  }

  eventEditarClick( event ) {
    //console.log(event);
    if (this.canEditDelete){
    this.operadorStyle = false;
    this.idDevicesActive = event;
    this.contentToShowModal = 2;

    this.configModal = {
      status: 2,
      icono: 'smart-icon-editar',
      titulo: 'Editar dispositivo',
      textos: [
        `¿Está seguro que quiere editar <b>${this.idDevicesActive}</b?>`
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Editar',
          accion: 'editarDispositivo'
        }
      ]
    };
    }else{

    console.log('no entra');
    this.idDevicesActive = event;
    this.contentToShowFormModal = 21;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowFormModal = -1;
      this.configModal = { status: -1 };
      this.conditionalLoaderTable = false;
      this.cdr.markForCheck();
    }, 300);
    
    }
  }

  viewEstadoClick( event ) {
    //console.log(event);
    this.operadorStyle = true;
    //console.log(this.operadorStyle);

    this.idDevicesActive = event;
    this.contentToShowFormModal = 11;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowFormModal = -1;
      this.configModal = { status: -1 };
      this.conditionalLoaderTable = false;
      this.cdr.markForCheck();
    }, 300);
    
  }

  eventEliminarClick( event ) {
    if (this.canEditDelete){
      this.idDevicesActive = event;
      this.contentToShowModal = 1;

      this.configModal = {
        status: 1,
        icono: 'smart-icon-eliminar',
        titulo: 'Desvincular dispositivo',
        textos: [
              `¿Esta seguro que quiere desvincular <b>${this.idDevicesActive}</b> del Bus?`
        ],
        botones: [
          {
            label: 'Cancelar',
            class: 'sths-btn-cancel',
            accion: 'cancelar'
          },
          {
            label: 'Eliminar',
            class: '',
            accion: 'eliminarDispositivo'
          }
        ]
      };
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowModal = -1;
        this.configModal = { status: -1 };
      }, 300);
    }else{
      
      console.log('no entra');
      this.idDevicesActive = event;
      this.contentToShowFormModal = 22;
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowFormModal = -1;
        this.configModal = { status: -1 };
        this.conditionalLoaderTable = false;
        this.cdr.markForCheck();
      }, 300);
      
    }
  }

  crearPlantilla() {
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-eliminar',
      titulo: 'Crear Plantilla',
      textos: [
        `¿Deseas guardar como plantilla?`
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Guardar',
          class: '',
          accion: 'crearPlantilla'
        }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModal = -1;
      this.configModal = { status: -1 };
    }, 300);
  }
}
