export class AdminCategoriaDispositivoDto {

    deviceId: string;
    contratista: string;
    template: string;
    stationId: string;
    marca: string;
    referencia: string;

    constructor(data?: any) {
        if (data !== undefined) {
            this.deviceId = data['deviceId'] !== undefined ? data['deviceId'] : null;
            this.contratista = data['contratista'] !== undefined ? data['contratista'] : null;
            this.template = data['template'] !== undefined ? data['template'] : null;
            this.stationId = data['stationId'] !== undefined ? data['stationId'] : null;
            this.marca = data['marca'] !== undefined ? data['marca'] : null;
            this.referencia = data['referencia'] !== undefined ? data['referencia'] : null;
        }
    }

    static fromJS(data: any): AdminCategoriaDispositivoDto {
        return new AdminCategoriaDispositivoDto(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['deviceId'] = this.deviceId !== undefined ? this.deviceId : null;
        data['contratista'] = this.contratista !== undefined ? this.contratista : null;
        data['template'] = this.template !== undefined ? this.template : null;
        data['stationId'] = this.stationId !== undefined ? this.stationId : null;
        data['marca'] = this.marca !== undefined ? this.marca : null;
        data['referencia'] = this.referencia !== undefined ? this.referencia : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['deviceId'] = this.deviceId !== undefined ? this.deviceId : '';
        data['contratista'] = this.contratista !== undefined ? this.contratista : '';
        data['template'] = this.template !== undefined ? this.template : '';
        data['stationId'] = this.stationId !== undefined ? this.stationId : '';
        data['marca'] = this.marca !== undefined ? this.marca : '';
        data['referencia'] = this.referencia !== undefined ? this.referencia : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminCategoriaDispositivoDto(JSON.parse(json));
    }

}
