import {
  NgModule,
  Component,
  OnInit,
  ChangeDetectorRef,
  Input
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelect2Module } from "ng-select2";
import { AuthService } from "./../../../../../core/auth/_services/auth.service";
import { HttpService } from "./../../../../shared/services/http.service";
import { UIService } from "./../../../../../views/trans/_services/UI.service";
import { UtilService } from "../../../../trans/administrador/_services/util.service";

@Component({
  selector: "kt-form-alarm",
  templateUrl: "./form-alarm.component.html",
  styleUrls: ["./form-alarm.component.scss"],
  providers: [NgbModalConfig, NgbModal]
})
@NgModule({
  imports: [FormsModule, CommonModule, NgSelect2Module, NgbModule]
})
export class FormAlarmComponent implements OnInit {

  conditionalLoader: boolean;
  user = this.auth.getUser().username;
  loading: boolean;

  @Input() modificarDatos: {
    alarma: any,
    alarmType: '',
    rango: any,
    motorTypeOptions: [],
    breakTypeOptions: [],
    possibleSignalOptions: [],
    possibleUnitOptions: any,
    signalTypeOptions: [],
    signalOptions: [],
    rangeOptions: [],
    baseUrl: "";
    id: "",
  };

  public tmpItem = {
    name: "",
    alarmType: '',
    prioridad: "",
    motorType: "",
    signalType: "",
    signal: "",
    breakType: "",
    initialRange: "",
    finalRange: "",
    measureUnit: "",
    range: "",
    id: "",
    actualRange: "",
    actualRangeText: ""
  };

  data = {
    motorTypeOptions: [],
    breakTypeOptions: [],
    possibleSignalOptions: [],
    possibleUnitOptions: [],
    signalTypeOptions: [],
    signalOptions: [],
    rangeOptions: [],
  };

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private http: HttpService,
    private uiService: UIService
  ) {
    this.conditionalLoader = false;
  }

  changeSignalType(event) {
    this.tmpItem.measureUnit = "";
    this.data.signalOptions = [];
    for (let item of this.data.possibleSignalOptions) {
      if (event == item.signalType.id) {
        this.data.signalOptions.push({
          'id': item.id,
          'text': item.text,
          'answerValue': item.answerValue,
        });
      }
    }
    this.cdr.markForCheck();
  }


  selectSignal(event) {
    if (event.data != null) {
      this.data.possibleSignalOptions.map(item => {
        if (item.id == event.value) {
          this.tmpItem.measureUnit = item.unit;
        }
      });
    }
  }

  setRange(id) {
    this.tmpItem.range = id;
    for (var j = 0; j < this.data.rangeOptions.length; j++) {
      this.data.rangeOptions[j].isDisabled = false;
    }
    for (var i = 0; i < this.data.rangeOptions.length; i++) {
      if (this.data.rangeOptions[i].id == id) {
        this.data.rangeOptions[i].isDisabled = true;
      }
    }
  }

  editarRango() {
    UtilService.editarAlarma.emit(this.tmpItem);
  }

  cerrarModal() {
    this.modalService.dismissAll();
    this.resetView();
  }

  resetView() {
    this.tmpItem.name = "";
    this.tmpItem.motorType = "";
    this.tmpItem.signalType = "";
    this.tmpItem.signal = "";
    this.tmpItem.breakType = "";
    this.tmpItem.initialRange = "";
    this.tmpItem.finalRange = "";
    this.tmpItem.measureUnit = "";
    this.tmpItem.range = "";
    this.data.signalOptions = [];
    for (var j = 0; j < this.data.rangeOptions.length; j++) {
      this.data.rangeOptions[j].isDisabled = false;
    }
  }

  validateRange(e) {
    // var tecla = (document.all) ? e.keyCode : e.which;
    // if (tecla == 8 || tecla == 46) {
    //   return true;
    // }
    // if (tecla == 101) {
    //   return false;
    // }
    // var patron = /[A-Za-z0-9]/;
    // var tecla_final = String.fromCharCode(tecla);
    // return patron.test(tecla_final);

    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) {
      if (charStr != ".") {
        e.preventDefault();
      }
    }
  }

  ngOnInit() {
    if (this.modificarDatos.rango) {
      this.tmpItem.name = this.modificarDatos.alarma.nombreAlarma.text;
      this.tmpItem.prioridad = this.modificarDatos.alarma.prioridad.id;
      this.tmpItem.motorType = this.modificarDatos.alarma.tipoMotor.id;
      this.tmpItem.signalType = this.modificarDatos.alarma.tipoSeñal.id;
      this.tmpItem.signal = this.modificarDatos.alarma.señal.id;
      this.tmpItem.breakType = this.modificarDatos.alarma.tipoFreno.id;
      this.tmpItem.initialRange = this.modificarDatos.rango.rangoInicial;
      this.tmpItem.finalRange = this.modificarDatos.rango.rangoFinal;
      this.tmpItem.range = this.modificarDatos.rango.rangeType.id;
      this.tmpItem.actualRange = this.modificarDatos.rango.rangeType.id;
      this.tmpItem.actualRangeText = this.modificarDatos.rango.rangeType.text
      this.tmpItem.id = this.modificarDatos.id;
      this.tmpItem.alarmType = this.modificarDatos.alarmType;

      this.data = {
        motorTypeOptions: this.modificarDatos.motorTypeOptions,
        breakTypeOptions: this.modificarDatos.breakTypeOptions,
        possibleSignalOptions: this.modificarDatos
          .possibleSignalOptions,
        possibleUnitOptions: this.modificarDatos.possibleUnitOptions,
        signalTypeOptions: this.modificarDatos.signalTypeOptions,
        signalOptions: [],
        rangeOptions: this.modificarDatos.rangeOptions,
      };

      this.changeSignalType(this.tmpItem.signalType);
      this.selectSignal(this.tmpItem.signal);
      this.setRange(this.tmpItem.range);
      this.tmpItem.measureUnit = this.modificarDatos.alarma.unidad.text;
    }
  }
}
