import { UIService } from './../../../../trans/_services/UI.service';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { AuthService } from './../../../../../core/auth/_services/auth.service';

import { Response } from '@angular/http';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '../../../../../../environments/environment';
import { AdminCategoriaDto } from '../../../../shared/dto/admin-categoria-dto';

// Servicios
import { UsuariosService } from '../../../../trans/administrador/_services/usuarios.service';
import { AdminUserDTO } from '../../../../shared/dto/admin-user-dto';

import { Select2OptionData, Options } from 'select2';

@Component({
  selector: 'kt-form-categorias',
  templateUrl: './form-categorias.component.html',
  styleUrls: []
})
export class FormCategoriasComponent {
  // Dto usuario para busquedas
  adminUser: AdminUserDTO = new AdminUserDTO;
  
  // Select2
  selectListAdmins: Array<Select2OptionData>;
  options: Options;
  
  // Dto Categoria
  formModelCategoria: AdminCategoriaDto = new AdminCategoriaDto;

  // Cargadores Información
  conditionalLoader: boolean;

  @Input() modificarDatos: AdminCategoriaDto;
  @Output() actionModificarCategoria  = new EventEmitter();
  
  // Modales
  contentToShow: number = -1;
  configModal: {} = { status: -1 };

  statusMostrar: boolean = true;
  
  constructor(private http: HttpService,
      private cdr: ChangeDetectorRef,
      public _usuariosService:UsuariosService,
      private uiService: UIService) {
    
    this.selectListAdmins = [];
    this.options = {
			language: 'es',
			closeOnSelect: true,
     	dropdownCssClass: "select2-dropdown-forms"
    }

    this._usuariosService.getUsuariosSearch( this.adminUser, 20, 1 ).subscribe( (res: Response) => {
      if ( res.json().length > 0 ) {
        this.crearSelectUsuarios( res.json() );
      }
    });
  }

  ngOnInit() {
    if( this.modificarDatos && this.modificarDatos.registryId ){
      this.statusMostrar = false;
      this.formModelCategoria = this.modificarDatos;
    }
  }

  crearSelectUsuarios( usuarios ){
    for (let index = 0; index < usuarios.length; index++) {
      usuarios[index]['id'] = usuarios[index]['username'];
      usuarios[index]['text'] = usuarios[index]['username'];
      if( index === usuarios.length - 1 ){
        this.selectListAdmins = usuarios;
      }
    }
  }
  
  openModalRegistryCreado(){
    this.contentToShow = 1;
    let nombreProfile:string = '';
    if( this.modificarDatos && this.modificarDatos.registryId ){
      nombreProfile = `<b>${this.formModelCategoria.registryId}</b>`;
    }

    this.configModal = {
      status: 1,
      icono: 'smart-icon-categorias',
      titulo: 'Operador',
      textos: [
                `Se guardó exitosamente el Operador ${nombreProfile}`
              ],
      botones: [
              {
                label: 'Aceptar',
                class: '',
                accion: !this.modificarDatos ? 'creacionCategoria' : 'modificarCategoria'
              }
      ]
    }
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShow = -1;
      this.configModal = { status: -1 }
    }, 300);
  }

  guardarRegistry(){
    let formCategoria = this.formModelCategoria.toJS();
    delete formCategoria['id'];
    if( formCategoria.registryId && formCategoria.name && formCategoria.registryLabel ){
      this.conditionalLoader = true;
      let rutaRegistry = environment.endpoints.adminRegistryCreate;
      if( this.modificarDatos && this.modificarDatos.registryId ){
        rutaRegistry = environment.endpoints.adminRegistryEdit;
      }
      console.log("AAAAA"+formCategoria)
      this.http.ejecutarServicioPost( rutaRegistry, formCategoria ).subscribe( (res: Response) => {
        this.conditionalLoader = false;
        if ( res.ok === true ) {
          // Realizar algo con el ID
          let resPost = res.json();
          
          // Mostrar Modal
          this.openModalRegistryCreado();
  
        } else {
          // TODO :: MANEJAR EXCEPCION
          this.uiService.showMessage( res.json()['message'] );
        }
      }, (error: Response) => {
        this.uiService.showMessage( error );
      });
    }
  }
  
  cerrarModal(){
    this.actionModificarCategoria.emit('cerrar');
  }

  changeStatusModal(event){
    if( this.modificarDatos && this.modificarDatos.registryId ){
      // Modificar
      this.actionModificarCategoria.emit('cerrar');
    }else{
      // Crear
      this.formModelCategoria = new AdminCategoriaDto;
    }
  }

}