import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges
} from '@angular/core';

import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { Response } from '@angular/http';
import { UIService } from '@services/UI.service';
import { NgbDateNativeAdapter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

class ImageSnippet {
	pending: boolean = false;
	status: string = 'init';

	constructor(public src: string, public file: File) {}
}

@Component({
	selector: 'kt-conductor-ingreso',
	templateUrl: './conductor-ingreso.component.html',
	styleUrls: ['./conductor-ingreso.component.scss'],
	providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class ConductorIngresoComponent implements OnInit, OnChanges {
	selectedFile: ImageSnippet;
	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {
		this.selectFitroTipoConcesionario = [];
		this.selectFitroTipoVehiculo = [];
	}
	public options: Options;
	loading: boolean = false;
	public selectFitro1: Array<Select2OptionData>;
	public selectFitroProfiles: Array<Select2OptionData>;
	public selectFitroTipoConcesionario: Array<Select2OptionData>;
	public selectFitroTipoVehiculo: Array<Select2OptionData>;

	@Input() model: any;
	@Output() modelChange = new EventEmitter();

	ngOnChanges(changes: SimpleChanges) {
		if (changes.model.currentValue) {
			this.cdr.markForCheck()
		}
	}

	private onSuccess() {
		this.selectedFile.pending = false;
		this.selectedFile.status = 'ok';
	}

	private onError() {
		this.selectedFile.pending = false;
		this.selectedFile.status = 'fail';
		this.selectedFile.src = '';
	}

	ngOnInit() {
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminParamConcesionarios
			)
			.subscribe((res: Response) => {
				let data = res.json();
				this.selectFitroTipoConcesionario = data.map(i => {
					return { id: i.id, text: i.id };
				});
			});

			this.http
			.ejecutarServicioGet(
				environment.endpoints.adminParamTipoVehiculos
			)
			.subscribe((res: Response) => {
				let data = res.json().answerGroups;
				this.selectFitroTipoVehiculo = data.map(i => {
					return { id: i[51], text: i[52] };
				});
			});

		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
	}

	processFile(imageInput: any) {
		const file: File = imageInput.files[0];
		const reader = new FileReader();
		reader.addEventListener('load', (event: any) => {
			this.selectedFile = new ImageSnippet(event.target.result, file);
			this.selectedFile.pending = true;
			const data = new FormData();
			data.append('file', this.selectedFile.file);
			this.http
				.multipartPost(environment.endpoints.filesUpload, data)
				.subscribe(
					res => {
						this.model.picture = res.text();
						this.cdr.markForCheck();
						this.onSuccess();
					},
					err => {
						this.onError();
					}
				);
		});

		reader.readAsDataURL(file);
	}

	updateModel() {
		this.modelChange.emit(this.model);
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	/**
	 * Valida que la fecha de vinculación sea menor a la fecha actual.
	 */
	validateLinkingDate() {
		let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
		+ (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
		setTimeout(()=>{
			if (this.model.linkingDate !== null && this.model.linkingDate !== undefined && this.model.linkingDate !== "") {
				let fechaVinculacion = new  Date(this.model.linkingDate);
				let fechaVinculacionFormato = fechaVinculacion.getFullYear() + "" + ((fechaVinculacion.getMonth()+1) < 10 ? '0' + (fechaVinculacion.getMonth()+1) : (fechaVinculacion.getMonth()+1)) + "" 
				+ (fechaVinculacion.getDate() < 10 ? '0' + fechaVinculacion.getDate() : fechaVinculacion.getDate());
				if(Number(fechaVinculacionFormato) > Number(fechaActualFormato)){
					this.uiService.showMessage("Seleccione una fecha menor o igual a la fecha de hoy.")
					this.model.linkingDate = null;
					this.cdr.markForCheck();
				}
			}
		})
	}
}
