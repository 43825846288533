export class AdminIdling {
    
    tramo: string;
    troncal: string;


    constructor(data?: any) {
        if (data !== undefined) {
            this.tramo = data['tramo'] !== undefined ? data['tramo'] : null;
            this.troncal = data['troncal'] !== undefined ? data['troncal'] : null;
        }
    }

    static fromJS(data: any): AdminIdling {
        return new AdminIdling(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['tramo'] = this.tramo !== undefined ? this.tramo : null;
        data['troncal'] = this.troncal !== undefined ? this.troncal : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['tramo'] = this.tramo !== undefined ? this.tramo : '';
        data['troncal'] = this.troncal !== undefined ? this.troncal : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminIdling(JSON.parse(json));
    }

}
