import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AdminCategoriaDto } from '../../../../shared/dto/admin-categoria-dto';

@Component({
  selector: 'kt-form-categorias-informacion',
  templateUrl: './form-categorias-informacion.component.html',
  styleUrls: []
})
export class FormCategoriasInformacionComponent {
  // Dto Categoria
  @Input() verDatos: AdminCategoriaDto;
  @Output() actionVerCategoria  = new EventEmitter();

  constructor() { }

  cerrarModal(){
    this.actionVerCategoria.emit('cerrar');
  }
}