import { Response } from '@angular/http';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Select2OptionData, Options } from 'select2';
import { UIService } from './../../../../trans/_services/UI.service';
import { environment } from '@environments/environment';
import { HttpService } from '../../../../shared/services/http.service';

@Component({
	selector: 'kt-analisis-predectivo',
	templateUrl: './analisis-predectivo.component.html',
	styleUrls: ['./analisis-predectivo.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class AnalisisPredectivoComponent implements OnInit {
	configTable: any;
	sthsQSTime = { hour: 13, minute: 30 };

	// Paginador
	paginador: any = {
		count: 10,
		page: 1
	};

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1, data: [] };

	dataTable: Array<any>;

	public selectSensor: Array<Select2OptionData>;
	public options: Options;

	// Modales
	contentToShowModal: number = -1;
	configModal: {} = { status: -1 };

	showModal: boolean = false;
	conditionalLoader: boolean;

	// Id Eliminar
	eliminarId: any;

	// Modal Modificar
	contentToModificar: number = -1;
	idAnalisis: any;
	selectedAnalisis: any = {
		17: '',
		18: '',
		19: ''
	};

	constructor(
		private cdr: ChangeDetectorRef,
		private http: HttpService,
		private uiService: UIService
	) {}

	ngOnInit() {
		this.obtenerDatos();
		this.selectSensor = [];

		let field = document.getElementById('umbral');
		field.addEventListener('keydown', function(evt) {
		  var inputValue = (<HTMLInputElement>document.getElementById('umbral')).value;
		  if (inputValue=="0") { 
			(<HTMLInputElement>document.getElementById('umbral')).value = ""; 
		  }
		});
		field = document.getElementById('tiempo');
		field.addEventListener('keydown', function(evt) {
		  var inputValue = (<HTMLInputElement>document.getElementById('tiempo')).value;
		  if (inputValue=="0") { 
			(<HTMLInputElement>document.getElementById('tiempo')).value = ""; 
		  }
		});	
	}

	obtenerDatos() {
		this.conditionalLoader = true;

		this.http
			.ejecutarServicioGet(
				environment.endpoints.paramPredictiveMaintenance,
				null
			)
			.subscribe(
				(resEstado: Response) => {
					switch (resEstado.status) {
						case 200:
						case 201:
						case 204:
							this.conditionalLoader = false;
							this.dataTable = resEstado.json().answerGroups;
							this.procesarJson(this.dataTable);

							let questions = resEstado.json().questions;
							let select = questions.find(
								i => Number(i.id) === 17
							);
							if (select) {
								this.selectSensor = select.possibleAnswers.map(
									i => {
										return {
											id: i.answerValue,
											text: i.answer
										};
									}
								);
							}
							this.cdr.markForCheck();
							break;
						default:
							this.showError(resEstado);
							break;
					}
				},
				(error: Response) => {
					this.showError(error);
				}
			);
	}

	/**
	 *
	 * @param data
	 */
	procesarJson(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				'answerGroup' in data[index]
					? data[index]['answerGroup'].toString()
					: '',
				'17' in data[index] ? data[index]['17'].toString() : '',
				'18' in data[index] ? data[index]['18'].toString() : '',
				'19' in data[index] ? data[index]['19'].toString() : '',
				''
			]);

			if (index === data.length - 1) {
				this.armarTabla(dataArray);
			}
		}

		if (!data.length) {
			this.armarTabla(dataArray);
		}
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: ['ID', 'Sensor', 'Umbral', 'Tiempo', 'Acciones'],
			typeColumn: [
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'eliminar'] }
			],
			showSearch: false,
			searchColumn: [1],
			searchTitle: 'Buscar Categoría',
			hideColumns: [0],
			showPag: false,
			leftsColumns: [1],
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data,
			paginador: this.paginador
		};
		this.cdr.markForCheck();
	}

	editarAnalisis(id) {
		let element = this.dataTable.find(itm => itm.answerGroup === id);
		console.log(element);
		this.selectedAnalisis = {...element};
		this.idAnalisis = id;
		this.showModal = true;
		this.cdr.markForCheck();

		this.contentToModificar = 15;
	}

	/**
	 * Actualizar registro
	 */
	updateAnalisis() {
		if (
			this.selectedAnalisis[17] === '' ||
			this.selectedAnalisis[18] === '' ||
			this.selectedAnalisis[19] === '' ||
			parseInt(this.selectedAnalisis[18], 10) <= 0 ||
			parseInt(this.selectedAnalisis[19], 10) <= 0
		) {
			this.uiService.showMessage(
				'Los campos no deben estar vacios o con valores menores o iguales a 0!'
			);
		} else {
			this.http
				.ejecutarServicioPut(
					environment.endpoints.paramPredictiveMaintenanceUpdate +
						this.selectedAnalisis.answerGroup,
					{
						answer: {
							17: this.selectedAnalisis[17],
							18: this.selectedAnalisis[18],
							19: this.selectedAnalisis[19]
						}
					}
				)
				.subscribe((res: Response) => {
					if (res.status === 200) {
						this.uiService.showMessage('Parámetros actualizados');
						this.showModal = false;
						this.obtenerDatos();
					} else {
						this.showError(res);
					}
				});
		}
	}

	/**
	 *
	 * @param id
	 */
	eliminarAnalisis(id) {
		// Guardar en variable de Eliminar Global
		this.eliminarId = id;
		this.contentToShowModal = 1;

		this.configModal = {
			status: 1,
			icono: 'smart-icon-eliminar',
			titulo: '¿Estas seguro que desea eliminar los datos?',
			textos: [
				'Los datos en la fila seleccionada serán eliminados permanentemente'
			],
			botones: [
				{
					label: 'Cancelar',
					class: 'sths-btn-cancel',
					accion: 'cancelarAnalisis'
				},
				{
					label: 'Eliminar',
					class: '',
					accion: 'eliminarAnalisis'
				}
			]
		};
		this.cdr.markForCheck();
	}

	eventGuardar(event) {
		this.http
			.ejecutarServicioPost(
				environment.endpoints.paramPredictiveMaintenanceSave,
				{ answer: event }
			)
			.subscribe((res: Response) => {
				this.obtenerDatos();
			});
		this.obtenerDatos();
	}

	statusModal(event) {
		this.showModal = false;
		this.selectedAnalisis = {};
	}

	changeStatusModal(event) {
		this.contentToShowModal = -1;
		this.configModal = { status: -1 };
		this.cdr.markForCheck();

		switch (event) {
			case 'eliminarAnalisis':
				// Accion eliminar Analisis
				// Webservice eliminar this.eliminarId.
				this.http
					.ejecutarServicioDelete(
						environment.endpoints.paramPredictiveMaintenanceDelete +
							this.eliminarId,
						null
					)
					.subscribe(
						(res: Response) => {
							switch (res.status) {
								case 200:
									this.obtenerDatos();
									this.cdr.markForCheck();
									setTimeout(() => {
										this.contentToShowModal = -1;
										this.configModal = { status: -1 };
									}, 300);

									break;
								default:
									this.showError(res);
									break;
							}
						},
						(error: Response) => {
							this.showError(error);
						}
					);

				// Refrescar Datos
				this.eliminarId = '';
				this.obtenerDatos();
				break;

			default:
				break;
		}
	}

	changeStatusEdit(event) {
		console.log('entró edit');
		this.contentToModificar = -1;
		this.cdr.markForCheck();
		this.idAnalisis = '';
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}
}
