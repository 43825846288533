import {
	Component,
	OnInit,
	ChangeDetectorRef,
	Input,
	Output,
	EventEmitter
} from '@angular/core';

import { Response } from '@angular/http';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from './../../../../../../environments/environment';
import { PermissionDTO } from '../../../../shared/dto/admin-permission-dto';
import { AdminProfileDTO } from '../../../../shared/dto/admin-profile-dto';
import { UIService } from '../../../_services/UI.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';


@Component({
	selector: 'kt-perfil-crear',
	templateUrl: './perfil-crear.component.html',
	styleUrls: ['./perfil-crear.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class PerfilCrearComponent implements OnInit {
	rutasMenu: object;
	permisos: {}[];
	configDatos: {};
	listadoRoles: PermissionDTO[];
	maxCharacters: number = 0;
	maxCharactersNombre: number = 0;
	formModelProfile: AdminProfileDTO = new AdminProfileDTO();
	profileForm: FormGroup;


	@Input() modificarDatos: AdminProfileDTO;

	@Output() actionModificarProfile = new EventEmitter();

	// Modales
	contentToShow: number = -1;
	configModal: {} = { status: -1 };

	loading: boolean = false;

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {}

	ngOnInit() {
		this.profileForm = new FormGroup({
			name: new FormControl('', [Validators.required]),
			description: new FormControl('', [Validators.required]),
			// userRoles: new FormArray([],[Validators.required])
		});
		// Si trae parametros para EDITAR
		if (this.modificarDatos) {
			this.formModelProfile = this.modificarDatos;
		}

		let ruta = `${environment.endpoints.adminUserRoleSearch}?id=&name=&description=&count=200&page=1`;
		this.loading = true;
		this.http.ejecutarServicioGet(ruta).subscribe(
			(res: Response) => {
				switch (res.status) {
					case 200:
					case 201:
					case 204:
						this.listadoRoles = res.json();

						if (this.modificarDatos) {
							this.http
								.ejecutarServicioGet(
									`${environment.endpoints.adminUserProfile}${this.modificarDatos.id}/roles`
								)
								.subscribe(
									(resRoles: Response) => {
										switch (resRoles.status) {
											case 200:
											case 201:
											case 204:
												let roles = resRoles.json();
												this.formModelProfile.userRoles = roles
													.map(function(e) {
														return e.id;
													})
													.join(',');

												for (
													let index = 0;
													index <
													this.listadoRoles.length;
													index++
												) {
													if (
														roles
															.map(function(e) {
																return e.id;
															})
															.indexOf(
																this
																	.listadoRoles[
																	index
																]['id']
															) !== -1
													) {
														this.listadoRoles[
															index
														]['active'] = true;
													} else {
														this.listadoRoles[
															index
														]['active'] = false;
													}
													if (
														index ===
														this.listadoRoles
															.length -
															1
													) {
														this.cdr.markForCheck();
													}
												}

												this.loading = false;
												this.cdr.markForCheck();
												break;
											case 401:
												// nothing
												break;
											default:
												this.showError(resRoles);
												break;
										}
									},
									(error: Response) => {
										this.showError(error);
									}
								); // END INNER GET
						} else {
							this.loading = false;
							this.resetRoles(this.listadoRoles, 'iniciar');
						}
						this.cdr.markForCheck();
						break;
					case 401:
						// nothing
						break;
					default:
						this.showError(res);
						break;
				}
			},
			(error: Response) => {
				this.showError(error);
			}
		); // END GET

		this.rutasMenu = [
			{
				label: 'Buscar Perfil',
				icon: 'smart-icon-search',
				ruta: `${environment.folders.routeAdmin}perfil/buscar`
			},
			{
				label: 'Crear Perfil',
				icon: 'smart-icon-usuario',
				ruta: `${environment.folders.routeAdmin}perfil/crear`
			}
		];

		// Select List
		this.configDatos = {
			icono: '',
			titulo: 'Detalles de Perfil',
			parametro1: 'Roles Disponibles',
			parametro2: 'Roles Asignados al Perfil'
		};
	}

	get f() {
		if (this.profileForm) {
			return this.profileForm.controls;
		} else {
			return null;
		}
  }

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else if (typeof response === 'string') {
			this.uiService.showMessage(response);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	resetRoles(roles, status) {
		if (status === 'iniciar') {
			for (let index = 0; index < roles.length; index++) {
				roles[index]['checked'] = false;
				roles[index]['active'] = false;
				if (index === roles[index].length - 1) {
					this.listadoRoles = roles;
					this.cdr.markForCheck();
				}
			}
		}
	}

	changeListProfile(event) {
		let profilesArray = [];
		this.formModelProfile.userRoles = '';
		for (let index = 0; index < event.length; index++) {
			this.formModelProfile.userRoles = `${event[index].id}, `;
			profilesArray.push(event[index].id);
			if (index === event.length - 1) {
				this.formModelProfile.userRoles = profilesArray.join(',');
			}
		}
	}

	openModalProfileCreado() {
		this.contentToShow = 1;
		let nombreProfile: string = '';
		if (this.modificarDatos) {
			nombreProfile = `<b>${this.formModelProfile.name}</b>`;
		}

		this.configModal = {
			status: 1,
			icono: 'smart-icon-usuario',
			titulo: 'Perfil',
			textos: [
				`Se guardó exitosamente la configuración del Perfil ${nombreProfile}`
			],
			botones: [
				{
					label: 'Aceptar',
					class: '',
					accion: !this.modificarDatos
						? 'creacionPerfil'
						: 'modificarPerfil'
				}
			]
		};
		this.cdr.markForCheck();
		setTimeout(() => {
			this.contentToShow = -1;
			this.configModal = { status: -1 };
		}, 300);
	}

	crearProfile() {
		if (this.formModelProfile.userRoles) {
			this.loading = true;
			let formProfile = this.formModelProfile.toJS();
			// ..this.profileForm.value
			formProfile = {...formProfile };
			delete formProfile['id'];
			this.http
				.ejecutarServicioPost(
					environment.endpoints.adminUserProfile,
					formProfile
				)
				.subscribe(
					(res: Response) => {
						switch (res.status) {
							case 200:
							case 201:
							case 204:
								// Mostrar Modal
								this.openModalProfileCreado();
								this.loading = false;
								break;
							case 401:
								// nothing
								break;
							default:
								this.showError(res);
								break;
						}
					},
					(error: Response) => {
						this.showError(error);
					}
				); // END POST
		} else {
			this.showError('Seleccione por lo menos un rol');
		}
	}

	cerrarModal() {
		this.actionModificarProfile.emit('cerrar');
	}
	modificarProfile() {
		this.loading = true;
		let formProfile = this.formModelProfile.toJS();
		let ruta = `${environment.endpoints.adminUserProfile}${formProfile.id}`;
		this.http.ejecutarServicioPut(ruta, formProfile).subscribe(
			(res: Response) => {
				switch (res.status) {
					case 200:
					case 201:
					case 204:
						// Mostrar Modal
						this.openModalProfileCreado();
						this.loading = false;
						break;
					case 401:
						// nothing
						break;
					default:
						this.showError(res);
						break;
				}
			},
			(error: Response) => {
				this.showError(error);
			}
		); // END POST
	}

	changeStatusModal(event) {
		if (this.modificarDatos) {
			// Modificar
			this.actionModificarProfile.emit('cerrar');
			this.maxCharacters = 0;
		} else {
			// Crear
			this.formModelProfile = new AdminProfileDTO();
			this.maxCharacters = 0;
			this.resetRoles(this.listadoRoles, 'iniciar');
		}
	}

	omit_special_char(event) {
		let k;
		k = event.charCode; //         k = event.keyCode;  (Both can be used)
		/*
		return (
			(k > 64 && k < 91) ||
			(k > 96 && k < 123) ||
			k === 8 ||
			k === 32 ||
			(k >= 48 && k <= 57) ||
			k === 241 ||
			k === 209
		);
		*/
	}

	cantCaracteres(event, max) {
		this.maxCharacters = max - event.length;
	}
}
