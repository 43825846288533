import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kt-menu-dispositivos',
  templateUrl: './menu-dispositivos.component.html',
  styleUrls: ['./menu-dispositivos.component.scss']
})
export class MenuDispositivosComponent implements OnInit {
  @Input() rutas: Object;
  constructor() {
  }

  ngOnInit() {
  }

}
