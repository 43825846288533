import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from "../../../../trans/administrador/_services/util.service";

@Component({
  selector: 'kt-Tramo-Edit-PopUp',
  templateUrl: './tramos-edit-popup.component.html',
  styleUrls: []
})
export class TramoEditPopUpComponent {
  // Dto Categoria
  @Input() verDatos: any;
  //@Output() close: EventEmitter<any> = new EventEmitter();
  @Output() actionEditarParametro: EventEmitter<any> = new EventEmitter();

  constructor() { }

  cerrarModal(){
    UtilService.editParametroTroncal.emit(this.verDatos);
    this.actionEditarParametro.emit('cerrar');
    //this.close.emit('close');
  }

  
  validateRange(e) {
    // var tecla = (document.all) ? e.keyCode : e.which;
    // if (tecla == 8 || tecla == 46) {
    //   return true;
    // }
    // if (tecla == 101) {
    //   return false;
    // }
    // var patron = /[A-Za-z0-9]/;
    // var tecla_final = String.fromCharCode(tecla);
    // return patron.test(tecla_final);

    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9-.,]+$/)) {
      if (charStr != ".") {
        e.preventDefault();
      }
    }
  }
}