import { LayoutUtilsService, MessageType } from './../../../core/_base/crud/utils/layout-utils.service';
import { MatSnackBarRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UIService {

  private _actualDialog: MatSnackBarRef<any>;
  
  private _collapseLeftMenu: Boolean;
  public collapseLeftMenu = new BehaviorSubject<{}>(this._collapseLeftMenu);
  public collapseLeftMenu$ = this.collapseLeftMenu.asObservable();
  
  private _selectedTopMenu: '';
  private selectedTopMenu = new BehaviorSubject<{}>(this._selectedTopMenu);
  public selectedTopMenu$ = this.selectedTopMenu.asObservable();

  private _selectAccordionStats: number = 0;
  private selectAccordionStats = new BehaviorSubject<{}>(this._selectAccordionStats);
  public selectAccordionStats$ = this.selectAccordionStats.asObservable();

  constructor(private layoutUtilsService: LayoutUtilsService) {
  }

  setActualSelectedTopMenu(selectedTopMenu) {
    this._selectedTopMenu = selectedTopMenu;
    this.selectedTopMenu.next( this._selectedTopMenu );
  }

  getActualSelectedTopMenu() {
    return this._selectedTopMenu;
  }

  setActualSelectedAccordionStats(selectedAccordionStats) {
    this._selectAccordionStats = selectedAccordionStats;
    this.selectAccordionStats.next(this._selectAccordionStats);
  }

  getActualSelectedAccordionStats() {
    return this._selectAccordionStats;
  }

  showMessage( message , position?, duration?, messageType?, showCancelButton = true, showUndoButton = false, durationUndoButton = 0  ) {
    if ( messageType === undefined ) { messageType = MessageType.Update; }
    if ( this._actualDialog ) { this._actualDialog.dismiss(); }
    this._actualDialog = this.layoutUtilsService.showActionNotification(message, messageType, duration, true, showUndoButton, durationUndoButton, position);
    return this._actualDialog;
  }

  downloadFile(data: any, type: string, name: string) {
    let docType = '';
    switch (type) {
      case 'pem':
        docType = 'application/x-pem-file';
        break;
      case 'csv':
        docType = 'text/csv';
        break;
      case 'xls':
        docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'xlsx':
        docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'pdf':
        docType = 'application/pdf';
        break;
      case 'zip':
        docType = 'application/zip';
        break;  
      case 'p12':
        docType = 'application/x-pkcs12';
        //cambios
        break;
      default:
        docType = 'text/plain';
        break;
    }
    const blob = new Blob([data], { type: docType });
    const url = window.URL.createObjectURL(blob);
    let linkDownload = document.createElement('a');
    linkDownload.download = name;
    linkDownload.href = url;
    linkDownload.click();
    linkDownload.remove();
  }

  downloadFileBase64(data: any, type: string, name: string) {
    let docType = '';
    switch (type) {
      case 'pem':
        docType = 'application/x-pem-file';
        break;
      case 'csv':
        docType = 'text/csv';
        break;
      case 'xls':
        docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'xlsx':
        docType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'pdf':
        docType = 'application/pdf';
        break;
      case 'zip':
        docType = 'application/zip';
        break;
      default:
        docType = 'text/plain';
        break;
    }

    let element = document.createElement('a');
    element.setAttribute('href', 'data:' + docType + ';base64,' + encodeURIComponent(data));
    element.setAttribute('download', name + '.' + (type === 'xls' ? 'xlsx' : type) );
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

  }

  changeFormatFecha( fecha, hora ){
    let day, month, year, hour, minutes, seconds;

    day = (fecha['day'] < 10 ) ? `0${fecha['day']}` : fecha['day'];
    month = (fecha['month'] < 10 ) ? `0${fecha['month']}` : fecha['month'];
    year = fecha['year'];

    hour = (hora['hour'] < 10 ) ? `0${hora['hour']}` : hora['hour'];
    minutes = (hora['minute'] < 10 ) ? `0${hora['minute']}` : hora['minute'];
    seconds = (hora['second'] < 10 ) ? `0${hora['second']}` : hora['second'];

    return `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`;
  }

}
