import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Select2OptionData, Options } from 'select2';
import { AdminAjustarCasoDto } from '../../../../shared/dto/admin-ajustar-caso-dto';
import { HttpService } from '../../../../shared/services/http.service';
import { UIService } from './../../../../trans/_services/UI.service';
import { environment } from '../../../../../../environments/environment';
import { Response } from '@angular/http';
import { AdminUserDTO } from '../../../../shared/dto/admin-user-dto';
import { AuthService } from '../../../../../core/auth/_services/auth.service';

@Component({
  selector: 'kt-ajustar-casos',
  templateUrl: './ajustar-casos.component.html',
  styleUrls: ['./ajustar-casos.component.scss'],
  host: {'class': 'sths-tb-element'}
})
export class AjustarCasosComponent implements OnInit {
  public selectRoles: Array<Select2OptionData>;
  public selectTroncal: Array<Select2OptionData>;
  public selectTypes: Array<Select2OptionData>;
  public selectEstados: Array<Select2OptionData>;

  configTable: Object;
  public options: Options;

  // Paginador
  paginador: Object = {
    count: 30,
    page: 1,
    total: 0,
    maxPages: 5
  };

  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  conditionalLoader: boolean;

  // Id Eliminar
  eliminarId: any;

  // Asignar Caso
  itemAsignar: any;

  // Modal Modificar
  contentToModificar: number = -1;
  casoManual: any;
  casoAutomatico: any;

  isCode: boolean;

  formModelGestion: AdminAjustarCasoDto = new AdminAjustarCasoDto;

  // Filtros, Columnas, Actions
  actions: {} = {
    reasignarCaso: '',
  };

  columnsHeader: {} = {
    duenoCaso: '',
    troncal: '',
    estadoCaso: '',
    tipoCaso: '',
    cantidad: '',
    accion: '',
  };

  filters: {} = {
    rol: '',
    troncal: '',
    estadoCaso: '',
    tipoCaso: '',
  };

  // Usuario
  usuario: {} = {
    username: '',
    perfil: ''
  };

  usuariosPerfil: any;

  formModelUser: AdminUserDTO = new AdminUserDTO;

  constructor(auth: AuthService,
              private http: HttpService,
              private uiService: UIService,
              private cdr: ChangeDetectorRef) {

    this.usuario['username'] = auth.getUser().username;
    this.formModelUser.username = this.usuario['username'];
  }

  ngOnInit() {
    this.buscarCasos();

    // Roles
    let ruta = `${environment.endpoints.adminUsersByRoleSearch}?username=&name=&lastName=&email=&entity=&available=&permissionList=118`;
    this.http.ejecutarServicioGet(ruta).subscribe((res: Response) => {

      switch (res.status) {
        case 200:
        case 201:
        case 204:
          let roles = res.json();
          for (let indexSta = 0; indexSta < roles.length; indexSta++) {
            roles[indexSta]['text'] = roles[indexSta]['username'];
            roles[indexSta]['id'] = roles[indexSta]['username'];
            if ( indexSta === roles.length - 1 ) {
              roles.unshift( {id:"-Sel", text: "Seleccionar Usuario"} );
              this.selectRoles = roles;
              break;
            }
          }
          this.cdr.markForCheck();
          break;
        case 401:
          // nothing
          break;
        default:
          this.showError(res);
          break;
      }
    }, (error: Response) => {
      this.showError(error);
    });// END GET

    // Troncal
    this.http.ejecutarServicioGet( environment.endpoints.adminTroncal, null ).subscribe( (resTroncal: Response) => {
      switch (resTroncal.status) {
        case 200:
        case 201:
        case 204:
          let troncal = resTroncal.json();
          for (let indexSta = 0; indexSta < troncal.length; indexSta++) {
            troncal[indexSta]['text'] = troncal[indexSta]['answer'];
            if ( indexSta === troncal.length - 1 ) {
              troncal.unshift( {id:"-Sel", text: "Seleccionar Troncal"} );
              this.selectTroncal = troncal;
              break;
            }
          }

          this.cdr.markForCheck();
          break;
        default:
            this.showError( resTroncal );
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    })

    // Estado de Casos
    this.http.ejecutarServicioGet( environment.endpoints.adminCasesStatus, null ).subscribe( (resEstado: Response) => {
      switch (resEstado.status) {
        case 200:
        case 201:
        case 204:
          let estados = resEstado.json();
          for (let indexSta = 0; indexSta < estados.length; indexSta++) {
            estados[indexSta]['text'] = estados[indexSta]['description'];
            if ( indexSta === estados.length - 1 ) {
              estados.unshift( {id:"-Sel", text: "Seleccionar Estado"} );
              this.selectEstados = estados;
              break;
            }
          }

          this.cdr.markForCheck();
          break;
        default:
            this.showError( resEstado );
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    })

    // Tipo de Casos
    this.http.ejecutarServicioGet( environment.endpoints.adminCasesTypes, null ).subscribe( (resCases: Response) => {
      switch (resCases.status) {
        case 200:
        case 201:
        case 204:
          let typesCase = resCases.json();
          for (let indexType = 0; indexType < typesCase.length; indexType++) {
            typesCase[indexType]['text'] = typesCase[indexType]['answer'];
            typesCase[indexType]['id'] = typesCase[indexType]['answer'];
            if ( indexType === typesCase.length - 1 ) {
              typesCase.unshift( {id:"-Sel", text: "Seleccionar Tipo de Caso"} );
              this.selectTypes = typesCase;
              break;
            }
          }
          this.cdr.markForCheck();
          break;
        default:
            this.showError( resCases );
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    })
    

    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: "select2-dropdown-forms"
    };
  }

  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage( response.json()['message'] );
    } else {
      this.uiService.showMessage( 'Error del servidor' );
    }
    this.cdr.markForCheck();
  }
  
  buscarCasosBtn(){
    this.paginador['page'] = 1;
    this.buscarCasos();  
  }

  buscarCasos(){
    let formGestion = this.formModelGestion.toStrings();
    let searchFields = ``;

    searchFields += ( formGestion['estadoCaso'] == "-Sel" || formGestion['estadoCaso'] == null ) ?  `&caseStatusId=` : `&caseStatusId=${formGestion['estadoCaso']}`;
    searchFields += ( formGestion['tipoCaso'] == "-Sel" || formGestion['tipoCaso'] == null ) ?  `&alertType=` : `&alertType=${formGestion['tipoCaso']}`;
    searchFields += ( formGestion['troncal'] == "-Sel" || formGestion['troncal'] == null ) ?  `&trunk=` : `&trunk=${formGestion['troncal']}`;
    searchFields += ( formGestion['rol'] == "-Sel" || formGestion['rol'] == null ) ?  `&profile=` : `&profile=${formGestion['rol']}`;

    let count = this.paginador['count'];
    let page = this.paginador['page'];

    let ruta = '';
    let rutaCount = '';

    ruta = `${environment.endpoints.adminCasesAdjuster}?count=${count}&page=${page}${searchFields}`;
    rutaCount = `${environment.endpoints.adminCasesAdjusterCount}?count=${count}&page=${page}${searchFields}`;
    
    this.conditionalLoader = true;
    this.http.ejecutarServicioGet( ruta, null ).subscribe( (res: Response) => {
      switch (res.status) {
         case 200:
         case 201:
            this.http.ejecutarServicioGet( rutaCount, null ).subscribe( (response: Response) => {
              switch (response.status) {
                case 200:
                case 201:
                case 204:
                  if ( res.json() && res.json().length > 0 ) {
                    this.paginador['total'] = response.json();
                    // console.log( res.json() )
                    this.procesarJson( res.json() );
                  } else {
                    // console.log('************* no encontro nada')
                    this.paginador['count'] = 30;
                    this.paginador['page'] = 1;
                    this.paginador['total'] = 0;
                    this.paginador['maxPages'] = 5;
                    this.procesarJson( [] );
                  }
                  this.cdr.markForCheck();
                  break;
                case 401:
                  break;
                default:
                  this.showError( response );
                  break;
              }
            }, (error: Response) => {
              this.showError( error );
            });
 
           this.cdr.markForCheck();
           break;
           case 204:
              // console.log('************* no encontro nada')
              this.paginador['count'] = 30;
              this.paginador['page'] = 1;
              this.paginador['total'] = 0;
              this.paginador['maxPages'] = 5;
             this.procesarJson( [] );
             this.cdr.markForCheck();
           break;
 
         default:
              // console.log('************* no encontro nada')
              this.paginador['count'] = 30;
              this.paginador['page'] = 1;
              this.paginador['total'] = 0;
              this.paginador['maxPages'] = 5;
             this.procesarJson( [] );
             this.cdr.markForCheck();
             this.showError( res );
           break;
       }
       this.conditionalLoader = false;
     }, (error : Response) => {
       this.conditionalLoader = false;
      //  console.log('************* no encontro nada')
        this.paginador['count'] = 30;
        this.paginador['page'] = 1;
        this.paginador['total'] = 0;
        this.paginador['maxPages'] = 5;
       this.procesarJson( [] );
       this.cdr.markForCheck();
       this.showError( error );
     });

    
    //   let data = [
    //     { id: 1, dueno: 'Usuario 1', troncal: 'Americas', estado: 'Creado', tipo: 'Boton Panico', cantidad: '100' },
    //     { id: 2, dueno: 'Usuario 2', troncal: 'Americas', estado: 'Creado', tipo: 'Boton Panico', cantidad: '100' },
    //     { id: 3, dueno: 'Usuario 3', troncal: 'Americas', estado: 'Creado', tipo: 'Boton Panico', cantidad: '100' }
    //   ]
  }

  procesarJson( data ) {
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['user'].toString(),
        data[index]['user'].toString(),
        (data[index]['trunk']) ? data[index]['trunk'].toString() : '',
        (data[index]['caseStatus']) ? data[index]['caseStatus'].toString() : '',
        (data[index]['alertType']) ? data[index]['alertType'].toString() : '',
        data[index]['totalCase'].toString(),
        (data[index]['userProfile']) ? data[index]['userProfile'].toString() : '',
        ''
      ]);

      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }

    if( !data.length ){
      this.armarTabla(dataArray);
    }
  }

  armarTabla( data ) {
    // console.log(this.paginador)
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Dueño del Caso', 'Troncal', 'Estado Caso', 'Tipo Caso', 'Cantidad', 'Perfil', 'Acciones'],
      typeColumn: [,,,,,,,{ tipo: 'acciones', acciones: ['reasignarmanual','reasignarauto'] } ],
      showSearch: false,
      searchColumn: [1],
      searchTitle: 'Buscar Categoría',
      hideColumns: [0,6],
      showPag: true,
      leftsColumns: [1],
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador,
    }
    this.cdr.markForCheck();
  }

  buscadorPagina(event) {
    this.paginador['page'] = event;
    this.buscarCasos();
  }
  
  eventReasignarCasoManu( data ){
    this.contentToModificar = 26;
    this.casoManual = {
      user: data['item'][1],
      trunk: data['item'][2],
      caseStatus: data['item'][3],
      caseType: data['item'][4],
      totalCase: data['item'][5],
      userProfile: data['item'][6]
    }
    this.cdr.markForCheck();
  }
  
  // data
  reasignarAutomaticamenteC(  ){
    // Servicio Guardar
    this.conditionalLoader = true;
    let casoAutomaticoSend = {
      currentUsername: this.casoAutomatico['user'],
      usernameToAsign: null,
      casesToAsign: this.casoAutomatico['totalCase'],
      caseTypeToAsign: this.casoAutomatico['caseType']
    }
    console.log(casoAutomaticoSend.currentUsername);
    this.http.ejecutarServicioPut( environment.endpoints.adminCasesSaveAuto, casoAutomaticoSend ).subscribe( (res: Response) => {
      switch ( res.status ) {
        case 200:
        case 201:
        case 204:
          // Mostrar Modalre
          this.mostrarModal();
          this.conditionalLoader = false;
          break;
        
        default:
          this.conditionalLoader = false;
          this.showError(res);
          break;
      }
    }, (error: Response) => {
      this.conditionalLoader = false;
      this.showError( error );
    });// END POST
  }
  
  // data
  eventReasignarCasoAuto( data ){
    // Guardar en variable de Eliminar Global
    // this.itemAsignar = data;
    this.contentToShowModal = 1;
    
    this.casoAutomatico = {
      user: data['item'][1],
      caseType: data['item'][4],
      totalCase: data['item'][5]
    }

    this.configModal = {
      status: 1,
      icono: 'smart-icon-guardar',
      titulo: '¿Estas seguro de asignar los casos automaticamente?',
      textos: [],
      botones: [
              {
                label: 'Cancelar',
                class: 'sths-btn-cancel',
                accion: 'cancelarAjCasos'
              },
              {
                label: 'Sí',
                class: '',
                accion: 'reasignarAutomaticamenteC'
              }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModal = -1;
      this.configModal = { status: -1 }
    }, 300);
  }

  mostrarModal( ){
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-guardar',
      titulo: 'Los datos han sido guardados',
      textos: [
            'Los datos ingresados han sido guardados con éxito'
      ],
      botones: [
              {
                label: 'Ok',
                class: '',
                accion: 'guardarAjCasos'
              }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModal = -1;
      this.configModal = { status: -1 }
    }, 300);
  }

  eventGuardar( event ){
    console.log( 'eventGuardar', event );
    this.buscarCasos();
  }
  
  changeStatusModal( event ){
    console.log(event);
    switch (event) {
      case 'reasignarAutomaticamenteC':
        // this.itemAsignar
        this.reasignarAutomaticamenteC(  );
        break;

      default:
        this.itemAsignar = {};
        this.buscarCasos();
        break;
    }
  }

  changeStatusEdit( event ){
    this.contentToModificar = -1;
    this.buscarCasos();
  }

}
