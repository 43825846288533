export class AdminDriverDTO {

    id: number;
    fechanacimiento: string;
    nombres: string;
    primerapellido: string;
    segundoapellido: string;
    correo: string;
    fechacreacion: string;
    password: string;
    passwordSalt: string;

    constructor(data?: any) {
        if (data !== undefined) {
            this.id = data['id'] !== undefined ? data['id'] : null;
            this.fechanacimiento = data['fechanacimiento'] !== undefined ? data['fechanacimiento'] : null;
            this.nombres = data['nombres'] !== undefined ? data['nombres'] : null;
            this.primerapellido = data['primerapellido'] !== undefined ? data['primerapellido'] : null;
            this.segundoapellido = data['segundoapellido'] !== undefined ? data['segundoapellido'] : null;
            this.correo = data['correo'] !== undefined ? data['correo'] : null;
            this.fechacreacion = data['fechacreacion'] !== undefined ? data['fechacreacion'] : null;
            this.password = data['password'] !== undefined ? data['password'] : null;
            this.passwordSalt = data['passwordSalt'] !== undefined ? data['passwordSalt'] : null;
        }
    }

    static fromJS(data: any): AdminDriverDTO {
        return new AdminDriverDTO(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : null;
        data['fechanacimiento'] = this.fechanacimiento !== undefined ? this.fechanacimiento : null;
        data['nombres'] = this.nombres !== undefined ? this.nombres : null;
        data['primerapellido'] = this.primerapellido !== undefined ? this.primerapellido : null;
        data['segundoapellido'] = this.segundoapellido !== undefined ? this.segundoapellido : null;
        data['correo'] = this.correo !== undefined ? this.correo : null;
        data['fechacreacion'] = this.fechacreacion !== undefined ? this.fechacreacion : null;
        data['password'] = this.password !== undefined ? this.password : null;
        data['passwordSalt'] = this.passwordSalt !== undefined ? this.passwordSalt : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : '';
        data['fechanacimiento'] = this.fechanacimiento !== undefined ? this.fechanacimiento : '';
        data['nombres'] = this.nombres !== undefined ? this.nombres : '';
        data['primerapellido'] = this.primerapellido !== undefined ? this.primerapellido : '';
        data['segundoapellido'] = this.segundoapellido !== undefined ? this.segundoapellido : '';
        data['corrreo'] = this.correo !== undefined ? this.correo : null;
        data['fechacreacion'] = this.fechacreacion !== undefined ? this.fechacreacion : '';
        data['password'] = this.password !== undefined ? this.password : '';
        data['passwordSalt'] = this.passwordSalt !== undefined ? this.passwordSalt : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminDriverDTO(JSON.parse(json));
    }

}
