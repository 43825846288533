import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

export class AdminGestionFilterDto {
    
    codigo: string;
    fechaCreacion: NgbDateStruct;
    horaCreacion: any;
    fechaFinal: NgbDateStruct;
    horaFinal: any;
    estadoCaso: string;
    tipoCaso: string;
    concesionario: string;
    ruta: string;
    bus: string;
    clase: string;
    prioridad: string;
    rango: string;


    constructor(data?: any) {
        if (data !== undefined) {
            this.codigo = data['codigo'] !== undefined ? data['codigo'] : null;
            this.fechaCreacion = data['fechaCreacion'] !== undefined ? data['fechaCreacion'] : null;
            this.horaCreacion = data['horaCreacion'] !== undefined ? data['horaCreacion'] : null;
            this.fechaFinal = data['fechaFinal'] !== undefined ? data['fechaFinal'] : null;
            this.horaFinal = data['horaFinal'] !== undefined ? data['horaFinal'] : null;
            this.estadoCaso = data['estadoCaso'] !== undefined ? data['estadoCaso'] : null;
            this.tipoCaso = data['tipoCaso'] !== undefined ? data['tipoCaso'] : null;
            this.concesionario = data['concesionario'] !== undefined ? data['concesionario'] : null;
            this.ruta = data['ruta'] !== undefined ? data['ruta'] : null;
            this.bus = data['bus'] !== undefined ? data['bus'] : null;
            this.clase = data['clase'] !== undefined ? data['clase'] : null;
            this.prioridad = data['prioridad'] !== undefined ? data['prioridad'] : null;
            this.rango = data['rango'] !== undefined ? data['rango'] : null;
        }
    }

    static fromJS(data: any): AdminGestionFilterDto {
        return new AdminGestionFilterDto(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['codigo'] = this.codigo !== undefined ? this.codigo : null;
        data['fechaCreacion'] = this.fechaCreacion !== undefined ? this.fechaCreacion : null;
        data['horaCreacion'] = this.horaCreacion !== undefined ? this.horaCreacion : null;
        data['fechaFinal'] = this.fechaFinal !== undefined ? this.fechaFinal : null;
        data['horaFinal'] = this.horaFinal !== undefined ? this.horaFinal : null;
        data['estadoCaso'] = this.estadoCaso !== undefined ? this.estadoCaso : null;
        data['tipoCaso'] = this.tipoCaso !== undefined ? this.tipoCaso : null;
        data['concesionario'] = this.concesionario !== undefined ? this.concesionario : null;
        data['ruta'] = this.ruta !== undefined ? this.ruta : null;
        data['bus'] = this.bus !== undefined ? this.bus : null;
        data['clase'] = this.clase !== undefined ? this.clase : null;
        data['prioridad'] = this.prioridad !== undefined ? this.prioridad : null;
        data['rango'] = this.rango !== undefined ? this.rango : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['codigo'] = this.codigo !== undefined ? this.codigo : '';
        data['fechaCreacion'] = this.fechaCreacion !== undefined ? this.fechaCreacion : '';
        data['horaCreacion'] = this.horaCreacion !== undefined ? this.horaCreacion : '';
        data['fechaFinal'] = this.fechaFinal !== undefined ? this.fechaFinal : '';
        data['horaFinal'] = this.horaFinal !== undefined ? this.horaFinal : '';
        data['estadoCaso'] = this.estadoCaso !== undefined ? this.estadoCaso : '';
        data['tipoCaso'] = this.tipoCaso !== undefined ? this.tipoCaso : '';
        data['concesionario'] = this.concesionario !== undefined ? this.concesionario : '';
        data['ruta'] = this.ruta !== undefined ? this.ruta : '';
        data['bus'] = this.bus !== undefined ? this.bus : '';
        data['clase'] = this.clase !== undefined ? this.clase : '';
        data['prioridad'] = this.prioridad !== undefined ? this.prioridad : '';
        data['rango'] = this.rango !== undefined ? this.rango : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminGestionFilterDto(JSON.parse(json));
    }

}
