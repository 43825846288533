import {FooterComponent} from './../../../trans/footer/footer.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserProfileComponent} from '../../partials/layout';
import {ModalMensajeComponent} from '../../components/modal-mensaje/modal-mensaje.component';
import {LoaderComponent} from '../../components/loader/loader.component';

import {PartialsModule} from '../../partials/partials.module';
import {ModalComponent} from '../../components/modal/modal.component';

import {ErrorPageComponent} from '../../../trans/content/error-page/error-page.component';


@NgModule({
	declarations: [
		FooterComponent,
		ModalMensajeComponent,
		ModalComponent,
		LoaderComponent,
		ErrorPageComponent
	],
	exports: [
		FooterComponent,
		ModalMensajeComponent,
		ModalComponent,
		LoaderComponent,
		ErrorPageComponent
	],
	imports: [
		CommonModule,
		PartialsModule
	]
})
export class SharedModule {
}
