import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { AdminDriverDTO } from '../../../../shared/dto/admin-driver-dto';
import { AdminGroupDto } from '../../../../shared/dto/admin-group-dto';
import { environment } from '../../../../../../environments/environment';
import { Response } from '@angular/http';
import { UIService } from '../../../_services/UI.service';
import { Router } from '@angular/router';
import { MatSnackBarRef } from '@angular/material';
import { LayoutUtilsService, MessageType } from './../../../../../core/_base/crud/utils/layout-utils.service';



@Component({
  selector: 'kt-tramos-buscar',
  templateUrl: './tramos-buscar.component.html',
  styleUrls: ['./tramos-buscar.component.scss']
})
export class TramosBuscarComponent implements OnInit {

  selectSentidoTramo: Array<Select2OptionData>;

  private _actualDialog: MatSnackBarRef<any>;

  nombre: string = "";
  sentidotramo = "All";
  id: string = "";
  
  baseUrl = environment.endpoints.crearTramo;

  itenToDelete: any;
  contentToShowModal: number = -1;
  lastRequest: string;

  constructor(private http: HttpService,
    private cdr: ChangeDetectorRef,
    private uiService: UIService,
    private layoutUtilsService: LayoutUtilsService,
    private router: Router
  ) {

    let urlSentidoTroncales = environment.endpoints.sentidoTramo;
    this.http.ejecutarServicioGet(urlSentidoTroncales).subscribe(res => {
      const self = this;
      self.selectSentidoTramo = Array<Select2OptionData>();
      if (res.json().length > 0) {
        let data = res.json();
        let selectTypes = data.map(i => {
          return { id: i['answerValue'].toString(), text: i['answer'] };
        });
        selectTypes.unshift({ id: 'All', text: 'Seleccione' });
        this.selectSentidoTramo = selectTypes;
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

  }

  rutasMenu: object;
  rutasMenuBuscar: object;
  public options: Options;

  formModelDriver: AdminDriverDTO = new AdminDriverDTO();
  @Input() modificarDatos: AdminDriverDTO;

  loading: boolean = false;

  public selectFitro1: Array<Select2OptionData>;
  public selectFitroProfiles: Array<Select2OptionData>;
  public selectFitroTipoCategoria: Array<Select2OptionData>;


  contentToModificar: number = -1;
  // Paginador
  paginador: object = {
    count: 10,
    page: 1,
    total: 0,
    maxPages: 5
  };

  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1 };

  dataSet: any = [];

  configModal: {} = { status: -1 };

  ngOnInit() {

    this.rutasMenu = [
      {
        label: 'Buscar Tramo',
        icon: 'smart-icon-usuario',
        ruta: `${environment.folders.routeAdmin}tramos/buscar`
      }
    ];


    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: 'select2-dropdown-forms'
    };

  }

  BuscarTramo(page: number = 1) {
    this.paginador['page'] = page;
    // if (this.nombre === "" && this.sentidotramo === "All" && this.id === "") {

    //   this.uiService.showMessage("El nombre, id o el sentido del tramo es requerido.");
    //   this.cdr.markForCheck();

    // } else {

    let urlLoadRange = `${environment.endpoints.buscarTramos}page=${page}&count=10`;
    let urlLoadRange2 = `${environment.endpoints.contarTramos}page=${page}&count=10`;
    let params = "";

    params += "&name=" + this.nombre;


    params += "&id=" + this.id;

    if (this.sentidotramo !== "All") {
      params += "&direction=" + this.sentidotramo;
    }
    else {
      params += "&direction=";
    }

    this.lastRequest = `?page=${page}&count=10` + params;

    this.loading = true;
    this.http.ejecutarServicioGet(urlLoadRange2 + params)
      .subscribe(resCount => {
        if (resCount.status === 200) {
          const self = this;
          this.http.ejecutarServicioGet(urlLoadRange + params, null).subscribe(res => {
            self.loading = false;
            let dataToDisplay = [];
            if (res != null) {
              self.dataSet = res.json();
              self.paginador['total'] = resCount.json();
              self.contentToShow = -1;
              self.configTabla = { status: -1 };
              for (let i = 0; i < self.dataSet.length; i++) {
                dataToDisplay.push([
                  self.dataSet[i]["id"],
                  self.dataSet[i]["name"],
                  self.dataSet[i]["direction"],
                  self.dataSet[i]["linestream"],
                  ""
                ]);
              }
              self.dataSet = dataToDisplay;
              self.armarTabla(dataToDisplay);
            }
            self.armarTabla(dataToDisplay);

          });
        }
      },
        (error: Response) => {
          //this.showError(error);
        }
      );
    //}
  }

  LimpiarTramo() {
    this.nombre = "";
    this.id = "";
    this.sentidotramo = "All";
  }

  procesarJson() {

    let data = [
      { "id": 350, "licencia": "02/01/1998", "fechaingreso": "22/14/1999" },
      { "id": 251, "licencia": "22/14/1999", "fechaingreso": "02/01/1998" }
    ];

    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['id'].toString(),
        data[index]['id'].toString(),
        data[index]['licencia'].toString(),
        data[index]['fechaingreso'].toString(),
        '',
      ]);

      if (index === data.length - 1) {
        this.armarTabla(dataArray);
      }
    }

    if (!data.length) {
      this.armarTabla(dataArray);
    }
  }

  armarTabla(data) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Nombre Tramo', 'Sentido Tramo', 'LineStream', 'Acciones'],
      typeColumn: [, , , , { tipo: 'acciones', acciones: ['editar', 'eliminar'] }],
      searchColumn: [1],
      hideColumns: [3],
      showPag: true,
      showExport: true,
      configColumns: {
        id: 0,
        columnClick: 0,
      },
      data: data,
      paginador: this.paginador
    };
    this.loading = false;
    this.cdr.markForCheck();
  }


  showError(response) {

    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage(response.json()['message']);
    } else {
      this.uiService.showMessage('Error del servidor');
    }
    this.loading = false;
    this.cdr.markForCheck();

  }

  editarElemento(event) {
    this.router.navigate([`${environment.folders.routeAdmin}tramos/crear/${event}`]).catch(e => {
      console.log(e);
    });
  }

  buscadorPagina(event) {
    if (this.paginador["total"] != 0) {
      this.BuscarTramo(event);
    }
  }

  exportData(event) {
    this.loading = true;
    let urlGetReport = environment.endpoints.exportTramos + event + this.lastRequest;

    this.http.ejecutarServicioGet(urlGetReport).subscribe((res) => {
      this.loading = false;
      this.uiService.downloadFileBase64(res['_body'], event, 'Tramos');
      this.cdr.markForCheck();
    }, (error: Response) => {
      this.showError(error);
    });// END GET
  }

  eliminarTramo(event) {
    this.itenToDelete = event;
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-eliminar',
      titulo: 'Eliminar Tramo',
      textos: [
        '¿Esta seguro que quiere eliminar este tramo?'
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Eliminar',
          class: '',
          accion: 'eliminar'
        }
      ]
    };
    this.cdr.markForCheck();
  }

  changeStatusModal(event) {
    if (event == "eliminar") {
      this.eliminarItem();
    }
    if (event == "cancelar") {
      this.armarTabla(this.dataSet);
      this.cdr.markForCheck();
    }
  }

  eliminarItem() {
    this.contentToShowModal = 1;
    let urlLoadAlarm = `${this.baseUrl}${this.itenToDelete}`;

    this.http.ejecutarServicioDelete(urlLoadAlarm, null).subscribe(res => {
      this.contentToShowModal = 10;
      let message;
      switch (res.status) {
        case 200:
        case 201:
        case 204:
          message = 'El tramo ha sido borrado';
          for (var i = 0; i < this.dataSet.length; i++) {
            if (this.dataSet[i][0] === this.itenToDelete) {
              this.dataSet.splice(i, 1);
              break;
            }
          }
          this.armarTabla(this.dataSet);
          this.cdr.markForCheck();
          break;
        default:
          message = 'Error borrando el tramo';
          break;
      }
      this.configModal = {
        status: 1,
        icono: 'smart-icon-notificaciones',
        titulo: message,
        textos: [
        ],
        botones: [
          {
            label: 'Ok',
            class: '',
            accion: 'ok'
          }
        ]
      };
      this.cdr.markForCheck();
    }, (error: Response) => {

      this.cdr.markForCheck();
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error borrando el tramo ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });
  }
}