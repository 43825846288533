import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { Response } from '@angular/http';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { AdminProfileDTO } from '../../../../shared/dto/admin-profile-dto';
import { UIService } from '../../../_services/UI.service';

@Component({
	selector: 'kt-perfil-buscar',
	templateUrl: './perfil-buscar.component.html',
	styleUrls: ['./perfil-buscar.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class PerfilBuscarComponent implements OnInit {
	configTable: object;
	rutasMenu: object;

	// Paginador
	paginador: object = {
		count: 30,
		page: 1,
		total: 0,
		maxPages: 5
	};

	loading: boolean = true;

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1 };

	// Modal Modificar
	contentToModificar: number = -1;

	formModelProfile: AdminProfileDTO = new AdminProfileDTO();
	formModelProfileEditar: AdminProfileDTO = new AdminProfileDTO();

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {}

	ngOnInit() {
		this.rutasMenu = [
			{
				label: 'Buscar Perfil',
				icon: 'smart-icon-search',
				ruta: `${environment.folders.routeAdmin}perfil/buscar`
			},
			{
				label: 'Crear Perfil',
				icon: 'smart-icon-usuario',
				ruta: `${environment.folders.routeAdmin}perfil/crear`
			}
		];

		this.buscarProfiles();
	}

	buscarProfiles() {
		//this.paginador['page'] = page;
		this.loading = true;
		let params = this.getParams();
		this.http
			.ejecutarServicioGet(
				`${environment.endpoints.adminUserProfileSearch}${params}`,
				null
			)
			.subscribe(
				(res: Response) => {
					switch (res.status) {
						case 200:
						case 201:
						case 204:
							this.http
								.ejecutarServicioGet(
									`${
										environment.endpoints
											.adminUserProfileCount
									}${params}`,
									null
								)
								.subscribe(
									(response: Response) => {
										switch (response.status) {
											case 200:
											case 201:
											case 204:
												if(response.json() > 0) {
													this.paginador['total'] = response.json();
													// if(this.paginador['total'] === 0){
													// 	this.paginador = {};
													// }
													/* this.contentToShow = -1;
													this.configTabla = {
														status: -1
													}; */
													this.procesarJson(res.json());
												} else {
													this.procesarJson([]);
													this.loading = false;
													this.cdr.markForCheck();
												}
												break;
											case 401:
												// nothing
												break;
											default:
												this.showError(response);
												break;
										}
									},
									(error: Response) => {
										this.showError(error);
									}
								); // END INNER GET
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							break;
					}
				},
				(error: Response) => {
					this.showError(error);
				}
			); // END GET
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	procesarJson(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				data[index]['id'].toString(),
				data[index]['id'].toString(),
				data[index]['name'].toString(),
				data[index]['description'].toString(),
				'',
				data[index]['active']
			]);
		}
		this.armarTabla(dataArray);
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: [
				'ID',
				'Id Perfil',
				'Nombre Perfil',
				'Descripción Perfil',
				'Acciones',
				'Estado'
			],
			typeColumn: [
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar'] },
				{ tipo: 'estado' }
			],
			searchColumn: [0, 1, 2],
			hideColumns: [0],
			showPag: true,
			showExport: true,
			configColumns: {
				id: 0
			},
			data: data,
			paginador: this.paginador
		};
		this.loading = false;
		this.cdr.markForCheck();
	}

	buscadorPagina(event) {
		this.paginador['page'] = event;
		// if (this.paginador['total'] > this.paginador['count']) {
			this.buscarProfiles();
		// }
		// this.paginador['page'] = event;
		// this.buscarProfiles();
	}

	editarElemento(event) {
		let ruta = `${environment.endpoints.adminUserProfile}${event}`;
		this.http.ejecutarServicioGet(ruta, null).subscribe(
			(res: Response) => {
				switch (res.status) {
					case 200:
					case 201:
					case 202:
						let perfil = res.json();
						if (perfil.active === true) {
							this.formModelProfileEditar.id = perfil.id;
							this.formModelProfileEditar.name = perfil.name;
							this.formModelProfileEditar.description =
								perfil.description;
							console.log(perfil.active);
							// Ejecutar Form 6 (Editar Profile)
							this.contentToModificar = 6;
							this.cdr.markForCheck();
							setTimeout(() => {
								this.contentToModificar = -1;
							}, 300);
						} else {
							// Ejecutar Form 7 (Editar User)
							this.contentToModificar = 14;
							this.cdr.markForCheck();
							setTimeout(() => {
								this.contentToModificar = -1;
							}, 300);
						}
						break;
					case 401:
						// nothing
						break;
					default:
						this.showError(res);
						break;
				}
			},
			(error: Response) => {
				this.showError(error);
			}
		); // END GET
	}

	statusModal(event) {
		if (event === 'modificarPerfil') {
			this.buscarProfiles();
		}
	}

	getParams() {
		let formProfile = this.formModelProfile.toStrings();
		delete formProfile['userRoles'];

		let id = formProfile['id'];
		let name = formProfile['name'];
		let description = formProfile['description'];
		let count = this.paginador['count'];
		let page = this.paginador['page'];

		return `?id=${id}&name=${name}&description=${description}&count=${count}&page=${page}`;
	}

	exportData(event) {
		let params = this.getParams();
		this.http
			.ejecutarServicioGet(
				`${
					environment.endpoints.adminUserProfileExport
				}${event}${params}`,
				null
			)
			.subscribe(
				(res: Response) => {
					switch (res.status) {
						case 200:
						case 201:
						case 202:
							this.uiService.downloadFileBase64(
								res['_body'],
								event,
								'perfiles'
							);
							this.loading = false;
							this.cdr.markForCheck();
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							break;
					}
				},
				(error: Response) => {
					this.showError(error);
				}
			); // END GET
	}

	eventEstadoClick(item) {
		this.loading = true;
		let state = 0;
		if (item.value) {
			state = 1;
		}

		this.http
			.ejecutarServicioPut(
				`${environment.endpoints.adminUserProfileState}${
					item.id
				}/state/${state}`,
				null
			)
			.subscribe(
				(res: Response) => {
					switch (res.status) {
						case 200:
						case 201:
						case 202:
							this.loading = false;
							this.cdr.markForCheck();
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							this.buscarProfiles();
							break;
					}
				},
				(error: Response) => {
					this.showError(error);
					this.buscarProfiles();
				}
			); // END PUT
	}
}
