import { Component, OnInit, ChangeDetectorRef } from '@angular/core'; 
import { environment } from '@environments/environment';
import { MatSnackBarRef } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from './../../../../../core/_base/crud/utils/layout-utils.service';
import { UIService } from '../../../../trans/_services/UI.service';
import { HttpService } from '../../../../shared/services/http.service';
import { Select2OptionData, Options } from 'select2';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment'

@Component({
	selector: 'kt-gestion-incidente',
	templateUrl: './gestion-incidente.component.html',
	styleUrls: ['./gestion-incidente.component.scss']
})
export class GestionIncidenteComponent implements OnInit {
	// Modales
	contentToShowModal: number = -1;
	configModal: {} = { status: -1 };
	showModal: boolean = false;
	selectedNote: any = {};
	noteText: string = '';
	// Paginador
	paginador: object = {
		count: 10,
		page: 1
	};
	rutasMenu = [
		{
			label: 'Buscar Incidentes',
			icon: 'smart-icon-asociar-dispositivos',
			ruta: `${environment.folders.routeAdmin}incidentes/buscar`
		},
		{
			label: 'Crear Incidente',
			icon: 'smart-icon-asociar-dispositivos',
			ruta: `${environment.folders.routeAdmin}incidentes/crear`
		}
	];
	selectedId: any = '';
	closingIncident: boolean = false;
	// Tabla
	configTable: object;
	contentToShow: number = -1;
	configTabla: {} = { status: -1, data: [] };
	public selectCategorias: Array<Select2OptionData>;
	public selectModule: Array<Select2OptionData>;
	public selectPrioridad: Array<Select2OptionData>;
	private _actualDialog: MatSnackBarRef<any>;
	private currentIncident: any = {};

	constructor(
		private cdr: ChangeDetectorRef,
		private http: HttpService,
		private route: ActivatedRoute,
		private uiService: UIService,
		private layoutUtilsService: LayoutUtilsService,
		private router: Router
	) {}

	ngOnInit() {
		this.loadIncident();
		let urlIncidentecategorias = environment.endpoints.Mesacategorias;

		this.http.ejecutarServicioGet(urlIncidentecategorias).subscribe(
			res => {
				if (res.json().length > 0) {
					let data = res.json();
					let selectCategoria = data.map(i => {
						return { id: i.value, text: i.text };
					});
					this.selectCategorias = selectCategoria;
				}
				this.cdr.markForCheck();
			},
			(error: Response) => {
				if (this._actualDialog) {
					this._actualDialog.dismiss();
				}
				const message = 'Error cargando datos ';
				this._actualDialog = this.layoutUtilsService.showActionNotification(
					message,
					MessageType.Update,
					10000,
					true,
					false,
					0,
					'top'
				);
			}
		);

		let urlIncidentemodulos = environment.endpoints.Mesamodulos;
		this.http.ejecutarServicioGet(urlIncidentemodulos).subscribe(
			res => {
				if (res.json().length > 0) {
					let data = res.json();
					let selectModulo = data.map(i => {
						return { id: i.value, text: i.text };
					});
					this.selectModule = selectModulo;
				}
				this.cdr.markForCheck();
			},
			(error: Response) => {
				if (this._actualDialog) {
					this._actualDialog.dismiss();
				}
				const message = 'Error cargando datos ';
				this._actualDialog = this.layoutUtilsService.showActionNotification(
					message,
					MessageType.Update,
					10000,
					true,
					false,
					0,
					'top'
				);
			}
		);

		let urlIncidenteprioridades = environment.endpoints.Mesaprioridades;
		this.http.ejecutarServicioGet(urlIncidenteprioridades).subscribe(
			res => {
				if (res.json().length > 0) {
					let data = res.json();
					let selectPrioridad = data.map(i => {
						return { id: i.value, text: i.text };
					});
					this.selectPrioridad = selectPrioridad;
				}
				this.cdr.markForCheck();
			},
			(error: Response) => {
				if (this._actualDialog) {
					this._actualDialog.dismiss();
				}
				const message = 'Error cargando datos ';
				this._actualDialog = this.layoutUtilsService.showActionNotification(
					message,
					MessageType.Update,
					10000,
					true,
					false,
					0,
					'top'
				);
			}
		);
	}

	loadIncident() {
		this.selectedId = this.route.snapshot.paramMap.get('id');
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminIncident + this.selectedId
			)
			.subscribe(
				(res: Response) => {
					this.currentIncident = res.json();
					console.log(this.currentIncident);
					this.procesarJson(this.currentIncident.notes);
				},
				(error: Response) => {
					this.showError(error);
				}
			);
	}

	closeIncident() {
		this.showModal = true;
		this.closingIncident = true;
		this.selectedNote = {
			noteDate: moment().toISOString(true),
			noteText: '',
			incident: this.selectedId
		};
	}

	searchNotes() {
		this.procesarJson([]);
	}

	onEditar(e) {
		if (this.currentIncident.state !== 'Cerrado') {
			let note = this.currentIncident.notes.find(i => i.id === Number(e));
			this.selectedNote = note;
			this.showModal = true;
			this.cdr.markForCheck();
		}
	}

	statusModal(e) {
		if (this.closingIncident) {
			this.closingIncident = false;
		}
		this.showModal = false;
	}

	addNote() {
		if (this.noteText !== '') {
			this.http
				.ejecutarServicioPost(
					environment.endpoints.adminIncident +
						this.selectedId +
						'/note',
					{
						noteDate: moment().toISOString(true),
						noteText: this.noteText,
						incident: this.selectedId
					}
				)
				.subscribe(res => {
					if (res.status === 201) {
						this.loadIncident();
						this.noteText = '';
						this.showModal = false;
						this.cdr.markForCheck();
					} else {
						this.showError(res.json());
					}
				});
		}
	}

	procesarJson(data) {
		let dataArray = [];
		for (let i = 0; i < data.length; i++) {
			dataArray.push([
				data[i]['id'].toString(),
				data[i]['noteDate'].toString(),
				data[i]['noteText'].toString(),
				''
			]);
		}
		this.armarTabla(dataArray);
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: ['ID', 'Fecha', 'Descripción', 'Acciones'],
			typeColumn: [, , , { tipo: 'acciones', acciones: ['editar'] }],
			showSearch: false,
			searchColumn: [1],
			searchTitle: 'Notas',
			hideColumns: [0],
			showPag: false,
			leftsColumns: [1],
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data,
			paginador: this.paginador
		};
		this.cdr.markForCheck();
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}

	updateNote() {
		if (this.selectedNote.noteText !== '') {
			if (this.closingIncident) {
				this.http
					.ejecutarServicioPost(
						environment.endpoints.adminIncident +
							this.selectedId +
							'/note',
						this.selectedNote
					)
					.subscribe(res => {
						if (res.status === 201) {
							this.selectedId = this.route.snapshot.paramMap.get(
								'id'
							);
							this.http
								.ejecutarServicioPost(
									`${environment.endpoints.adminIncident}${
										this.selectedId
									}/state/0`
								)
								.subscribe(
									(res2: Response) => {
										if (res2.status === 200) {
											this.router.navigate([
												`${environment.folders.routeAdmin}incidentes/buscar`
											]);
										} else {
											this.showError(res2.json());
										}
									},
									(error: Response) => {
										// this.showError(error);
									}
								);
							this.showModal = false;
							this.cdr.markForCheck();
						} else {
							this.showError(res.json());
						}
					});
			} else {
				this.http
					.ejecutarServicioPut(
						environment.endpoints.adminIncident +
							this.selectedId +
							'/note/' +
							this.selectedNote.id,
						this.selectedNote
					)
					.subscribe(res => {
						if (res.status === 200 || res.status === 201) {
							this.loadIncident();
							this.showModal = false;
							this.cdr.markForCheck();
						} else {
							this.showError(res.json());
						}
					});
			}
		}
	}

	downloadFileIncident(file) {
		console.log(file);
	}
}
