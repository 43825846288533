import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges
} from '@angular/core';

import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { Response } from '@angular/http';
import { UIService } from '@services/UI.service';
import {
	NgbDateNativeAdapter,
	NgbDateAdapter
} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-conductor-registro-capacitaciones',
	templateUrl: './conductor-registro-capacitaciones.component.html',
	styleUrls: ['./conductor-registro-capacitaciones.component.scss'],
	providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ConductorRegistroCapacitacionesComponent
	implements OnInit, OnChanges {
	@Input() model: any;
	@Output() modelChange = new EventEmitter();
	@Input() showTable: boolean = false;
	@Input() showForm: boolean = false;
	@Output() reload = new EventEmitter();

	private emptyTraining = {
		id: null,
		trainingCreationDate: '',
		trainingExpirationDate: '',
		trainingName: '',
		trainingInstitution: ''
	};
	currentTraining: any = this.emptyTraining;
	idRegistry: string;
	dataArray = [];

	trainingCreationDate: string;
	trainingExpirationDate: string;
	trainingName: string;
	trainingInstitution: string;

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {}
	public options: Options;
	loading: boolean = false;
	contentToModificar: number = -1;
	// Paginador
	paginador: object = {
		count: 10,
		page: 1,
		total: 0,
		maxPages: 5
	};

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1 };

	ngOnInit() {
		this.showForm = !this.showTable;
		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.model.currentValue) {
			let trainings = changes.model.currentValue.trainings;
			if (trainings) {
				this.procesarJson(trainings);
			}
		}
	}

	procesarJson(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				data[index]['id'].toString(),
				data[index]['trainingCreationDate'].toString(),
				data[index]['trainingExpirationDate'].toString(),
				data[index]['trainingInstitution'].toString(),
				data[index]['trainingName'].toString(),
				''
			]);
		}
		this.armarTabla(dataArray);
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: [
				'ID',
				'Fecha de Creacion',
				'Fecha de Vencimiento',
				'Institucion',
				'Titulo',
				'Acciones'
			],
			typeColumn: [
				,
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'eliminar'] }
			],
			searchColumn: [0, 1, 2],
			hideColumns: [0],
			showPag: false,
			showExport: false,
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data,
			paginador: this.paginador
		};
		this.cdr.markForCheck();
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	agregarCapacitacion() {
		let url = `${environment.endpoints.adminDriver}document_type/${this.model['documentType']}/document/${this.model['document']}/training`;

		this.http.ejecutarServicioPost(url, this.currentTraining).subscribe(
			(res: Response) => {
				if (res.status === 200 || res.status === 201) {
					this.currentTraining = this.emptyTraining;
					this.cdr.markForCheck();
					this.reload.emit(true);
				} else { this.showError(res); }
			},
			e => {
				this.uiService.showMessage('Error cargando datos');
				this.cdr.markForCheck();
			}
		);
	}

	eventEliminarClick(event) {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/training/${event}`;
		this.http.ejecutarServicioDelete(url).subscribe(
			(res: Response) => {
				if (res.status === 200) {
					this.reload.emit(true);
				} else { this.showError(res); }
			},
			e => {
				this.uiService.showMessage('Error cargando datos');
				this.cdr.markForCheck();
			}
		);
	}

	updateModel() {
		this.model.trainingCreationDate = this.currentTraining.trainingCreationDate;
		this.model.trainingExpirationDate = this.currentTraining.trainingExpirationDate;
		this.model.trainingName = this.currentTraining.trainingName;
		this.model.trainingInstitution = this.currentTraining.trainingInstitution;
	}

	actualizarCapacitacion() {
		let url = `${environment.endpoints.adminDriver}document_type/
${this.model['documentType']}/document/
${this.model['document']}/training/
${this.currentTraining.id}`;
		this.http.ejecutarServicioPut(url, this.currentTraining).subscribe(
			(res: Response) => {
				if (res.status === 200 || res.status === 201) {
					this.currentTraining = this.emptyTraining;
					this.cdr.markForCheck();
					this.reload.emit(true);
				} else { this.showError(res); }
			},
			e => {
				this.uiService.showMessage('Error cargando datos');
				this.cdr.markForCheck();
			}
		);
	}

	editarElemento(event) {
		let item = this.model.trainings.find(
			itm => itm.id === parseInt(event, 10)
		);
		this.currentTraining = item;
		this.showForm = true;
		this.cdr.markForCheck();
	}
	cancelarCapacitacion() {
		this.showForm = false;
		this.currentTraining = this.emptyTraining;
		this.cdr.markForCheck();
	}

	guardarCapacitacion() {
		if((this.model.trainingCreationDate === null || this.model.trainingCreationDate === undefined || this.model.trainingCreationDate === "") ||
			(this.model.trainingExpirationDate === null || this.model.trainingExpirationDate === undefined || this.model.trainingExpirationDate === "") ||
			(this.model.trainingName === null || this.model.trainingName === undefined || this.model.trainingName === "") ||
			(this.model.trainingInstitution === null || this.model.trainingInstitution === undefined || this.model.trainingInstitution === "") 
		) {
			this.uiService.showMessage("Llene los campos marcados con asterisco (*)");
			return;
		}
		this.showForm = false;
		if (this.currentTraining.id) {
			this.actualizarCapacitacion();
		} else {
			this.agregarCapacitacion();
		}
	}

	/**
	 * Valida que la fecha de creación de capacitación sea menor a la fecha de vencimiento.
	 */
	validateTrainingCreationDate() {
		setTimeout(()=>{
			if (this.currentTraining.trainingCreationDate !== null && this.currentTraining.trainingCreationDate !== undefined && this.currentTraining.trainingCreationDate !== "") {
				if(this.currentTraining.trainingExpirationDate !== null && this.currentTraining.trainingExpirationDate !== undefined && this.currentTraining.trainingExpirationDate !== "") {
					let fechaExpiracionCap = new  Date(this.currentTraining.trainingCreationDate);
					let fechaExpiracionCapFormato = fechaExpiracionCap.getFullYear() + "" + ((fechaExpiracionCap.getMonth()+1) < 10 ? '0' + (fechaExpiracionCap.getMonth()+1) : (fechaExpiracionCap.getMonth()+1)) + "" 
					+ (fechaExpiracionCap.getDate() < 10 ? '0' + fechaExpiracionCap.getDate() : fechaExpiracionCap.getDate());
					let fechaCreacionCap = new  Date(this.currentTraining.trainingExpirationDate);
					let fechaCreacionCapFormato = fechaCreacionCap.getFullYear() + "" + ((fechaCreacionCap.getMonth()+1) < 10 ? '0' + (fechaCreacionCap.getMonth()+1) : (fechaCreacionCap.getMonth()+1)) + "" 
					+ (fechaCreacionCap.getDate() < 10 ? '0' + fechaCreacionCap.getDate() : fechaCreacionCap.getDate());
					if(!(Number(fechaExpiracionCapFormato) < Number(fechaCreacionCapFormato))){
						this.uiService.showMessage("La fecha de creación debe ser menor a la fecha de vencimiento.")
						this.currentTraining.trainingCreationDate = null;
						this.cdr.markForCheck();
					}
				}
			}
		})
	}

	/**
	 * Valida que la fecha de vencimiento de capacitación sea mayor a la fecha actual y mayor a la fecha de creación.
	 */
	validateTrainingExpirationDate() {
		let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
		+ (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
		setTimeout(()=>{
			if (this.currentTraining.trainingExpirationDate !== null && this.currentTraining.trainingExpirationDate !== undefined && this.currentTraining.trainingExpirationDate !== "") {
				let fechaExpiracionCap = new  Date(this.currentTraining.trainingExpirationDate);
				let fechaExpiracionCapFormato = fechaExpiracionCap.getFullYear() + "" + ((fechaExpiracionCap.getMonth()+1) < 10 ? '0' + (fechaExpiracionCap.getMonth()+1) : (fechaExpiracionCap.getMonth()+1)) + "" 
				+ (fechaExpiracionCap.getDate() < 10 ? '0' + fechaExpiracionCap.getDate() : fechaExpiracionCap.getDate());
				if(!(Number(fechaExpiracionCapFormato) > Number(fechaActualFormato))){
					this.uiService.showMessage("Seleccione una fecha mayor a la fecha de hoy.")
					this.currentTraining.trainingExpirationDate = null;
					this.cdr.markForCheck();
				} else if (this.currentTraining.trainingCreationDate !== null && this.currentTraining.trainingCreationDate !== undefined && this.currentTraining.trainingCreationDate !== "") {
					let fechaCreacionCap = new  Date(this.currentTraining.trainingCreationDate);
					let fechaCreacionCapFormato = fechaCreacionCap.getFullYear() + "" + ((fechaCreacionCap.getMonth()+1) < 10 ? '0' + (fechaCreacionCap.getMonth()+1) : (fechaCreacionCap.getMonth()+1)) + "" 
					+ (fechaCreacionCap.getDate() < 10 ? '0' + fechaCreacionCap.getDate() : fechaCreacionCap.getDate());
					if(!(Number(fechaExpiracionCapFormato) > Number(fechaCreacionCapFormato))){
						this.uiService.showMessage("La fecha de vencimiento debe ser mayor a la fecha de creación.")
						this.currentTraining.trainingExpirationDate = null;
						this.cdr.markForCheck();
					}
				}
			}
		})
	}
}
