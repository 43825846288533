import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UIService } from './../../../../trans/_services/UI.service';
// import { HttpService } from '../../../../shared/services/http.service';
import { MatSnackBarRef } from '@angular/material';
import { LayoutUtilsService, MessageType } from './../../../../../core/_base/crud/utils/layout-utils.service';
import { environment } from '@environments/environment';
// import { Response } from '@angular/http';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateAdapter } from '../../../estadistica/helpers/customDateAdapter';
import { CustomDateParserFormatter } from '../../../estadistica/helpers/customDateParserFormatter';
import * as moment from 'moment';

@Component({
  selector: 'kt-validacion',
  templateUrl: './validacion-conductor.component.html',
  styleUrls: [
    './validacion-conductor.component.scss',
    './../../gestion/gestion-manual/gestion-manual.component.scss'
  ],
  host: { 'class': 'sths-tb-element' },
  providers: [
    {provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class ReporteValidacionConductorComponent implements OnInit {
  conditionalLoader: boolean = false;
  idNumber: number = 1;
  fechasSeleccionadas: {}[] = [];
  private _actualDialog: MatSnackBarRef<any>;
  lastRequest: any;
  dataTablechunk: Array<any>;

  /** NgModel */
  tipoFechas: string = 'Rango Fechas';
  fechaEscogida: any;
  fechaDesde: any;
  fechaHasta: any;
  filtrarConcesionario: boolean = true;
  selectConcesionarios: Array<any> = [];
  filtrarTipologia: boolean = true;
  selectTipologias: Array<any> = [];
  filtrarBus: boolean = true;
  selectBuses: Array<any> = [];
  filtrarRuta: boolean = true;
  selectRutas: Array<any> = [];
  filtrarConductor: boolean = true;
  selectConductores: Array<any> = [];
  filtrarTipoReporte: boolean = true;
  selectTiposReportes: Array<any> = [];

  /** Data for select box */
  concesionarios: Array<any> = [];
  tipologias: Array<any> = [];
  buses: Array<any> = [];
  rutas: Array<any> = [];
  conductores: Array<any> = [];
  tiposReportes: Array<any> = [];

  /** Stetting TablasComponent */
  configTable: object;
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };
  paginador = {
    count: 30, // cantidad de itemes por pagina
    page: 1, // paginador seleccionado
    total: 0, // total de items 
    maxPages: 5 // paginadores a mostrar 
  };

  /** Stetting MultiSelectComponent */
  dropdownSettings: {};

  _loadData = false;


  constructor(
    private uiService: UIService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.loadDataCombobox();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Todos',
      unSelectAllText: 'Ninguno',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText : 'Buscar',
      noDataAvailablePlaceholderText : 'Datos no disponibles...'
    };

    this.fechaDesde = moment().add(-1, 'year').format('YYYY-MM-DD');
    this.fechaHasta = moment().format('YYYY-MM-DD');
  }

  /**
   * Error al cargar datos
   */
  loadDataError(error) {
    if (this._actualDialog) { this._actualDialog.dismiss(); }
    const message = 'Error cargando datos ';
    this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
  }

  /**
   * Cargar datos para todos los select
   */
  loadDataCombobox() {
    this._loadData = true;
    this.http.get(`${environment.endpoints.drivers}/drivervalidation/get_filter_values`).subscribe((res: any) => {
      let data = res;// res.json();
      if (!_.isEmpty(data)) {
        this.buses = data.buses;
        this.rutas = data.rutas;
        this.conductores = data.operadores;
        this.tiposReportes = data.resultado;
      }
      this._loadData = false;
      this.cdr.markForCheck();
    }, (error: any) => this.loadDataError(error) );
  }

  /**
   * Enviar fecha
   * @param event
   */
  enviarFecha(event) {
    this.idNumber++;
    this.fechasSeleccionadas.push({
      fecha: this.fechaEscogida.year + '-' + this.fechaEscogida.month + '-' + this.fechaEscogida.day
    });
  }

  /**
   * Eliminar fecha
   */
  eliminarFecha(item) {
    const index: number = this.fechasSeleccionadas.indexOf(item);
    if (index !== -1) {
      this.fechasSeleccionadas.splice(index, 1);
    }
  }

  /**
   * Click page paginator
   * @param event 
   */
  goPage(event) {
    // console.log(event)
    this.paginador['page'] = event;
    this.loadData();
  }

  /**
   * Ejecutar reportes
   */
  generarReporte() {
    this.conditionalLoader = true;

    let fechaInicial;
    let fechaFinal;
    let typoFiltroFecha;
    let rangoFechasArr = [];
    let _concesionarios = '';
    let _tipologias = '';
    let _buses = '';
    let _rutas = '';
    let _conductores = '';
    let _tipoReporte = '';

    if (this.tipoFechas === 'Rango Fechas') {
      if (this.fechaDesde !== null && this.fechaDesde !== undefined && this.fechaHasta !== null && this.fechaHasta !== undefined) {
        typoFiltroFecha = 'RangoFechas';
        fechaInicial = this.fechaDesde;
        fechaFinal = this.fechaHasta;
      } else {
        this.uiService.showMessage("Debe llenar los campos de fecha.");
        this.conditionalLoader = false;
        return;
      }
    } else {
      typoFiltroFecha = 'FechasPuntuales';
      if(this.fechasSeleccionadas.length > 0) {
        this.fechasSeleccionadas.forEach(fecha => {
          rangoFechasArr.push(fecha['fecha']);
        });
      } else {
        this.uiService.showMessage("Debe seleccionar al menos una fecha.");
        this.conditionalLoader = false;
        return;
      }
    }

    console.log('Buses => ', this.selectBuses);
    if (this.selectBuses.length > 0 && this.filtrarBus) {
      this.selectBuses.forEach(element => {
        if (typeof element === 'object') {
          _buses = element.id;
        } else {
          _buses = element;
        }
      });
    }
    console.log('Rutas => ', this.selectRutas);
    if (this.selectRutas.length > 0 && this.filtrarRuta) {
      this.selectRutas.forEach(element => {
        if (typeof element === 'object') {
          _rutas = element.id;
        } else {
          _rutas = element;
        }
      });
    }
    console.log('Conductores => ', this.selectConductores);
    if (this.selectConductores.length > 0 && this.filtrarConductor) {
      this.selectConductores.forEach(element => {
        if (typeof element === 'object') {
          _conductores = element.id;
        } else {
          _conductores = element;
        }
      });
    }
    console.log('Tipo Reporte => ', this.selectTiposReportes);
    if (this.selectTiposReportes.length > 0 && this.filtrarTipoReporte) {
      this.selectTiposReportes.forEach(element => {
        if (typeof element === 'object') {
          _tipoReporte = element.id;
        } else {
          _tipoReporte = element;
        }
      });
    }

    this.lastRequest = {
      'fechaInicial': fechaInicial,
      'fechaFinal': fechaFinal,
      'filtrarBuses': this.filtrarBus,
      'bus' : _buses,
      'filtrarRutas': this.filtrarRuta,
      'ruta': _rutas,
      'filtrarOperadores': this.filtrarConductor,
      'operador': _conductores,
      'filtrarResultado': this.filtrarTipoReporte,
      'resultado' : _tipoReporte
    };

    this.http.post(`${environment.endpoints.drivers}/drivervalidation/report/`, this.lastRequest).subscribe( (res: any) => {
      this.conditionalLoader = false;
      let respose = res.datos ? res.datos : [];// res.json();
      if (respose.length > 0) {
        this.paginador['total'] = respose.length;
        this.dataTablechunk = _.chunk(respose, this.paginador['count']);
        // console.log(this.dataTablechunk);
        this.loadData();
      } else {
        this.paginador['total'] = 0;
        this.procesarJson([]);
        this.cdr.markForCheck();
      }
    }, (error: any) => {this.loadDataError(error); this.conditionalLoader = false; this.cdr.markForCheck();} );

  }

  /**
   * cargar datos en la tabla con paginador
   */
  loadData() {
    // console.log(this.paginador);
    this.procesarJson(this.dataTablechunk[this.paginador['page'] - 1]);
  }

  /**
   * Mapear data del servifor para mostrar en tabla de resulatados
   * @param data 
   */
  procesarJson(data) {
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        '',
        data[index]['busName'] ? data[index]['busName'].toString() : '',
        data[index]['routeName'] ? data[index]['routeName'].toString() : '',
        data[index]['driverName'] ? data[index]['driverName'].toString() : '',
        data[index]['validationResult'] ? data[index]['validationResult'].toString() : ''
      ]);

      if (index === data.length - 1) {
        this.armarTabla(dataArray);
      }
    }

    if (!data.length) {
      this.armarTabla(dataArray);
    }
  }

  /**
   * Contruir y pinta tabla de reportes
   * @param data
   */
  armarTabla(data) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Bus', 'Ruta', 'Conductor', 'Resultado'],
      typeColumn: [, , , , , , , ,],
      showSearch: false,
      searchColumn: [1],
      searchTitle: 'Buscar Categoría',
      hideColumns: [0],
      showExport: true,
      showPag: true,
      leftsColumns: [1],
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }

  /**
   * Error del servidor
   * @param response
   */
  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage(response.json()['message']);
    } else {
      this.uiService.showMessage('Error del servidor');
    }
    this.conditionalLoader = false;
    this.cdr.markForCheck();
  }

  /**
   * Exportat archivo
   * @param event
   */
  exportData(event) {
    this.conditionalLoader = true;
    let request = Object.assign({} , this.lastRequest);
    request.title = 'REPORTE DE VALIDACIÓN DE CONDUCTOR';
    this.http.post(`${environment.endpoints.drivers}/drivervalidation/report/${event}`, request).subscribe((res: any) => {
      console.log(res);
      this.conditionalLoader = false;
      this.uiService.downloadFileBase64(res, event, 'Validaciones Conductores');
      this.cdr.markForCheck();
    }, (error: any) => this.showError(error) );
  }

  validateDate(){
    setTimeout(()=>{    
      let fechaIni = this.fechaDesde.replace(/-/g, '')
      let fechaFin = this.fechaHasta.replace(/-/g, '')
      if(Number(fechaFin) < Number(fechaIni)){
        this.fechaHasta = this.fechaDesde;
        this.cdr.markForCheck();
      }
    })
  }
}
