import { environment } from './../../../../../../environments/environment';
import { HttpService } from '../../../../shared/services/http.service';
import { UIService } from './../../../../trans/_services/UI.service';

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData, Options } from 'select2';

import { Response } from '@angular/http';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { AdminUserDTO } from '../../../../shared/dto/admin-user-dto';
import { AdminGestionFilterDto } from '../../../../shared/dto/admin-gestion-filter-dto';

import { ActivatedRoute, Router } from '@angular/router';

import * as _ from 'lodash';

@Component({
  selector: 'kt-recibir-casos',
  templateUrl: './recibir-casos.component.html',
  styleUrls: [
  				'./recibir-casos.component.scss',
  				'./../gestion-manual/gestion-manual.component.scss'
  ],
  host: {'class': 'sths-tb-element'}
})
export class RecibirCasosComponent implements OnInit {
  // Select2
  public selectConcesionarios: Array<Select2OptionData>;
  public selectBuses: Array<Select2OptionData>;
  public selectTypes: Array<Select2OptionData>;
  public selectEstados: Array<Select2OptionData>;
  public selectClases: Array<Select2OptionData>;
  public selectPrioridades: Array<Select2OptionData>;
  public selectRangos: Array<Select2OptionData>;
  public selectRutas: Array<Select2OptionData>;
  showResult: boolean = false;
  fromDate: NgbDate;
  toDate: NgbDate;

  sthsQSModel1: NgbDateStruct;
  sthsQSTime1 = {hour: 9, minute: 0};
  sthsQSModel2: NgbDateStruct;
  sthsQSTime2 = {hour: 10, minute: 0};
  public options: Options;

  casesTypes: any;


  // Paginador
  paginador: Object = {
    count: 15,
    page: 1,
    maxPages: 5
  };

  // Table Test
  configTable: Object;

  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  // Cargadores Información
  conditionalLoader: boolean = true;


  formModelUser: AdminUserDTO = new AdminUserDTO;

  codigo: string;
  isCode: boolean;

  formModelGestion: AdminGestionFilterDto = new AdminGestionFilterDto;

  // Filtros, Columnas, Actions
  actions: {} = {
    gestioncaso: ''//,
    // localizar: '',
  };

  columnsHeader: {} = {
    codigo: '',
    fechaCreacion: '',
    horaCreacion: '',
    fechaFinal: '',
    horaFinal: '',
    estadoCaso: '',
    tipoCaso: '',
    concesionario: '',
    ruta: '',
    bus: '',
  };

  filters: {} = {
    codigo: '',
    fechaCreacion: '',
    horaCreacion: '',
    fechaFinal: '',
    horaFinal: '',
    estadoCaso: '',
    tipoCaso: '',
    concesionario: '',
    ruta: '',
    bus: '',
  };

  constructor(auth: AuthService,
            private cdr: ChangeDetectorRef,
            private http: HttpService,
            private uiService: UIService,
            calendar: NgbCalendar,
            private router: Router
  ){

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  onDateSelection(date: NgbDate) {
    // console.log( date );
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  ngOnInit() {
    let ruta = `${environment.endpoints.adminCasesManagementHeader}`;
    this.http.ejecutarServicioGet( ruta, null ).subscribe( (resHe: Response) => {
      switch (resHe.status) {
        case 200:
        case 201:
        case 204:
          this.columnsHeader = resHe.json()['inbox'][0];
          this.filters = resHe.json()['filters'][0];
          this.actions = resHe.json()['actions'][0];
          this.buscarRecibirCasos();
          this.cdr.markForCheck();
          break;
        default:
          this.showError(resHe);
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    });

    // Estado de Casos
    this.http.ejecutarServicioGet( environment.endpoints.adminCasesStatus, null ).subscribe( (resEstado: Response) => {
      switch (resEstado.status) {
        case 200:
        case 201:
        case 204:
          let estados = resEstado.json();
          for (let indexSta = 0; indexSta < estados.length; indexSta++) {
            estados[indexSta]['text'] = estados[indexSta]['description'];
            if ( indexSta === estados.length - 1 ) {
              estados.unshift( {id:"-Sel", text: "Seleccionar Estado"} );
              this.selectEstados = estados;
              break;
            }
          }

          this.cdr.markForCheck();
          break;
        default:
            this.showError( resEstado );
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    })

    this.classCase();
    this.prioridadesCase();
    this.rangoCase();
    this.rutaCase();

    // Si llega la acción 'gestioncaso1', entonces se consulta estos WS
    if (this.actions!=='gestioncaso1') {
      // Tipo de Casos
      this.http.ejecutarServicioGet(  `${environment.endpoints.adminCasesFilter}/alertType`, null ).subscribe( (resp: Response) => {
        // console.log(resp);
        switch (resp.statusText) {
          case 'OK':
            let data = resp.json();
            // console.log(data);
            let dataSelect = [];
            dataSelect.push( {id:"-Sel", text: "Seleccionar Tipo de Caso"} );
            _.forEach(data, (value, key) => {
              // console.log({id: key, text: value})
              dataSelect.push({id: key, text: value});
            })
            this.selectTypes = dataSelect;
            this.cdr.markForCheck();
            break;
          default:
            this.showError( resp );
            break;
        }
        }, (error: Response) => {
        this.showError( error );
      })

      // Concesionarios
      this.http.ejecutarServicioGet( environment.endpoints.adminRegistries, null ).subscribe( (resRegistry: Response) => {
        switch (resRegistry.status) {
          case 200:
          case 201:
          case 204:
            let registry = resRegistry.json();
            for (let indexReg = 0; indexReg < registry.length; indexReg++) {
              registry[indexReg]['text'] = registry[indexReg]['id'];
              if ( indexReg === registry.length - 1 ) {
                registry.unshift( {id:"-Sel", text: "Seleccionar Concesionario"} );
                this.selectConcesionarios = registry;
                break;
              }
            }
            this.cdr.markForCheck();
            break;
          default:
              this.showError( resRegistry );
            break;
        }
      }, (error: Response) => {
        this.showError( error );
      })
    }

    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: "select2-dropdown-forms"
    };
  }

  updateBuses(event) {
      
      if (event == null) { return false; }
      // Buses
      this.http.ejecutarServicioGet( `${environment.endpoints.adminRegistryGroup}?registry=${event}`, null ).subscribe( (resBuses: Response) => {
        switch (resBuses.status) {
          case 200:
          case 201:
          case 204:
            let buses = resBuses.json().groups;
            // se valida que vengan registros en el WS
            if (buses.length>0) {
              for (let indexBus = 0; indexBus < buses.length; indexBus++) {
                buses[indexBus]['id'] = buses[indexBus]['name'];
                buses[indexBus]['text'] = buses[indexBus]['name'];
                if ( indexBus === buses.length - 1 ) {
                  buses.unshift( { id:"-Sel", text: "Seleccionar Bus"} );
                  this.selectBuses = buses;
                  break;
                }
              }
            }
            this.cdr.markForCheck();
            break;
          default:
              this.showError( resBuses );
            break;
        }
      }, (error: Response) => {
        this.showError( error );
      })
  }

  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage( response.json()['message'] );
    } else {
      this.uiService.showMessage( 'Error del servidor' );
    }
    this.cdr.markForCheck();
  }

  onKey( event : any ){
    if( event.target.value.length ){
      this.isCode = true;
      this.formModelGestion = new AdminGestionFilterDto;
      this.formModelGestion.codigo = event.target.value;
      this.formModelGestion.estadoCaso = '-Sel';
      this.formModelGestion.concesionario = '-Sel';
      this.formModelGestion.ruta = '-Sel';
      this.formModelGestion.bus = '-Sel';
      this.formModelGestion.tipoCaso = '-Sel';
      this.cdr.markForCheck();
    } else {
      this.isCode = false;
    }
  }

  validarFecha(){
    let formGestion = this.formModelGestion.toStrings();
    if ( !formGestion['fechaCreacion'] && !formGestion['horaCreacion'] && !formGestion['fechaFinal'] && !formGestion['horaFinal'] ){

    }else{
      if( !formGestion['fechaCreacion'] || !formGestion['horaCreacion'] || !formGestion['fechaFinal'] || !formGestion['horaFinal'] ){
        this.uiService.showMessage( 'Debes seleccionar la fecha - hora inicial y la fecha - hora final' );
        return false;
      }else{
        // Validar
        let dateIni = new Date(formGestion['fechaCreacion']['year'], formGestion['fechaCreacion']['month']-1, formGestion['fechaCreacion']['day'], formGestion['horaCreacion']['hour'], formGestion['horaCreacion']['minute'], formGestion['horaCreacion']['second']);
        let dateFin = new Date(formGestion['fechaFinal']['year'], formGestion['fechaFinal']['month']-1, formGestion['fechaFinal']['day'], formGestion['horaFinal']['hour'], formGestion['horaFinal']['minute'], formGestion['horaFinal']['second']);
        if( dateIni > dateFin ){
          this.uiService.showMessage( 'La fecha inicial es mayor a la fecha final' );
          return false;
        }
      }
    }
    return true;
  }

  buscadorPagina(event) {
    this.paginador['page'] = event;
    this.buscarRecibirCasos();
  }

  limpiar(){
    this.formModelGestion = new AdminGestionFilterDto;
    this.formModelGestion.estadoCaso = '-Sel';
    this.formModelGestion.concesionario = '-Sel';
    this.formModelGestion.ruta = '-Sel';
    this.formModelGestion.bus = '-Sel';
    this.formModelGestion.tipoCaso = '-Sel';
    this.formModelGestion.clase = '-Sel';
    this.formModelGestion.prioridad = '-Sel';
    this.formModelGestion.rango = '-Sel';
    this.buscarRecibirCasos();
    this.cdr.markForCheck();
  }

  buscarRecibirCasos(pagina?) {
    this.conditionalLoader = true;
    let formGestion = this.formModelGestion.toStrings();
    let initialDate, finalDate;
    let searchFields = ``;

    if( formGestion['fechaCreacion'] && formGestion['horaCreacion'] ){
      initialDate = this.uiService.changeFormatFecha( formGestion['fechaCreacion'], formGestion['horaCreacion']);
      searchFields += `&initialDate=${initialDate}`;
    }

    if( formGestion['fechaFinal'] && formGestion['horaFinal'] ){
      finalDate = this.uiService.changeFormatFecha( formGestion['fechaFinal'], formGestion['horaFinal']);
      searchFields += `&finalDate=${finalDate}`;
    }

    if ( !this.validarFecha() ){
      return true;
    }

    if ( formGestion['estadoCaso'] && formGestion['estadoCaso'] != "-Sel" ){
      searchFields += `&caseStatus=${formGestion['estadoCaso']}`;
    }

    if ( formGestion['tipoCaso'] && formGestion['tipoCaso'] != "-Sel" ){
      searchFields += `&alertType=${formGestion['tipoCaso']}`;
    }

    if ( formGestion['concesionario'] && formGestion['concesionario'] != "-Sel" ){
      searchFields += `&caseConcessionaire=${formGestion['concesionario']}`;
    }

    if ( formGestion['ruta'] && formGestion['ruta'] != "-Sel" ){
      searchFields += `&caseRoute=${formGestion['ruta']}`;
    }

    if ( formGestion['bus'] && formGestion['bus'] != "-Sel" ){
      searchFields += `&caseBus=${formGestion['bus']}`;
    }

    if ( formGestion['codigo'] && formGestion['codigo'] != "-Sel" ){
      searchFields += `&caseCode=${formGestion['codigo']}`;
    }

    if ( formGestion['clase'] && formGestion['clase'] != "-Sel" ){
      searchFields += `&caseType=${formGestion['clase']}`;
    }

    if ( formGestion['prioridad'] && formGestion['prioridad'] != "-Sel" ){
      searchFields += `&alertLevel=${formGestion['prioridad']}`;
    }

    if ( formGestion['rango'] && formGestion['rango'] != "-Sel" ){
      searchFields += `&alertRange=${formGestion['rango']}`;
    }

    let count = this.paginador['count'];
    if (pagina !== null && pagina !== undefined) {
      this.paginador['page'] = pagina;
    }
    let page = this.paginador['page'];

    let ruta = '';
    let rutaCount = '';

    ruta = `${environment.endpoints.adminCasesManagement}?count=${count}&page=${page}${searchFields}`;
    rutaCount = `${environment.endpoints.adminCasesManagementCount}?count=${count}&page=${page}${searchFields}`;

    this.http.ejecutarServicioGet( ruta, null ).subscribe( (res: Response) => {
     switch (res.status) {
        case 200:
        case 201:
            this.http.ejecutarServicioGet( rutaCount, null ).subscribe( (response: Response) => {
              switch (response.status) {
                case 200:
                case 201:
                case 204:
                  this.conditionalLoader = false;
                  this.paginador['total'] = response.json();
                  if ( res.json() && res.json().length > 0 ) {
                    this.procesarJson( res.json() );
                    this.showResult = true;
                  } else {
                    this.procesarJson( [] );
                    this.showResult = false;
                  }
                  break;
                case 401:
                  // nothing
                  break;
                default:
                  this.showError( response );
                  break;
              }

            }, (error: Response) => {
              this.showError( error );

            });

          this.cdr.markForCheck();
          break;
          case 204:
            this.procesarJson( [] );
            this.cdr.markForCheck();
          break;

        default:
            this.procesarJson( [] );
            this.cdr.markForCheck();
            this.showError( res );
          break;
      }
      this.conditionalLoader = false;
    }, (error : Response) => {
      this.conditionalLoader = false;
      this.procesarJson( [] );
      this.cdr.markForCheck();
      this.showError( error );
    });
  }

  getDataString(text) {
    return text ? text.toString() : " ";
  }

  procesarJson( data ) {
    /*data = [
      { id: 1, caseStatusObj:{ description : 'description'}, casesObj:{ id:'1', creationDate:'06/22/2019', alertType : 'alertType', concessionaire :'concessionaire', route :'route', bus : 'bus'} },
    ]*/

    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    this.casesTypes = new Map();
    
    for (let index = 0; index < data.length; index++) {
      
      this.casesTypes.set( this.getDataString(data[index]['caseManagement']), 
        this.getDataString(data[index]['caseType'])); //Cases status hashmap  
      let dataArrayColumn = [];
      dataArrayColumn.push( this.getDataString(data[index]['caseManagement']) );
      dataArrayColumn.push( this.getDataString(data[index]['id']) );
      dataArrayColumn.push( this.getDataString(data[index]['caseType']) );
      dataArrayColumn.push( this.getDataString(data[index]['alertLevel']) );
      dataArrayColumn.push( this.getDataString(data[index]['alertRange']) );
      for (const key in this.columnsHeader) {
        switch (key) {
          case 'fechaCreacion':
            dataArrayColumn.push( this.getDataString(data[index]['creationDate']) );
            break;
          case 'tipoCaso':
            dataArrayColumn.push( this.getDataString(data[index]['alertType']));
            break;
          case 'estadoCaso':
            dataArrayColumn.push( this.getDataString(data[index]['caseStatus']));
            break;
          case 'concesionario':
            dataArrayColumn.push( this.getDataString(data[index]['concessionaire']));
            break;
          case 'ruta':
            dataArrayColumn.push( this.getDataString(data[index]['route']));
            break;
          case 'bus':
            dataArrayColumn.push( this.getDataString(data[index]['bus']));
            break;
          default:
            break;
        }
      }

      dataArrayColumn.push( ' ' );
      dataArrayColumn.push( '' );

      // Toda la Fila
      dataArray.push(dataArrayColumn);

      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }

    if ( !data.length ) {
      this.armarTabla(dataArray);
    }
  }

  armarTabla( data ) {
    console.log("La data: ",data);
    this.contentToShow = 1;
    let headerColumns = [];
    let headerTypes = [];

    headerColumns.push('ID');
    headerColumns.push('Cod. Caso');
    headerColumns.push('Clase Caso');
    headerColumns.push('Prioridad');
    headerColumns.push('Rango');
    headerTypes.push('');
    headerTypes.push('');
    headerTypes.push('');
    headerTypes.push('');
    headerTypes.push('');

    for (const key in this.columnsHeader) {
      headerColumns.push( this.columnsHeader[key] );
      headerTypes.push( '' );
    }

    //headerColumns.push('Tipo Caso');
    //headerColumns.push('Concesionario');
    headerColumns.push('Ruta');
    //headerColumns.push('Bus');
    headerTypes.push('');
    headerColumns.push('Acciones');

    // Acciones
    let acciones = [];
    for (const key in this.actions) {
      // console.log(key)
      if(key != 'localizar'){
        acciones.push(this.actions[key]);
      }
    }

    headerTypes.push({ tipo: 'acciones' , acciones: acciones });
    // console.log('headerTypes: ', headerTypes);
    this.configTabla = {
      status: 1,
      header: headerColumns,
      typeColumn: headerTypes,
      showSearch: false,
      searchColumn: [1,2],
      hideColumns: [0],
      showPag: true,
      configColumns: {
        id: 1,
        columnClick: 0,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }

  eventGestionCaso1( event ){
    console.log( 'eventGestionCaso1', event );
    this.router.navigateByUrl( `${environment.folders.routeAdmin}gestion/responder/${event}` );

  }

  eventGestionCaso2( event ){
    console.log( 'eventGestionCaso2', event );

    let caseType = this.casesTypes.get(event);
    console.log( 'eventGestionCaso2', caseType );

    if( caseType == 'MANUAL' ) {
      this.router.navigateByUrl( `${environment.folders.routeAdmin}gestion/responder/${event}` );
    }
    else {
      this.router.navigateByUrl( `${environment.folders.routeAdmin}gestion/recibir/gestionar/${event}` );
    }
  }

  eventLocalizar( event ){
    console.log( 'eventLocalizar', event );
  }

  irMapa(){
    this.router.navigate( [`${environment.folders.routeMap}`] , { queryParams: {incidentes: "true"}, state: { from: 'admin' } });
  }

  /**
   * listado de clases de caso
   */
  classCase() {
    this.http.ejecutarServicioGet( `${environment.endpoints.adminCasesFilter}/caseType`, null ).subscribe( (resp: Response) => {
      // console.log(resp);
      switch (resp.statusText) {
        case 'OK':
          let data = resp.json();
          // console.log(data);
          let dataSelect = [];
          dataSelect.push( {id:"-Sel", text: "Seleccionar Clase"} );
          _.forEach(data, (value, key) => {
            // console.log({id: key, text: value})
            dataSelect.push({id: key, text: value});
          })
          this.selectClases = dataSelect;
          this.cdr.markForCheck();
          break;
        default:
          this.showError( resp );
          break;
      }
      }, (error: Response) => {
      this.showError( error );
    });
  }

  /**
   * listado de prioridades
   */
  prioridadesCase() {
    this.http.ejecutarServicioGet( `${environment.endpoints.adminCasesFilter}/alertLevel`, null ).subscribe( (resp: Response) => {
      // console.log(resp);
      switch (resp.statusText) {
        case 'OK':
          let data = resp.json();
          // console.log(data);
          let dataSelect = [];
          dataSelect.push( {id:"-Sel", text: "Seleccionar Prioridad"} );
          _.forEach(data, (value, key) => {
            // console.log({id: key, text: value})
            dataSelect.push({id: key, text: value});
          })
          this.selectPrioridades = dataSelect;
          this.cdr.markForCheck();
          break;
        default:
          this.showError( resp );
          break;
      }
      }, (error: Response) => {
      this.showError( error );
    });
  }

  /**
   * listado de rango
   */
  rangoCase() {
    this.http.ejecutarServicioGet( `${environment.endpoints.adminCasesFilter}/alertRange`, null ).subscribe( (resp: Response) => {
      // console.log(resp);
      switch (resp.statusText) {
        case 'OK':
          let data = resp.json();
          // console.log(data);
          let dataSelect = [];
          dataSelect.push( {id:"-Sel", text: "Seleccionar Rango"} );
          _.forEach(data, (value, key) => {
            // console.log({id: key, text: value})
            dataSelect.push({id: key, text: value});
          })
          this.selectRangos = dataSelect;
          this.cdr.markForCheck();
          break;
        default:
          this.showError( resp );
          break;
      }
      }, (error: Response) => {
      this.showError( error );
    });
  }

  /**
   * listado de ruta
   */
  rutaCase() {
    this.http.ejecutarServicioGet( `${environment.endpoints.adminCasesFilter}/route`, null ).subscribe( (resp: Response) => {
      // console.log(resp);
      switch (resp.statusText) {
        case 'OK':
          let data = resp.json();
          // console.log(data);
          let dataSelect = [];
          dataSelect.push( {id:"-Sel", text: "Seleccionar Ruta"} );
          _.forEach(data, (value, key) => {
            // console.log({id: key, text: value})
            dataSelect.push({id: key, text: value});
          })
          this.selectRutas = dataSelect;
          this.cdr.markForCheck();
          break;
        default:
          this.showError( resp );
          break;
      }
      }, (error: Response) => {
      this.showError( error );
    });
  }

  /**
   * Valida que la fecha de creación sea menor o igual que la fecha actual y menor o igual a fecha final.
   */
  validateCreationDate() {
    let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
    + (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
    setTimeout(()=>{
			if (this.formModelGestion.fechaCreacion !== null && this.formModelGestion.fechaCreacion !== undefined) {
        let fechaCreacion = this.formModelGestion.fechaCreacion.year + "" + (this.formModelGestion.fechaCreacion.month < 10 ? '0' + this.formModelGestion.fechaCreacion.month : this.formModelGestion.fechaCreacion.month) + "" 
				+ (this.formModelGestion.fechaCreacion.day < 10 ? '0' + this.formModelGestion.fechaCreacion.day : this.formModelGestion.fechaCreacion.day);
				if(Number(fechaCreacion) > Number(fechaActualFormato)){
					this.uiService.showMessage("Seleccione una fecha menor o igual a la fecha de hoy.")
					this.formModelGestion.fechaCreacion = null;
				} else if (this.formModelGestion.fechaFinal !== null && this.formModelGestion.fechaFinal !== undefined) {
					let fechaFinal = this.formModelGestion.fechaFinal.year + "" + (this.formModelGestion.fechaFinal.month < 10 ? '0' + this.formModelGestion.fechaFinal.month : this.formModelGestion.fechaFinal.month) + "" 
          + (this.formModelGestion.fechaFinal.day < 10 ? '0' + this.formModelGestion.fechaFinal.day : this.formModelGestion.fechaFinal.day);
					if(Number(fechaFinal) < Number(fechaCreacion)){
						this.uiService.showMessage("La fecha de creación debe ser menor o igual a la fecha final.")
						this.formModelGestion.fechaCreacion = null;
					}
        }
        this.cdr.markForCheck();
			}
		})
  }

  /**
   * Valida que la fecha final sea menor o igual que la fecha actual y menor o igual a fecha de creación.
   */
  validateFinalDate() {
    let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
    + (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
    setTimeout(()=>{
			if (this.formModelGestion.fechaFinal !== null && this.formModelGestion.fechaFinal !== undefined) {
        let fechaFinal = this.formModelGestion.fechaFinal.year + "" + (this.formModelGestion.fechaFinal.month < 10 ? '0' + this.formModelGestion.fechaFinal.month : this.formModelGestion.fechaFinal.month) + "" 
				+ (this.formModelGestion.fechaFinal.day < 10 ? '0' + this.formModelGestion.fechaFinal.day : this.formModelGestion.fechaFinal.day);
				if(Number(fechaFinal) > Number(fechaActualFormato)){
					this.uiService.showMessage("Seleccione una fecha menor o igual a la fecha de hoy.")
					this.formModelGestion.fechaFinal = null;
				} else if (this.formModelGestion.fechaCreacion !== null && this.formModelGestion.fechaCreacion !== undefined) {
					let fechaCreacion = this.formModelGestion.fechaCreacion.year + "" + (this.formModelGestion.fechaCreacion.month < 10 ? '0' + this.formModelGestion.fechaCreacion.month : this.formModelGestion.fechaCreacion.month) + "" 
          + (this.formModelGestion.fechaCreacion.day < 10 ? '0' + this.formModelGestion.fechaCreacion.day : this.formModelGestion.fechaCreacion.day);
					if(Number(fechaFinal) < Number(fechaCreacion)){
						this.uiService.showMessage("La fecha final debe ser mayor o igual a la fecha de creación.")
						this.formModelGestion.fechaFinal = null;
					}
        }
        this.cdr.markForCheck();
			}
		})
  }
}

