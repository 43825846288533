import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData, Options } from 'select2';
import {HttpService} from '../../../../shared/services/http.service';
import {environment} from '../../../../../../environments/environment';
import {UIService} from '../../../../trans/_services/UI.service';
import {Response} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'kt-supervisar-caso',
  templateUrl: './supervisar-caso.component.html',
  styleUrls: ['./supervisar-caso.component.scss'],
  host: {'class': 'sths-tb-element'}
})
export class SupervisarCasoComponent implements OnInit {

  sthsQSModel1: NgbDateStruct;
  sthsQSModel2: NgbDateStruct;
  sthsCambiar: boolean = true;
  public options: Options;

  // Paginador
  paginador: Object = {
    count: 10,
    page: 1
  };
  // Tabla
  configTable: Object;
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  conditionalLoader: boolean = false;
  conditionalLoaderForm: boolean = false;

  public selectProfiles: Array<Select2OptionData>;

  isDP1Filled: boolean = false;
  isDP2Filled: boolean = false;
  isProfileFilled: boolean = false;

  reportForm: FormGroup;
  submitted = false;

  dp1: string;
  dp2: string;
  profile: string;
  initDate: string;
  finalDate: string;
  maxDate = null;
  minDate = null;

  totalAssignedCases: string;
  totalAttendedCases: string;
  totalPendingCases: string;
  totalTransferredCases: string;
  dataArray = [];
  dataJson = {
    superviseResult: []
  };

  constructor(private cdr: ChangeDetectorRef,
    private router: Router,
    private http: HttpService,
    private uiService: UIService,
		private formBuilder: FormBuilder,) { }

  ngOnInit() {
		this.http
			.ejecutarServicioGet(environment.endpoints.adminUsersProfiles, null)
			.subscribe((resProfiles: Response) => {
				let dataArray = [];
				switch (resProfiles.status) {
					case 200:
					case 201:
					case 204:
            let data = resProfiles.json();
            
            //all porfiles option
            data[0].id = '0';
            data[0].text = 'Todos los perfiles';

						for (let index=0;index<data.length;index++) {
              let idx = index+1;
              data[idx].id = data[idx].id;
							data[idx].text = data[idx].name;
							if (idx===data.length-1) {
								this.selectProfiles = data;
								break;
							}
						}

						this.cdr.markForCheck();
						break;
					default:
						this.showError(resProfiles);
						break;
				}
			}, (error: Response) => {
				this.showError(error);
			}
    );
    
		this.reportForm = this.formBuilder.group({
			profile: [0, Validators.required],
			dp2: ['', Validators.required],
			dp1: ['', Validators.required]
		});

    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: "select2-dropdown-forms"
    }
  }

  get f() {
		return this.reportForm.controls;
	}

  consultarReporte() {
    this.conditionalLoaderForm = true;
    this.submitted = true;

    if(!this.reportForm.value['profile'] && !this.reportForm.value['dp1'] && 
       !this.reportForm.value['dp2']){
        let fieldDP1 = this.reportForm.get('dp1');
  			let fieldDP2 = this.reportForm.get('dp2');
        let fieldProfile = this.reportForm.get('profile');
        
        fieldDP1.setValidators([Validators.required, Validators.maxLength(100)]);
        fieldDP2.setValidators([Validators.required, Validators.maxLength(100)]);
        fieldProfile.setValidators([Validators.required, Validators.maxLength(100)]);
        fieldDP1.updateValueAndValidity();
        fieldDP2.updateValueAndValidity();
        fieldProfile.updateValueAndValidity();
        return;
    }
    if (this.reportForm.invalid) {
       return;
    }

    this.dp1 = this.reportForm.value['dp1'];
    this.dp2 = this.reportForm.value['dp2'];
    this.profile = this.reportForm.value['profile'];

    let ruta = environment.endpoints.caseMonitorReport
                          .replace('{{profile_id}}', this.profile)
                          .replace('{{initial_Date}}', this.dp1['year']+"-"+this.dp1['month']+"-"+this.dp1['day'])
                          .replace('{{final_Date}}', this.dp2['year']+"-"+this.dp2['month']+"-"+this.dp2['day']);
    this.http
        .ejecutarServicioGet(ruta, null)
        .subscribe((resData: Response) => {
          let dataArray = [];
          switch (resData.status) {
            case 200:
            case 201:
            case 204:
              let data = resData.json();

              this.profile = data.profile;
              this.initDate = data.initDate;
              this.finalDate = data.finalDate;
              this.totalAssignedCases = data.totalAssignedCases;
              this.totalAttendedCases = data.totalAttendedCases;
              this.totalPendingCases = data.totalPendingCases;
              this.totalTransferredCases = data.totalTransferredCases;
              console.log(data);
              console.log(data.superviseResult);
              if( data && data.superviseResult ) {
                for (let x = 0; x < data.superviseResult.length; x++) {
                  this.dataArray.push(
                    [
                      '',
                      data.superviseResult[x].profileName == undefined?' ': data.superviseResult[x].profileName,
                      data.superviseResult[x].userName == undefined?' ': data.superviseResult[x].userName,
                      data.superviseResult[x].assignedCases == undefined?' ': data.superviseResult[x].assignedCases, 
                      data.superviseResult[x].attendedCases == undefined?' ': data.superviseResult[x].attendedCases,
                      data.superviseResult[x].transferredCases == undefined?' ': data.superviseResult[x].transferredCases,
                      data.superviseResult[x].pendingCases == undefined?' ': data.superviseResult[x].pendingCases 
                    ]
                  );

                  this.dataJson.superviseResult.push({
                    "profileName": data.superviseResult[x].profileName == undefined?' ': data.superviseResult[x].profileName,
                    "userName": data.superviseResult[x].userName == undefined?' ': data.superviseResult[x].userName,
                    "assignedCases": data.superviseResult[x].assignedCases == undefined?' ': data.superviseResult[x].assignedCases,
                    "attendedCases": data.superviseResult[x].attendedCases == undefined?' ': data.superviseResult[x].attendedCases,
                    "transferredCases": data.superviseResult[x].transferredCases == undefined?' ': data.superviseResult[x].transferredCases,
                    "pendingCases": data.superviseResult[x].pendingCases == undefined?' ': data.superviseResult[x].pendingCases 
                  });    
                }

                let script = document.createElement("script");
                let code = document.createTextNode(
                  'var reportPieChart = {' + "\n" +
                    '"$schema": "https://vega.github.io/schema/vega/v5.json",' + "\n" +
                    '"width": 500,' + "\n" +
                    '"height": 300,' + "\n" +
                    '"autosize": "none",' + "\n" +
                    '"data": [{' + "\n" +
                            '"name": "table",' + "\n" +
                            '"values": [' + "\n" +
                                '{"id": "Total Casos Atendidos", "field":' + "\n" + ((parseInt(this.totalAttendedCases)/parseInt(this.totalAssignedCases))*100).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + '},' + "\n" +
                                '{"id": "Total Casos Escalados", "field":' + "\n" + ((parseInt(this.totalTransferredCases)/parseInt(this.totalAssignedCases))*100).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + '},' + "\n" +
                                '{"id": "Total Casos Pendientes", "field":' + "\n" + ((parseInt(this.totalPendingCases)/parseInt(this.totalAssignedCases))*100).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] + '}' + "\n" +
                            '], "transform": [{' + "\n" +
                                '"type": "pie",' + "\n" +
                                '"field": "field"' + "\n" +
                            '}]' + "\n" +
                    '}],' + "\n" +
                    '"scales": [{' + "\n" +
                        '"name": "color",' + "\n" +
                        '"type": "ordinal",' + "\n" +
                        '"domain": {"data": "table", "field": "id"},' + "\n" +
                        '"range": {"scheme": "category20"}' + "\n" +
                    '},' + "\n" +
                    '{' + "\n" +
                    '    "name": "r",' + "\n" +
                    '    "type": "sqrt",' + "\n" +
                    '    "domain": {"data": "table", "field": "field"},' + "\n" +
                    '    "zero": true,' + "\n" +
                    '    "range": [20, 100]' + "\n" +
                    '}],' + "\n" +
                    '"legends": [{' + "\n" +
                            '"stroke": "color",' + "\n" +
                            '"title": "Tipos de Casos",' + "\n" +
                            '"orient": "top-right",' + "\n" +
                            '"encode": {' + "\n" +
                                '"symbols": {' + "\n" +
                                    '"update": {' + "\n" +
                                        '"fill": {"value": ""},' + "\n" +
                                        '"strokeWidth": {"value": 2},' + "\n" +
                                        '"size": {"value": 64}' + "\n" +
                    '}}}}],' + "\n" +
                    '"marks": [{' + "\n" +
                            '"type": "arc",' + "\n" +
                            '"from": {"data": "table"},' + "\n" +
                            '"encode": {' + "\n" +
                                '"enter": {' + "\n" +
                                    '"fill": {"scale": "color", "field": "id"},' + "\n" +
                                    '"x": {"signal": "width / 2"},' + "\n" +
                                    '"y": {"signal": "height / 2"}' + "\n" +
                            '}, "update": {' + "\n" +
                                    '"startAngle": {"field": "startAngle"},' + "\n" +
                                    '"endAngle": {"field": "endAngle"},' + "\n" +
                                    '"outerRadius": {"signal": "width / 4"},' + "\n" +
                    '}}},' + "\n" +
                    '{' + "\n" +
                        '"type": "text",' + "\n" +
                        '"from": {"data": "table"},' + "\n" +
                        '"encode": {' + "\n" +
                        '    "enter": {' + "\n" +
                        '        "x": {"field": {"group": "width"}, "mult": 0.5},' + "\n" +
                        '        "y": {"field": {"group": "height"}, "mult": 0.5},' + "\n" +
                        '        "radius": {"scale": "r", "field": "field", "offset": 8},' + "\n" +
                        '        "theta": {"signal": "(datum.startAngle + datum.endAngle)/2"},' + "\n" +
                        '        "fill": {"value": "#000"},' + "\n" +
                        '        "align": {"value": "center"},' + "\n" +
                        '        "baseline": {"value": "top"},' + "\n" +
                        '        "text": {"field": "field"}' + "\n" +
                        '    }' + "\n" +
                        '}' + "\n" +
                      '}' + "\n" +
                  ']' + "\n" +
                  '};' + "\n" +
                  "vegaEmbed('#report', reportPieChart)" +
                  ".then(result => console.log(result))"+
                  ".catch(console.warn);"
                );
                script.appendChild(code);
                document.body.appendChild(script);

                this.cambiarPantalla();
              } else {
                this.uiService.showMessage('No se encontraron resultados');
              }
              this.conditionalLoaderForm = false;
              this.cdr.markForCheck();
              break;
            default:
              this.conditionalLoaderForm = false;
              this.showError(resData);
              this.cdr.markForCheck();
              break;
          }
        }, (error: Response) => {
          this.showError(error);
          this.conditionalLoaderForm = false;
          this.cdr.markForCheck();
        }
      );
  }

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}

  procesarJson( data ) {
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['id'] != undefined? data[index]['id'].toString(): ' ',
        data[index]['perfil'] != undefined? data[index]['perfil'].toString(): ' ',
        data[index]['usuario'] != undefined? data[index]['usuario'].toString(): ' ',
        data[index]['asignados'] != undefined? data[index]['asignados'].toString(): ' ',
        data[index]['atendidos'] != undefined? data[index]['atendidos'].toString(): ' ',
        data[index]['escalados'] != undefined? data[index]['escalados'].toString(): ' ',
        data[index]['pendientes'] != undefined? data[index]['pendientes'].toString(): ' '
      ]);

      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }

    if( !data.length ){
      this.armarTabla(dataArray);
    }
  }

  armarTabla(data) {
    this.contentToShow = 1;
    this.sthsCambiar = false;

    this.configTabla = {
      status: 1,
      header: ['ID', 'Perfil', 'Nombre de Usuario', 'Casos Asignados', 'Casos Atendidos', 'Casos Escalados', 'Casos Pendientes'],
      typeColumn: [,,,,,,],
      showSearch: false,
      searchColumn: [1],
      searchTitle: 'Buscar Categoría',
      hideColumns: [0],
      showExport: true,
      showPag: false,
      leftsColumns: [1],
      configColumns: {
        id: 0,
        columnClick: 0,
      },
      data: data,
      paginador: this.paginador,
    }

    this.cdr.markForCheck();
  }

  cambiarPantalla() {
  	if (this.sthsCambiar) {
      this.sthsCambiar = false;
      
      this.conditionalLoader = true;
      
      setTimeout(() => {
        // Cambiar por el Servicio
        this.conditionalLoader = false;
        this.armarTabla(this.dataArray);
      }, 400);

  	} else {
      this.dataArray = []; 

      this.reportForm = new FormGroup({
        profile: new FormControl(''),
        dp1: new FormControl(''),
        dp2: new FormControl('')
      });
      this.sthsCambiar = true;
    }
  }

  exportData(event) {
    this.conditionalLoader = true;
    let ruta = environment.endpoints.caseMonitorReportExport.replace('{{REPORT_TYPE}}', event);
    this.conditionalLoader = true;
    let lastRequest = {
      "profile": this.profile,
      "initDate": this.initDate,
      "finalDate": this.finalDate,
      "totalAssignedCases": this.totalAssignedCases,
      "totalAttendedCases": this.totalAttendedCases,
      "totalTransferredCases": this.totalTransferredCases,
      "totalPendingCases": this.totalPendingCases,
      "superviseResult": this.dataJson.superviseResult
    };

    this.http
        .ejecutarServicioPost(ruta, lastRequest)
        .subscribe((res) => {
            this.conditionalLoader = false;
            this.uiService.downloadFileBase64(res['_body'], event, 'supervisar_caso');
            this.conditionalLoader = false;
            this.cdr.markForCheck();
        }, (error: Response) => {
            this.showError(error);  
            this.conditionalLoader = false;
            this.cdr.markForCheck();
        }
    );// END GET*/
  }

  changeFacheInicio(e){
    // console.log(e)
    if (e){
      this.minDate = e
    }
  }

  changeFacheFin(e){
    // console.log(e)
    if (e){
      this.maxDate = e
    }
  }

  



}
