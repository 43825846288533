export class AdminRolDTO {
    id: number;
    name: string;
    description: string;
    userPermissions: string;
    // userPermissions: number[];

    constructor(data?: any) {
        if (data !== undefined) {
            this.id = data['id'] !== undefined ? data['id'] : null;
            this.name = data['name'] !== undefined ? data['name'] : null;
            this.description = data['description'] !== undefined ? data['description'] : null;
            this.userPermissions = data['userPermissions'] !== undefined ? data['userPermissions'] : null;
        }
    }

    static fromJS(data: any): AdminRolDTO {
        return new AdminRolDTO(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : null;
        data['name'] = this.name !== undefined ? this.name : null;
        data['description'] = this.description !== undefined ? this.description : null;
        data['userPermissions'] = this.userPermissions !== undefined ? this.userPermissions : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : '';
        data['name'] = this.name !== undefined ? this.name : '';
        data['description'] = this.description !== undefined ? this.description : '';
        data['userPermissions'] = this.userPermissions !== undefined ? this.userPermissions : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminRolDTO(JSON.parse(json));
    }
}
