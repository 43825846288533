import { NgModule, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Select2OptionData, Options } from 'select2';
import { environment } from '@environments/environment';
import { HttpService } from '@app/views/shared/services/http.service';
import { Response } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-editar-indicadores',
	templateUrl: './editar-indicadores.component.html',
	styleUrls: ['./editar-indicadores.component.scss'],
	host: { class: 'sths-tb-element' }
})

export class EditarIndicadoresComponent implements OnInit {
	public options: Options;

	// Modales
	contentToShowModal: number = -1;
	configModal: {} = { status: -1 };
	indicators = [];
	public selectIndicadores: Array<Select2OptionData>;
	currentIndicator: any = {};
	selectedId: any = null;

	constructor(private cdr: ChangeDetectorRef, private http: HttpService) {
		this.http
			.ejecutarServicioGet(environment.endpoints.adminIndicatorsList)
			.subscribe((indicatorsResponse: Response) => {
				const indicators = indicatorsResponse.json();
				this.indicators = indicators.map(i => {
					return { ...i, active: +i.active };
				});
				this.currentIndicator = this.indicators[0];
				this.selectedId = this.currentIndicator["id"];
				this.selectIndicadores = this.indicators.map(i => {
					return {
						id: i.id,
						text: i.name
					};
				});
				
				this.cdr.markForCheck();
			});
	}

	ngOnInit() {

		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
	}

	guardarIndicaciones() {
		// Modal Guardó
		// this.loader = true;
		this.http.ejecutarServicioPut(`${environment.endpoints.adminIndicator}${this.currentIndicator.id}/state/${this.currentIndicator.active}`)
			.subscribe(
				(res: Response) => {
					// this.loader = false;
					this.showModal();
				});
	}

	changeIndicator() {
		console.log(this.selectedId,'selected');
		if (this.selectedId !== '' && this.selectedId !== null && this.selectedId !== undefined) {
			this.currentIndicator = this.indicators.find(
				i => {
					if (i.id === parseInt(this.selectedId, 10)) {
						return i;
					}
				}
			);
		}
	}

	showModal() {
		this.contentToShowModal = 1;

		this.configModal = {
			status: 1,
			icono: 'smart-icon-guardar',
			titulo: 'Los datos han sido guardados',
			textos: ['Los datos ingresados han sido guardados con éxito'],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'guardarIndicaciones'
				}
			]
		};
		this.cdr.markForCheck();
	}

	changeStatusModal(event) {
		console.log(event);
		this.contentToShowModal = -1;
		this.configModal = { status: -1 };
		this.cdr.markForCheck();
	}
}
