import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UIService } from './../../../../trans/_services/UI.service';
import { HttpService } from '../../../../shared/services/http.service';
import { MatSnackBarRef } from '@angular/material';
import { LayoutUtilsService, MessageType } from './../../../../../core/_base/crud/utils/layout-utils.service';
import { environment } from '@environments/environment';
import { Response } from '@angular/http';
import * as _ from 'lodash';

@Component({
  selector: 'kt-reporte-sensores-peso',
  templateUrl: './reporte-sensores-peso.component.html',
  styleUrls: [
    './reporte-sensores-peso.component.scss',
    './../../gestion/gestion-manual/gestion-manual.component.scss'
  ],
  host: { 'class': 'sths-tb-element' }
})
export class ReporteSensoresPesoComponent implements OnInit {
  conditionalLoader: boolean = false;
  idNumber: number = 1;
  fechasSeleccionadas: {}[] = [];
  private _actualDialog: MatSnackBarRef<any>;
  lastRequest: any;
  dataTablechunk: Array<any>;

  /** NgModel */
  tipoFechas: string = 'Rango Fechas';
  fechaEscogida: any;
  fechaDesde: any;
  fechaHasta: any;
  filtrarConcesionario: boolean = true;
  selectConcesionarios: Array<any> = [];
  filtrarTipologia: boolean = true;
  selectTipologias: Array<any> = [];
  filtrarBus: boolean = true;
  selectBuses: Array<any> = [];
  filtrarRuta: boolean = true;
  selectRutas: Array<any> = [];
  filtrarConductor: boolean = true;
  selectConductores: Array<any> = [];
  filtrarTipoReporte: boolean = true;
  selectTiposReportes: Array<any> = [{id: 'Peso', nombre: 'Peso'}];

  /** Data for select box */
  concesionarios: Array<any> = [];
  tipologias: Array<any> = [];
  buses: Array<any> = [];
  rutas: Array<any> = [];
  conductores: Array<any> = [];
  tiposReportes: Array<any> = [];

  /** Stetting TablasComponent */
  configTable: object;
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };
  paginador = {
    count: 30, // cantidad de itemes por pagina
    page: 1, // paginador seleccionado
    total: 0, // total de items 
    maxPages: 5 // paginadores a mostrar 
  };

  /** Stetting MultiSelectComponent */
  dropdownSettings: {};

  _loadConcesionario = false;
  _loadTipologia = false;
  _loadBus = false;
  _loadRuta = false;
  _loadConductor = false;
  _loadTipoReportes = false;

  loadValidate = false;

  constructor(
    private uiService: UIService,
    private cdr: ChangeDetectorRef,
    private http: HttpService,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  ngOnInit() {
    this.loadConcesionario();
    this.loadTipologia();
    this.loadBus();
    this.loadRuta();
    this.loadConductor();
    // this.loadTipoReportes();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'nombre',
      selectAllText: 'Todos',
      unSelectAllText: 'Ninguno',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText : 'Buscar',
      noDataAvailablePlaceholderText : 'Datos no disponibles...'
    };

    let d = new Date();
    this.fechaDesde = { year: d.getFullYear() -1, month: d.getMonth() + 1, day: d.getDate() };
    this.fechaHasta = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  /**
   * Error al cargar datos
   */
  loadDataError(error) {
    if (this._actualDialog) { this._actualDialog.dismiss(); }
    const message = 'Error cargando datos ';
    this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
  }

  /**
   * Cargar datos consecionarios
   */
  loadConcesionario() {
    this._loadConcesionario = true;
    this.http.ejecutarServicioGet(`${environment.endpoints.parent}/lista_filtro/concesionario`).subscribe((res: Response) => {
      let data = res.json();
      if (data.length > 0) {
        this.concesionarios = data;
      }
      this._loadConcesionario = false;
      this.loaded();
      this.cdr.markForCheck();
    }, (error: any) => {this.loadDataError(error); this._loadConcesionario = false; this.cdr.markForCheck();} );
  }

  /**
   * Cargar datos tipologias
   */
  loadTipologia() {
    this._loadTipologia = true;
    this.http.ejecutarServicioGet(`${environment.endpoints.parent}/lista_filtro/tipologia`).subscribe((res: Response) => {
      let data = res.json();
      if (data.length > 0) {
        this.tipologias = data;
      }
      this._loadTipologia = false;
      this.loaded();
      this.cdr.markForCheck();
    }, (error: any) => {this.loadDataError(error); this._loadTipologia = false; this.cdr.markForCheck();} );
  }

  /**
   * Cargar datos Buses
   */
  loadBus() {
    this._loadBus = true;
    this.http.ejecutarServicioGet(`${environment.endpoints.parent}/lista_filtro/Bus`).subscribe((res: Response) => {
      let data = res.json();
      if (data.length > 0) {
        this.buses = data;
      }
      this._loadBus = false;
      this.loaded();
      this.cdr.markForCheck();
    }, (error: any) => {this.loadDataError(error); this._loadBus = false; this.cdr.markForCheck();} );
  }

  /**
   * Cargar datos Rutas
   */
  loadRuta() {
    this._loadRuta = true;
    this.http.ejecutarServicioGet(`${environment.endpoints.parent}/lista_filtro/ruta`).subscribe((res: Response) => {
      let data = res.json();
      console.log('ruta', data);
      if (data.length > 0) {
        this.rutas = data;
      }
      this._loadRuta = false;
      this.cdr.markForCheck();
    }, (error: any) => {this.loadDataError(error); this._loadRuta = false; this.cdr.markForCheck();} );
  }

  /**
   * Cargar datos Conductores
   */
  loadConductor() {
    this._loadConductor = true;
    this.http.ejecutarServicioGet(`${environment.endpoints.parent}/lista_filtro/conductor`).subscribe((res: Response) => {
      let data = res.json();
      if (data.length > 0) {
        this.conductores = data;
      }
      this._loadConductor = false;
      this.loaded();
      this.cdr.markForCheck();
    }, (error: any) => {this.loadDataError(error); this._loadConductor = false; this.cdr.markForCheck();} );
  }

  /**
   * Cargar datos Tipo de reportes
   */
  loadTipoReportes() {
    this._loadTipoReportes = true;
    this.http.ejecutarServicioGet(`${environment.endpoints.parent}/lista_filtro/botonpanico_tipocaso`).subscribe((res: Response) => {
      let data = res.json();
      if (data.length > 0) {
        this.tiposReportes = data;
      }
      this._loadTipoReportes = false;
      this.loaded();
      this.cdr.markForCheck();
    }, (error: any) => {this.loadDataError(error); this._loadTipoReportes = false; this.cdr.markForCheck();} );
  }

  /**
   * Enviar fecha
   * @param event 
   */
  enviarFecha(event) {
    this.idNumber++;
    let dia = this.fechaEscogida.day < 10 ? '0'+this.fechaEscogida.day.toString() : this.fechaEscogida.day.toString();
    let mes = this.fechaEscogida.month < 10 ? '0'+this.fechaEscogida.month.toString() : this.fechaEscogida.month.toString();
    let fechaSeleccionada = this.fechaEscogida.year + "" + mes + "" + dia;
    let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
    + (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
    if(Number(fechaSeleccionada) > Number(fechaActualFormato)){
      this.uiService.showMessage("Seleccione una fecha menor o igual a la fecha de hoy.");
      this.cdr.markForCheck();
    } else {
      this.fechasSeleccionadas.push({
        fecha: this.fechaEscogida.year +"-" + mes + "-"+ dia
      });
    }
  }

  /**
   * Eliminar fecha
   */
  eliminarFecha(item) {
    const index: number = this.fechasSeleccionadas.indexOf(item);
    if (index !== -1) {
      this.fechasSeleccionadas.splice(index, 1);
    }
  }

  /**
   * Click page paginator
   * @param event 
   */
  goPage(event) {
    // console.log(event)
    this.paginador['page'] = event;
    this.loadData();
  }

  /**
   * Ejecutar reportes
   */
  generarReporte() {
    this.conditionalLoader = true;

    let fechaInicial;
    let fechaFinal;
    let typoFiltroFecha;
    let rangoFechasArr = [];
    let _concesionarios = [];
    let _tipologias = [];
    let _buses = [];
    let _rutas = [];
    let _conductores = [];
    let _tipoReporte = [];

    if (this.tipoFechas === 'Rango Fechas') {
      if (this.fechaDesde !== null && this.fechaDesde !== undefined && this.fechaHasta !== null && this.fechaHasta !== undefined) {
        typoFiltroFecha = 'RangoFechas';
        let diaIni = this.fechaDesde.day < 10 ? '0'+this.fechaDesde.day.toString() : this.fechaDesde.day.toString();
        let mesIni = this.fechaDesde.month < 10 ? '0'+this.fechaDesde.month.toString() : this.fechaDesde.month.toString();
        let diaFin = this.fechaHasta.day < 10 ? '0'+this.fechaHasta.day.toString() : this.fechaHasta.day.toString();
        let mesFin = this.fechaHasta.month < 10 ? '0'+this.fechaHasta.month.toString() : this.fechaHasta.month.toString();  
        fechaInicial = this.fechaDesde.year.toString()+"-"+mesIni+"-"+diaIni;
        fechaFinal = this.fechaHasta.year.toString()+"-"+mesFin+"-"+diaFin;
      } else {
        this.uiService.showMessage("Debe llenar los campos de fecha.");
        this.conditionalLoader = false;
        return;
      }
    } else {
      typoFiltroFecha = 'FechasPuntuales';
      if(this.fechasSeleccionadas.length > 0) {
          this.fechasSeleccionadas.forEach(fecha => {
            rangoFechasArr.push(fecha['fecha']);
        });
      } else {
        this.uiService.showMessage("Debe seleccionar al menos una fecha.");
        this.conditionalLoader = false;
        return;
      }
    }

    console.log('Concesionarios => ',this.selectConcesionarios);
    if (this.selectConcesionarios.length > 0 && this.filtrarConductor) {
      this.selectConcesionarios.forEach(element => {
        if (typeof element === 'object') {
          _concesionarios.push(element.id);
        } else {
          _concesionarios.push(element);
        }
      });
    }
    console.log('Tipologias => ', this.selectTipologias);
    if (this.selectTipologias.length > 0 && this.filtrarTipologia) {
      this.selectTipologias.forEach(element => {
        if (typeof element === 'object') {
          _tipologias.push(element.id);
        } else {
          _tipologias.push(element);
        }
      });
    }
    console.log('Buses => ', this.selectBuses);
    if (this.selectBuses.length > 0 && this.filtrarBus) {
      this.selectBuses.forEach(element => {
        if (typeof element === 'object') {
          _buses.push(element.id);
        } else {
          _buses.push(element);
        }
      });
    }
    console.log('Rutas => ', this.selectRutas);
    if (this.selectRutas.length > 0 && this.filtrarRuta) {
      this.selectRutas.forEach(element => {
        if (typeof element === 'object') {
          _rutas.push(element.id);
        } else {
          _rutas.push(element);
        }
      });
    }
    console.log('Conductores => ', this.selectConductores);
    if (this.selectConductores.length > 0 && this.filtrarConductor) {
      this.selectConductores.forEach(element => {
        if (typeof element === 'object') {
          _conductores.push(element.id);
        } else {
          _conductores.push(element);
        }
      });
    }
    console.log('Tipo Reporte => ', this.selectTiposReportes);
    if (this.selectTiposReportes.length > 0 && this.filtrarTipoReporte) {
      this.selectTiposReportes.forEach(element => {
        if (typeof element === 'object') {
          _tipoReporte.push(element.id);
        } else {
          _tipoReporte.push(element);
        }
      });
    } else if (this.tiposReportes.length > 0 && this.filtrarTipoReporte) {
      this.tiposReportes.forEach(element => {
        if (typeof element === 'object') {
          _tipoReporte.push(element.id);
        } else {
          _tipoReporte.push(element);
        }
      });
    }

    this.lastRequest = {
      'tipo_filtro_fechas' : typoFiltroFecha,
      'fechaInicial': fechaInicial,
      'fechaFinal': fechaFinal,
      'fechasPuntuales': rangoFechasArr,
      'filtrarConcesionarios': this.filtrarConcesionario,
      'concesionarios': _concesionarios,
      'filtrarTipologias': this.filtrarTipologia,
      'tipologias': _tipologias,
      'filtrarBuses': this.filtrarBus,
      'buses' : _buses,
      'filtrarRutas': this.filtrarRuta,
      'rutas': _rutas,
      'filtrarConductores': this.filtrarConductor,
      'conductores': _conductores,
      'filtrarTipoReporte': this.filtrarTipoReporte,
      'tipo_reporte' : _tipoReporte
    };

    this.http.ejecutarServicioPost(`${environment.endpoints.parent}/get_report/`, this.lastRequest).subscribe(res => {
      this.conditionalLoader = false;
      let respose = res.json();
      if (respose.length > 0) {
        this.paginador['total'] = respose.length;
        this.dataTablechunk = _.chunk(respose, this.paginador['count']);
        // console.log(this.dataTablechunk);
        this.loadData();
      } else {
        this.paginador['total'] = 0;
        this.procesarJson([]);
        this.cdr.markForCheck();
      }
    }, (error: any) => {
      this.conditionalLoader = false;
      this.loadDataError(error);
      this.cdr.markForCheck();
    });

  }

  /**
   * cargar datos en la tabla con paginador
   */
  loadData() {
    // console.log(this.paginador);
    this.procesarJson(this.dataTablechunk[this.paginador['page'] - 1]);
  }

  /**
   * Mapear data del servifor para mostrar en tabla de resulatados
   * @param data 
   */
  procesarJson(data) {
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        '',
        data[index]['fecha'] ? data[index]['fecha'].toString() : '',
        data[index]['concesionario_nombre'] ? data[index]['concesionario_nombre'].toString() : '',
        data[index]['bus'] ? data[index]['bus'].toString() : '',
        data[index]['tipologia_nombre'] ? data[index]['tipologia_nombre'].toString() : '',
        data[index]['conductor_nombre'] ? data[index]['conductor_nombre'].toString() : '',
        data[index]['ruta_nombre'] ? data[index]['ruta_nombre'].toString() : '',
        // data[index]['tipo_reporte_descripcion'] ? data[index]['tipo_reporte_descripcion'].toString() : '',
        data[index]['count'] ? data[index]['count'].toString() : ''
      ]);

      if (index === data.length - 1) {
        this.armarTabla(dataArray);
      }
    }

    if (!data.length) {
      this.armarTabla(dataArray);
    }
  }

  /**
   * Contruir y pinta tabla de reportes
   * @param data
   */
  armarTabla(data) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Fecha', 'Concesionario', 'Bus', 'Tipología Bus', 'Conductor', 'Ruta', /*'Tipo Reporte',*/ 'Cantidad'],
      typeColumn: [, , , , , , , ,],
      showSearch: false,
      searchColumn: [1],
      searchTitle: 'Buscar Categoría',
      hideColumns: [0],
      showExport: true,
      showPag: true,
      leftsColumns: [1],
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }

  /**
   * Error del servidor
   * @param response
   */
  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage(response.json()['message']);
    } else {
      this.uiService.showMessage('Error del servidor');
    }
    this.conditionalLoader = false;
    this.cdr.markForCheck();
  }

  /**
   * Exportat archivo
   * @param event
   */
  exportData(event) {
    this.conditionalLoader = true;
    let request = Object.assign({} , this.lastRequest);
    request.title = 'REPORTE DE SENSORES DE PESO';
    this.http.ejecutarServicioPost(`${environment.endpoints.parent}/get_report/${event}`, request).subscribe((res: Response) => {
      this.conditionalLoader = false;
      this.uiService.downloadFileBase64(res['_body'], event, 'sensores peso');
      this.cdr.markForCheck();
    }, (error: any) => this.showError(error) );
  }

  loaded(){
    if(
      !this._loadBus && 
      !this._loadTipoReportes && 
      !this._loadTipologia && 
      !this._loadRuta && 
      !this._loadConductor &&
      !this._loadConcesionario
      ){
      this.loadValidate = true;
      this.cdr.markForCheck();
    }else{
      this.loadValidate = false;
      setTimeout(()=>{
        this.loaded();
      },3000)
    }
  }

  validateDate(){
    let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
		+ (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
    setTimeout(()=>{
      if(this.fechaHasta !== null && this.fechaHasta !== undefined && this.fechaHasta !== "") {
        let diaFin = this.fechaHasta.day < 10 ? '0'+this.fechaHasta.day.toString() : this.fechaHasta.day.toString();
        let mesFin = this.fechaHasta.month < 10 ? '0'+this.fechaHasta.month.toString() : this.fechaHasta.month.toString();
        let fechaFin = this.fechaHasta.year.toString()+mesFin+diaFin;
        if(Number(fechaFin) > Number(fechaActualFormato)){
          this.fechaHasta = null;
          this.uiService.showMessage("Seleccione una fecha menor o igual a la fecha de hoy.");
          this.cdr.markForCheck();
          return;
        }
      }
      if (this.fechaDesde !== null && this.fechaDesde !== undefined && this.fechaHasta !== null && this.fechaHasta !== undefined && this.fechaHasta !== "") {
        let diaIni = this.fechaDesde.day < 10 ? '0'+this.fechaDesde.day.toString() : this.fechaDesde.day.toString();
        let mesIni = this.fechaDesde.month < 10 ? '0'+this.fechaDesde.month.toString() : this.fechaDesde.month.toString();
        let diaFin = this.fechaHasta.day < 10 ? '0'+this.fechaHasta.day.toString() : this.fechaHasta.day.toString();
        let mesFin = this.fechaHasta.month < 10 ? '0'+this.fechaHasta.month.toString() : this.fechaHasta.month.toString();  
        let fechaIni = this.fechaDesde.year.toString()+mesIni+diaIni;
        let fechaFin = this.fechaHasta.year.toString()+mesFin+diaFin;
  
        if(Number(fechaFin) < Number(fechaIni)){
          //this.fechaHasta = this.fechaDesde;
          this.uiService.showMessage("Fecha desde debe ser menor o igual a Fecha hasta.")
          this.fechaDesde = this.fechaHasta;
          this.cdr.markForCheck();
        }
      }
    })
  }

}
