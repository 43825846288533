import {
  Component, OnInit, ChangeDetectorRef,
} from '@angular/core';
import { LayoutUtilsService, MessageType } from './../../../../../core/_base/crud/utils/layout-utils.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { MatSnackBarRef } from '@angular/material';
import { AuthService } from './../../../../../core/auth/_services/auth.service';
import * as _ from 'lodash';
import { UIService } from '../../../_services/UI.service';
import { UtilService } from '../../_services/util.service';
import { environment } from '@environments/environment';
import { debug } from 'util';

@Component({
  selector: 'kt-buscar-alarma',
  templateUrl: './buscar-alarma.component.html',
  styleUrls: ['./buscar-alarma.component.scss'],
  providers: [NgbModal]
})
export class BuscarAlarmaComponent implements OnInit {

  //Select2
  public motorTypeOptions: Array<Select2OptionData>;
  options: any = {
    centered: true,
    size: 'lg',
    windowClass: 'sths-modal-style',
    backdropClass: 'sths-backdrop'
  };

  loading: boolean = false;
  isDisabledSearch: boolean = false;
  isBuscarAlarma: boolean = true;
  isModificarAlarma: boolean = false;
  guardarIsDisabled: boolean = true;
  baseUrl = environment.endpoints.alarmsModule;
  itenToDelete: any;
  itemToChangeStatus: any = undefined;

  vehicleTypeOptions: any;
  alarmTypes: any;
  breakTypeOptions: any;
  signalTypeOptions: any;
  rangoInicialMax: any = [];
  possibleSignalOptions: any;
  possibleUnitOptions: any;
  groupedData: any = [];
  idGroupedData: number = 0;

  public signalOptions: any = [];
  public rangeOptions: any = [];
  private _actualDialog: MatSnackBarRef<any>;

  public tmpItem = {
    name: '',
    motorType: '',
    signalType: '',
    signal: '',
    breakType: '',
    initialRange: '',
    finalRange: '',
    measureUnit: '',
    range: '',
    alarmType: '',
    vehicleType: ""
  };

  // modal
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  // Tabla
  data: any;
  dataSet: any = [];
  dataSetAlarma: any = [];
  contentToShow: number = -1;
  configTabla: {} = { status: -1 };
  count = 0;
  paginador: object = {
    count: 10,
    page: 1,
    total: 0,
    maxPages: 5
  };

  filter = {
    answers: []
  }

  datosEditarAlarma = {
    alarma: <any>[],
    rangos: <any>[],
    alarmTypes: [],
    motorTypeOptions: {},
    breakTypeOptions: [],
    possibleSignalOptions: {},
    signalTypeOptions: {},
    rangeOptions: {},
    vehicleTypeOptions: {},
    baseUrl: this.baseUrl,
    isModificarAlarma: false
  };

  constructor(
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    private http: HttpService,
    private auth: AuthService,
    private layoutUtilsService: LayoutUtilsService,
    private uiService: UIService) {

    UtilService.volverBuscarAlarma.subscribe(data => {
      this.isModificarAlarma = false;
      this.isBuscarAlarma = true;
      /* this.paginador["total"] = 0;
      this.dataSet = [];
      this.armarTabla(this.dataSet); */
      this.buscarAlarma();
    });

    this.data = [];
    let urlTipoAlarma = this.baseUrl + '/alarmTypes/list';

    this.http.ejecutarServicioGet(urlTipoAlarma).subscribe(res => {
      const self = this;
      self.alarmTypes = [];
      if (res.json().length > 0) {
        let dataAlarmTypes = res.json();
        for (var i = 0; i < dataAlarmTypes.length; i++) {
          self.alarmTypes.push({
            id: dataAlarmTypes[i].id,
            text: dataAlarmTypes[i].name,
            answer: dataAlarmTypes[i].name,
            answerValue: dataAlarmTypes[i].name,
          })
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    let urlMotor = this.baseUrl + '/motors/list';
    this.http.ejecutarServicioGet(urlMotor).subscribe(res => {
      const self = this;
      self.motorTypeOptions = [];
      if (res.json().length > 0) {
        let dataMotor = res.json();
        for (var i = 0; i < dataMotor.length; i++) {
          if (self.motorTypeOptions.length === 0) {
            self.motorTypeOptions.push({
              id: "Todos",
              text: "Todos",
            })
          }

          self.motorTypeOptions.push({
            id: dataMotor[i].id,
            text: dataMotor[i].name,
            answer: dataMotor[i].name,
            answerValue: dataMotor[i].name,
          })
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    let urlVehicle = this.baseUrl + '/vehicleTypes/list';
    this.http.ejecutarServicioGet(urlVehicle).subscribe(res => {
      const self = this;
      self.vehicleTypeOptions = [];
      if (res.json().length > 0) {
        let dataVehicle = res.json();
        for (var i = 0; i < dataVehicle.length; i++) {
          if (self.vehicleTypeOptions.length === 0) {
            self.vehicleTypeOptions.push({
              id: "Todos",
              text: "Todos",
            })
          }

          self.vehicleTypeOptions.push({
            id: dataVehicle[i].id,
            text: dataVehicle[i].name,
          })
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    let urlBrake = this.baseUrl + '/brakes/list';
    this.http.ejecutarServicioGet(urlBrake).subscribe(res => {
      const self = this;
      self.breakTypeOptions = [];
      if (res.json().length > 0) {
        let dataBrake = res.json();
        for (var i = 0; i < dataBrake.length; i++) {
          self.breakTypeOptions.push({
            id: dataBrake[i].id,
            text: dataBrake[i].name,
            answer: dataBrake[i].name,
            answerValue: dataBrake[i].name,
          })
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    let urlSignalTypes = this.baseUrl + '/signalTypes/list';
    this.http.ejecutarServicioGet(urlSignalTypes).subscribe(res => {
      const self = this;
      self.signalTypeOptions = [];
      if (res.json().length > 0) {
        let dataSignalType = res.json();
        for (var i = 0; i < dataSignalType.length; i++) {
          self.signalTypeOptions.push({
            id: dataSignalType[i].id,
            text: dataSignalType[i].name,
            answer: dataSignalType[i].name,
            answerValue: dataSignalType[i].name,
          })
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    let urlSignal = this.baseUrl + '/signals/list';
    this.http.ejecutarServicioGet(urlSignal).subscribe(res => {
      const self = this;
      if (res.json().length > 0) {
        self.possibleSignalOptions = [];
        if (res.json().length > 0) {
          let dataSignal = res.json();
          for (var i = 0; i < dataSignal.length; i++) {
            self.possibleSignalOptions.push({
              id: dataSignal[i].id,
              text: dataSignal[i].name,
              answer: dataSignal[i].name,
              answerValue: dataSignal[i].name,
              unit: dataSignal[i].unit,
              signalType: dataSignal[i].signalType
            })
          }
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    this.armarTabla(this.data);
  }

  eliminarAlarma(event) {
    this.itenToDelete = event;
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-eliminar',
      titulo: 'Eliminar Alarma',
      textos: [
        '¿Esta seguro que quiere eliminar esta Alarma?'
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Eliminar',
          class: '',
          accion: 'eliminar'
        }
      ]
    };
    this.cdr.markForCheck();
  }

  eventEstadoClick(event) {
    this.itenToDelete = event;
    this.itemToChangeStatus = event;
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-eliminar',
      titulo: 'Cambiar Estado',
      textos: [
        '¿Seguro que desea cambiar el estado?'
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Cambiar',
          class: '',
          accion: 'estado'
        }
      ]
    };
    this.cdr.markForCheck();
  }

  limpiarCampos() {
    this.tmpItem.name = "";
    let priority;
    this.tmpItem.alarmType = "";
    this.tmpItem.motorType = "";
    this.tmpItem.breakType = "";
    this.tmpItem.signal = "";
    this.tmpItem.signalType = "";
    this.tmpItem.vehicleType = "";
  }

  eliminarItem() {
    this.contentToShowModal = 1;
    let urlLoadAlarm = `${this.baseUrl}/alarms/${this.itenToDelete}`;

    this.http.ejecutarServicioDelete(urlLoadAlarm, null).subscribe(res => {
      this.contentToShowModal = 10;
      let message;
      switch (res.status) {
        case 200:
        case 201:
        case 204:
          message = 'La alarma ha sido borrada';
          for (var i = 0; i < this.dataSet.length; i++) {
            if (this.dataSet[i][0] === this.itenToDelete) {
              this.dataSet.splice(i, 1);
              break;
            }
          }
          this.armarTabla(this.dataSet);
          this.cdr.markForCheck();
          break;
        default:
          message = 'Error borrando la alarma';
          break;
      }
      this.configModal = {
        status: 1,
        icono: 'smart-icon-notificaciones',
        titulo: message,
        textos: [
        ],
        botones: [
          {
            label: 'Ok',
            class: '',
            accion: 'ok'
          }
        ]
      };
      this.cdr.markForCheck();
    }, (error: Response) => {

      this.cdr.markForCheck();
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error borrando alarma ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });
  }

  cambiarEstado() {
    this.itemToChangeStatus = undefined;
    this.contentToShowModal = 1;
    let urlLoadAlarm = `${this.baseUrl}/alarms/${this.itenToDelete.id}/changeState`;

    this.http.ejecutarServicioPut(urlLoadAlarm, null).subscribe(res => {
      this.contentToShowModal = 10;
      let message;
      switch (res.status) {
        case 200:
        case 201:
        case 204:
          message = 'El estado ha sido cambiado';
          break;
        default:
          message = 'Error cambiando el estado';
          break;
      }
      this.configModal = {
        status: 1,
        icono: 'smart-icon-notificaciones',
        titulo: message,
        textos: [
        ],
        botones: [
          {
            label: 'Ok',
            class: '',
            accion: 'ok'
          }
        ]
      };
      this.cdr.markForCheck();
    }, (error: Response) => {

      this.cdr.markForCheck();
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cambiando el estado ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });
  }

  changeStatusDataset(){
    for (var i = 0; i < this.dataSet.length; i++) {
      if (this.dataSet[i][0] === this.itemToChangeStatus.id) {
        // Change Campo Status
        this.dataSet[i][10] = !this.dataSet[i][10];
        break;
      }
    }
    
    this.itemToChangeStatus = undefined;
    this.armarTabla( this.dataSet );
    this.cdr.markForCheck();
  }

  changeStatusModalDelete(event) {
    console.log( event );
    
    switch ( event ) {
      case "eliminar":
        this.eliminarItem();
        break;
      
      case "estado":
        this.cambiarEstado();
        break;
      
      case "cancelar":
        this.changeStatusDataset();
        break;
      
      default:
        if ( this.itemToChangeStatus !== undefined && this.itemToChangeStatus.id ){
          this.changeStatusDataset();
        }
        break;
    }

  }

  changeSignalType(event) {
    this.tmpItem.measureUnit = "";
    this.signalOptions = [];
    //for (let item of this.possibleSignalOptions) {
    for (let i = 0; i < this.possibleSignalOptions.length; i++) {
      if (event == this.possibleSignalOptions[i].signalType.id) {
        if (this.signalOptions.length === 0) {
          this.signalOptions.push({
            id: "Todos",
            text: "Todos",
          })
        }

        this.signalOptions.push({
          'id': this.possibleSignalOptions[i].id,
          'text': this.possibleSignalOptions[i].text,
          'answerValue': this.possibleSignalOptions[i].answerValue,
        });
      }
    }
    this.cdr.markForCheck();
  }

  breakSelected(breakName: any) {
    if (this.tmpItem.breakType === breakName.id.toString()) {
      this.tmpItem.breakType = "";
    }
    else {
      this.tmpItem.breakType = breakName.id.toString();
    }
  }

  signalSelected(signalName: any) {
    if (this.tmpItem.signalType === signalName.id.toString()) {
      this.tmpItem.signalType = "";
      this.signalOptions = [];
    }
    else {
      this.tmpItem.signalType = signalName.id.toString();
    }
  }

  getAlarm(answerGroupId) {
    let alarmToReturn: any;
    for (let i = 0; i < this.groupedData.length; i++) {
      if (answerGroupId === this.groupedData[i].answers.id) {
        alarmToReturn = this.groupedData[i];
        return alarmToReturn;
      }
    }
  }

  buscarAlarma(page: number = 1) {
    this.paginador['page'] = page;
    let urlLoadRange = `${this.baseUrl}/alarms/list?page=${page}&count=10`;
    let urlLoadRange2 = `${this.baseUrl}/alarms/count?page=${page}&count=10`;
    let params = "";
    let name = this.tmpItem.name;
    let priority;
    let alarmType_id = this.tmpItem.alarmType;
    let motor_id = this.tmpItem.motorType == "Todos" ? '' : this.tmpItem.motorType;
    let brakes_id = this.tmpItem.breakType;
    let signal_id = this.tmpItem.signal == "Todos" ? '' : this.tmpItem.signal;
    let signalType_id = this.tmpItem.signalType;
    let vehicleType_id = this.tmpItem.vehicleType == "Todos" ? '' : this.tmpItem.vehicleType;

    if (name !== "") {
      params += "&name=" + name;
    }
    if (alarmType_id !== "") {
      params += "&alarmType_id=" + alarmType_id;
    }
    if (motor_id !== "") {
      params += "&motor_id=" + motor_id;
    }
    if (brakes_id !== "") {
      params += "&brakes_id=" + brakes_id;
    }
    if (signal_id !== "") {
      params += "&signal_id=" + signal_id;
    }
    if (signalType_id !== "") {
      params += "&signalType_id=" + signalType_id;
    }
    if (vehicleType_id !== "") {
      params += "&vehicleType_id=" + vehicleType_id;
    }

    //name=${name}&priority=${priority}&alarmType_id=${alarmType_id}&motor_id=${motor_id}&brakes_id=${brakes_id}&signal_id=${signal_id}&signalType_id=${signalType_id}&page=1&count=10
    //let urlLoadRange = `${this.baseUrl}/alarms/1916`;

    this.loading = true;
    this.http.ejecutarServicioGet(urlLoadRange2 + params)
      .subscribe(resCount => {
        if (resCount.status === 200) {
          const self = this;
          this.http.ejecutarServicioGet(urlLoadRange + params, null).subscribe(res => {
            self.loading = false;
            let dataToDisplay = [];
            if (res != null) {
              self.dataSet = res.json();
              self.paginador['total'] = resCount.json();
              self.contentToShow = -1;
              self.configTabla = { status: -1 };
              for (let i = 0; i < self.dataSet.length; i++) {
                let active = self.dataSet[i]["state"] == "Activa" ? true : false;
                dataToDisplay.push([
                  self.dataSet[i]["id"],
                  self.dataSet[i]["name"],
                  self.dataSet[i]["priority"],
                  self.dataSet[i]["brakes"]["name"],
                  self.dataSet[i]["motor"]["name"],
                  self.dataSet[i]["vehicleType"]["name"],
                  self.dataSet[i]["signal"]["signalType"]["name"],
                  self.dataSet[i]["signal"]["name"],
                  self.dataSet[i]["signal"]["unit"],
                  "",
                  active
                ]);
              }
              self.dataSet = dataToDisplay;
              self.armarTabla(dataToDisplay);
            }
            self.armarTabla(dataToDisplay);

          });
        }
      },
        (error: Response) => {
          //this.showError(error);
        }
      );
  }

  armarTabla(data) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Nombre de la alarma', 'Prioridad', 'Tipo de frenos', 'Tipo de motor', 'Tipo de vehículo', 'Tipo de señal', 'Señal', 'Unidad de medida', 'Acciones', 'Estado'],
      typeColumn: [, , , , , , , , , { tipo: 'acciones', acciones: ['editar', 'eliminar'] }, { tipo: 'estado' }],
      searchColumn: [1, 2],
      hideColumns: [0],
      showPag: true,
      showExport: false,
      searchTitle: 'Resultado de Búsqueda',
      configColumns: {
        id: 0,
        columnClick: 0,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }

  ngOnInit() {
    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: 'select2-dropdown-forms'
    };
    this.buscarAlarma();
  }

  buscadorPagina(event) {
    if (this.paginador["total"] != 0) {
      this.buscarAlarma(event);
    }
  }

  editarAlarma(event) {

    let alarm;
    let ranges;
    let urlLoadAlarm = `${this.baseUrl}/alarms/${event}`;
    let urlLoadRange = `${this.baseUrl}/alarms/${event}/ranges`;
    //let urlLoadRange = `${this.baseUrl}/alarms/1916`;

    this.datosEditarAlarma.alarmTypes = this.alarmTypes;
    this.datosEditarAlarma.motorTypeOptions = this.motorTypeOptions;
    this.datosEditarAlarma.breakTypeOptions = this.breakTypeOptions;
    this.datosEditarAlarma.possibleSignalOptions = this.possibleSignalOptions;
    this.datosEditarAlarma.signalTypeOptions = this.signalTypeOptions;
    this.datosEditarAlarma.vehicleTypeOptions = this.vehicleTypeOptions;
    this.datosEditarAlarma.isModificarAlarma = true;

    this.http.ejecutarServicioGet(urlLoadAlarm, null).subscribe(res => {

      alarm = res.json();

      this.http.ejecutarServicioGet(urlLoadRange, null).subscribe(res => {

        ranges = res.json();

        /*let data = {
          "alarm": alarm,
          "rage": ranges,
          "isModificarAlarma": true
        }*/

        this.datosEditarAlarma.alarma = alarm;
        this.datosEditarAlarma.rangos = ranges;
        this.isBuscarAlarma = false;
        this.isModificarAlarma = true;

        //console.log(data);

        this.cdr.markForCheck();
        //UtilService.editar.emit(data);
      });
    });
  }
}
