import {Response} from '@angular/http';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import {UIService} from './../../../../trans/_services/UI.service';
import {environment} from './../../../../../../environments/environment';
import {HttpService} from '../../../../shared/services/http.service';

@Component({
  selector: 'kt-clustering',
  templateUrl: './clustering.component.html',
  styleUrls: ['./clustering.component.scss'],
  host: {'class': 'sths-tb-element'}
})
export class ClusteringComponent implements OnInit {
  
  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  // Valor para zonas
  cantidad: string;
  answerGroup: string;

  constructor(
      private cdr: ChangeDetectorRef,
      private http: HttpService,
      private uiService: UIService,) { }

  ngOnInit() {
    this.loadZone();

    let canti = document.getElementById('cantidad');
    canti.addEventListener('keydown', function(evt) {
      var inputValue = (<HTMLInputElement>document.getElementById('cantidad')).value;

      if (inputValue=="0") { 
        (<HTMLInputElement>document.getElementById('cantidad')).value = "1"; 
      }
    });
  }

  loadZone() {
    /*
     * Se consume WS con el valor del cluster para ponerlo 
     * en el campo "Cantidad de zonas"
     */
		this.http
			.ejecutarServicioGet(environment.endpoints.paramZonesCluster, null)
			.subscribe((resEstado: Response) => {
				let dataArray = [];
				switch (resEstado.status) {
					case 200:
					case 201:
					case 204:
            let estados = resEstado.json();
            console.log(estados);

            this.cantidad = estados.answerGroups['0']['6'];
            this.answerGroup = estados.answerGroups['0'].answerGroup;
            this.cdr.markForCheck();
						break;
					default:
						this.showError(resEstado);
						break;
				}
			}, (error: Response) => {
				this.showError(error);
			}
		);
  }

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}

  guardarClustering( ){
    let data = {
      "answer":
      {
        "6": this.cantidad
      }
    };

    // Se valida que el campo no esté vacio y se mayor a 0
    if (this.cantidad!="" && parseInt(this.cantidad)>0) {
        this.http
        .ejecutarServicioPut(
            environment.endpoints.paramSaveZones+this.answerGroup, 
            data
        )
        .subscribe((res: Response) => {
          console.log(res);
          switch (res.status) {
            case 200:
                console.log(res);
                this.contentToShowModal = 1;

                this.configModal = {
                  status: 1,
                  icono: 'smart-icon-guardar',
                  titulo: 'Los datos han sido guardados',
                  textos: [
                        'Los datos ingresados han sido guardados con éxito'
                  ],
                  botones: [
                          {
                            label: 'Ok',
                            class: '',
                            accion: 'guardarClustering'
                          }
                  ]
                };
                this.cdr.markForCheck();
                setTimeout(() => {
                  this.contentToShowModal = -1;
                  this.configModal = { status: -1 }
                }, 300);
                this.loadZone();
                break;
            default:
                console.log(res);
                this.showError(res);
            break;
          }
        }, (error: Response) => {
          this.showError(error);
        }
      );
    }

  }
  
  changeStatusModal( event ){
    if ( event === 'guardarClustering' ){
      
    }
  }

}
