export class AdminCategoriaEstacionDto {

    id: string;
    name: string;
    contratista: string;
    trama: string;
    vagones: string;
    accesos: string;
    tipo: string;
    troncal: string;
    puertas: string;

    constructor(data?: any) {
        if (data !== undefined) {
            this.id = data['id'] !== undefined ? data['id'] : null;
            this.name = data['name'] !== undefined ? data['name'] : null;
            this.contratista = data['contratista'] !== undefined ? data['contratista'] : null;
            this.trama = data['trama'] !== undefined ? data['trama'] : null;
            this.vagones = data['vagones'] !== undefined ? data['vagones'] : null;
            this.accesos = data['accesos'] !== undefined ? data['accesos'] : null;
            this.tipo = data['tipo'] !== undefined ? data['tipo'] : null;
            this.troncal = data['troncal'] !== undefined ? data['troncal'] : null;
            this.puertas = data['puertas'] !== undefined ? data['puertas'] : null;
        }
    }

    static fromJS(data: any): AdminCategoriaEstacionDto {
        return new AdminCategoriaEstacionDto(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : null;
        data['name'] = this.name !== undefined ? this.name : null;
        data['contratista'] = this.contratista !== undefined ? this.contratista : null;
        data['trama'] = this.trama !== undefined ? this.trama : null;
        data['vagones'] = this.vagones !== undefined ? this.vagones : null;
        data['accesos'] = this.accesos !== undefined ? this.accesos : null;
        data['tipo'] = this.tipo !== undefined ? this.tipo : null;
        data['troncal'] = this.troncal !== undefined ? this.troncal : null;
        data['puertas'] = this.puertas !== undefined ? this.puertas : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : '';
        data['name'] = this.name !== undefined ? this.name : '';
        data['contratista'] = this.contratista !== undefined ? this.contratista : '';
        data['trama'] = this.trama !== undefined ? this.trama : '';
        data['vagones'] = this.vagones !== undefined ? this.vagones : '';
        data['accesos'] = this.accesos !== undefined ? this.accesos : '';
        data['tipo'] = this.tipo !== undefined ? this.tipo : '';
        data['troncal'] = this.troncal !== undefined ? this.troncal : '';
        data['puertas'] = this.puertas !== undefined ? this.puertas : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminCategoriaEstacionDto(JSON.parse(json));
    }

}
