import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../../../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { MatSnackBarRef } from '@angular/material';
import { LayoutUtilsService, MessageType } from '../../../../../../../core/_base/crud/utils/layout-utils.service';
import { Select2OptionData, Options } from 'select2';
import { UIService } from '../../../../../_services/UI.service';
import { AdminCategoriaEstacionDto } from '../../../../../../shared/dto/admin-categoria-dto-estacion';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
// import { element } from 'protractor';
// import { TouchSequence } from 'selenium-webdriver';

@Component({
  selector: 'kt-generar-estacion',
  templateUrl: './generar-estacion.component.html',
  styleUrls: ['./generar-estacion.component.scss']
})
export class GenerarEstacionComponent implements OnInit {

  @Input() modificarDatos: AdminCategoriaEstacionDto;
  @Output() actionModificarCategoria  = new EventEmitter();

  formModelCategoria: AdminCategoriaEstacionDto = new AdminCategoriaEstacionDto;
  public selectContratistas: Array<Select2OptionData>;
  public selectEstaciones: Array<Select2OptionData>;
  public estacionSelected: string = '';
  public options: Options;
  search: boolean = true ;
  create: boolean = false;
  private _actualDialog: MatSnackBarRef<any>;
  public estacionSelectedId = [];
  public vagones: string = '';
  public id: string = '';
  public accesos: string = '';
  public tipo: string = '';
  public troncal: string = '';
  public contratista: string = ''; 
  public trama: string = '';
  public puertas: string = '';
  rutasMenu: object;
  requiredEstacion = false;
  requiredContratista = false;
  requiredVersionTrama = false;
  requiredTroncal = false;
  requiredNumeroPuertas = false;

  contentToShow: number = -1;
  configModal: {} = { status: -1 };
  conditionalLoader: boolean;
  statusEditar: boolean = false;
  


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private layoutUtilsService: LayoutUtilsService,
    private http2: HttpService,
    private uiService: UIService,
    private cdr: ChangeDetectorRef
  ) {  }

  async ngOnInit() {
    this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
    await Promise.all([this.loadEstaciones(), this.getContratistas()]);

    if(this.loadEditEstacion()){
      this.loadEditEstacion()
    }
    this.activatedRoute.params.subscribe(
      e=>{ 
        let idPuerta = e['idPuerta'];
        let idContratista = e['idContratista'];
        if(idPuerta != undefined){
          this.rutasMenu = [
            {
              label: 'Asociar puertas',
              icon: 'smart-icon-search',
              ruta: `${environment.folders.routeAdmin}puertas/asociar-puertas/${idContratista}/${idPuerta}`
            },
            {
              label: 'Asociar dispositivos',
              icon: 'smart-icon-search',
              ruta: `${environment.folders.routeAdmin}puertas/dispositivos/${idContratista}/${idPuerta}`
            },
            {
              label: 'Estación',
              icon: 'smart-icon-usuario',
              ruta: `${environment.folders.routeAdmin}puertas/generar-estacion/${idContratista}/${idPuerta}`
            }
          ];
        }else{
          this.rutasMenu = [
            {
              label: 'Estación',
              icon: 'smart-icon-usuario',
              ruta: `${environment.folders.routeAdmin}puertas/crear-estacion`
            }
          ];
        }
       });    
    this.cdr.markForCheck();
  }

  loadDataError(error) {
    if (this._actualDialog) { this._actualDialog.dismiss(); }
    const message = 'Error cargando datos ';
    this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
  }

  loadEstaciones(){
    this.search = false;
    this.create = true;
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.endpoints.estaciones}`).subscribe( (res:any) => {
        let data = res// res.json();
        //this.infoEstaciones = this.infoEstaciones.slice(0,7);
        let selectEstacion = data.features.map(i => {
          return { id: i.attributes.codigo_nodo_estacion, text: i.attributes.nombre_estacion }
        });
        this.selectEstaciones = selectEstacion
        this.cdr.markForCheck();
        resolve({});
        }, (error: any) => this.loadDataError(error) );
    });
  }

  loadEditEstacion(){
    this.activatedRoute.params.subscribe(
      e=>{
        let id = e['idPuerta'];
        if(id != undefined){
          this.http2.ejecutarServicioGet(`${environment.endpoints.cargarEstacion}${id}`, id).subscribe( (res:any) => {
            let data = res.json()
            this.statusEditar= true;
            const nombreEstacion = this.selectEstaciones.find((estacion) => estacion.text === data.nombre);
            this.estacionSelected = nombreEstacion.id;
            this.formModelCategoria.name = data.nombre
            this.formModelCategoria.id = data.id
            this.formModelCategoria.contratista = data.contratista
            this.formModelCategoria.trama = data.versionTrama
            this.formModelCategoria.vagones = data.numeroVagones
            this.formModelCategoria.accesos = data.numeroAccesos
            this.formModelCategoria.tipo = data.tipoEstacion
            this.formModelCategoria.troncal = data.troncal
            this.formModelCategoria.puertas = data.numeroPuertas;
            this.cdr.markForCheck();
          } ); 
          return true
        }
      }
    );
    return false;
  }

  setCamp(estacionSelected){
    if (estacionSelected != "") {
      this.http.get(`${environment.endpoints.estaciones}`).subscribe( (res:any) => {
        let data = res// res.json();
        let selectEstacionid = data.features.map(i => {
          return { id: i.attributes.codigo_nodo_estacion, 
            vagones: i.attributes.numero_vagones_estacion, 
            accesos: i.attributes.numero_accesos_estacion,
            tipo: i.attributes.tipo_estacion,
            troncal: i.attributes.troncal_estacion,
            name: i.attributes.nombre_estacion
          }
        });
        let selectId = selectEstacionid.filter(object => {
          return object['id'] == estacionSelected
        })
        this.formModelCategoria.name = selectId[0]['name']
        this.formModelCategoria.id = selectId[0]['id']
        this.formModelCategoria.vagones = selectId[0]['vagones']
        this.formModelCategoria.accesos = selectId[0]['accesos']
        this.formModelCategoria.tipo = selectId[0]['tipo']
        this.formModelCategoria.troncal = selectId[0]['troncal']
        this.cdr.markForCheck();
          
        this.http.get(`${environment.endpoints.puertas}`).subscribe( (res2:any) => {
          let dataPuertas = res2.features;
          console.log("ID EstacioNNN: ",this.formModelCategoria.id)
          this.getNumPuertas(dataPuertas,this.formModelCategoria.id)
  
        });
      }, (error: any) => this.loadDataError(error) );

    }
  }

  getNumPuertas(data,idEstacionSelected){
    let dataArray = []
    let idEstacionSelectedInt = parseInt(idEstacionSelected)
    console.log("ID selected", idEstacionSelectedInt)
    for (let index = 0; index < data.length; index++) {
      let idEstacionData = parseInt(data[index].attributes['codigo_estacion'])
      if(idEstacionSelectedInt == idEstacionData){
        dataArray.push([
          data[index].attributes['codigo_estacion'].toString()
        ])
        this.cdr.markForCheck();
      }
    }
    this.formModelCategoria.puertas = dataArray.length.toString()
    // console.log("Numero de puertas: ",this.formModelCategoria.puertas)
  }

  async getContratistas() {
    return new Promise((response, reject) => {
      this.http2.ejecutarServicioGet(environment.endpoints.adminRegistriesContratista)
      .subscribe((res) => {
        if (res.json().length > 0) {
          let data = res.json();
          this.selectContratistas = data.map(i => ({id: i.id, text: i.name }));
          this.cdr.markForCheck();
          response({});
        }
      },
      (error) => {
        console.log(error)
      });
    });
  }

  saveEstacion() {
    this.requiredEstacion = (this.estacionSelected == '' ? true:false)
    this.requiredContratista = (this.formModelCategoria.contratista == undefined ? true:false)
    this.requiredVersionTrama = (this.formModelCategoria.trama == undefined ? true:false)
    if(this.requiredEstacion == false && this.requiredContratista == false && this.requiredVersionTrama ==false){
      let formCategoria = this.formModelCategoria.toJS();
      let ruta = environment.endpoints.crearEstacion;
      if( this.statusEditar ){
        this.conditionalLoader = true;
        ruta = environment.endpoints.editarEstacion;
        this.http2.ejecutarServicioPut( ruta, formCategoria ).subscribe( (res: Response) => {
          this.conditionalLoader = false;
          if ( res.ok === true ) {
            this.openModalEstacionCreado();
            this.router.navigateByUrl(`${environment.folders.routeAdmin}puertas/crear-estacion`);
          } else {
            // TODO :: MANEJAR EXCEPCION
            this.uiService.showMessage( res.json()['message'] );
          }
        }, (error: Response) => {
          this.uiService.showMessage( error );
        });
      }else{
        this.http2.ejecutarServicioPost( ruta, formCategoria ).subscribe( (res: Response) => {
          this.conditionalLoader = false;
          if ( res.ok === true ) {
            this.openModalEstacionCreado();
            this.router.navigateByUrl(`${environment.folders.routeAdmin}puertas/crear-estacion`);
          } else {
            // TODO :: MANEJAR EXCEPCION
            this.uiService.showMessage( res.json()['message'] );
          }
        }, (error: Response) => {
          this.uiService.showMessage( error );
        });
      }
      
      //this.router.navigateByUrl('/admin/dashboard/puertas/generar-estacion');
    }
    // }
  }
  openModalEstacionCreado(){
    this.contentToShow = 1;
    let nombreProfile:string = '';
    let formCategoria = this.formModelCategoria.toJS();
    if( formCategoria && formCategoria.id ){
      nombreProfile = `<b>${formCategoria.name}</b>`;
    }

    this.configModal = {
      status: 1,
      icono: 'smart-icon-categorias',
      titulo: 'Estación',
      textos: [
                `Se guardó exitosamente la Estación ${nombreProfile}`
              ],
      botones: [
              {
                label: 'Cancelar',
                class: 'sths-btn-cancel',
                accion: 'cancelar'
              },
              {
                label: 'Aceptar',
                class: '',
                accion: 'goListEstaciones'
              }
      ]
    }
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShow = -1;
      this.configModal = { status: -1 }
    }, 300);
  }

  goListEstaciones(){
    this.router.navigateByUrl(`${environment.folders.routeAdmin}puertas/crear-estacion`)    
  }

  cerrarModal(){
    this.actionModificarCategoria.emit('cerrar');
  }

  changeStatusModal(event){
    if( this.modificarDatos && this.modificarDatos.id ){
      // Modificar
      this.actionModificarCategoria.emit('cerrar');
    }else{
      // Crear
      this.formModelCategoria = new AdminCategoriaEstacionDto;
    }
  }
}
