import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { MatSnackBarRef } from '@angular/material';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud/utils/layout-utils.service';
import { AdminCategoriaPuertaDto } from '../../../../shared/dto/admin-categoria-dto-puerta'
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'kt-asociar-puertas',
  templateUrl: './asociar-puertas.component.html',
  styleUrls: ['./asociar-puertas.component.scss']
})
export class AsociarPuertasComponent implements OnInit {

  rolAdmin: boolean = true;
  rutasMenu: object;
  private _actualDialog: MatSnackBarRef<any>;
  listadoPuertas: AdminCategoriaPuertaDto[];

  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  // Paginador
  paginador: Object = {
   count: 30,
   page: 1
  };
 
  constructor(
	private http: HttpClient,
	private layoutUtilsService: LayoutUtilsService,
	private cdr: ChangeDetectorRef,
	private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
	this.activatedRoute.params.subscribe(
		e=>{ 
      let idPuerta = e['idPuerta'];
      let idContratista = e['idContratista'];
			this.rutasMenu = [
				{
					label: 'Asociar puertas',
					icon: 'smart-icon-search',
					ruta: `${environment.folders.routeAdmin}puertas/asociar-puertas/${idContratista}/${idPuerta}`
				},
				{
					label: 'Asociar dispositivos',
					icon: 'smart-icon-search',
					ruta: `${environment.folders.routeAdmin}puertas/dispositivos/${idContratista}/${idPuerta}`
				},
				{
					label: 'Estación',
					icon: 'smart-icon-usuario',
					ruta: `${environment.folders.routeAdmin}puertas/generar-estacion/${idContratista}/${idPuerta}`
				}
			];
			this.loadPuertas();
		 });
  }

  loadPuertas(){
    this.http.get(`${environment.endpoints.puertas}`).subscribe( (res:any) => {
	  this.listadoPuertas = res.features;
	  this.procesarJson(this.listadoPuertas)
      this.cdr.markForCheck();
        
    }, (error: any) => this.loadDataError(error) );

  }

  procesarJson(data){
	let dataArray = [];
	this.activatedRoute.params.subscribe(
		e=>{
			let id = parseInt(e['idPuerta']);
			for (let index = 0; index < data.length; index++) {
				let idEstacion = parseInt(data[index].attributes['codigo_estacion'].toString())
				if (idEstacion == id) {
					dataArray.push([
						data[index].attributes['codigo_apertura'].toString(),
						data[index].attributes['id_puerta'].toString(),
						data[index].attributes['id_vagon'].toString(),
						data[index].attributes['codigo_estacion'].toString()
					])
					this.cdr.markForCheck();
					this.armarTabla(dataArray);

				}

			}
			if( !data.length ){
				this.cdr.markForCheck();
				this.armarTabla(dataArray);
			}

		});
  }

  armarTabla( data ) {
	this.cdr.markForCheck();
    this.contentToShow = 1;
    if ( this.rolAdmin ) {
      this.configTabla = {
        status: 1,
        header: ['Código Puerta', 'ID Puerta', 'Código Vagón', 'Código Estación'],
        typeColumn: [,,,],
        // showSearch: true,
        searchColumn: [1,2],
        // searchTitle: 'Buscar Contratista',
        // hideColumns: [0],
        showPag: false,
        configColumns: {
          id: 0
        },
        data: data,
        paginador: this.paginador
      };
    } else {
      this.configTabla = {
        status: 1,
        header: ['Código Puerta', 'ID Puerta', 'Código Vagón', 'Código Estación'],
        typeColumn: [,,,],
        // showSearch: true,
        searchColumn: [1,2],
        // searchTitle: 'Buscar Contratista',
        // hideColumns: [0],
        showPag: false,
        leftsColumns: [1],
        configColumns: {
          id: 0,
          columnClick: 1,
        },
        data: data,
        paginador: this.paginador,
      }
    }
    this.cdr.markForCheck();
  }

  loadDataError(error) {
    if (this._actualDialog) { this._actualDialog.dismiss(); }
    const message = 'Error cargando datos ';
    this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
  }

  buscadorPagina(event) {
    this.paginador['page'] = event;
  }


}
