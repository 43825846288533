import { currentUser } from './../../../../../../core/auth/_selectors/auth.selectors';
import { AuthService } from './../../../../../../core/auth/_services/auth.service';
import { Logout } from './../../../../../../core/auth/';
import { UserDTO } from './../../../../dto/user-dto';
// Angular
import { Component, OnInit, Input, ChangeDetectorRef, HostListener, ElementRef } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';
// NGRX
import { select, Store } from '@ngrx/store';
// State
import { AppState } from '../../../../../../core/reducers';
import { UtilService } from '../../../../../trans/_services/util.service';
import { environment } from './../../../../../../../environments/environment';
import { HttpService } from '../../../../../shared/services/http.service';
import { UIService } from '../../../../../trans/_services/UI.service';
import { Response } from '@angular/http';

import { CapaService } from '../../../../../trans/_services/capa.service';
import { IndicatorsService } from '../../../../../trans/_services/indicators.service';


@Component({
	selector: 'kt-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
	// Public properties
	user$: Observable<UserDTO>;
	user: UserDTO;
	version: string = environment["version"];
	ambiente: string = environment["ambiente"];

	@Input() showAvatar: boolean = true;
	@Input() showHi: boolean = true;
	@Input() showBadge: boolean = false;

	caseTypes = [{label: 'Mantenimiento', value: false}, {label: 'Seguridad', value: false}, {label: 'Operaciones', value: false}, {label: 'ITS', value: false}, {label:'Comunicaciones', value:false}];
	status = false;
	showCasesTypesList = true;
  /**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(
		private store: Store<AppState>,
		private authService: AuthService,
		private http: HttpService,
		private uiService: UIService,
		private cdr: ChangeDetectorRef,
		private capasService: CapaService,
		private indicatorSrv: IndicatorsService,
		private eRef: ElementRef
	) {
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.user$ = this.authService.userService$;
		this.user = this.authService.getUser();
		if(!this.user){
			this.user = new UserDTO;
		}

		// this.user$ = this.store.pipe(select(currentUser));

		let ruta = environment.endpoints.UserStatus
				.replace('{{user.name}}', this.user['username']);
		this.http
			.ejecutarServicioGet(ruta)
			.subscribe(
			(res: Response) => {
				let status = res.json();
				if (status.assignedTo && status.assignedTo.length > 0) {
					let arrayCases = status.assignedTo.split(',');
					this.caseTypes = this.caseTypes.map((iteracion)=> {
						if (arrayCases.includes(iteracion.label)){
							iteracion.value = true;
						}
						return iteracion          
					});
				}
			  
			  let span = document.getElementById("dot") as HTMLInputElement;
			  let img = document.getElementById("card_pic") as HTMLInputElement;
			  let select = document.getElementById("checkClass") as HTMLInputElement;
			  
			  if (status.available==true) {
				img.classList.remove("imagenred");
				img.classList.add("imagengreen");
				span.style.background = "#00FF00";
				select.value = "1";
        		this.status = true
			  } else {
				img.classList.remove("imagengreen");
				img.classList.add("imagenred");
				span.style.background = "#FF0000";
				select.value = "2";
        		this.status = false
			  }
	
			},
			(error: Response) => {
			  this.showError(error);
			}
		);	
	}
	/**
	 * Log out
	 */
	logout() {
		this.authService.logout().subscribe((resp) => {
			this.store.dispatch(new Logout());
		}, err => {
			this.store.dispatch(new Logout());
		});
		
		// Limpiar Capas
		this.capasService.resetAll();
		// Limpiar Indicadores
		this.indicatorSrv.clearOptions();

		let ruta = environment.endpoints.UserSetStatus
						.replace('{{user.name}}', this.user['username'])
						.replace('{{status}}', 'false');

		this.http
			.ejecutarServicioPut(ruta, null)
			.subscribe(res => {
				console.log('respuesta', res);
				if (res.status === 204) {
					this.cdr.markForCheck();
				} else {
					try {
						this.showError(res.json());
					}catch (error) {
						
					}
				}
		});
	}

	cambiarClase() {
		this.showCasesTypesList = true;
		let status: string = 'false';
		let select = document.getElementById("checkClass") as HTMLInputElement;
		let span = document.getElementById("dot") as HTMLInputElement;
		let img = document.getElementById("card_pic") as HTMLInputElement;
	
		if (select.value=="1") {
			img.classList.remove("imagenred");
			img.classList.add("imagengreen");
			span.style.background = "#00FF00";
			status = 'true';
      		this.status = true
		} else {
			img.classList.remove("imagengreen");
			img.classList.add("imagenred");
			span.style.background = "#FF0000";
			status = 'false';
     		 this.status = false
		}

		let ruta = environment.endpoints.UserSetStatus
					  .replace('{{user.name}}', this.user['username'])
					  .replace('{{status}}', status);
		this.http
			.ejecutarServicioPut(ruta, null)
			.subscribe(res => {
				console.log('respuesta', res);
				if (res.status === 204) {
				  this.cdr.markForCheck();
				} else {
				  this.showError(res.json());
				}
			});
	}
	
	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}

	setCaseType(label) {
		let typeChanged = this.caseTypes.find((type) => type.label === label);
		typeChanged.value = !typeChanged.value;
	}

	setCaseTypeConfig() {
		const cases = this.caseTypes.reduce((acc, caseType) => {
			if (caseType.value == true) {
				acc.push(caseType.label)
			}
			return acc;
		}, []).join(',');
		this.showCasesTypesList = false;
		let ruta = `${environment.endpoints.UserStatus.replace('{{user.name}}', this.user['username'])}/available/true/assigned/${cases}`
    	this.http.ejecutarServicioPut(ruta,null).subscribe(res => {
			if (res.status === 204) {
			  console.log(res)
			} else {
			  this.showError(res.json());
			}
		});
	}	

	switchProfileState() {
		this.showCasesTypesList = true;
	}

}
