export class PermissionDTO {

    id: number;
    name: string;
    checked: boolean = false;
    active: boolean = false;

    constructor(data?: any) {
        if (data !== undefined) {
            this.id = data['id'] !== undefined ? data['id'] : null;
            this.name = data['name'] !== undefined ? data['name'] : null;
            this.checked = data['checked'] !== undefined ? data['checked'] : null;
            this.active = data['active'] !== undefined ? data['active'] : null;
        }
    }

    static fromJS(data: any): PermissionDTO {
        return new PermissionDTO(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : null;
        data['name'] = this.name !== undefined ? this.name : null;
        data['checked'] = this.checked !== undefined ? this.checked : null;
        data['active'] = this.active !== undefined ? this.active : null;
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new PermissionDTO(JSON.parse(json));
    }

}
