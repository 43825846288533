import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter
} from '@angular/core';
import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { AdminDriverDTO } from '../../../../shared/dto/admin-driver-dto';
import { AdminGroupDto } from '../../../../shared/dto/admin-group-dto';
import { environment } from '@environments/environment';
import { Response } from '@angular/http';
import { UIService } from '../../../_services/UI.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';

@Component({
	selector: 'kt-conductor-buscar',
	templateUrl: './conductor-buscar.component.html',
	styleUrls: ['./conductor-buscar.component.scss']
})
export class ConductorBuscarComponent implements OnInit {
	formModelGroup: AdminGroupDto = new AdminGroupDto();
	formModelDriverEditar: AdminDriverDTO = new AdminDriverDTO();

	public selectDocumento: Array<Select2OptionData>;
	public selectCategoria: Array<Select2OptionData>;
	public selectConcesionario: Array<Select2OptionData>;

	tipoDocumento: string = 'all';
	tipoCategoria: string = 'all';
	tipoConcesionario: string = 'all';
	documento: string = '';
	fechaNace: string = '';
	numLicencia: string = '';
	fechaIngreso: string = '';
	data: [];
	ruta: string = environment.endpoints.adminDriverSearch;

	rutasMenu: object;
	public options: Options;
	contentToModificar: number = -1;

	paginadorbolean : any;

	// Paginador
	paginador: object = {
		count: 10,
		page: 1,
		total: 0,
		maxPages: 5
	};

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1 };

	formModelDriver: AdminDriverDTO = new AdminDriverDTO();
	@Input() modificarDatos: AdminDriverDTO;

	@Output() actionModificarDriver = new EventEmitter();

	loading: boolean = true;

	@Input() model = {};
	@Output() modelChange = new EventEmitter();

	public selectFitro1: Array<Select2OptionData>;
	// public selectFitroProfiles: Array<Select2OptionData>;
	public selectFitroTipoId: Array<Select2OptionData>;

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.formModelGroup.metaData = {
			tipo_id: ''
		};

		this.selectFitroTipoId = [
			{
				id: 'Cedula Ciudadania',
				text: 'Cédula Ciudadanía.'
			},
			{
				id: 'Cedula Extranjeria',
				text: 'Cédula Extranjería'
			}
		];
	}

	ngOnInit() {
		this.buscarDrivers();
		if (this.modificarDatos) {
			this.formModelDriver = this.modificarDatos;
		}
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminRegistries
			)
			.subscribe(
				(resRegistries: Response) => {
					switch (resRegistries.status) {
						case 200:
						case 201:
						case 204:
							let entities = resRegistries.json();
							for (let indexEntities = 0; indexEntities < entities.length; indexEntities++) {
								entities[indexEntities][
									'text'
								] =
									entities[indexEntities][
									'id'
									];
								if (
									indexEntities ===
									entities.length - 1
								) {
									this.selectFitro1 = entities;
								}
							}

							this.loading = false;
							this.cdr.markForCheck();
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(resRegistries);
							break;
					}
				},
				(error: Response) => {
					this.showError(error);
				}
			); // END INNER GET
		this.rutasMenu = [
			{
				label: 'Buscar Conductor',
				icon: 'smart-icon-asociar-dispositivos',
				ruta: `${environment.folders.routeAdmin}conductor/buscar`
			},
			{
				label: 'Crear Conductor',
				icon: 'smart-icon-usuario',
				ruta: `${environment.folders.routeAdmin}conductor`
			}
		];

		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms',
			allowClear: true,
		};

		// Llenar los combobox del formulario
		this.http
			.ejecutarServicioGet(environment.endpoints.adminParamTipoDocumento)
			.subscribe(
				(eventRecords: Response) => {
					let data = eventRecords.json().answerGroups;
					let selectDocumento = data.map(i => {
						return { id: i['49'].toString(), text: i['50'] };
					});
					selectDocumento.unshift({ id: 'all', text: 'Todos' });
					this.selectDocumento = selectDocumento;
				},
				(error: Response) => {
					this.showError(error);
				}
			);

		this.http
			.ejecutarServicioGet(environment.endpoints.adminParamCategoriasLicencias)
			.subscribe(
				(eventRecords: Response) => {
					let data = eventRecords.json().answerGroups;
					let selectCategoria = data.map(i => {
						return { id: i['53'].toString(), text: i['54'] };
					});
					selectCategoria.unshift({ id: 'all', text: 'Todos' });
					this.selectCategoria = selectCategoria;
				},
				(error: Response) => {
					this.showError(error);
				}
			);

		this.http
			.ejecutarServicioGet(environment.endpoints.adminParamConcesionarios)
			.subscribe(
				(eventRecords: Response) => {
					let data = eventRecords.json();
					console.log(data);
					let selectConcesionario = data.map(i => {
						return { id: i.id, text: i.name };
					});
					selectConcesionario.unshift({ id: 'all', text: 'Todos' });
					this.selectConcesionario = selectConcesionario;
				},
				(error: Response) => {
					this.showError(error);
				}
			);
	}

	buscarDrivers(event?) {
		if(event === 1) {
			this.paginador['page'] = 1;
		}
		this.loading = true;
		//this.paginador['page'] = page;
		let count = this.paginador['count'];
		// let page = page;
    let params = '';
    let fechaNacimiento = '';
    if (this.fechaNace) {
		let diaN = this.fechaNace['day'] < 10 ? '0'+this.fechaNace['day'].toString() : this.fechaNace['day'].toString();
		let mesN = this.fechaNace['month']  < 10 ? '0'+this.fechaNace['month'].toString() : this.fechaNace['month'].toString();
      	fechaNacimiento = this.fechaNace['year'] + "-" + mesN + "-" + diaN;
    }
    let fechaIngreso = '';
    if (this.fechaIngreso) {
		let diaI = this.fechaIngreso['day'] < 10 ? '0'+this.fechaIngreso['day'].toString() : this.fechaIngreso['day'].toString();
		let mesI = this.fechaIngreso['month']  < 10 ? '0'+this.fechaIngreso['month'].toString() : this.fechaIngreso['month'].toString();
      	fechaIngreso = this.fechaIngreso['year'] + '-' + mesI + '-' + diaI;
    }
    let tipoDocumento = this.tipoDocumento;
    if (tipoDocumento === 'all') {
      tipoDocumento = '';
    }
    let tipoCategoria = this.tipoCategoria;
    if (tipoCategoria === 'all') {
      tipoCategoria = '';
    }
    let tipoConcesionario = this.tipoConcesionario;
    if (tipoConcesionario === 'all') {
      tipoConcesionario = '';
    }
		// tslint:disable-next-line:max-line-length
		params = `?count=${count}&page=${this.paginador["page"]}&documentType=${tipoDocumento}&licenseCategory=${tipoCategoria}&dealership=${tipoConcesionario}&document=${this.documento}&birthDate=${fechaNacimiento}&licenseNumber=${this.numLicencia}&admissionDate=${fechaIngreso}`;

		// Se consulta los registros
		this.http
			.ejecutarServicioGet(
				this.ruta + 'search' + params
			)
			.subscribe(
				(eventRecords: Response) => {
					let data = eventRecords.json();
					this.data = data;
					// Endpoint para ver cantidad de registros totales
					this.http
					.ejecutarServicioGet(
						this.ruta + 'count' +
						params
					)
					.subscribe(
						(eventRecords: Response) => {
							this.paginador['total'] = eventRecords.json();
							this.procesarJson(data);
						},
						(error: Response) => {
							this.showError(error);
						}
					);
					//this.procesarJson(data);
				},
				(error: Response) => {
					this.showError(error);
				}
			);
	}

	procesarJson(data) {
		let dataArray = [];

		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				data[index]['documentType'].toString() +
				data[index]['document'].toString(),
				data[index]['document'].toString(),
				data[index]['licenseNumber'].toString(),
				data[index]['birthDate'].toString(),
				data[index]['admissionDate'].toString(),
				data[index]['dealership'].toString(),
				'',
				data[index]['active']
			]);
		}

		this.armarTabla(dataArray);
	}

	armarTabla(data) {
		if(data.length === 0){
			this.paginadorbolean = false;
		}else{
			this.paginadorbolean = this.paginador ;
		}
		console.log(data.length,'datass');
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: [
				'ID',
				'Número de documento',
				'Número Licencia',
				'Fecha de Nacimiento',
				'Fecha de Ingreso',
				'Concesionario',
				'Acciones',
				'Estado'
			],
			typeColumn: [
				,
				,
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar'] },
				{ tipo: 'estado' }
			],
			searchColumn: [0, 1, 2],
			hideColumns: [0],
			showPag: true,
			showExport: false,
			configColumns: {
				id: 1,
				columnClick: 1
			},
			data: data,
			paginador: this.paginadorbolean
		};
		this.loading = false;
		this.cdr.markForCheck();
	}

	buscadorPagina(event) {
		console.log('pages',event);
		this.paginador['page'] = event;
		this.buscarDrivers();
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	editarElemento(event: string) {
		let item = this.data.find(itm => itm['document'] === event);
		this.router
			.navigate([
				`${environment.folders.routeAdmin}conductor/modificar/${
				item['documentType']
				}/${event}`
			])
			.catch(e => {
				console.log(e);
			});
	}

	eventEstadoClick(event) {
		let item = this.data.find(itm => itm['document'] === event.id);
		this.http
			.ejecutarServicioPut(
				`${environment.endpoints.adminDriver}document_type/${
				item['documentType']
				}/document/${event.id}/state/${+event.value}`
			)
			.subscribe(
				(res: Response) => {
					console.log(res);
				},
				(error: Response) => {
					this.showError(error);
				}
			);
	}

	/**
	 * Valida que la fecha de nacimiento sea menor a la fecha actual.
	 */
	validateBirthDate() {
		let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
		+ (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
		setTimeout(()=>{
			if (this.fechaNace !== null && this.fechaNace !== undefined && this.fechaNace !== "") {
				let diaN = this.fechaNace['day'] < 10 ? '0'+this.fechaNace['day'].toString() : this.fechaNace['day'].toString();
				let mesN = this.fechaNace['month']  < 10 ? '0'+this.fechaNace['month'].toString() : this.fechaNace['month'].toString();
				let fechaNac = this.fechaNace['year'] + "" + mesN + "" + diaN;
				if(!(Number(fechaNac) < Number(fechaActualFormato))){
					this.uiService.showMessage("Seleccione una fecha menor a la fecha de hoy.")
					this.fechaNace = "";
					this.cdr.markForCheck();
				} else if (this.fechaIngreso !== null && this.fechaIngreso !== undefined && this.fechaIngreso !== "") {
					let diaI = this.fechaIngreso['day'] < 10 ? '0'+this.fechaIngreso['day'].toString() : this.fechaIngreso['day'].toString();
					let mesI = this.fechaIngreso['month']  < 10 ? '0'+this.fechaIngreso['month'].toString() : this.fechaIngreso['month'].toString();
					let fechaIng = this.fechaIngreso['year'] + "" + mesI + "" + diaI;
					if(!(Number(fechaNac) < Number(fechaIng))){
						this.uiService.showMessage("La fecha de nacimiento debe ser menor a la fecha de ingreso.");
						this.fechaNace = "";
						this.cdr.markForCheck();
					}
				}
			}
		})
	}

	/**
	 * Valida que la fecha de ingreso sea menor a la fecha actual y mayor a la fecha de nacimiento.
	 */
	validateDateAdmission() {
		let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
		+ (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
		setTimeout(()=>{
			if (this.fechaIngreso !== null && this.fechaIngreso !== undefined && this.fechaIngreso !== "") {
				let diaI = this.fechaIngreso['day'] < 10 ? '0'+this.fechaIngreso['day'].toString() : this.fechaIngreso['day'].toString();
				let mesI = this.fechaIngreso['month']  < 10 ? '0'+this.fechaIngreso['month'].toString() : this.fechaIngreso['month'].toString();
				let fechaIng = this.fechaIngreso['year'] + "" + "" + mesI + "" + diaI;
				if(!(Number(fechaIng) < Number(fechaActualFormato))){
					this.uiService.showMessage("Seleccione una fecha menor a la fecha de hoy.")
					this.fechaIngreso = "";
					this.cdr.markForCheck();
				} else if (this.fechaNace !== null && this.fechaNace !== undefined && this.fechaNace !== "") {
					let diaN = this.fechaNace['day'] < 10 ? '0'+this.fechaNace['day'].toString() : this.fechaNace['day'].toString();
					let mesN = this.fechaNace['month']  < 10 ? '0'+this.fechaNace['month'].toString() : this.fechaNace['month'].toString();
					let fechaNac = this.fechaNace['year'] + "" + mesN + "" + diaN;
					if(!(Number(fechaNac) < Number(fechaIng))){
						this.uiService.showMessage("La fecha de ingreso debe ser mayor a la fecha de nacimiento.")
						this.fechaIngreso = "";
						this.cdr.markForCheck();
					}
				}
			}
		})
	}
}
