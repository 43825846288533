import { MenuDispositivosComponent } from '../../shared/components/menu-dispositivos/menu-dispositivos.component';
// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
// import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
// import { MatExpansionModule } from '@angular/material/expansion';
// import { MatCardModule } from '@angular/material/card';
// import { MatIconModule } from '@angular/material/icon';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { RouterModule, Routes } from '@angular/router';
import { PagesRouting } from './administrador.routes';

// NgBootstrap
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Select2
import { NgSelect2Module } from 'ng-select2';

// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

// Core Module
import { CoreModule } from '../../../core/core.module';

import { AdministradorComponent } from './administrador.component';
import { NavbarAdminComponent } from '../../shared/components/navbar/navbar.component';
import { TablasComponent } from '../../shared/components/tablas/tablas.component';
import { TablasDispositivosComponent } from '../../shared/components/tablas-dispositivos/tablas-dispositivos.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { DispositivosGruposComponent } from './categorias/dispositivos-grupos/dispositivos-grupos.component';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb/breadcrumb.component';
import { DispositivosDetalleComponent } from './categorias/dispositivos-grupos/dispositivos-detalle/dispositivos-detalle.component';
import { DispositivosFormularioComponent } from './categorias/dispositivos-grupos/dispositivos-formulario/dispositivos-formulario.component';
import { DatosAdicionalesComponent } from '../../shared/components/datos-adicionales/datos-adicionales.component';
import { AsociarComponent } from './categorias/asociar/asociar.component';
import { NavegacionComponent } from './navegacion/navegacion.component';
import { SelectListComponent } from '../../shared/components/select-list/select-list.component';
// Formularios Modales
import { FormularioModalComponent } from '../../shared/components/formularios/formulario-modal.component';
import { FormCategoriasComponent } from './categorias/form-categorias/form-categorias.component';
import { FormCategoriasInformacionComponent } from './categorias/form-categorias-informacion/form-categorias-informacion.component';
import { FormTerminarRegistroComponent } from './categorias/form-terminar-registro/form-terminar-registro.component';
import { FormCrearGrupoComponent } from './categorias/form-creargrupo/form-creargrupo.component';
// Usuarios, Perfil, Rol
import { UsuarioBuscarComponent } from './usuarios/usuario-buscar/usuario-buscar.component';
import { UsuarioCrearComponent } from './usuarios/usuario-crear/usuario-crear.component';
import { UsuarioOffComponent } from './usuarios/usuario-off/usuario-off.component';

import { ConductorCrearComponent } from './conductor/conductor-crear/conductor-crear.component';
import { ConductorBuscarComponent } from './conductor/conductor-buscar/conductor-buscar.component';
import { ConfiguracionCorreosComponent } from './configuracion-correos/configuracion-correos.component';
import { FormCorreosComponent } from './configuracion-correos/form-correos/form-correos.component';
import { ConductorRegistroLicenciasComponent } from './conductor/conductor-registro-licencias/conductor-registro-licencias.component';
import { ConductorRegistroCarnetsComponent } from './conductor/conductor-registro-carnets/conductor-registro-carnets.component';
import { ConductorIngresoComponent } from './conductor/conductor-ingreso/conductor-ingreso.component';
import { ConductorRegistroCapacitacionesComponent } from './conductor/conductor-registro-capacitaciones/conductor-registro-capacitaciones.component';
import { ConductorRegistroNotasComponent } from './conductor/conductor-registro-notas/conductor-registro-notas.component';
import { CargaMasivaComponent } from './conductor/carga-masiva/carga-masiva.component';
import { TramosBuscarComponent } from './tramos/tramos-buscar/tramos-buscar.component';
import { TramosCrearComponent } from './tramos/tramos-crear/tramos-crear.component';
import { TramoEditPopUpComponent } from './tramos/tramo-edit-popup/tramos-edit-popup.component';
import { CrearTramoPopUpComponent } from './tramos/tramo-crear-popup/tramos-crear-popup.component';
import { PerfilBuscarComponent } from './perfil/perfil-buscar/perfil-buscar.component';
import { PerfilCrearComponent } from './perfil/perfil-crear/perfil-crear.component';
import { RolBuscarComponent } from './rol/rol-buscar/rol-buscar.component';
import { RolCrearComponent } from './rol/rol-crear/rol-crear.component';

// import { TransModule } from '../admin.module';
import { SharedModule } from '../../shared/modules/shared/shared.module';
import { PartialsModule } from '../../shared/partials/partials.module';
import { GestionManualComponent } from './gestion/gestion-manual/gestion-manual.component';


import { NavegacionService } from './_services/navegacion.service';
import { ImagenCorporativaService } from './_services/imagen-corporativa.service';
import { UsuariosService } from './_services/usuarios.service';
import { AlarmaComponent } from './alarma/alarma.component';
import { FormAlarmComponent } from '../../shared/components/formularios/form-alarm/form-alarm.component';
import { BuscarAlarmaComponent } from './alarma/buscar-alarma/buscar-alarma.component';

import { ConductorAgresivoComponent } from './parametros/conductor-agresivo/conductor-agresivo.component';
import { ClusteringComponent } from './parametros/clustering/clustering.component';
import { MantenimientoPreventivoComponent } from './parametros/mantenimiento-preventivo/mantenimiento-preventivo.component';
import { FormularioComponent } from './parametros/mantenimiento-preventivo/formulario/formulario.component';
import { FormEventosComponent } from './parametros/eventos-redes/formulario/form-eventos.component';
import { EventosRedesComponent } from './parametros/eventos-redes/eventos-redes.component';
import { FormAlarmInfoComponent } from '../../shared/components/formularios/form-alarm-info/form-alarm-info.component';
import { CrearIncidenteComponent } from './incidentes/crear-incidente/crear-incidente.component';
import { GestionIncidenteComponent } from './incidentes/gestion-incidente/gestion-incidente.component';
import { BuscarIncidenteComponent } from './incidentes/buscar-incidente/buscar-incidente.component';
import { RecibirCasosComponent } from './gestion/recibir-casos/recibir-casos.component';
import { GestionarCasoComponent } from './gestion/recibir-casos/gestionar-caso/gestionar-caso.component';

import { UIService } from './../_services/UI.service';
import { LayoutUtilsService } from './../../../core/_base/crud/utils/layout-utils.service';
import { ActionNotificationComponent } from './../../shared/partials/content/crud';
import { SupervisarCasoComponent } from './gestion/supervisar-caso/supervisar-caso.component';
import { AjustarCasosComponent } from './gestion/ajustar-casos/ajustar-casos.component';
import { FormCasosComponent } from './gestion/ajustar-casos/formulario/form-casos.component';
import { ResponderCasoManualComponent } from './gestion/responder-caso-manual/responder-caso-manual.component';
import { FormularioCasoComponent } from './gestion/responder-caso-manual/formulario-caso/formulario-caso.component';
import { FormGrupoComponent } from './categorias/form-grupo/form-grupo.component';
import { FormEditarDispositivosComponent } from './categorias/form-editar-dispositivos/form-editar-dispositivos.component';

import { DeteccionBloqueosComponent } from './parametros/deteccion-bloqueos/deteccion-bloqueos.component';
import { MovilDetenidoComponent } from './parametros/movil-detenido/movil-detenido.component';
import { AnalisisPredectivoComponent } from './parametros/analisis-predectivo/analisis-predectivo.component';
import { FormAnalisisComponent } from './parametros/analisis-predectivo/form-analisis/form-analisis.component';
import { DeteccionIdlingComponent } from './parametros/deteccion-idling/deteccion-idling.component';
import { UtilService } from './_services/util.service';
import { ConductorComponent } from './conductor/conductor.component';
import { MatFormFieldModule, MatInputModule, MatCheckboxModule, MatRadioModule, MatDatepickerModule } from '@angular/material';
import Reportes, { Components as ReportesComponents } from '@admin/reportes/index';
import { EditarIndicadoresComponent } from './indicadores/editar-indicadores/editar-indicadores.component';
import { DefinirIndicadoresRolComponent } from './indicadores/definir-indicadores-rol/definir-indicadores-rol.component';
import { DefinirIndicadoresPerfilComponent } from './indicadores/definir-indicadores-perfil/definir-indicadores-perfil.component';
import { ImagenCorporativaComponent } from './imagen-corporativa/imagen-corporativa.component';

import { VgCoreModule } from 'videogular2/core';
import { VgControlsModule } from 'videogular2/controls';
import { VgOverlayPlayModule } from 'videogular2/overlay-play';
import { VgBufferingModule } from 'videogular2/buffering';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { OnlynumberDirective } from '../../../directives/onlynumber-directive.directive';
import { DomseguroPipe } from '../pipe/domseguro.pipe';
import { EnvioStsComponent } from './envio-sts/envio-sts.component';
import { CrearContratistaComponent } from './puertas/crear-contratista/crear-contratista.component';
import { CrearEstacionComponent } from './puertas/crear-estacion/crear-estacion.component';
import { DispositivosComponent } from './puertas/dispositivos/dispositivos.component';
import { AsociarPuertasComponent } from './puertas/asociar-puertas/asociar-puertas.component';
import { GenerarContratistaComponent } from './puertas/crear-contratista/generar-contratista/generar-contratista.component';
import { GenerarEstacionComponent } from './puertas/crear-estacion/generar-estacion/generar-estacion/generar-estacion.component';
import { AuthInterceptorService } from '@app/views/shared/interceptors/auth-interceptor.service';


@NgModule({
	declarations: [
		// Propios
		AdministradorComponent,
		NavbarAdminComponent,
		TablasComponent,
		TablasDispositivosComponent,
		CategoriasComponent,
		DispositivosGruposComponent,
		BreadcrumbComponent,
		DispositivosDetalleComponent,
		DispositivosFormularioComponent,
		DatosAdicionalesComponent,
		FormularioModalComponent,
		AsociarComponent,
		MenuDispositivosComponent,
		NavegacionComponent,
		SelectListComponent,
		// Formularios
		FormCategoriasComponent,
		FormCategoriasInformacionComponent,
		FormTerminarRegistroComponent,
		FormAlarmComponent,
		FormEventosComponent,
		FormAlarmInfoComponent,
		FormCrearGrupoComponent,
		// Usuarios, Perfil, Rol
		UsuarioBuscarComponent,
		UsuarioCrearComponent,
		UsuarioOffComponent,
		CrearTramoPopUpComponent,
		ConductorCrearComponent,
		ConfiguracionCorreosComponent,
		ConductorBuscarComponent,
		ConductorIngresoComponent,
		ConductorRegistroLicenciasComponent,
		ConductorRegistroCarnetsComponent,
		ConductorRegistroLicenciasComponent,
		ConductorRegistroCapacitacionesComponent,
		ConductorRegistroNotasComponent,
		CargaMasivaComponent,
		TramosBuscarComponent,
		TramosCrearComponent,
		TramoEditPopUpComponent,
		PerfilBuscarComponent,
		PerfilCrearComponent,
		RolBuscarComponent,
		RolCrearComponent,
		GestionManualComponent,
		AlarmaComponent,
		BuscarAlarmaComponent,
		ConductorAgresivoComponent,
		ClusteringComponent,
		MantenimientoPreventivoComponent,
		FormularioComponent,
		CrearIncidenteComponent,
		RecibirCasosComponent,
		GestionIncidenteComponent,
		BuscarIncidenteComponent,
		GestionarCasoComponent,
		SupervisarCasoComponent,
		AjustarCasosComponent,
		FormCasosComponent,
		ResponderCasoManualComponent,
		FormularioCasoComponent,
		FormGrupoComponent,
		FormEditarDispositivosComponent,
		DeteccionBloqueosComponent,
		EventosRedesComponent,
		MovilDetenidoComponent,
		AnalisisPredectivoComponent,
		FormAnalisisComponent,
		DeteccionIdlingComponent,
		FormCorreosComponent,
		ConductorComponent,
		...ReportesComponents,
		EditarIndicadoresComponent,
		DefinirIndicadoresRolComponent,
		DefinirIndicadoresPerfilComponent,
		ImagenCorporativaComponent,
		DomseguroPipe,
		EnvioStsComponent,
		CrearContratistaComponent,
		CrearEstacionComponent,
		DispositivosComponent,
		AsociarPuertasComponent,
		GenerarContratistaComponent,
		GenerarEstacionComponent
	],
	exports: [
		ImagenCorporativaComponent,
		DomseguroPipe
	],
	imports: [
		NgMultiSelectDropDownModule.forRoot(),
		// RouterModule.forChild(routes),
		PagesRouting,
		CommonModule,
		SharedModule,
		PartialsModule,
		FormsModule,
		ReactiveFormsModule,
		NgbModule,
		CoreModule,
		NgSelect2Module,
		PerfectScrollbarModule,
		MatTabsModule,
		MatCheckboxModule,
		MatRadioModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		NgxMapboxGLModule.withConfig({
			accessToken: 'pk.eyJ1Ijoic2tndGVjbm9sb2dpYTIwMjAiLCJhIjoiY2tkMjA3anA0MGZoajJ0cDg4cjN4Mmo2NSJ9.E7JApHh43a1Xm5IS_TDANA', // Optionnal, can also be set per map (accessToken input of mgl-map)
			geocoderAccessToken: 'TOKEN' // Optionnal, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
		})
	],
	providers: [
		NgbActiveModal,
		NavegacionService,
		UsuariosService,
		LayoutUtilsService,
		UIService,
		UtilService,
		ImagenCorporativaService
	],
	bootstrap: [
		FormularioModalComponent
	],
	entryComponents: [
		FormCategoriasComponent,
		FormCategoriasInformacionComponent,
		FormTerminarRegistroComponent,
		FormAlarmComponent,
		FormAlarmInfoComponent,
		FormCrearGrupoComponent,
		ActionNotificationComponent,
		FormCorreosComponent,
		FormCasosComponent
	]
})
export class AdministradorModule {
}
