import { environment } from './../../../../../../environments/environment';

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../../shared/services/http.service';
import { Response } from '@angular/http';
import { UIService } from './../../../_services/UI.service';
import { element } from '@angular/core/src/render3';

@Component({
  selector: 'kt-dispositivos-grupos',
  templateUrl: './dispositivos-grupos.component.html',
  styleUrls: [
    './dispositivos-grupos.component.scss',
    './../categorias.component.scss'
  ],
  host: { 'class': 'sths-tb-element' }
})
export class DispositivosGruposComponent implements OnInit {
  titulo: string;
  configTable: {};

  configModal: {} = { status: -1 };
  idDevicesActive: string;


  idRegistry: string;
  idActive : any;

  rolAdmin: boolean = true;
  iDatos: number = 1;

  registryGroupIdDelete: any;

  idRegistryGroup: string;
  conditionalLoaderTable: boolean;

  // Paginador
  paginador: {} = {
    count: 30,
    page: 1
  };

  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  // Cargadores Información
  conditionalLoader: boolean = true;

  // Modales
  contentToShowDelete: number = -1;
  contentToShowModal: {} = { status: -1 };

  contentToShowFormModal: number = -1;

  /*
   * Permitir interactuar con las opciones de la lista,
   * además de habilitar los botones de crear buses
   *
   * El usuario que no tiene una entidad asociada, será quien
   * podrá vincular buses al ser creados.
   */
  canDoActions: boolean = true;

  /*
   * Datos principales de bus, extraidos de endpoint
   */
  dataArray = [];

  dataResponse = [];

  constructor(private _route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private cdr: ChangeDetectorRef,
    private uiService: UIService,
  ) {
    this.idRegistry = this._route.snapshot.paramMap.get('registryId');

    this.titulo = 'Registro de Bus';
    // this.configTable = {
    //   header: ['ID', 'Grupo de Dispositivos', 'Acciones'],
    //   typeColumn: [,,{ tipo: 'acciones', acciones: ['editar', 'eliminar'] } ],
    //   searchColumn: [0, 1, 2],
    //   leftsColumns: [1],
    //   hideColumns: [0],
    //   configColumns: {
    //     id: 0,
    //     columnClick: 1,
    //   },
    //   data: [
    //     ['1', 'Bus 1', ''],
    //     ['2', 'Bus 2', ''],
    //     ['3', 'Bus 3', ''],
    //     ['4', 'Bus 4', ''],
    //     ['5', 'Bus 5', '']
    //   ]
    // }
  }

  ngOnInit() {
    this.cargarGroupTable();
  }

  cargarGroupTable() {
    this.conditionalLoaderTable = true;
    this.cdr.markForCheck();
    let ruta = `${environment.endpoints.adminRegistryGroup}?registry=${this.idRegistry}`;
    
    this.http.ejecutarServicioGet(ruta, null).subscribe((res: Response) => {
      this.conditionalLoader = false;
      this.conditionalLoaderTable = false;
      
      this.canDoActions = res.json()['canDoActions']=='true'?true:false;
      

      //console.log(res.json(),'res');

      if (res.json()['groups']!=undefined) {
        if (res.json()['groups'].length > 0) {
          this.procesarJson(res.json());
        }
      }
      this.cdr.markForCheck();
    });
  }

  procesarJson(data) {
    console.log(data,'data');
    /*
      @ID (Referencia)
      @ID,
    */
   this.dataResponse = data['groups'];

   //console.log(this.canDoActions,'cando');
    for (let index = 0; index < data['groups'].length; index++) {

      let switchValue = {};
      let complete =  data['groups'][index]['complete'].toString()=='true' ? true : false;
      let link =  data['groups'][index]['link'].toString()=='true' ? true : false;

      if (this.canDoActions){
        //esto es para la columna completar
        switchValue["values"] = complete;
        switchValue["show"] =   ( !link) ? true : false;
      } else {
        //esto es para la columna vincular
        switchValue["values"] =  link;
        switchValue["show"] =   ( complete) ? true : false;
      }

      this.dataArray.push([
        {values: data['groups'][index]['name'].toString(), show: true},
        {values: data['groups'][index]['name'].toString(), show: true},
        //{value :(this.canDoActions? data['groups'][index]['complete'].toString()=='true'?false:true : data['groups'][index]['link'].toString()=='true'?false:true)},
        //{value : data['groups'][index]['complete'], show: false},
        '',
        switchValue
      ]);

      if (index === data.length - 1) {
        this.armarTabla(this.dataArray);
      }
    }

    if (!data.length) {
      this.armarTabla(this.dataArray);
    }
  }

  armarTabla(data) {
    console.log("Data groups",data);
    console.log("Condicion",this.canDoActions)
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: [
        'ID', 'Nombre',
        (this.canDoActions?'Acciones':''),
        (this.canDoActions?'Completo':'Vincular')
      ],
      typeColumn_old: [, , { tipo: 'acciones', acciones: ['editar', 'eliminar','credenciales'] }, { tipo: 'estado' }],
      typeColumn: [, ,
        {
          tipo: 'acciones',
          acciones: (this.canDoActions?['editar', 'eliminar','credenciales',{ tipo: 'estado' }]:[])
        },
        { tipo: (this.canDoActions?'estado':'estado') }
      ],
      showSearch: true,
      leftsColumns: [1],
      searchColumn: [2],
      searchTitle: 'Buscar Bus',
      hideColumns: [0,4],
      showPag: false,
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }

  eventEliminarClick(event) {
    console.log(this.dataResponse);
    let selected = this.dataResponse.find(element =>{
      return element['name'] == event['values'];
    }
    );
    console.log('selected',selected,'event',event['values']);
    if (selected['link'] == 'true'){
      this.contentToShowFormModal = 20;
      //vinculado
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowFormModal = -1;
        this.contentToShowModal = { status: -1 };
      }, 400);
    }else if (selected['complete'] == 'true'){
      this.contentToShowFormModal = 18;
      //completado
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowFormModal = -1;
        this.contentToShowModal = { status: -1 };
      }, 400);
    }else{
    //console.log(event);
    this.registryGroupIdDelete = event;
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-eliminar',
      titulo: 'Eliminar Bus',
      textos: [
        '¿Esta seguro que quiere eliminar este Bus'
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Eliminar',
          class: '',
          accion: 'eliminar'
        }
      ]
      }
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowDelete = -1;
      this.contentToShowModal = { status: -1 };
    }, 300);
  }

  eventCredencialesClick(id, type?: string) {
    this.idDevicesActive = id;
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-nombre-categoria',
      titulo: 'Credenciales',
      textos: [
        ''
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Descargar rsa',
          class: '',
          accion: 'descargarCredencial'
        }
      ]
    };

    if (type === 'regenerado') {
      this.configModal['textos'].push(' Se ha regenerado correctamente...');
    } else {
      this.configModal['botones'].push({
        label: 'Regenerar rsa',
        class: '',
        accion: 'regenerarCredencial'
      },
      {
        label: 'Descargar p12',
        class: '',
        accion: 'descargarp12'
      });
    }

    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModal = -1;
      this.configModal = { status: -1 };
    }, 300);
  }

  changeStatusModal(event) {
    if(this.canDoActions){
    console.log(this.registryGroupIdDelete);
    let ruta;
    if (event === 'eliminar') {
      this.conditionalLoader = true;
      let params = {
        registry: this.idRegistry,
        name: this.registryGroupIdDelete.values
      };
      this.http.ejecutarServicioPost(environment.endpoints.adminRegistryGroupDelete, params).subscribe((res: Response) => {
        // this.conditionalLoader = false;
        if (res.ok === true) {
          this.dataArray = [];
          this.cargarGroupTable();
          // Realizar algo con el ID
          let resPost = res.json();
        } else {
          // TODO :: MANEJAR EXCEPCION
        }

        this.conditionalLoader = false;
        this.cdr.markForCheck();
      });

      this.registryGroupIdDelete = '';
    } else if (event == 'descargarCredencial') {
      console.log(this.idDevicesActive,'idDevicesActive',this.idRegistry);
        this.conditionalLoaderTable = true;
        this.cdr.markForCheck();
        if (this.idDevicesActive === Object(this.idDevicesActive)){
          console.log('object',this.idDevicesActive);
          this.idActive = this.idDevicesActive['values'];
        }else{
          console.log('no object');
          this.idActive = this.idDevicesActive;
        }
        ruta = `${environment.endpoints.adminRegistryGroupPK}/${this.idRegistry}/${this.idActive}`;

        console.log(ruta,'descargarCredencial');
        this.http.ejecutarServicioGet(ruta, null).subscribe(
          data => this.uiService.downloadFile(data['_body'], 'pem', `${this.idActive}.rsa.pem`),
          (e) => {
            this.uiService.showMessage('Error descagando las Credenciales');
          });
        this.conditionalLoaderTable = false;
        this.cdr.markForCheck();
    } else if (event == 'descargarp12') {
        this.conditionalLoaderTable = true;
        this.cdr.markForCheck();
        ruta = `${environment.endpoints.adminRegistryGetP12}${this.idRegistry}`;
        console.log(ruta,'descargarp12');
        this.http.ejecutarServicioGet(ruta, null).subscribe(
          data => this.uiService.downloadFile(data['_body'], 'p12', `${this.idRegistry}-video-upload.p12`),
          (e) => {
            this.uiService.showMessage('Error descagando las Credenciales');
          });
        this.conditionalLoaderTable = false;
        this.cdr.markForCheck();
    } else if(event == 'regenerarCredencial') {
      this.conditionalLoaderTable = true;
      this.cdr.markForCheck();
      ruta = `${environment.endpoints.adminRegistryGroupUpdatePK}/${this.idRegistry}/${this.idDevicesActive['values']}`;
      console.log(ruta,'regenerarCredencial');
      this.http.ejecutarServicioPost(ruta, null).subscribe((res: Response) => {
        if (res.status === 200) {
          this.uiService.showMessage('Credenciales listas');
          this.eventCredencialesClick(this.idDevicesActive['values'], 'regenerado');
        } else {
          this.uiService.showMessage('Error generando las Credenciales');
        }
        this.conditionalLoaderTable = false;
        this.cdr.markForCheck();
      }, (e) => {
          this.uiService.showMessage('Error generando las Credenciales');
      });
    } else if(event =='cerrar'){
      this.dataArray = [];
      this.cargarGroupTable();
    }
  }
}

  editarElemento(event) {
    console.log(this.dataResponse);
    let selected = this.dataResponse.find(element =>{
      return element['name'] == event['values'];
    }
    );
    console.log('selected',selected,'event',event['values']);
    /*if (selected['link'] == 'true'){
      this.contentToShowFormModal = 19;
      //vinculado
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowFormModal = -1;
        this.contentToShowModal = { status: -1 };
      }, 400);
    }else if (selected['complete'] == 'true'){
      this.contentToShowFormModal = 17;
      //completado
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowFormModal = -1;
        this.contentToShowModal = { status: -1 };
      }, 400);
    }else{*/
      this.router.navigateByUrl(`${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}/${event['values']}/editar`);
    //}
  }

  eventLinkClick(event) {
    this.router.navigateByUrl(`${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}/${event['values']}/detalle`);
    this.cargarGroupTable();
  }

  registrarGrupoDispositivos() {
    this.router.navigateByUrl(`${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}/create`);
  }

  registrarGrupoDispositivosPlantilla() {
    // //console.log( 'registrarGrupoDispositivosPlantilla' );
    this.contentToShowFormModal = 8;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowFormModal = -1;
      this.contentToShowModal = { status: -1 };
    }, 400);
  }

  changeStatusModalTemplate(event) {
    this.contentToShowModal = -1;
  }

  eventEstadoClick(item) {
  if(this.canDoActions){
    //console.log('no entra',this.idRegistry,item['id']);

    let links = document.getElementsByTagName("a");
    let inputs = document.getElementsByTagName("input");

    for (let i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true;
    }
    for (let i = 0; i < links.length; i++) {
      links[i].setAttribute("style", "pointer-events: none;cursor: default;");
    }

    let ruta = environment.endpoints.adminBusesComplete;
    let params = {
      registry: this.idRegistry,
      name: item['id'],
      complete: (item['value']==true?'true':'false')
    };

    //console.log(params,'params');

    this.conditionalLoaderTable = true;
    this.http.ejecutarServicioPost(ruta, params)
        .subscribe((res: Response) => {
      if (res.status === 200) {
        setTimeout(() => {
          this.dataArray = [];
          this.cargarGroupTable();
          this.conditionalLoaderTable = false;
          this.uiService.showMessage('Dispositivo actualizado');

          /* for (let i = 0; i < inputs.length; i++) {
              inputs[i].disabled = false;
          } */
          for (let i = 0; i < links.length; i++) {
              links[i].setAttribute("style", "");
          }
          this.cdr.markForCheck();
        }, 1000);
      }else if (res.status === 500) {
        console.log(res);
        let message = JSON.parse(res['_body']);
        this.uiService.showMessage(message['message']);
        setTimeout(() => {
          this.dataArray = [];
          this.cargarGroupTable();
          this.conditionalLoaderTable = false;
          /* for (let i = 0; i < inputs.length; i++) {
              inputs[i].disabled = false;
          } */
          for (let i = 0; i < links.length; i++) {
              links[i].setAttribute("style", "");
          }
          this.cdr.markForCheck();
        }, 1000);
      }
      else {
        this.conditionalLoaderTable = false;
        this.uiService.showMessage('Error actualizando Dispositivo1');
      }
      this.cdr.markForCheck();
    }, (e) => {
      this.uiService.showMessage('Error actualizando Dispositivo2');
      this.conditionalLoaderTable = false;
      this.cdr.markForCheck();
    });

  }else{
    //console.log('entra');

          // Se deshabilitan todos los links de la tabla
          let links = document.getElementsByTagName("a");
          let inputs = document.getElementsByTagName("input");
          for (let i = 0; i < inputs.length; i++) {
              inputs[i].disabled = true;
          }
          for (let i = 0; i < links.length; i++) {
              links[i].setAttribute("style", "pointer-events: none;cursor: default;");
          }

          let ruta = environment.endpoints.adminRegistryGroupLink;
          let params = {
            registry: this.idRegistry,
            name: item['id'],
            link: (item['value']==true ? 'true' : 'false')
          };


           //console.log(params,'params');

          this.conditionalLoaderTable = true;
          this.http.ejecutarServicioPost(ruta, params)
              .subscribe((res: Response) => {
            if (res.status === 200) {
              setTimeout(() => {
                this.dataArray = [];
                this.cargarGroupTable();
                //this.conditionalLoaderTable = false;
                this.uiService.showMessage('Dispositivo actualizado');

                /* for (let i = 0; i < inputs.length; i++) {
                    inputs[i].disabled = false;
                } */
                for (let i = 0; i < links.length; i++) {
                    links[i].setAttribute("style", "");
                }
                this.cdr.markForCheck();
              }, 1000);
            }else if (res.status === 500) {
              console.log(res);
              let message = JSON.parse(res['_body']);
              this.uiService.showMessage(message['message']);
              setTimeout(() => {
                this.dataArray = [];
                this.cargarGroupTable();
                this.conditionalLoaderTable = false;
                /* for (let i = 0; i < inputs.length; i++) {
                    inputs[i].disabled = false;
                } */
                for (let i = 0; i < links.length; i++) {
                    links[i].setAttribute("style", "");
                }
                this.cdr.markForCheck();
              }, 1000);
            }
            else {
              this.conditionalLoaderTable = false;
              this.uiService.showMessage('Error actualizando Dispositivo1');
            }
            this.cdr.markForCheck();
          }, (e) => {
            this.uiService.showMessage('Error actualizando Dispositivo2');
            this.conditionalLoaderTable = false;
            this.cdr.markForCheck();
          });
      }

  }
}
