import { Component, OnInit, EventEmitter,Input,Output,ChangeDetectorRef } from '@angular/core';
import { AdminRolDTO } from '../../../../shared/dto/admin-rol-dto';
import { HttpService } from '../../../../shared/services/http.service';
import { PermissionDTO } from '../../../../shared/dto/admin-permission-dto';
import { UIService } from '../../../_services/UI.service';
import { environment } from '@environments/environment';
import { Response } from '@angular/http';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'kt-definir-indicadores-rol',
  templateUrl: './definir-indicadores-rol.component.html',
  styleUrls: ['./definir-indicadores-rol.component.scss']
})
export class DefinirIndicadoresRolComponent implements OnInit {
  rutasMenu: object;
  permisos: {}[];
  layers: any = [];
  configDatos: {};
  selectedLayer: any = null;
  selectedIndicators: any = [];
  listadoIndicadores: any = [];
  // listadoPermissions: PermissionDTO[];
  currentLayer: any = {};
  public selectLayers: Array<Select2OptionData>;
  formModelRol: AdminRolDTO = new AdminRolDTO();
  data: AdminRolDTO = new AdminRolDTO();
  @Input() modificarDatos: AdminRolDTO;
  @Output() actionModificarRol = new EventEmitter();
  // Modales
  contentToShow: number = -1;
  configModal: {} = { status: -1 };
  loading: boolean = false;
  constructor(private http: HttpService,
    private uiService: UIService,
    private cdr: ChangeDetectorRef,
    ) { }

  ngOnInit() {
    //this.selectedLayer = 1;
    this.loading = true;
    this.loadLayers();

    this.rutasMenu = [
        {
          label: 'Asignar indicadores a capas',
          ruta: `${environment.folders.routeAdmin}indicadores/definir-rol`
        },
        // {
        //   label: 'Asignar Capas a perfiles',
        //   ruta: `${environment.folders.routeAdmin}indicadores/definir-perfil`
        // }
      ];

    // Select List
    this.configDatos = {
      icono: '',
      titulo: 'Indicadores',
      parametro1: 'Indicadores Disponibles',
      parametro2: 'Indicadores Asignados al capa',
      editar: (this.currentLayer.id) ? true : false
    };
  }

  loadLayers() {
      let ruta = `${environment.endpoints.adminLayersList}`;
      this.http.ejecutarServicioGet(ruta).subscribe((resPermisos: Response) => {
        switch (resPermisos.status) {
          case 200:
          case 201:
          case 204:
            if (resPermisos.json().length > 0) {
              this.layers = resPermisos.json();
              //console.log(this.layers,'layerts');
              this.loading = false;
              this.selectLayers = this.layers.map(i => {
                return {
                  id: i.id,
                  text: i.name
                };
              });
              //this.selectLayers.unshift({ id: 'All', text: 'Seleccione' });
            this.cdr.markForCheck();
            /*
            this.currentLayer = this.layers.find(
              i => i.id === '1'
            );*/
            this.http
            .ejecutarServicioGet(environment.endpoints.adminIndicatorsList)
              .subscribe((indicatorsResponse: Response) => {
              //this.changeLayer();
              this.listadoIndicadores = indicatorsResponse.json();
              this.cdr.markForCheck();
              //this.changeLayer();

              this.LayerCharge();
              });
            }
            
            // this.cdr.markForCheck();
            break;
          case 401:
            // nothing
            break;
          default:
            this.showError(resPermisos);
            break;
        }
      }, (error: Response) => {
        this.showError(error);
      });// END INNER GET
  }

  save() {
    
    if (this.selectedLayer == null){
      this.uiService.showMessage('No ha seleccionado una capa');
    }else{
      let indicators = [];
      indicators = this.selectedIndicators;
      this.http.ejecutarServicioPost(`${environment.endpoints.adminLayer}${this.currentLayer.id}/indicators`, { layer: this.currentLayer.id, indicators: indicators.join()})
      .subscribe(
        (res: Response) => {
          // this.loader = false;
          // this.showModal();
          //console.log('guardado');
          this.uiService.showMessage('Ha sido guardado con éxito');

          this.loadLayers();
          //this.changeLayer();
        });
    }
  }

  showError(response) {

    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage(response.json()['message']);
    } else {
      this.uiService.showMessage('Error del servidor');
    }
    this.loading = false;
    this.cdr.markForCheck();

  }

  resetIndicadores(perm, status) {
    if (status === 'iniciar') {
      for (let index = 0; index < perm.length; index++) {
        perm[index]['checked'] = false;
        perm[index]['active'] = false;
        if (index === perm[index].length - 1) {
          this.listadoIndicadores = perm;
          this.cdr.markForCheck();
        }
      }
    }
  }

  changeLayer() {
    console.log('---select---'); 
    
		if (typeof this.selectedLayer === 'string') {
			this.currentLayer = this.layers.find(
				i => i.id === this.selectedLayer
      );
      //console.log(this.currentLayer);
      let indicadores = [];
      indicadores = this.currentLayer.indicators;
      for (let index = 0; index < this.listadoIndicadores.length; index++) {
        if (indicadores.map(function(e) { return e.id; }).indexOf(this.listadoIndicadores[index]['id']) !== -1) {
          this.listadoIndicadores[index]['active'] = true;
        } else {
          this.listadoIndicadores[index]['active'] = false;
          //console.log('listado indicadores',this.listadoIndicadores);
        }
      }
      for (let i = 0; i < this.currentLayer.indicators.length; i++) {
        this.selectedIndicators.push(this.currentLayer.indicators[i].id);
      }
      this.cdr.markForCheck();
    }
  }

  LayerCharge(){

    if (typeof this.selectedLayer === 'string') {
			this.currentLayer = this.layers.find(
				i => i.id === this.selectedLayer
      );
      //console.log(this.currentLayer);
      let indicadores = [];
      indicadores = this.currentLayer.indicators;
      for (let index = 0; index < this.listadoIndicadores.length; index++) {
        if (indicadores.map(function(e) { return e.id; }).indexOf(this.listadoIndicadores[index]['id']) !== -1) {
          this.listadoIndicadores[index]['active'] = true;
        } else {
          this.listadoIndicadores[index]['active'] = false;
          //console.log('listado indicadores',this.listadoIndicadores);
        }
      }
      this.cdr.markForCheck();
    }
  }


  changeListIndicador(event) {
    //console.log(2);
    // let indicadoresArray = [];
    // this.formModelRol.userPermissions = ''
    this.selectedIndicators = [];
    for (let index = 0; index < event.length; index++) {
      // this.formModelRol.userPermissions = `${event[index].id}, `;
      this.selectedIndicators.push(event[index].id);
      // if (index === event.length - 1) {
        // this.selectedIndicators
        // this.formModelRol.userPermissions = indicadoresArray.join(',');
      // }
    }
    this.cdr.markForCheck();
    //console.log('---indicators----')
    //console.log(this.selectedIndicators);

  }

  // resetPermisos(perm, status) {
  //   if (status === 'iniciar') {
  //     for (let index = 0; index < perm.length; index++) {
  //       perm[index]['checked'] = false;
  //       perm[index]['active'] = false;
  //       if (index === perm[index].length - 1) {
  //         this.listadoPermissions = perm;
  //         this.cdr.markForCheck();
  //       }
  //     }
  //   }
  // }

}
