import { ChangeDetectorRef, Component, OnInit, ViewChild  } from '@angular/core';
import { Select2OptionData, Options } from 'select2';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../../../shared/services/http.service';
import { environment } from './../../../../../../../environments/environment';
import { UIService } from './../../../../../trans/_services/UI.service';
import { Response } from '@angular/http';
import {VgAPI} from 'videogular2/core';



import { AdminGestionCrearAutoDto } from '../../../../../shared/dto/admin-gestion-crear-auto-dto';
import { debug } from 'util';

@Component({
	selector: 'kt-gestionar-caso',
	templateUrl: './gestionar-caso.component.html',
	styleUrls: [
		'./gestionar-caso.component.scss',
		'./../../gestion-manual/gestion-manual.component.scss'
	],
	host: { 'class': 'sths-tb-element' },
	providers: [NgbModal]
})
export class GestionarCasoComponent implements OnInit {

	public options: Options;

	// Id de caso asignado
	idCase: string;

	// Llenan los select box del formulario
	public selectIncidents: Array<Select2OptionData>;
	public selectImages: Array<Select2OptionData>;

	// Llenan el select box del modal
	public selectEntities: Array<Select2OptionData>;
	alertType: string;

	formGestionAuto: AdminGestionCrearAutoDto = new AdminGestionCrearAutoDto;

	// Paginador
	paginador: Object = {
		count: 30,
		page: 1,
		maxPages: 5
	};

	// Table Test
	configTable: Object;

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1, data: [] };

	// Filtros, Columnas, Actions
	columnsHeader: {} = {
		codigo: '',
		fechaCreacion: '',
		horaCreacion: '',
		estadoCaso: '',
		tipoCaso: '',
		concesionario: '',
		ruta: '',
		bus: '',
		condutor: '',
	};

	// toogle modal escalar
	showModalEscalar: boolean = false;

	// Máxima cantidad de carácteres en textarea Observaciones
	maxCharacters: number = 0;

	conditionalLoader = 1;

	dataCaseResponse: object;

	videoUrl: string;

	currenVideo: number = 1;

	sources: Array<Object>;
	api:VgAPI;
	video2: any;

	cameraList: object;

	blackList : Array<string> = [];
	listProfiles:Array<Object>;

	// Tipo de bus que se mostrará en la vista
	busTypeImg: string = "";

	// Permite tener activado el checkbox "Activar CCTV"
	activeCCTV: boolean = false;

	// Validar los campos del formulario
	validarCampos: boolean = false;
	showClasificacion: boolean = true;
	readOnly: boolean = true;

	// Tipo de Bus para CSS
	typeBusClass: string = '';
	busImage: string = "";

	public modalRef: NgbModalRef;
	@ViewChild('content') contentRef: any;
	getStreamURL: string;
	camera: string = '';
	IP: string;
	dataLoaded: boolean = false;
	flagVideo = true;
	disable: boolean = false;
	video = 'assets/img/tenor.gif';

	cameraLabels = {
		"A": { 1: "AO", 2: "ATE", 3: "AFE", 4: "AV2_4", 5: "AV2_3", 6: "AV2_2", 7: "AV2_1", 8: "AV1_4", 9: "AV1_3", 10: "AV1_2", 11: "AV1_1" },
		"B": { 1: "BO", 2: "BTE", 3: "BFE", 4: "BV3_4", 5: "BV3_3", 6: "BV3_2", 7: "BV3_1", 8: "BV2_2", 9: "BV2_1", 10: "BV1_4", 11: "BV1_3", 12: "BV1_2", 13: "BV1_1" },
		"Z": { 1: "AO", 3: "AFE", 8: "AV1_4", 9: "AV1_3", 10: "AV1_2", 11: "AV1_1" },
		"T": { 1: "BSO", 3: "BSF", 8: "BST", 10: "BS-2", 11: "BS-1" },
		"P": { 1: "PDO", 3: "PDF", 7: "PDT", 8: "PD-4", 9: "PD-3", 10: "PD-2", 11: "PD-1" },
		"M": { 1: "MRO", 3: "MRF", 8: "MRT", 11: "MR-1" }
	
	}

	constructor(
		private cdr: ChangeDetectorRef,
		private _route: ActivatedRoute,
		private router: Router,
		private http: HttpService,
		private uiService: UIService,
		private _sanitizer: DomSanitizer,
		public modalService: NgbModal,
	) {
		this.idCase = this._route.snapshot.paramMap.get('idCase');
		this.selectIncidents = [];
		this.selectImages = [];
		this.selectEntities = [];
	}

	ngOnInit() {
		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: "select2-dropdown-forms"
		};

		// Se consume WS con los datos básicos del caso para enviar a la vista
		this.http
			.ejecutarServicioGet(environment.endpoints.adminCasesManagementId + this.idCase, null)
			.subscribe((resEstado: Response) => {
				let dataArray = [];
				switch (resEstado.status) {
					case 200:
					case 201:
					case 204:

						this.dataCaseResponse = resEstado.json();
						console.log("DATAA",this.dataCaseResponse)
						let estados = resEstado.json();
						this.formGestionAuto.alerta = estados.falseAlert == true ? "1" : "0";
						this.formGestionAuto.incidentType = estados.incidentType;
						this.formGestionAuto.incidentClass = estados.incidentClass;
						this.formGestionAuto.imageClassification = estados.imageClassification;
						this.formGestionAuto.observations = estados.observations;
						this.formGestionAuto.caseStatus = estados.caseStatus;
						//this.formGestionAuto.entity = estados.;

						// Se enviará en el correo de escalar caso
						this.alertType = estados.casesObj.alertType;

						/*
						 * Si tipo caso es botón de pánico o accidente, 
						 * se bloquea el checkbox y queda checked, también se desactiva el combo
						 * de Clasificación de imágenes
						 */
						if (this.alertType=='Activación de botón de pánico' || this.alertType=='Accidente o colisión') {
							let input = document.getElementById("defaultCheck1") as HTMLInputElement;
							input.checked = true;
							input.setAttribute("onclick", "return false;");

							this.activeCCTV = false;
						} else {
							this.activeCCTV = true;
						}

						/*
						 * Dependiendo del tipo de alerta, se mostrarán u ocultarán campos 
						 * en "Clasificación del Caso"
						 */
						let validarAlarma = document.getElementById("validarAlarma") as HTMLInputElement;
						let tipoIncidente = document.getElementById("tipoIncidente") as HTMLInputElement;
						let claseIncidente = document.getElementById("claseIncidente") as HTMLInputElement;
						let clasificacionImagenes = document.getElementById("clasificacionImagenes") as HTMLInputElement;
						validarAlarma.style.display='none';
						tipoIncidente.style.display='none';
						claseIncidente.style.display='none';
						clasificacionImagenes.style.display='none';
						switch(this.alertType) {
							case 'Activación de botón de pánico':
								this.validarCampos = true;
								validarAlarma.style.display='block';
								tipoIncidente.style.display='block';
								claseIncidente.style.display='block';
								clasificacionImagenes.style.display='block';
								break;
							case 'Accidente o colisión':
								validarAlarma.style.display='block';
								clasificacionImagenes.style.display='block';
								break;
							default:
								clasificacionImagenes.style.display='none';
								this.showClasificacion = false;
								break;
						}


						this.armarTabla([[
							estados.case_id,
							estados.casesObj.creationDate.substr(0, 10),
							estados.casesObj.creationDate.substr(11, 5),
							estados.caseStatusObj.description,
							estados.casesObj.alertType,
							estados.casesObj.concessionaire,
							estados.casesObj.alertValue,
							estados.casesObj.alertLevel,
							estados.bus
						]]);

						//this.openVideo(1);

						//this.getListVideos();

						if (estados.casesObj.busType === "B") {
							this.typeBusClass = 'biarticulado';
							this.busTypeImg = "./assets/media/cameras/bus-buseton.svg";
						  }else if (estados.casesObj.busType === "A"){
							this.typeBusClass = 'articulado';
							this.busTypeImg = "./assets/media/cameras/bus.svg";
						  }else if (estados.casesObj.busType === "Z"){
							this.typeBusClass = 'buseton';
							this.busTypeImg = "./assets/media/cameras/bus-zonal.svg";
						  }else if (estados.casesObj.busType === "P"){
							this.typeBusClass = 'padron';
							this.busTypeImg = "./assets/media/cameras/bus-padron.svg";
						  }else if (estados.casesObj.busType === "T"){
							this.typeBusClass = 'buseton';
							this.busTypeImg = "./assets/media/cameras/bus-buseton.svg";
						  }
						  else if (estados.casesObj.busType === "M"){
							this.typeBusClass = 'microbus';
							this.busTypeImg = "./assets/media/cameras/bus-microbus.svg";
						  }
						this.cdr.markForCheck();

						break;
					default:
						this.showError(resEstado);
						break;
				}
			}, (error: Response) => {
				this.showError(error);
			}
			);

		// Se consume WS para llenar los select box
		this.http
			.ejecutarServicioGet(environment.endpoints.adminCasesListIncident, null)
			.subscribe((resEstado: Response) => {
				switch (resEstado.status) {
					case 200:
					case 201:
					case 204:
						let data = resEstado.json();

						for (let index = 0; index < data.length; index++) {
							data[index].id = data[index].answer;
							data[index].text = data[index].answer;
							if (index === data.length - 1) {
								// data.unshift( {id:"-Sel", text: "Seleccionar Incidente"} );
								this.selectIncidents = data;
								break;
							}
						}

						this.cdr.markForCheck();
						break;
					default:
						this.showError(resEstado);
						break;
				}
			}, (error: Response) => {
				this.showError(error);
			}
			);

		this.http
			.ejecutarServicioGet(environment.endpoints.adminCasesListImages, null)
			.subscribe((resEstado: Response) => {
				switch (resEstado.status) {
					case 200:
					case 201:
					case 204:
						let data = resEstado.json();

						for (let index = 0; index < data.length; index++) {
							data[index].id = data[index].answer;
							data[index].text = data[index].answer;
							if (index === data.length - 1) {
								// data.unshift( {id:"-Sel", text: "Seleccionar Clasificación"} );
								this.selectImages = data;
								break;
							}
						}

						this.cdr.markForCheck();
						break;
					default:
						this.showError(resEstado);
						break;
				}
			}, (error: Response) => {
				this.showError(error);
			}
			);

		// Llenar combobox de Escalar
		this.http
			.ejecutarServicioGet(environment.endpoints.adminCasesManualEntidades, null)
			.subscribe((resEntities: Response) => {
				switch (resEntities.status) {
					case 200:
					case 201:
					case 204:
						let data = resEntities.json();
						let index: number;


						for (index = 0; index < data.length; index++) {
							data[index].id = data[index].answer + '||' + data[index].answerValue;
							data[index].text = data[index].answer;
							if (index === data.length - 1) {
								break;
							}
						}

						// Se agregan la lista de perfiles para transferir casos al array
						this.http
							.ejecutarServicioGet(environment.endpoints.adminCasesProfilesList, null)
							.subscribe((resEntities: Response) => {
								switch (resEntities.status) {
									case 200:
									case 201:
									case 204:
										let dataProfiles = resEntities.json();
										this.listProfiles = dataProfiles;

										for (let index2 = 0; index2 < dataProfiles.length; index2++) {
											data[index].id = dataProfiles[index2].id.toString();
											data[index].text = dataProfiles[index2].name;
											this.blackList.push(data[index].id );
											if (index2 === dataProfiles.length - 1) {
												this.selectEntities = data;
												break;
											}
											index++;
										}

										break;
									default:
										this.showError(resEntities);
										break;
								}
							}, (error: Response) => {
								this.showError(error);
							}
						);
					
						this.cdr.markForCheck();
						break;
					default:
						this.showError(resEntities);
						break;
				}
			}, (error: Response) => {
				this.showError(error);
			}
		);
	}

	armarTabla(data) {
		this.contentToShow = 1;
		let headerColumns = [];
		let headerTypes = [];

		headerColumns.push('Código Caso');
		headerColumns.push('Fecha de creación');
		headerColumns.push('Hora de creación');
		headerColumns.push('Estado Caso');
		headerColumns.push('Tipo Caso');
		headerColumns.push('Concesionario');
		headerColumns.push('Valor');
		headerColumns.push('Nivel Alarma');
		headerColumns.push('Bus');

		headerTypes.push('');
		headerTypes.push('');
		headerTypes.push('');
		headerTypes.push('');
		headerTypes.push('');
		headerTypes.push('');
		headerTypes.push('');
		headerTypes.push('');
		headerTypes.push('');

		this.configTabla = {
			status: 1,
			header: headerColumns,
			typeColumn: headerTypes,
			showSearch: false,
			searchColumn: [1],
			hideColumns: [],
			showPag: false,
			configColumns: {
				id: -1,
				columnClick: -1
			},
			data: data,
			paginador: this.paginador
		};
		this.cdr.markForCheck();
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}

	escalar() {
		this.showModalEscalar = true;
	}

	escalarSubmit() {

		if (this.formGestionAuto.entity != null && this.formGestionAuto.entity != '') {

			this.crearCasoAuto(5);
			/*if (this.blackList.indexOf(this.formGestionAuto.entity) == -1){

				
			
			} else {
				this.uiService.showMessage('No se puede escalar a esta entidad');
			}*/

			
		}
	}

	statusModal1() {
		this.showModalEscalar = false;
		this.cdr.markForCheck();
	}

	statusModal(event) {
		this.showModalEscalar = false;
	}

	/**
	 * Método encargado de enviar los datos al endpoint "Gestionar Caso automático"
	 * 
	 * @param caseStatus 
	 */
	crearCasoAuto(caseStatus: number) {
		let message = "Se guardó la información diligenciada del caso." +
			"El estado del caso es \"00\"";
		console.log("ALERT FALSE",this.formGestionAuto.falseAlert)
		if (this.validarCampos) {
			// Los campos "falseAlert" y "incidentType" son obligatorios
			if (this.formGestionAuto.falseAlert == null) {

				this.uiService.showMessage("Los campos del formulario principal son obligatorios.");
				return false;
			}
		}

		/*
		 * Si no es falsa alarma, se deben validar los campos 
		 * "incidentClass" e "imageClassification"
		 */
		this.formGestionAuto.falseAlert = this.formGestionAuto.alerta == "1" ? true : false;
		if (this.validarCampos==true && 
			this.formGestionAuto.falseAlert == false &&
			(this.formGestionAuto.incidentClass == null ||
				this.formGestionAuto.imageClassification == null)) {

			this.uiService.showMessage("Debe escoger una Clase de incidente y una Clasificación de imágenes.");
			console.log("si");
		} else {
			console.log("sino");
			let ruta = `${environment.endpoints.adminCasesAutoManagement}${this.idCase}`;
			this.formGestionAuto.caseStatus = caseStatus;
			let formGestion = this.formGestionAuto.toJS();
			this.http
				.ejecutarServicioPut(ruta, formGestion)
				.subscribe((res: Response) => {
					switch (res.status) {
						case 200:
						case 201:
						case 204:
							// Sólo cuando es caso 
							if (caseStatus == 5) {
								let dataEntity = this.formGestionAuto.entity.split('||');

								let formScale = {
									"externalEntityMail": dataEntity[1],
									"externalEntityName": dataEntity[0],
									"caseType": this.alertType,
									"videos": this.getListVideos()
									
									/*[
										{
											"id": 1,
											"url": "http://localhost:8095/cases_management//transfer/external1"
										}
									]*/
								}

								let entity_id = dataEntity[0];

								if (this.blackList.indexOf(this.formGestionAuto.entity) == -1){
									message = message.replace("00", "Escalado");

									//Escalar a una entidad externa
									ruta = environment.endpoints.scalarCasesExternal
									.replace('{{case_id}}', this.idCase)
									.replace('{{entity_id}}', entity_id);
									this.http
										.ejecutarServicioPut(ruta, formScale)
										.subscribe((res: Response) => {
											switch (res.status) {
												case 200:
												case 201:
												case 204:
													this.uiService.showMessage(res.json()['message']);
													this.statusModal1();
													this.router.navigate(['admin/dashboard/gestion/recibir']);
													break;
												default:
													this.showError(res);
													break;
											}
										}, (error: Response) => {
											this.showError(error);
										}
										);			
								} else {

									let profileSelected = this.listProfiles.find(i => i["id"] ==  this.formGestionAuto.entity);
									message = message.replace("El estado del caso es \"00\"", "");

									entity_id = profileSelected["profileId"]

									//Escalar a una entidad externa
									ruta = environment.endpoints.scalarCasesInternal
									.replace('{{case_id}}', this.idCase);
									this.http
										.ejecutarServicioPut(ruta, {})
										.subscribe((res: Response) => {
											switch (res.status) {
												case 200:
												case 201:
												case 204:
													this.uiService.showMessage(res.json()['message']);
													this.statusModal1();
													this.router.navigate(['admin/dashboard/gestion/recibir']);
													break;
												default:
													this.showError(res);
													break;
											}
										}, (error: Response) => {
											this.showError(error);
										}
										);
								} 

							} else if (caseStatus == 4) {
								message = message.replace("00", "Cerrado");
								this.uiService.showMessage(message);
								this.router.navigate(['admin/dashboard/gestion/recibir']);
							} else if (caseStatus == 2) {
								message = message.replace("00", "En gestión");
								this.uiService.showMessage(message);
								this.router.navigate(['admin/dashboard/gestion/recibir']);
							}

							//this.uiService.showMessage(message);
							//this.router.navigate(['admin/dashboard/gestion/recibir']);
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							break;
					}
				}, (error: Response) => {
					this.showError(error);
				}
				);
		}
	}

	onPlayerReady(api:VgAPI) {
		this.api = api;
	
		this.api.getDefaultMedia().subscriptions.ended.subscribe(
			() => {
				// Set the video2 to the beginning
				this.api.getDefaultMedia().currentTime = 0;
			}
		);
	}


	getListVideos() {

		let concessionaire = this.dataCaseResponse['concessionaire'];
		let idBus = this.dataCaseResponse['bus'];
		let date = this.getDateFormatted(this.dataCaseResponse['casesObj']['creationDate']);
		let id_alerta = this.dataCaseResponse['casesObj']['alertId'];

		let videoUrls = [];

		for (let key in this.cameraList) {

			let urlVideo = environment.endpoints.videoCameraCase
									.replace('{{concessionaire}}', concessionaire)
									.replace('{{idBus}}', idBus)
									.replace('{{date}}', date)
									.replace('{{id_alerta}}', id_alerta)
									.replace('{{idCamera}}',this.cameraList[key])
			videoUrls.push({ id : key, videoUrl : urlVideo});
			
		}

		return videoUrls;

	}

	cameraIdToLabel( id ) {
		return this.cameraLabels[this.dataCaseResponse['busType']][ id ];
	  }

	startVideo( camera ) {
		this.modalRef = this.modalService.open(this.contentRef, this.options );
		this.getStreamURL = environment.endpoints.videostream.transformation + environment.endpoints.videostream.getStreamURL;
		this.getStreamURL = this.getStreamURL.replace( "{registry_id}", "" + this.dataCaseResponse['concessionaire'] );
		this.getStreamURL = this.getStreamURL.replace( "{bus_id}", "" + this.dataCaseResponse['bus'] );
		this.getStreamURL = this.getStreamURL.replace( "{camera_id}", this.cameraIdToLabel( camera ) );
		this.camera = this.cameraIdToLabel(camera);
		this.http.ejecutarServicioGet( this.getStreamURL, null )
		  .subscribe( ( response: Response ) => {
			// console.log(response);
			if( response.status === 200 ) {
			  let streamURL = response[ '_body' ];
			  this.IP = streamURL;
			  console.log('IP', this.IP)
			  let fecha = new Date().getTime();
			  this.video = `${environment.endpoints.videostream.execution}${this.IP}&t=${fecha}`;
			  this.cdr.markForCheck();
			} else {
			  this.uiService.showMessage( 'Ha ocurrido un error obteniendo la URL de la cámara' );
			  // Close the modal
			  this.closeModal();
			}
		  }, error => {
			// Close the modal
			this.closeModal();
		  } );
		
	}

	loadVideo(){
		if (!this.flagVideo) {
		  // this.dataLoaded = false;
		  let el = this;
		  // this.videoInterval = setInterval(function() {
			let fecha = new Date().getTime();
			el.video = `${environment.endpoints.videostream.execution}${el.IP}&t=${fecha}`;
			
			//el.video2 = 'https://www.powerdata.es/hs-fs/hubfs/images/Big%20data-1.jpg?width=1425&name=Big%20data-1.jpg';
			// console.log(el.video2);
			el.cdr.markForCheck();
		  // }, 400);
		  this.disable = true;
		} else {
		  this.flagVideo = false;
		}
	}

	errorLoadVideo() {
		// this.dataLoaded = false;
		this.modalRef.close();
		this.flagVideo = true;
		this.video = 'assets/img/tenor.gif';
		this.uiService.showMessage( 'Ha ocurrido un error obteniendo la URL de la cámara' );
		this.cdr.markForCheck();
	}

	closeModal() {
		// Close the modal
		this.modalRef.close();
		this.flagVideo = true;
		this.video = 'assets/img/tenor.gif';
		this.cdr.markForCheck();
		// Stop the video
		// if (this.videoInterval > 0) {
		//   clearInterval(this.videoInterval);
		// }
		//this.stopVideo();
	}

	openVideo(currenVideo) {
		let concessionaire = this.dataCaseResponse['concessionaire'];
		let idBus = this.dataCaseResponse['bus'];
		let date = this.getDateFormatted(this.dataCaseResponse['casesObj']['creationDate']);
		let id_alerta = this.dataCaseResponse['casesObj']['alertId'];		
		this.videoUrl = environment.endpoints.videoCameraCase
			.replace('{{concessionaire}}', concessionaire)
			.replace('{{idBus}}', idBus)
			.replace('{{date}}', date).replace('{{id_alerta}}', id_alerta)
			.replace('{{idCamera}}', currenVideo)
		console.log("URL VIDEO2",this.videoUrl)
		this.http
			.ejecutarServicioGet(this.videoUrl, null)
			.subscribe((resEstado: Response) => {
				switch (resEstado.status) {
					case 200:
					case 201:
					case 204:
						this.video2 = this._sanitizer.bypassSecurityTrustResourceUrl(resEstado.text());
						this.cdr.markForCheck();
						break;
					default:
						this.showError(resEstado);
						break;
				}
			}, (error: Response) => {
				this.showError(error);
			}
		);
	}

	getVideo() {
		return this.videoUrl;
	}

	nextVideo() {
		let videoIds: String;
		console.log("cameras Id",this.cameraLabels[this.dataCaseResponse['busType']])
		if (this.currenVideo < Object.keys(this.cameraLabels[this.dataCaseResponse['busType']]).length) {
			videoIds = this.cameraLabels[this.dataCaseResponse['busType']][Object.keys(this.cameraLabels[this.dataCaseResponse['busType']])[this.currenVideo]];			
			this.openVideo(videoIds);
			this.currenVideo = this.currenVideo + 1
			console.log("ID Camara ",videoIds)
		} else{		
			videoIds = this.cameraLabels[this.dataCaseResponse['busType']][Object.keys(this.cameraLabels[this.dataCaseResponse['busType']])[this.currenVideo]];			
			this.openVideo(videoIds);
		}
	}

	preVideo() {
		let videoIds: String;
		console.log("cameras Id",this.cameraLabels[this.dataCaseResponse['busType']])
		if (this.currenVideo > 1) {
			videoIds = this.cameraLabels[this.dataCaseResponse['busType']][Object.keys(this.cameraLabels[this.dataCaseResponse['busType']])[this.currenVideo]];			
			this.openVideo(videoIds);
			this.currenVideo = this.currenVideo - 1
			console.log("ID Camara ",videoIds)
		} else{		
			videoIds = this.cameraLabels[this.dataCaseResponse['busType']][Object.keys(this.cameraLabels[this.dataCaseResponse['busType']])[this.currenVideo]];			
			this.openVideo(videoIds);
		}
	}

	/**
	 * Cuenta la cantidad de carácteres del textbox observaciones,
	 * se debe evitar que hayan más de 2000
	 * 
	 * @param event 
	 */
	cantCaracteres(event) {
		this.maxCharacters = 2000 - event.length;
	}

	getDateFormatted(dateStr) {


		let splitStr = dateStr.split(" ")
		let splitdate = splitStr[0].split("-");
		// console.log(splitdate);

		//let current_datetime = new Date("2019", "07", "16");

		let current_datetime = new Date(parseInt(splitdate[2]), parseInt(splitdate[1]), parseInt(splitdate[0]));

		let month = (current_datetime.getMonth() > 9) ? current_datetime.getMonth() : "0" + current_datetime.getMonth()
		let day = (current_datetime.getDate() > 9) ? current_datetime.getDate() : "0" + current_datetime.getDate()
		let formatted_date = current_datetime.getFullYear() + "" + month + "" + day

		return formatted_date;
	}

	/**
	 * En caso de checkear "Activar CCTV", se activa el combo "Clasificación de imágenes"
	 */
	onActivarCCTV() {
		let input = document.getElementById("defaultCheck1") as HTMLInputElement;
		if (input.checked==true) {
			this.activeCCTV = false;
		} else {
			this.activeCCTV = true;
		}
	}

	backCaso() {
		this.router.navigate(['admin/dashboard/gestion/recibir']);
	}

	changed(evt) {
		if(this.formGestionAuto.alerta == "1"){
			this.readOnly = false;
		}else{
			this.readOnly = true;
		}
	}
}
