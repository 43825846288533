import { AuthService } from './../../../../../core/auth/_services/auth.service';
// Angular
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
// Auth
import { AuthNoticeService, Login } from '../../../../../core/auth';
// import { environment } from './../../../../../../environments/environment';
import { UserDTO } from '../../../dto/user-dto';
import { UIService } from './../../../../trans/_services/UI.service';
import { CapaService } from './../../../../trans/_services/capa.service';
import { UtilService } from '../../../../trans/_services/util.service';
import { RefreshToken } from '@app/core/auth/_actions/auth.actions';
import { ImagenCorporativaService, Images } from '../../../../trans/administrador/_services/imagen-corporativa.service';

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	// Modales
	showInvalidCredentials: number = -1;
	configModalInvalidCredentials: {} = { status: -1 };
	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	imagenCooporativa: Images;

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private capasService: CapaService,
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private uiService: UIService,
		private imagenCorporativaService: ImagenCorporativaService,
		private ref: ChangeDetectorRef
	) {
		this.unsubscribe = new Subject();
	}

	async ngOnInit() {
		UtilService.resetInterval.emit();
		this.initLoginForm();
		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params['returnUrl'] || '/';
		});

		this.imagenCooporativa = this.imagenCorporativaService.dataStore;
		this.imagenCorporativaService.getImages();
		this.awaitImage();
	}

	/**
	 * Se suscribe al cambio del observable en el servicio -imagenCorporativaService-
	 */
	awaitImage():void{
		this.imagenCorporativaService.images.subscribe( (dataStore:any) => {			
			this.imagenCorporativaService = dataStore;
			this.ref.detectChanges();
		});
	}


	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {
		this.loginForm = this.fb.group({
			username: [
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
				])
			],
			password: [
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100)
				])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		// this.capasService.resetAll();
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
		this.auth
			.login(controls['username'].value, controls['password'].value)
			.subscribe(
				(response: Response) => {
					switch (response.status) {
						case 200:
							const user = new UserDTO(response.json());
							if (user) {
								this.auth.setUser(user);
								this.store.dispatch(
									new Login({ authToken: user.access_token })
								);
								this.store.dispatch(new RefreshToken({ token: user.refresh_token }));

								this.auth.redirectHome( this.router );
								return;
							}
							break;
						case 401:
							// this.invalidCredentials();
							this.uiService.showMessage(
								'El nombre de usuario y/o la contraseña que ingresaste no coinciden con nuestros registros. Por favor revisa e inténtalo de nuevo.'
							);

							break;
						default:
							if (
								response.json()['message'] ===
								'Se debe cambiar la contraseña'
							) {
								this.router.navigateByUrl(
									'/auth/change-password?user=' +
										controls['username'].value
								); // Change password
								return;
							} else {
								this.uiService.showMessage(
									response.json()['message']
								);
							}
							break;
					}

					this.loading = false;
					this.cdr.markForCheck();
				},
				(error: Response) => {
					if (error.json()['message'] === "User Not Found") {
						this.uiService.showMessage(
							'El nombre de usuario y/o la contraseña que ingresaste no coinciden con nuestros registros. Por favor revisa e inténtalo de nuevo.'
						);
					}
					else if (error.json()['message'] === 'Se debe cambiar la contraseña.') {
						this.router.navigateByUrl('/auth/change-password?user=' + controls['username'].value); // Change password
					}
					else if (error.json()['message'] === "Credentials Do Not Match") {
						this.uiService.showMessage(
							'El nombre de usuario y/o la contraseña que ingresaste no coinciden con nuestros registros. Por favor revisa e inténtalo de nuevo.'
						);
					} else {
						this.uiService.showMessage(error.json()['message']);
					}
					this.loading = false;
					this.cdr.markForCheck();
				}
			); // END POST
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}
		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

	invalidCredentials() {
		this.showInvalidCredentials = 1;
		this.configModalInvalidCredentials = {
			status: 1,
			icono: 'smart-icon-laptop-x',
			titulo: 'Error de Usuario o contraseña',
			textos: [
				'El nombre de usuario y/o la contraseña que ingresaste no coinciden con nuestros registros. <br> Por favor revisa e inténtalo de nuevo.'
			],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'ok'
				}
			]
		};
		this.cdr.markForCheck();
	}

	changeInput(input: any): any {
		input.type = input.type === 'password' ? 'text' : 'password';
	}
}
