import { Component, OnInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { NavegacionService } from '@app/views/trans/administrador/_services/navegacion.service';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { Logout } from '../../../../core/auth/_actions/auth.actions';
import { UserDTO } from '../../dto/user-dto';

// State
import { AppState } from '../../../../core/reducers';
import { environment } from './../../../../../environments/environment';
import { HttpService } from '../../../shared/services/http.service';
import { UIService } from '../../../trans/_services/UI.service';

import { CapaService } from '../../../trans/_services/capa.service';
import { IndicatorsService } from '../../../trans/_services/indicators.service';
import { ImagenCorporativaService, Images } from '../../../trans/administrador/_services/imagen-corporativa.service';

@Component({
  selector: 'kt-admin-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarAdminComponent implements OnInit {
  @Input() showAvatar: boolean = true;
  @Input() showHi: boolean = true;

  user$: Observable<UserDTO>;
  user: UserDTO;
  version: string = environment['version'];
  ambiente: string = environment['ambiente'];
  imagenCooporativa: Images;

  caseTypes = [{label: 'Mantenimiento', value: false}, {label: 'Seguridad', value: false}, {label: 'Operaciones', value: false}, {label: 'ITS', value: false}, {label: 'Comunicaciones', value:false}];
  status = false;
  showCasesTypesList = true;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService,
    private http: HttpService,
    private uiService: UIService,
    private cdr: ChangeDetectorRef,
    private capasService: CapaService,
		private indicatorSrv: IndicatorsService,
    private eRef: ElementRef,
    private imagenCorporativaService: ImagenCorporativaService,
    private ref: ChangeDetectorRef,
    private navegacionService: NavegacionService
  ) { }

  ngOnInit() {
    this.user$ = this.authService.userService$;
    this.user = this.authService.getUser();

    this.imagenCooporativa = this.imagenCorporativaService.dataStore;
    this.imagenCorporativaService.getImages();
    this.awaitImage();

    let ruta = environment.endpoints.UserStatus.replace('{{user.name}}', this.user['username']);
    console.log('RUTA', ruta)
    this.http
        .ejecutarServicioGet(ruta)
        .subscribe(
        (res: Response) => {
          let status = res.json();
          //console.log("ROOOLL",status)
          //console.log("Perfil usuario",status.userProfiles)
          if (status.assignedTo && status.assignedTo.length > 0) {
            let arrayCases = status.assignedTo.split(',');
            this.caseTypes = this.caseTypes.map((iteracion)=> {
              if (arrayCases.includes(iteracion.label)){
                iteracion.value = true;
              }
              return iteracion          
            });
          }

          let span = document.getElementById('dot') as HTMLInputElement;
          let img = document.getElementById('card_pic') as HTMLInputElement;
          let select = document.getElementById('checkClass') as HTMLInputElement;

          if (status.available==true) {
            img.classList.remove("imagenred");
            img.classList.add("imagengreen");
            span.style.background = "#00FF00";
            select.value = "1";
            this.status = true
          } else {
            img.classList.remove("imagengreen");
            img.classList.add("imagenred");
            span.style.background = "#FF0000";
            select.value = "2";
            this.status = false
          }

        },
        (error: Response) => {
          this.showError(error);
        }
    );
  }

  /**
	 * Se suscribe al cambio del observable en el servicio -imagenCorporativaService-
	 */
	awaitImage():void{
		this.imagenCorporativaService.images.subscribe( (dataStore:any) => {			
			this.imagenCorporativaService = dataStore;
			this.ref.detectChanges();
		});
	}

  logOut() {
    this.authService.logout().subscribe((resp) => {
      this.store.dispatch(new Logout());
    }, err => {
      this.store.dispatch(new Logout());
    });

    // Limpiar Capas
		this.capasService.resetAll();
		// Limpiar Indicadores
		this.indicatorSrv.clearOptions();

    let ruta = environment.endpoints.UserSetStatus
                  .replace('{{user.name}}', this.user['username'])
                  .replace('{{status}}', 'false');

    this.http
      .ejecutarServicioPut(ruta, null)
      .subscribe(res => {
          if (res.status === 204) {
            this.cdr.markForCheck();
          } else {
            this.showError(res.json());
          }
    });
  }

  home(){    
    this.navegacionService.boxSelected(null);
    //localStorage.setItem( "breadcrumbsSH", JSON.stringify([]));
  }

  irLink() {
    this.router.navigateByUrl(`${environment.folders.routeAdmin}gestion/recibir/gestionar`);
  }

  cambiarClase() {
    this.showCasesTypesList = true;
    let status: string = 'false';
    let select = document.getElementById("checkClass") as HTMLInputElement;
    let span = document.getElementById("dot") as HTMLInputElement;
    let img = document.getElementById("card_pic") as HTMLInputElement;

    if (select.value=="1") {
      img.classList.remove("imagenred");
      img.classList.add("imagengreen");
      span.style.background = "#00FF00";
      status = 'true';
      this.status = true;
    } else {
      img.classList.remove("imagengreen");
      img.classList.add("imagenred");
      span.style.background = "#FF0000";
      status = 'false';
      this.status = false;
    }
    let ruta = environment.endpoints.UserSetStatus
                  .replace('{{user.name}}', this.user['username'])
                  .replace('{{status}}', status);
    this.http
        .ejecutarServicioPut(ruta, null)
        .subscribe(res => {
            if (res.status === 204) {
              this.cdr.markForCheck();
            } else {
              this.showError(res.json());
            }
        });
  }

  showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}

  goMap(){
    this.router.navigate( [environment.folders.routeMap], { state: { from: 'admin' } });
  }

  setCaseType(label) {
		let typeChanged = this.caseTypes.find((type) => type.label === label);
		typeChanged.value = !typeChanged.value;
	}

	setCaseTypeConfig() {
		const cases = this.caseTypes.reduce((acc, caseType) => {
			if (caseType.value == true) {
				acc.push(caseType.label)
			}
			return acc;
		}, []).join(',');
		this.showCasesTypesList = false;
		let ruta = `${environment.endpoints.UserStatus.replace('{{user.name}}', this.user['username'])}/available/true/assigned/${cases}`
    	this.http.ejecutarServicioPut(ruta,null).subscribe(res => {
			if (res.status !== 204) {
        this.showError(res.json());
			} 
		});
  }

  switchProfileState() {
    this.showCasesTypesList = true;
  }
}
