import { BaseModel } from '../../_base/crud';

export class User extends BaseModel {

    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
    username: string;
    roles: string[];

    clear(): void {
        this.access_token = 'access-token-' + Math.random();
        this.expires_in = null;
        this.refresh_token = 'access-token-' + Math.random();
        this.token_type = '';
        this.username = '';
        this.roles = [];
    }
}
