import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminCategoriaDto } from '../../../../shared/dto/admin-categoria-dto';
import { environment } from '../../../../../../environments/environment';
import { HttpService } from '../../../../shared/services/http.service';
import { UIService } from '../../../_services/UI.service';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { Response } from '@angular/http';
import { UserDTO } from '../../../../shared/dto/user-dto';

@Component({
  selector: 'kt-crear-contratista',
  templateUrl: './crear-contratista.component.html',
  styleUrls: ['./crear-contratista.component.scss']
})
export class CrearContratistaComponent implements OnInit {
  userName: string = 'Usuario';

  rolAdmin: boolean = true;
  iDatos: number = 1;
  titulo: string;
  configTable: Object;

  contentToShowModal: number = -1;
  listadoCategorias: AdminCategoriaDto[];
  
  registryIdDelete: any;
  rolUser: any;
  user$: Observable<UserDTO>;
  user: UserDTO;
  
  // Paginador
  paginador: Object = {
    count: 30,
    page: 1
  };
  
  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };
  
  // Cargadores Información
  conditionalLoader: boolean = true;
  
  // Modales
  contentToShowDelete: number = -1;
  configModalDelete: {} = { status: -1 };
  configModal: {} = { status: -1 };
  
  datosEditarContratistas: AdminCategoriaDto;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private http: HttpService,
              private authService: AuthService) {
    this.userName = authService.getUser().username;
  }

  ngOnInit() {
    
    this.route.data.subscribe( data => {

      this.rolAdmin = ( data['tipo'] === 1 );

      if ( this.rolAdmin ) {
        this.titulo = 'Registro de Contratistas';
        this.buscarContratistas();
      } else {
        this.titulo = 'Contratistas registrados';
        this.buscarContratistas();
      }
    } );
    //Verificacion de rol de usuario 
    this.user$ = this.authService.userService$;
    this.user = this.authService.getUser();
    let ruta = environment.endpoints.UserStatus.replace('{{user.name}}', this.user['username']);
    this.http.ejecutarServicioGet(ruta).subscribe( (res: Response) => {
      let status = res.json();
      console.log('Info User', status)
      this.rolUser = status.userProfiles
      console.log('Rol Usuario', this.rolUser)
      //this.rolUser = status.
    } )
  }

  buscarContratistas(){
    // FASE 2, BUSCAR SOLO LOS DEL USUARIO LOGUEADO
    // let params = {
    //   adminRegistryUser: this.userName
    // }
    // this.http.ejecutarServicioPost( environment.endpoints.adminRegistryListAdmins, params ).subscribe( (res: Response) => {
    this.http.ejecutarServicioGet( environment.endpoints.adminRegistriesContratista, null ).subscribe( (res: Response) => {
      //console.log("DDDDsdeed",res.json().length)  
      this.conditionalLoader = false;
      if ( res.json().length > 0 ) {
        this.listadoCategorias = res.json();
        
        this.procesarJson(this.listadoCategorias);
        
      }
      this.cdr.markForCheck();
    });
  }

  procesarJson( data ) {
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['id'].toString(),
        data[index]['id'].toString(),
        data[index]['name'].toString(),
        data[index]['registryLabel'].toString(),
        ''
      ]);
      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }

    if( !data.length ){
      this.armarTabla(dataArray);
    }
  }

  buscarRegistriesContratistas(){
    // this.http.ejecutarServicioPost( environment.endpoints.adminRegistryListAdmins, params ).subscribe( (res: Response) => {
    this.http.ejecutarServicioGet( environment.endpoints.adminUserContratistas, null ).subscribe( (res: Response) => {
      this.conditionalLoader = false;
      
      if ( res.json().length > 0 ) {
        this.listadoCategorias = res.json();
        this.procesarJsonOperador(this.listadoCategorias);
      }
      this.cdr.markForCheck();
    });
  }

  procesarJsonOperador( data ) {
    /*
      @ID (Referencia)
      @ID,
    */
   
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['id'].toString(),
        data[index]['id'].toString(),
        data[index]['name'].toString(),
        data[index]['registryLabel'].toString()
      ]);

      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }
    


    if( !data.length ){
      this.armarTabla(dataArray);
    }
  }

  armarTabla( data ) {
    this.contentToShow = 1;
    if ( this.rolAdmin ) {
      this.configTabla = {
        status: 1,
        header: ['ID', 'ID Contratista', 'Nombre de Contratista', 'ID Label', 'Acciones'],
        typeColumn: [,,,,,{ tipo: 'acciones' , acciones: ['editar', 'eliminar'] }],
        showSearch: true,
        searchColumn: [1,2],
        searchTitle: 'Buscar Contratista',
        hideColumns: [0],
        showPag: false,
        configColumns: {
          id: 0
        },
        data: data,
        paginador: this.paginador
      };
    } else {
      this.configTabla = {
        status: 1,
        header: ['ID', 'ID Contratista', 'Nombre de Contratista', 'ID Label', 'Acciones'],
        typeColumn: [,,,,{ tipo: 'acciones' , acciones: ['editar', 'eliminar'] }],
        showSearch: true,
        searchColumn: [1,2],
        searchTitle: 'Buscar Contratista',
        hideColumns: [0],
        showPag: false,
        leftsColumns: [1],
        configColumns: {
          id: 0
        },
        data: data,
        paginador: this.paginador,
      }
    }
    this.cdr.markForCheck();
  }

  buscadorPagina(event) {
    this.paginador['page'] = event;
  }

  eventLinkClick( event ) {
    if ( this.rolAdmin ) {
      let ruta = `${environment.endpoints.adminRegistryContratistaGet}${event}`;
      this.http.ejecutarServicioGet( ruta, null ).subscribe( (res: Response) => {
        let registry = res.json();
        this.datosEditarContratistas = new AdminCategoriaDto;
        this.datosEditarContratistas.id = registry.id;
        this.datosEditarContratistas.registryId = registry.id;
        this.datosEditarContratistas.name = registry.name;
  
        this.contentToShowModal = 28;
        this.cdr.markForCheck();
      });
    }else{
      this.router.navigateByUrl( `${environment.folders.routeAdmin}registry/tipo2/groups/${event}` );
    }
  }

  editarElemento( event ){
    if(this.rolUser == '678' || this.rolUser == '3'){

      let ruta = `${environment.endpoints.adminRegistryContratistaGet}${event}`;
      this.http.ejecutarServicioGet( ruta, null ).subscribe( (res: Response) => {
        let registry = res.json();
        this.datosEditarContratistas = new AdminCategoriaDto;
        this.datosEditarContratistas.id = registry.id;
        this.datosEditarContratistas.registryId = registry.id;
        this.datosEditarContratistas.name = registry.name;
        this.datosEditarContratistas.registryLabel = registry.registryLabel;
  
        this.contentToShowModal = 27;
        this.cdr.markForCheck();
      });
    }else{
      this.configModal = {
        status: 1,
        icono: 'smart-icon-nombre-categoria',
        titulo: 'No tienes permisos',
        textos: [
          'No cuentas con los permisos para editar un contratista'
        ],
        botones: [
          {
            label: 'Cancelar',
            class: 'sths-btn-cancel',
            accion: 'cancelar'
          }
        ]
      };
    }
  }

  eventEliminarClick( registryId ){
    if(this.rolUser == '678' || this.rolUser == '3'){
      this.registryIdDelete = registryId;
      this.contentToShowDelete = 1;
  
      this.configModalDelete = {
        status: 1,
        icono: 'smart-icon-eliminar',
        titulo: 'Eliminar contratista',
        textos: [
              '¿Esta seguro que quiere eliminar este contratista?'
        ],
        botones: [
                {
                  label: 'Cancelar',
                  class: 'sths-btn-cancel',
                  accion: 'cancelar'
                },
                {
                  label: 'Eliminar',
                  class: '',
                  accion: 'eliminar'
                }
        ]
      };
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowDelete = -1;
        this.configModalDelete = { status: -1 }
      }, 300);
    }else{
      this.configModal = {
        status: 1,
        icono: 'smart-icon-nombre-categoria',
        titulo: 'No tienes permisos',
        textos: [
          'No cuentas con los permisos para eliminar un contratista'
        ],
        botones: [
          {
            label: 'Cancelar',
            class: 'sths-btn-cancel',
            accion: 'cancelar'
          }
        ]
      };
    }
  }

  registrarCategoria() {
    if(this.rolUser == '678' || this.rolUser == '3'){
      this.contentToShowModal = 29;
      this.cdr.markForCheck();
    }else{
      this.configModal = {
        status: 1,
        icono: 'smart-icon-nombre-categoria',
        titulo: 'No tienes permisos',
        textos: [
          'No cuentas con los permisos para crear un contratista'
        ],
        botones: [
          {
            label: 'Cancelar',
            class: 'sths-btn-cancel',
            accion: 'cancelar'
          }
        ]
      };
    }
  }
  
  // Cerrar Modal Registry
  statusModal(){
    this.contentToShowModal = -1;
    this.datosEditarContratistas = new AdminCategoriaDto;
    this.buscarContratistas();
  }

  changeStatusModalDelete( event ){
    if ( event === 'eliminar' ){
      
      let formModelCategoria = new AdminCategoriaDto;
      formModelCategoria.registryId = this.registryIdDelete;
      let formCategoria = formModelCategoria.toJS();
      delete formCategoria.id;
      delete formCategoria.name;

      
      this.conditionalLoader = true;
      this.cdr.markForCheck();
      this.http.ejecutarServicioPost( environment.endpoints.adminRegistryContratistaDelete, formCategoria ).subscribe( (res: Response) => {
        if ( res.ok === true ) {
          // this.buscarRegistries();
          // Realizar algo con el ID
          let resPost = res.json();
  
        } else {
          // TODO :: MANEJAR EXCEPCION
        }
        
        this.buscarContratistas();
        this.conditionalLoader = false;
        this.cdr.markForCheck();
      });

      this.registryIdDelete = '';
    }
  }
}
