import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PermissionDTO } from '../../dto/admin-permission-dto';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@Component({
  selector: 'kt-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements OnInit {
  @Input('iPermisos') 
  get iPermisos(){
    return this.permisos;
  }
  set iPermisos(p){
    console.log( p );
    this.permisos = p;
    this.filter1.setValue('');
    this.filter2.setValue('');
    this.cdr.markForCheck();
  }
  @Input() iConfig: {};
  // Retornar variable
  @Output() listSelected  = new EventEmitter();
  filter1 = new FormControl('');
  data1$: Observable<any>;

  filter2 = new FormControl('');
  data2$: Observable<any>;

  config = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
  @ViewChild('scrollIndicadores') scrollIndicadores: PerfectScrollbarComponent;

  /*
    @icono
    @titulo
    @parametro1
    @parametro2
  */
  configDatos: {};
  permisos: PermissionDTO[];

  constructor(private cdr: ChangeDetectorRef) {
    this.data1$ = this.filter1.valueChanges.pipe(
      startWith(''),
      map(text => this.search(text))
    );

    this.data2$ = this.filter2.valueChanges.pipe(
      startWith(''),
      map(text => this.search(text))
    );
  }

  search(text: string): any[] {
    return this.permisos.filter(itemData => {
      const term = text.toLowerCase();
      if( itemData['name'].toLowerCase().includes(term) ) {
        return true;
      }
    });
  }

  ngOnInit() {
    this.configDatos = this.iConfig;
  }
  
  activarTodos(){
    this.permisos.forEach(element => {
      element['active'] = true;
      element['checked'] = false;
    });
    this.listSelected.emit( this.permisos );
  }
  activarChecks(){
    let permisosCheckeados = [];
    this.permisos.forEach( (element, index) => {
      if( element['checked'] ){
        element['active'] = true;
      }
      if( element['active'] ){
        permisosCheckeados.push(element);
      }
      element['checked'] = false;
      if( index == this.permisos.length - 1 ){
        this.listSelected.emit( permisosCheckeados );
      }
    });
  }
  desactivarTodos(){
    this.permisos.forEach(element => {
      element['active'] = false;
      element['checked'] = false;
    });
    this.listSelected.emit( [] );
  }
  desactivarChecks(){
    let permisosCheckeados = [];
    this.permisos.forEach( (element, index) => {
      if( element['checked'] ){
        element['active'] = false;
      }
      if( element['active'] ){
        permisosCheckeados.push(element);
      }
      element['checked'] = false;
      if( index == this.permisos.length - 1 ){
        this.listSelected.emit( permisosCheckeados );
      }
    });
  }
}
