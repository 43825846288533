import { environment } from './../../../../../environments/environment';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Route, ActivatedRoute, Router } from '@angular/router';

import { Response } from '@angular/http';
import { HttpService } from '../../../shared/services/http.service';
import { AdminCategoriaDto } from '../../../shared/dto/admin-categoria-dto';
import { AuthService } from '../../../../core/auth/_services/auth.service';

@Component({
  selector: 'kt-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss'],
  host: {'class': 'sths-tb-element'}
})
export class CategoriasComponent implements OnInit {
  userName: string = 'Usuario';

  rolAdmin: boolean = true;
  iDatos: number = 1;
  titulo: string;
  configTable: Object;

  contentToShowModal: number = -1;
  listadoCategorias: AdminCategoriaDto[];
  
  registryIdDelete: any;
  
  // Paginador
  paginador: Object = {
    count: 30,
    page: 1
  };
  
  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };
  
  // Cargadores Información
  conditionalLoader: boolean = true;
  
  // Modales
  contentToShowDelete: number = -1;
  configModalDelete: {} = { status: -1 };
  
  datosEditarCategorias: AdminCategoriaDto;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private http: HttpService,
              private auth: AuthService) {
    this.userName = auth.getUser().username;
  }

  ngOnInit() {
    this.route.data.subscribe( data => {

      this.rolAdmin = ( data['tipo'] === 1 );

      if ( this.rolAdmin ) {
        this.titulo = 'Registro de Operadores';
        this.buscarRegistries();
      } else {
        this.titulo = 'Operadores registrados';
        this.buscarRegistriesOperador();
      }
    } );
  }

  buscarRegistries(){
    // FASE 2, BUSCAR SOLO LOS DEL USUARIO LOGUEADO
    // let params = {
    //   adminRegistryUser: this.userName
    // }
    // this.http.ejecutarServicioPost( environment.endpoints.adminRegistryListAdmins, params ).subscribe( (res: Response) => {
    this.http.ejecutarServicioGet( environment.endpoints.adminRegistries, null ).subscribe( (res: Response) => {
      this.conditionalLoader = false;
      if ( res.json().length > 0 ) {
        this.listadoCategorias = res.json();
        this.procesarJson(this.listadoCategorias);
        console.log('Listado Categorias:',this.listadoCategorias)
      }
      this.cdr.markForCheck();
    });
  }

  procesarJson( data ) {
    /*
      @ID (Referencia)
      @ID,
    */
    console.log('TIPO: ',typeof(data))
    console.log('data: ',data)
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['id'].toString(),
        data[index]['id'].toString(),
        data[index]['name'].toString(),
        data[index]['registryLabel'].toString(),
        ''
      ]);
      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }

    if( !data.length ){
      this.armarTabla(dataArray);
    }
  }

  buscarRegistriesOperador(){
    // FASE 2, BUSCAR SOLO LOS DEL USUARIO LOGUEADO
    // let params = {
    //   adminRegistryUser: this.userName
    // }
    // this.http.ejecutarServicioPost( environment.endpoints.adminRegistryListAdmins, params ).subscribe( (res: Response) => {
    this.http.ejecutarServicioGet( environment.endpoints.adminUserRegistries, null ).subscribe( (res: Response) => {
      this.conditionalLoader = false;
      console.log( this.conditionalLoader );
      if ( res.json().length > 0 ) {
        this.listadoCategorias = res.json();
        this.procesarJsonOperador(this.listadoCategorias);
      }
      this.cdr.markForCheck();
    });
  }

  procesarJsonOperador( data ) {
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['id'].toString(),
        data[index]['id'].toString(),
        data[index]['name'].toString(),
        data[index]['registryLabel'].toString()
      ]);

      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }

    if( !data.length ){
      this.armarTabla(dataArray);
    }
  }

  armarTabla( data ) {
    this.contentToShow = 1;
    if ( this.rolAdmin ) {
      this.configTabla = {
        status: 1,
        header: ['ID', 'ID Operador', 'Nombre de Operador', 'ID Label', 'Acciones'],
        typeColumn: [,,,,{ tipo: 'acciones' , acciones: ['editar', 'eliminar'] }],
        showSearch: true,
        searchColumn: [1,2],
        searchTitle: 'Buscar Operador',
        hideColumns: [0],
        showPag: false,
        configColumns: {
          id: 0,
          columnClick: 1,
        },
        data: data,
        paginador: this.paginador
      };
    } else {
      this.configTabla = {
        status: 1,
        header: ['ID', 'ID Operador', 'Nombre de Operador', 'ID Label'],
        typeColumn: [,,,,],
        showSearch: true,
        searchColumn: [1,2],
        searchTitle: 'Buscar Operador',
        hideColumns: [0],
        showPag: false,
        leftsColumns: [1],
        configColumns: {
          id: 0,
          columnClick: 1,
        },
        data: data,
        paginador: this.paginador,
      }
    }
    this.cdr.markForCheck();
  }

  buscadorPagina(event) {
    this.paginador['page'] = event;
  }

  eventLinkClick( event ) {
    if ( this.rolAdmin ) {
      let ruta = `${environment.endpoints.adminRegistryGet}${event}`;
      this.http.ejecutarServicioGet( ruta, null ).subscribe( (res: Response) => {
        let registry = res.json();
        this.datosEditarCategorias = new AdminCategoriaDto;
        this.datosEditarCategorias.id = registry.id;
        this.datosEditarCategorias.registryId = registry.id;
        this.datosEditarCategorias.name = registry.name;
  
        this.contentToShowModal = 3;
        this.cdr.markForCheck();
      });
    }else{
      this.router.navigateByUrl( `${environment.folders.routeAdmin}registry/tipo2/groups/${event}` );
    }
  }

  editarElemento( event ){
    let ruta = `${environment.endpoints.adminRegistryGet}${event}`;
    this.http.ejecutarServicioGet( ruta, null ).subscribe( (res: Response) => {
      let registry = res.json();
      this.datosEditarCategorias = new AdminCategoriaDto;
      this.datosEditarCategorias.id = registry.id;
      this.datosEditarCategorias.registryId = registry.id;
      this.datosEditarCategorias.name = registry.name;
      this.datosEditarCategorias.registryLabel = registry.registryLabel;

      this.contentToShowModal = 2;
      this.cdr.markForCheck();
    });
  }

  eventEliminarClick( registryId ){
    // Eliminar
    // console.log( 'Eliminar ' );
    // console.log( registryId );
    this.registryIdDelete = registryId;
    this.contentToShowDelete = 1;

    this.configModalDelete = {
      status: 1,
      icono: 'smart-icon-eliminar',
      titulo: 'Eliminar Operador',
      textos: [
            '¿Esta seguro que quiere eliminar este operador?'
      ],
      botones: [
              {
                label: 'Cancelar',
                class: 'sths-btn-cancel',
                accion: 'cancelar'
              },
              {
                label: 'Eliminar',
                class: '',
                accion: 'eliminar'
              }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowDelete = -1;
      this.configModalDelete = { status: -1 }
    }, 300);
  }

  registrarCategoria() {
    this.contentToShowModal = 1;
    this.cdr.markForCheck();
  }
  
  // Cerrar Modal Registry
  statusModal(){
    this.contentToShowModal = -1;
    this.datosEditarCategorias = new AdminCategoriaDto;
    this.buscarRegistries();
  }

  changeStatusModalDelete( event ){
    if ( event === 'eliminar' ){
      
      let formModelCategoria = new AdminCategoriaDto;
      formModelCategoria.registryId = this.registryIdDelete;
      let formCategoria = formModelCategoria.toJS();
      delete formCategoria.id;
      delete formCategoria.name;

      
      this.conditionalLoader = true;
      this.cdr.markForCheck();
      this.http.ejecutarServicioPost( environment.endpoints.adminRegistryDelete, formCategoria ).subscribe( (res: Response) => {
        if ( res.ok === true ) {
          // this.buscarRegistries();
          // Realizar algo con el ID
          let resPost = res.json();
  
        } else {
          // TODO :: MANEJAR EXCEPCION
        }
        
        this.buscarRegistries();
        this.conditionalLoader = false;
        this.cdr.markForCheck();
      });

      this.registryIdDelete = '';
    }
  }
}
