import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

export class AdminGestionCrearDto {
    
    caseType: string = "MANUAL";
    caseStatus: number = 1;
    creationUser: string;
    assignedBy: string;
    modificationUser: string;
    requestBy: string = 'Entidad externa';
    requestByValue: string;
    requestByDescription: string;
    eventOccurrenceDate: string;
    userId: string;
    userRole: string;
    // Variables Armar
    fechaHecho: NgbDateStruct;
    horaHecho: any;
    entidad: string;
    area: string;
    name: string;

    constructor(data ? : any) {
        if (data !== undefined) {
            this.caseType = data['caseType'] !== undefined ? data['caseType'] : null;
            this.caseStatus = data['caseStatus'] !== undefined ? data['caseStatus'] : null;
            this.creationUser = data['creationUser'] !== undefined ? data['creationUser'] : null;
            this.assignedBy = data['assignedBy'] !== undefined ? data['assignedBy'] : null;
            this.modificationUser = data['modificationUser'] !== undefined ? data['modificationUser'] : null;
            this.requestBy = data['requestBy'] !== undefined ? data['requestBy'] : null;
            this.requestByValue = data['requestByValue'] !== undefined ? data['requestByValue'] : null;
            this.requestByDescription = data['requestByDescription'] !== undefined ? data['requestByDescription'] : null;
            this.userRole = data['userRole'] !== undefined ? data['userRole'] : null;
            // 
            this.fechaHecho = data['fechaHecho'] !== undefined ? data['fechaHecho'] : null;
            this.horaHecho = data['horaHecho'] !== undefined ? data['horaHecho'] : null;
            this.entidad = data['entidad'] !== undefined ? data['entidad'] : null;
            this.area = data['area'] !== undefined ? data['area'] : null;
            this.name = data['name'] !== undefined ? data['name'] : null;
        }
    }

    static fromJS(data: any): AdminGestionCrearDto {
        return new AdminGestionCrearDto(data);
    }

    toJS(data ? : any) {
        data = data === undefined ? {} : data;
        data['caseType'] = this.caseType !== undefined ? this.caseType : null;
        data['caseStatus'] = this.caseStatus !== undefined ? this.caseStatus : null;
        data['creationUser'] = this.creationUser !== undefined ? this.creationUser : null;
        data['assignedBy'] = this.assignedBy !== undefined ? this.assignedBy : null;
        data['modificationUser'] = this.modificationUser !== undefined ? this.modificationUser : null;
        data['requestBy'] = this.requestBy !== undefined ? this.requestBy : null;
        data['requestByValue'] = this.requestByValue !== undefined ? this.requestByValue : null;
        data['requestByDescription'] = this.requestByDescription !== undefined ? this.requestByDescription : null;
        data['eventOccurrenceDate'] = this.eventOccurrenceDate !== undefined ? this.eventOccurrenceDate : null;
        data['userId'] = this.userId !== undefined ? this.userId : null;
        data['userRole'] = this.userRole !== undefined ? this.userRole : null;
        data['fechaHecho'] = this.fechaHecho !== undefined ? this.fechaHecho : null;
        data['horaHecho'] = this.horaHecho !== undefined ? this.horaHecho : null;
        data['entidad'] = this.entidad !== undefined ? this.entidad : null;
        data['area'] = this.area !== undefined ? this.area : null;
        data['name'] = this.name !== undefined ? this.name : null;
        return data;
    }

    toStrings(data ? : any) {
        data = data === undefined ? {} : data;
        data['caseType'] = this.caseType !== undefined ? this.caseType : '';
        data['caseStatus'] = this.caseStatus !== undefined ? this.caseStatus : '';
        data['creationUser'] = this.creationUser !== undefined ? this.creationUser : '';
        data['assignedBy'] = this.assignedBy !== undefined ? this.assignedBy : '';
        data['modificationUser'] = this.modificationUser !== undefined ? this.modificationUser : '';
        data['requestBy'] = this.requestBy !== undefined ? this.requestBy : '';
        data['requestByValue'] = this.requestByValue !== undefined ? this.requestByValue : '';
        data['requestByDescription'] = this.requestByDescription !== undefined ? this.requestByDescription : '';
        data['eventOccurrenceDate'] = this.eventOccurrenceDate !== undefined ? this.eventOccurrenceDate : '';
        data['userId'] = this.userId !== undefined ? this.userId : '';
        data['userRole'] = this.userRole !== undefined ? this.userRole : '';
        data['fechaHecho'] = this.fechaHecho !== undefined ? this.fechaHecho : '';
        data['horaHecho'] = this.horaHecho !== undefined ? this.horaHecho : '';
        data['entidad'] = this.entidad !== undefined ? this.entidad : '';
        data['area'] = this.area !== undefined ? this.area : '';
        data['name'] = this.name !== undefined ? this.name : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminGestionCrearDto(JSON.parse(json));
    }

}