import { EstadisticosComponent } from './reporte-boton-panico/estadisticos.component';
import { ReporteDanoVandalismoComponent } from './reporte-dano-vandalismo/reporte-dano-vandalismo.component';
import { ReporteIncidentesSeguridadComponent } from './reporte-incidentes-seguridad/reporte-incidentes-seguridad.component';
import { ReporteSensoresMotorComponent } from './reporte-sensores-motor/reporte-sensores-motor.component';
import { ReporteSensoresPesoComponent } from './reporte-sensores-peso/reporte-sensores-peso.component';
import { ReporteSensoresUmbralesComponent } from './reporte-sensores-umbrales/reporte-sensores-umbrales.component';
import { ReporteSensoresUmbralesPesoComponent } from './reporte-sensores-umbrales-peso/reporte-sensores-umbrales-peso.component';
import { ReporteValidacionConductorComponent } from './reporte-validacion-conductor/validacion-conductor.component';
import { ReporteRecaudoPagoComponent } from './reporte-recaudo-pago/reporte-recaudo-pago.component';
import { ReporteValidacionesIncorrectasConductorComponent } from './reporte-validaciones-incorrectas-conductor/reporte-validaciones-incorrectas-conductor.component';

export default {
  EstadisticosComponent,
  ReporteDanoVandalismoComponent,
  ReporteIncidentesSeguridadComponent,
  ReporteSensoresMotorComponent,
  ReporteSensoresPesoComponent,
  ReporteSensoresUmbralesComponent,
  ReporteSensoresUmbralesPesoComponent,
  ReporteValidacionConductorComponent,
  ReporteRecaudoPagoComponent,
  ReporteValidacionesIncorrectasConductorComponent
};

export const Components = [
  EstadisticosComponent,
  ReporteDanoVandalismoComponent,
  ReporteIncidentesSeguridadComponent,
  ReporteSensoresMotorComponent,
  ReporteSensoresPesoComponent,
  ReporteSensoresUmbralesComponent,
  ReporteSensoresUmbralesPesoComponent,
  ReporteValidacionConductorComponent,
  ReporteRecaudoPagoComponent,
  ReporteValidacionesIncorrectasConductorComponent
];
