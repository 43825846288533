import {Component, OnInit, ViewChild, Input, EventEmitter, Output} from '@angular/core';
import {NgbModalConfig, NgbModal, NgbActiveModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	providers: [NgbModalConfig, NgbModal]
})
export class ModalComponent implements OnInit {
	@ViewChild('content') contentRef: any;

	// @Input() header: string = 'this is header';
	// @Input() footer: string = 'this is footer';
	@Output() statusModal = new EventEmitter();

	@Input('show')
	set show(num) {
		if (num === true) {
			this.open();
		} else {
			this.modalService.dismissAll('');
		}
	}

	options: any = {
		centered: true,
		size: 'lg',
		windowClass: 'sths-modal-style',
		backdropClass: 'sths-backdrop'
	};

	constructor(config: NgbModalConfig,
				private modalService: NgbModal) {
		config.backdrop = 'static';
		config.keyboard = false;

	}

	ngOnInit() {
	}

	open() {
		setTimeout(() => {
			this.modalService.open(this.contentRef, this.options).result.then((result) => {
				// Cerrar
				this.statusModal.emit('');
			}, (reason) => {
				// Cerrar
				this.statusModal.emit(reason);
			});
		}, 300);
	}
}
