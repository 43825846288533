import { Component, OnInit, ChangeDetectorRef } from '@angular/core';

import { Response } from '@angular/http';
import { AdminUserDTO } from '../../../../shared/dto/admin-user-dto';

import { UsuariosService } from '../../_services/usuarios.service';

import { Select2OptionData, Options } from 'select2';
import { UIService } from '../../../_services/UI.service';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'kt-usuario-buscar',
	templateUrl: './usuario-buscar.component.html',
	styleUrls: ['./usuario-buscar.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class UsuarioBuscarComponent implements OnInit {
	// Select2
	public selectFitroProfiles: Array<Select2OptionData>;
	public selectFitro1: Array<Select2OptionData>;
	public options: Options;

	configTable: object;
	rutasMenu: object;
	showForgotModal: number = -1;
	configForgotModal: {} = { status: -1 };
	modify: boolean = false;
	// Paginador
	paginador: object = {
		count: 30,
		page: 1,
		total: 0,
		maxPages: 5
	};

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1 };

	// Modal Modificar
	contentToModificar: number = -1;

	formModelUser: AdminUserDTO = new AdminUserDTO();
	formModelUserEditar: AdminUserDTO = new AdminUserDTO();

	loading: boolean = true;
	loadingConcesionarios: boolean = true;

	constructor(
		private cdr: ChangeDetectorRef,
		public _usuariosService: UsuariosService,
		private uiService: UIService,
		private http: HttpService
	) {}

	ngOnInit() {
		this.rutasMenu = [
			{
				label: 'Buscar Usuario',
				icon: 'smart-icon-search',
				ruta: `${environment.folders.routeAdmin}usuario/buscar`
			},
			{
				label: 'Crear Usuario',
				icon: 'smart-icon-usuario',
				ruta: `${environment.folders.routeAdmin}usuario/crear`
			}
		];

		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};

		this.buscarUsers();
		this.formModelUser.entity = 'all';

		this.http
			.ejecutarServicioGet(environment.endpoints.adminRegistries)
			.subscribe(
				(resRegistries: Response) => {
					switch (resRegistries.status) {
						case 200:
						case 201:
						case 204:
							let entities = resRegistries.json();
							for (
								let indexEntities = 0;
								indexEntities < entities.length;
								indexEntities++
							) {
								entities[indexEntities]['text'] =
									entities[indexEntities]['id'];
								// if (indexEntities === entities.length - 1) {
								// }
							}
							this.selectFitro1 = entities;
							this.loading = false;
							this.cdr.markForCheck();
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(resRegistries);
							this.selectFitro1 = [];
							break;
					}
					this.selectFitro1.unshift({ id: 'all', text: 'Todas' });
					this.loadingConcesionarios = false;
				},
				(error: Response) => {
					this.showError(error);
					this.selectFitro1.unshift({ id: 'all', text: 'Todas' });
					this.formModelUser['entity'] = 'all';
					this.loadingConcesionarios = false;
				}
			); // END GET
	}

	buscarUsers() {
		this.loading = true;
		//let count = this.paginador['count'];
		//this.paginador['page'] = String(page);
		let params = this.getParams();
		this.http
			.ejecutarServicioGet(
				`${environment.endpoints.adminUserCount}${params}`,
				null
			)
			.subscribe(
				(response: Response) => {
					if(response.json() > 0) {
						this.paginador['total'] = response.json();
						// if(this.paginador['total'] === 0){
						// 	this.paginador = { count: 30, total: 0, page: };
						// }
						this._usuariosService
							.getUsuariosSearch(this.formModelUser, this.paginador['count'], this.paginador['page'])
							.subscribe(
								(res: Response) => {
									switch (res.status) {
										case 200:
										case 201:
										case 204:
											/* this.contentToShow = -1;
											this.configTabla = {
												status: -1
											}; */
											this.procesarJson(res.json());
											break;
										case 401:
											// nothing
											break;
										default:
											this.showError(res);
											break;
									}
								},
								(error: Response) => {
									this.showError(error);
								}
							); // END GET
					} else {
						this.procesarJson([]);
						this.loading = false;
						this.cdr.markForCheck();
					}
					
				},
				(error: Response) => {
					this.showError(error);
				}
			);
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage(response.json());
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	procesarJson(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				data[index]['id'].toString(),
				data[index]['id'].toString(),
				data[index]['username'].toString(),
				data[index]['name'].toString(),
				data[index]['lastName'].toString(),
				data[index]['email'].toString(),
				data[index]['entity'].toString(),
				'',
				// '',
				data[index]['active']
			]);
		}
		this.armarTabla(dataArray);
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: [
				'ID',
				'Id Usuario',
				'Usuario',
				'Nombre',
				'Apellido',
				'Correo',
				'Entidad',
				// 'Cambiar Contraseña',
				'Acciones',
				'Estado'
			],
			typeColumn: [
				,
				,
				,
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'reset-password'] },
				// { tipo: 'acciones', acciones: ['reset'] },
				{ tipo: 'estado' }
			],
			searchColumn: [0, 1, 2],
			hideColumns: [0],
			showPag: true,
			showExport: true,
			configColumns: {
				id: 2,
				email: 5
			},
			data: data,
			paginador: this.paginador
		};
		this.loading = false;
		this.cdr.markForCheck();
	}

	buscadorPagina(event) {
		this.paginador['page'] = event;
		this.buscarUsers();
		/* if (this.paginador['total'] > this.paginador['count']) {
			// this.paginador['page'] = event;
			this.buscarUsers(Number(event));
		} */
	}

	editarElemento(event) {
		this.modify = true;
		this._usuariosService.getUsuarioID(event).subscribe(
			(res: Response) => {
				switch (res.status) {
					case 200:
					case 201:
					case 204:
						let user = res.json();
						// search profiles
						this.http
							.ejecutarServicioGet(
								`${environment.endpoints.adminUserProfiles}${event}/profiles`,
								null
							)
							.subscribe(
								(response: Response) => {
									switch (response.status) {
										case 200:
										case 201:
										case 204:
											if (user.active === true) {
												let profiles = response.json();
												this.formModelUserEditar.id =
													user.id;
												this.formModelUserEditar.name =
													user.name;
												this.formModelUserEditar.username =
													user.username;
												this.formModelUserEditar.entity =
													user.entity;
												this.formModelUserEditar.email =
													user.email;
												this.formModelUserEditar.lastName = user.lastName;
												if (profiles[0]) {
													this.formModelUserEditar.userProfiles =
														profiles[0].id;
												}
												// Ejecutar Form 7 (Editar User)
												this.contentToModificar = 7;
												this.cdr.markForCheck();
												setTimeout(() => {
													this.contentToModificar = -1;
												}, 300);
											} else {
												// Ejecutar Form 7 (Editar User)
												this.contentToModificar = 14;
												this.cdr.markForCheck();
												setTimeout(() => {
													this.contentToModificar = -1;
												}, 300);
											}
											break;
										case 401:
											// nothing
											break;
										default:
											this.showError(response);
											break;
									}
								},
								(error: Response) => {
									this.showError(error);
								}
							); // END INNER GET
						break;
					case 401:
						// nothing
						break;
					default:
						this.showError(res);
						break;
				}
			},
			(error: Response) => {
				this.showError(error);
			}
		); // END GET
	}

	statusModal(event) {
		if (event === 'modificarUsuario') {
			this.buscarUsers();
		}
	}

	getParams() {
		let formUserStr = this.formModelUser.toStrings();
		let username = formUserStr['username'];
		let name = formUserStr['name'];
		let lastName = formUserStr['lastName'];
		let email = formUserStr['email'];
		let count = this.paginador['count'];
		let page = this.paginador['page'];
		let entity = '';
		if (formUserStr['entity'] && formUserStr['entity'] !== 'all') {
			entity = formUserStr['entity'];
		}
		return `?username=${username}&name=${name}&lastName=${lastName}&email=${email}&entity=${entity}&count=${count}&page=${page}`;
	}

	exportData(event) {
		this.loading = true;
		let params = this.getParams();
		this.http
			.ejecutarServicioGet(
				`${environment.endpoints.adminUserExport}${event}${params}`,
				null
			)
			.subscribe(
				(res: Response) => {
					switch (res.status) {
						case 200:
						case 201:
						case 202:
							this.uiService.downloadFileBase64(
								res['_body'],
								event,
								'usuarios'
							);
							this.loading = false;
							this.cdr.markForCheck();
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							break;
					}
				},
				(error: Response) => {
					this.showError(error);
				}
			); // END GET
	}

	eventEstadoClick(item) {
		this.loading = true;
		let state = 0;
		if (item.value) {
			state = 1;
		}
		this.http
			.ejecutarServicioPut(
				`${environment.endpoints.adminUserState}${item.id}/state/${state}`,
				null
			)
			.subscribe(
				(res: Response) => {
					switch (res.status) {
						case 200:
						case 201:
						case 202:
							this.loading = false;
							this.cdr.markForCheck();
							this.buscarUsers();
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							this.buscarUsers();
							break;
					}
				},
				(error: Response) => {
					this.showError(error);
					this.buscarUsers();
				}
			); // END PUT
	}

	resetPassword(item) {
		console.log(item);
		this.loading = true;
		this.http
			.ejecutarServicioPost(environment.endpoints.forgot + item, null)
			.subscribe(
				(response: Response) => {
					switch (response.status) {
						case 200:
							this.forgotSuccess(item);
							break;
						default:
							this.uiService.showMessage(
								response.json()['message']
							);
							break;
					}
					this.loading = false;
					this.cdr.markForCheck();
				},
				(error: Response) => {
					this.loading = false;
					this.uiService.showMessage(error.json()['message']);
					this.cdr.markForCheck();
				}
			); // END POST
	}

	forgotSuccess(email) {
		this.showForgotModal = 1;
		this.configForgotModal = {
			status: 1,
			icono: 'smart-icon-check',
			titulo: 'Revisa tu bandeja de entrada',
			textos: [
				`Hemos enviado un correo a <b>${email}</b> <br> Allí encontrarás las instruciones para restablecer tu contraseña`
			],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'ok'
				}
			]
		};
		this.cdr.markForCheck();
	}
}
