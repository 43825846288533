import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { Response } from '@angular/http';
import { FormBuilder, Validators, FormGroup ,FormControl} from '@angular/forms';
import { UIService } from '../../../../trans/_services/UI.service';
import { environment } from '../../../../../../environments/environment';
import { HttpService } from '../../../../shared/services/http.service';

@Component({
  selector: 'kt-form-correos',
  templateUrl: './form-correos.component.html',
  styleUrls: ['./form-correos.component.scss']
})
export class FormCorreosComponent implements OnInit {
  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  // DATOS pueden ser en un dto
  formModelCorreos: {} = {
    email: '',
    propietario: '',
  };

  eventoForm: FormGroup;
  submitted = false;

  conditionalLoader: boolean;

  @Input() idCorreo: string;

  @Output() eventGuardar = new EventEmitter();
  @Output() actionModificarCorreo = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef, 
    private http: HttpService, 
    private uiService: UIService, 
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    console.log('==== ngOnInit form-correos.component ====');
    console.log(this.idCorreo);
    // Si trae algo idCorreos, cargar los datos del webservice por ID
    /*
      this.eventoForm = this.formBuilder.group({
        47: ['', Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(100) 
        ])],
        48: ['', Validators.compose([
          Validators.required,
          Validators.maxLength(100) 
        ])]
      });
    */
 
    this.eventoForm = this.formBuilder.group({      
      47: ['', [Validators.required, Validators.email]],
      48: ['', [Validators.required]]
    });
  }

  get f() {
    return this.eventoForm.controls;
  }

  guardar() {
    const dataForm = this.eventoForm.value;
    if (!this.validateEmail(dataForm[47])) {
      this.uiService.showMessage('El formato de correo electrónico no es válido');
			return false;
		}
    this.submitted = true;

    if (this.eventoForm.invalid) {
      let self = this;
			Object.keys(this.eventoForm.controls).forEach(controlName =>
				this.eventoForm.controls[controlName].markAsTouched()
			);
			return;
    }

    // Servicio Guardar
    this.guardarConfgCorreos();
  }

  guardarConfgCorreos() {

    console.log('==== guardar 1 ====');
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-guardar',
      titulo: 'Los datos han sido guardados',
      textos: [
        'Los datos ingresados han sido guardados con éxito'
      ],
      botones: [
        {
          label: 'Ok',
          class: '',
          accion: 'guardarCorreo'
        }
      ]
    };
    this.cdr.markForCheck();

    var result = {
      '47': this.eventoForm.value[47] ? this.eventoForm.value[47] : '',
      '48': this.eventoForm.value[48] ? this.eventoForm.value[48] : '',
    }
    this.eventGuardar.emit(result);
  }

  changeStatusModal(event) {
    this.eventoForm.reset();
    this.contentToShowModal = -1;
    this.configModal = { status: -1 }
    this.cdr.markForCheck();

    switch (event) {
      case 'guardarCorreo':
        console.log('==== guardar 2 ====');
        // Limpiar, si es un dto se instancia de nuevo la variable
        this.formModelCorreos = {
          email: '',
          propietario: '',
        };
        // Refrescar Datos
        // this.eventGuardar.emit( 'guardarCorreo' );
        // this.actionModificarCorreo.emit( 'guardarCorreo' );
        // EMIT al PADRE
        break;

      default:
        // EMIT al PADRE
        // this.eventGuardar.emit( 'guardarCorreo' );
        // this.actionModificarCorreo.emit( 'guardarCorreo' );
        break;
    }
  }

  validateEmail(email) {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}
}
