import { Injectable } from '@angular/core';
import { HttpService } from '@app/views/shared/services/http.service';
import { repeat, mergeMap, delay, tap, catchError, map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class IndicatorsService {
	private subject = new BehaviorSubject<any>({});

	constructor(private http: HttpService) { }

	getIndicators(id) {
		return this.http.ejecutarServicioGet(environment.endpoints.adminLayer + id)
			.pipe(
				map((res) => {
					let data2 = res.json();
					return data2.indicators.map(i => {
						return {
							type: i.trend.toLowerCase(),
							name: i.name,
							value: i.value
						};
					});
				}),
				delay(1000),
				repeat(),
				catchError(err => {
					console.log(err);
					throw 'error en el origen: ' + err;
				})
			);
	}

	sendOption(message: any) {
		this.subject.next(message);
	}

	clearOptions() {
		this.subject.next({});
	}

	getOption(): Observable<any> {
		return this.subject.asObservable();
	}
}
