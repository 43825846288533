import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '@environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminCategoriaDispositivoDto } from '../../../../shared/dto/admin-categoria-dto-dispositivo';
import { HttpService } from '../../../../shared/services/http.service';
import { Response } from '@angular/http';
import { UIService } from '../../../_services/UI.service';

@Component({
  selector: 'kt-dispositivos',
  templateUrl: './dispositivos.component.html',
  styleUrls: ['./dispositivos.component.scss']
})
export class DispositivosComponent implements OnInit {

	formModelCategoria: AdminCategoriaDispositivoDto = new AdminCategoriaDispositivoDto;
	rutasMenu: object;
	configTable: object;
	rolAdmin: boolean = true;
	conditionalLoaderTable: boolean;

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1 };
	contentToShowTable: number = 1;

	// Cargadores Información
	conditionalLoader: boolean = true;
	contentToShowModal: number = -1;
	
	deviceIdDelete: any;
	contentToShowDelete: number = -1;
	configModalDelete: {} = { status: -1 };
	selected:any;
	dataResponse = [];
	listadoDevices: AdminCategoriaDispositivoDto[];


	// Paginador
	paginador: Object = {
	  count: 30,
	  page: 1
	};

	configModal: {} = { status: -1 };
	idContratista: string;
	idEstacion: string;

	requiredDevice = false;
	requiredMarca = false;
	requiredReferencia = false;

	constructor(
		private activatedRoute: ActivatedRoute,
		private http: HttpService,
		private uiService: UIService,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.activatedRoute.params.subscribe(
			e=>{ 
				this.idEstacion = e['idPuerta'];
				this.idContratista = e['idContratista'];
				this.rutasMenu = [
					{
						label: 'Asociar puertas',
						icon: 'smart-icon-search',
						ruta: `${environment.folders.routeAdmin}puertas/asociar-puertas/${this.idContratista}/${this.idEstacion}`
					},
					{
						label: 'Asociar dispositivos',
						icon: 'smart-icon-search',
						ruta: `${environment.folders.routeAdmin}puertas/dispositivos/${this.idContratista}/${this.idEstacion}`
					},
					{
						label: 'Estación',
						icon: 'smart-icon-usuario',
						ruta: `${environment.folders.routeAdmin}puertas/generar-estacion/${this.idContratista}/${this.idEstacion}`
					}
				];
				
			});
		this.listDispositivos()
	}

	saveDevice(){
		this.conditionalLoader = true;
		this.cdr.markForCheck();

		this.requiredDevice = (this.formModelCategoria.template == undefined ? true:false)
		this.requiredMarca = (this.formModelCategoria.marca == undefined ? true:false)
		this.requiredReferencia = (this.formModelCategoria.referencia == undefined ? true:false)
		if(this.requiredDevice == false && this.requiredMarca == false && this.requiredReferencia == false){
			let formCategoria = this.formModelCategoria.toJS();
			let ruta = environment.endpoints.crearDispositivo;
			formCategoria.contratista = this.idContratista
			this.cdr.markForCheck();
			//console.log("formCategoria",formCategoria)
			formCategoria.stationId = this.idEstacion
			this.http.ejecutarServicioPost( ruta, formCategoria ).subscribe( (res: Response) => {
				this.contentToShowModal = 1;
				this.cdr.markForCheck();
				this.conditionalLoader = false;
				this.listDispositivos();
				this.openModalEstacionCreado();
				if ( res.ok === true ) {
					
				} else {
				  // TODO :: MANEJAR EXCEPCION
				  this.uiService.showMessage( res.json()['message'] );
				}
			  }, (error: Response) => {
				this.uiService.showMessage( error );
			  });
		}
	}

	listDispositivos(){
		this.conditionalLoaderTable = true;
		this.http.ejecutarServicioGet(`${environment.endpoints.listDispositivos}${this.idEstacion}`, null).subscribe( (res:any) => {
			
			this.conditionalLoader = false;
			this.conditionalLoaderTable = false;
			let data = res.json()
			this.listadoDevices = res.json()
			//console.log("data ENDPOINT", data)
			this.procesarJson(data)
            this.cdr.markForCheck();
        } ); 
	}

	procesarJson(data){
		let dataArray = [];
		console.log("GROUPID",data)
		for (let index = 0; index < data.length; index++) {
			let idGroup = data[index].metadata.groupId.toString();
			if (idGroup == this.idEstacion) {
				dataArray.push([
					data[index].id.toString(),
					data[index].metadata.marca.toString(),
					data[index].metadata.referencia.toString(),
					data[index].metadata.template.toString(),
					''
				])
				this.cdr.markForCheck();
				this.armarTabla(dataArray);

			}

		}
	}

	armarTabla( data ) {
		this.cdr.markForCheck();
		//console.log("puertas data",data);
		this.contentToShow = 1;
		if ( this.rolAdmin ) {
		  this.configTabla = {
			status: 1,
			header: ['ID Dispositivo','Marca','Referencia',,'Acciones'],
			typeColumn: [,,,,{ tipo: 'acciones' , acciones: ['eliminar'] }],
			// showSearch: true,
			searchColumn: [0],
			// searchTitle: 'Buscar Contratista',
			hideColumns: [3],
			showPag: false,
			configColumns: {
			  id: 0
			},
			data: data,
			paginador: this.paginador
		  };
		} else {
		  this.configTabla = {
			status: 1,
			header: ['ID Dispositivo',,,,'Acciones'],
			typeColumn: [,,,,{ tipo: 'acciones' , acciones: ['eliminar'] }],
			// showSearch: true,
			searchColumn: [0],
			// searchTitle: 'Buscar Contratista',
			hideColumns: [1,2,3],
			showPag: false,
			leftsColumns: [1],
			configColumns: {
			  id: 0,
			  columnClick: 1,
			},
			data: data,
			paginador: this.paginador,
		  }
		}
		this.cdr.markForCheck();
	}

	openModalEstacionCreado(){
		this.contentToShow = 1;
		this.conditionalLoader = true;
		let nombreProfile:string = '';
		let formCategoria = this.formModelCategoria.toJS();
		if( formCategoria && formCategoria.id ){
		  nombreProfile = `<b>${formCategoria.name}</b>`;
		}
	
		this.configModal = {
		  status: 1,
		  icono: 'smart-icon-categorias',
		  titulo: 'Dispositivo',
		  textos: [
					`Se guardó exitosamente el Dispositivo ${nombreProfile}`
				  ],
		  botones: [
				  {
					label: 'Aceptar',
					class: '',
					accion: 'aceptar'
				  }
		  ]
		}
		this.cdr.markForCheck();
		setTimeout(() => {
		  this.contentToShow = 1;
		  this.configModal = { status: -1 }
		}, 300);
	}

	eventEliminarClick(event){
		// Eliminar
		console.log( 'Eliminar ', event['values'] );
		// let selected = this.dataResponse.find(element =>{
		// 	// return element['id'] == event['values'];
		// 	console.log(element)
		// });
		//console.log( selected );
		this.deviceIdDelete = event;
		this.contentToShowDelete = 1;
		this.configModalDelete = {
		  status: 1,
		  icono: 'smart-icon-eliminar',
		  titulo: 'Eliminar dispositivo',
		  textos: [
				'¿Esta seguro que quiere eliminar este dispositivo?'
		  ],
		  botones: [
				  {
					label: 'Cancelar',
					class: 'sths-btn-cancel',
					accion: 'cancelar'
				  },
				  {
					label: 'Eliminar',
					class: '',
					accion: 'eliminar'
				  }
		  ]
		};
		this.cdr.markForCheck();
		setTimeout(() => {
		  this.contentToShowDelete = -1;
		  this.configModalDelete = { status: -1 }
		}, 300);
	}

	changeStatusModalDelete( event ){
		if ( event === 'eliminar' ){
			
			let formModelCategoriaDelete = new AdminCategoriaDispositivoDto;
			formModelCategoriaDelete.deviceId = this.deviceIdDelete
			formModelCategoriaDelete.stationId = this.idEstacion
			formModelCategoriaDelete.contratista = this.idContratista
			formModelCategoriaDelete.template = "."
			formModelCategoriaDelete.marca = "."
			formModelCategoriaDelete.referencia = "."
			let formCategoria = formModelCategoriaDelete.toJS();
			console.log("parametros", formCategoria)

		  
			this.conditionalLoader = true;
			this.cdr.markForCheck();
			this.http.ejecutarServicioDelete( environment.endpoints.eliminarDispositivo, formCategoria ).subscribe( (res: Response) => {
				if ( res.ok === true ) {
				// this.buscarRegistries();
				// Realizar algo con el ID
				let resPost = res.json();
		
				} else {
				// TODO :: MANEJAR EXCEPCION
				}
				
				this.listDispositivos();
				this.conditionalLoader = false;
				this.cdr.markForCheck();
			});
		
			//this.registryIdDelete = '';
		}
	}

}
