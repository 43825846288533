import { ActivatedRoute } from '@angular/router';
import { Response } from '@angular/http';
import { UIService } from '../../../_services/UI.service';
import { environment } from '../../../../../../environments/environment';
import { HttpService } from '../../../../shared/services/http.service';
import { NgModule, Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'kt-form-editar-dispositivos',
  templateUrl: './form-editar-dispositivos.component.html',
  styleUrls: [
              './form-editar-dispositivos.component.scss',
              './../dispositivos-grupos/dispositivos-formulario/dispositivos-formulario.component.scss'
            ],
  host: {'class': 'sths-tb-element'}
})
export class FormEditarDispositivosComponent implements OnInit {

 private excludes = ['groupId' , 'template' , 'dispositivo', 'marca' , 'referencia', 'blocked'];

 @Output() close: EventEmitter<any> = new EventEmitter();
 @Input() idDispositivo: string;

 // Select2
  options: {};
  selectFitroDispositivos: {}[];

 // Cargador
 loader: boolean;
 idRegistry: string;

 extraMetaDatas: {}[];
 metaDatas: {};
 idNumber: number = 1;

  constructor(private _route: ActivatedRoute,
              private http: HttpService,
              private cdr: ChangeDetectorRef,
              private uiService: UIService
  ) {
    this.idRegistry = this._route.snapshot.paramMap.get('registryId');

    // Select2
    this.options = {
			language: 'es',
			closeOnSelect: true,
     	dropdownCssClass: 'select2-dropdown-forms'
    };

    this.extraMetaDatas = [{ key: '' , value: ''}];
  }

  @Input () operadorStyle = false;

  ngOnInit() {
    let ruta = `${environment.endpoints.adminRegistryDevicesGetMetadata}/${this.idRegistry}/${this.idDispositivo}`;
    this.http.ejecutarServicioGet( ruta , null ).subscribe( (res: Response) => {
      if ( res.status === 200 ) {
        this.extraMetaDatas = [];
        this.metaDatas = res.json();
        let metaDataList = Object.keys(this.metaDatas);
        for ( let i = 0; i < metaDataList.length; i++) {
          if ( this.excludes.includes(metaDataList[i]) ) {
            metaDataList.splice(i, 1);
            i--;
          }
        }

        for ( let i = 0; i < metaDataList.length; i++) {
          this.idNumber++;
          this.extraMetaDatas.push({ key: metaDataList[i] , value: this.metaDatas[metaDataList[i]] });
          this.metaDatas[metaDataList[i]] = '';
        }

        if ( this.extraMetaDatas.length === 0 ) {
          this.extraMetaDatas = [{ key: '' , value: ''}];
        }

        this.cdr.markForCheck();
      } else {
      this.uiService.showMessage('Error actualizando Dispositivo');
      this.closeEmit();
      }
    } , (e) => {
      this.uiService.showMessage('Error actualizando Dispositivo');
      this.closeEmit();
    });


    this.http.ejecutarServicioGet( environment.endpoints.adminRegistryDevicesComboDisp , null ).subscribe( (res: Response) => {
      const dispositivos: {}[] = res.json();
      let selectFitro2 = [];
      this.selectFitroDispositivos = [];
      for ( let disp of dispositivos ) {
        selectFitro2.push( { id: disp['id'] , text: disp['name'] } );
      }
      this.selectFitroDispositivos = selectFitro2;
    });

  }

  eliminarMetadata(item) {
    const index: number = this.extraMetaDatas.indexOf(item);
    if (index !== -1) {
        this.extraMetaDatas.splice(index, 1);
    }
  }

  agregarDato() {
    this.idNumber++;
    this.extraMetaDatas.push({
      key: '',
      value: ''
    });
  }

  capitalize( word: string) {
    return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_' , ' ');
  }

  validateForm( event ) {
    setTimeout( () => {
      for ( let metadata in this.metaDatas ) {
        //removed regex: .replace(/[^a-zA-Z0-9_.]/g, ' ').replace(' ' , '')
        this.metaDatas[metadata] = this.metaDatas[metadata].trim();
      }
      for ( let metadata in this.extraMetaDatas ) {
        this.extraMetaDatas[metadata]['key'] =  this.extraMetaDatas[metadata]['key'].replace(/[^a-zA-Z0-9_-]/g, ' ').replace(' ', '').trim();
        //removed regex: .replace(/[^a-zA-Z0-9_.]/g, ' ').replace(' ' , '')
        this.extraMetaDatas[metadata]['value'] =  this.extraMetaDatas[metadata]['value'].trim();
      }
      this.cdr.markForCheck();
    } , 100);
  }

  save() {


    this.metaDatas['registry'] = this.idRegistry;
    this.metaDatas['device'] = this.idDispositivo;
    for (let index = 0; index < this.extraMetaDatas.length; index++) {
      this.metaDatas[this.extraMetaDatas[index]['key']] = this.extraMetaDatas[index]['value'];
    }
    this.loader = true;
    this.http.ejecutarServicioPost( environment.endpoints.adminRegistryDevicesAddMetadata, this.metaDatas ).subscribe( (res: Response) => {
      this.loader = false;
      if ( res.status === 200 ) {
        this.uiService.showMessage('Dispositivo actualizado');
      } else  if ( res.status === 500 ) {
        let message = JSON.parse(res['_body']);
        this.uiService.showMessage(message['message']);
      } else {
        this.uiService.showMessage('Error actualizando Dispositivo');
      }
      this.cdr.markForCheck();
      this.closeEmit();
    } , (e) => {
      this.loader = false;
      this.uiService.showMessage('Error actualizando Dispositivo');
      this.cdr.markForCheck();
      this.closeEmit();
    });
  }

  closeEmit() {
    this.close.emit('close');
  }

}
