import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {Response} from '@angular/http';
import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { Select2OptionData, Options } from 'select2';

import {UIService} from '../../../../../trans/_services/UI.service';
import {environment} from '../../../../../../../environments/environment';
import {HttpService} from '../../../../../shared/services/http.service';

@Component({
  selector: 'kt-form-analisis',
  templateUrl: './form-analisis.component.html',
  styleUrls: ['./form-analisis.component.scss']
})
export class FormAnalisisComponent implements OnInit {
	// Paginador
	paginador: any = {
		count: 10,
		page: 1
	};

	// Tabla
	contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };
  
  dataTable: Array<any>;

  // Select2
  public selectSensor: Array<Select2OptionData>;
  public options: Options;
  
  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  // DATOS pueden ser en un dto
  formModelAnalisis: {} = {
    sensor: '',
    umbral: '',
    tiempo: '',
  };

  analisisForm: FormGroup;
  submitted = false;

  conditionalLoader: boolean;

  @Input() idAnalisis: string;

  @Output() eventGuardar  = new EventEmitter();
  @Output() actionModificarAnalisis  = new EventEmitter();

  constructor(
    private cdr: ChangeDetectorRef,
    private http: HttpService,
    private uiService: UIService,
		private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.selectSensor = [];
    // Si trae algo idAnalisis, cargar los datos del webservice por ID

    this.showData();
		this.analisisForm = this.formBuilder.group({
			17: ['', Validators.required],
			18: ['', [Validators.maxLength(100)]],
			19: ['', [Validators.maxLength(100)]]
		});
  
    // Select2
    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: "select2-dropdown-forms"
    };
  }

  get f() {
    return this.analisisForm.controls;
  }

  /**
   * 
   * @param data 
   */
	procesarJson(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				'answerGroup' in data[index] ? data[index]['answerGroup'].toString(): '',
				'17' in data[index] ? data[index]['17'].toString(): '',
				'18' in data[index] ? data[index]['18'].toString(): '',
				'19' in data[index] ? data[index]['19'].toString(): '',
				''
			]);

			if (index === data.length - 1) {
				this.armarTabla(dataArray);
			}
		}

		if (!data.length) {
			this.armarTabla(dataArray);
		}
	}

	armarTabla(data) {
//    console.log(data);
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: ['ID', 'Red social', 'Palabra', 'Hashtag', 'Acciones'],
			typeColumn: [
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'eliminar'] }
			],
			showSearch: false,
			searchColumn: [1],
			searchTitle: 'Buscar Categoría',
			hideColumns: [0],
			showPag: false,
			leftsColumns: [1],
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data,
			paginador: this.paginador
		};
		this.cdr.markForCheck();
	}

  /**
   * Consulta endpoint para cargar tabla
   */
  showData() {
    console.log("entro al show");
    this.http
      .ejecutarServicioGet(environment.endpoints.paramPredictiveMaintenance, null)
      .subscribe((resEstado: Response) => {
        switch (resEstado.status) {
          case 200:
          case 201:
          case 204:
            this.dataTable = resEstado.json().answerGroups;
            this.procesarJson(this.dataTable);

            let questions = resEstado.json().questions;
            let select = questions.find(i => Number(i.id) === 17);
            if (select) {
              this.selectSensor = select.possibleAnswers.map(i => {
                return { id: i.answerValue, text: i.answer };
              });
            }
            this.cdr.markForCheck();
            break;
          default:
            this.showError(resEstado);
            break;
        }
      }, (error: Response) => {
        this.showError(error);
      }
    );
  }

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}

  guardarParametros(){
		this.submitted = true;
		// Servicio Guardar
		this.contentToShowModal = 1;

		if(!this.analisisForm.value[18] && !this.analisisForm.value[19] ){
			let field1 = this.analisisForm.get('18');
			let field2 = this.analisisForm.get('19');
			field1.setValidators([Validators.required]);
			field2.setValidators([Validators.required]);
			field1.updateValueAndValidity();
			field2.updateValueAndValidity();
			return;
		}
		if (this.analisisForm.invalid) {
			return;
		}
		this.configModal = {
			status: 1,
			icono: 'smart-icon-guardar',
			titulo: 'Los datos han sido guardados',
			textos: ['Los datos ingresados han sido guardados con éxito'],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'guardarParametros'
				}
			]
		};
		this.cdr.markForCheck();
		var result = {
			'17': this.analisisForm.value[17] ? this.analisisForm.value[17] : '',
			'18': this.analisisForm.value[18] ? this.analisisForm.value[18] : '',
			'19': this.analisisForm.value[19] ? this.analisisForm.value[19] : '',
		}
		this.eventGuardar.emit(result);

		setTimeout(() => {
			this.contentToShowModal = -1;
			this.configModal = { status: -1 };
		}, 300);


    // Servicio Guardar
    // this.guardarConfgConductor();
  }

  guardarConfgConductor( ){
    let titulo = '';
    let texto = '';
    let accion = '';
    this.contentToShowModal = 1;
    if (this.formModelAnalisis['sensor']=="" ||
        this.formModelAnalisis['umbral']=="" ||
        this.formModelAnalisis['tiempo']=="" ||
        parseInt(this.formModelAnalisis['umbral'])<=0 ||
        parseInt(this.formModelAnalisis['tiempo'])<=0) {
          titulo = 'Error al guardar';
          texto = 'Los campos no deben estar vacios';
          accion = 'cancelarAnalisis';
    } else {
        titulo = 'Los datos han sido guardados';
        texto = 'Los datos ingresados han sido guardados con éxito';
        accion = 'guardarAnalisis';
    }

    this.configModal = {
      status: 1,
      icono: 'smart-icon-guardar',
      titulo: titulo,
      textos: [texto],
      botones: [
              {
                label: 'Ok',
                class: '',
                accion: accion
              }
      ]
    };

    this.cdr.markForCheck();
  }
  
  changeStatusModal( event ){
		this.analisisForm.reset();
		this.submitted = false;

    /*this.contentToShowModal = -1;
    this.configModal = { status: -1 }
    this.cdr.markForCheck();

    switch (event) {
      case 'guardarAnalisis':
        // Se valida que ninguno de los campos esté vacio
        if (this.formModelAnalisis['sensor']=="" ||
            this.formModelAnalisis['umbral']=="" ||
            this.formModelAnalisis['tiempo']=="") {
              console.log("Error al guardar")
              break;
        }

        let datos = {
          "answer": {
            "17": this.formModelAnalisis['sensor'],
            "18": this.formModelAnalisis['umbral'],
            "19": this.formModelAnalisis['tiempo']
          }
        };

        this.guardaAnalisis(datos);
      
        // Limpiar, si es un dto se instancia de nuevo la variable
        this.formModelAnalisis = {
          sensor: '-Sel',
          umbral: '',
          tiempo: '',
        };
        // Refrescar Datos-
        this.eventGuardar.emit( 'guardarAnalisis' );
        // EMIT al PADRE
        break;

      default:
        // EMIT al PADRE
        this.eventGuardar.emit( 'guardarAnalisis' );
        break;
    }*/
  }

  /**
   * Envía al endpoint los datos a guardar del mantenimiento predictivo
   * 
   * @param datos 
   */
  guardaAnalisis(data) {
    console.log("entro aca");
    return false;
    this.http
        .ejecutarServicioPost(
            environment.endpoints.paramPredictiveMaintenanceSave, 
            data
        )
        .subscribe((res: Response) => {
          switch (res.status) {
            case 200:
                console.log("Se guardó");
                this.cdr.markForCheck();
                setTimeout(() => {
                  this.contentToShowModal = -1;
                  this.configModal = { status: -1 }
                }, 300);
                break;
            default:
                console.log(res);
                this.showError(res);
            break;
          }
        }, (error: Response) => {
          this.showError(error);
        }
      );
  }

  /**
   * Valida que los valores de los campos numéricos
   * 
   * @param event 
   * @param campo 
   */
  validateForm( event, campo ) {
    setTimeout( () => {
      if( campo==18 ){
        this.analisisForm.value[18] = event.toString().replace(/[^0-9]/g, '').trim();
      } else {
        this.analisisForm.value[19] = event.toString().replace(/[^0-9]/g, '').trim();
      }
      this.cdr.markForCheck();
    }, 100);
  }

}
