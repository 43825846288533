import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from '@app/views/shared/services/http.service';
import { environment } from '@environments/environment';
import { UIService } from '@services/UI.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'kt-conductor',
	templateUrl: './conductor.component.html',
	styleUrls: ['./conductor.component.scss']
})
export class ConductorComponent implements OnInit {
	selectedTab = 0;
	private showTable = false;
	rutasMenu: object;
	contentToShow: number = -1;
	configModal: {} = { status: -1 };
	loading: boolean = false;
	selectedId = null;
	selectedType = null;
	model: any = {};
	dataRequired: Array<any> = [];

	constructor(
		private http: HttpService,
		private uiService: UIService,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private router: Router
	) {
		this.loadDataRequiredToCreate();
	}

	ngOnInit() {
		this.reload(true);
		this.rutasMenu = [
			{
				label: 'Buscar Conductor',
				icon: 'smart-icon-asociar-dispositivos',
				ruta: `${environment.folders.routeAdmin}conductor/buscar`
			},
			{
				label: 'Crear Conductor',
				icon: 'smart-icon-usuario',
				ruta: `${environment.folders.routeAdmin}conductor`
			}
		];
	}
	changeTab() {
		console.log("model:  ", this.model)
		if (this.selectedTab == 0) {
			if((this.model.documentType === null || this.model.documentType === undefined || this.model.documentType === "") ||
				(this.model.document === null || this.model.document ===undefined || this.model.document === "") ||
				(this.model.birthDate === null || this.model.birthDate ===undefined || this.model.birthDate === "") ||
				(this.model.name === null || this.model.name ===undefined || this.model.name === "") ||
				(this.model.lastName1 === null || this.model.lastName1 ===undefined || this.model.lastName1 === "") ||
				(this.model.admissionDate === null || this.model.admissionDate ===undefined || this.model.admissionDate === "")
			) {
				this.uiService.showMessage("Llene los campos marcados con asterisco (*)");
				return;
			} else if (this.model.hasOwnProperty('email')) {
				if(this.model.email !== null && this.model.email !== undefined && this.model.email !== "") {
					let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					let emailValidate = re.test(this.model.email);
					if (!emailValidate) {
						this.uiService.showMessage("El formato de correo electrónico no es válido.");
						return;
					}
				}
			}
		} else if (this.selectedTab == 1) {
			if((this.model.dealership === null || this.model.dealership === undefined || this.model.dealership === "") ||
				(this.model.linkingDate === null || this.model.linkingDate === undefined || this.model.linkingDate === "") ||
				(this.model.vehicleType === null || this.model.vehicleType === undefined || this.model.vehicleType === "") 
			) {
				this.uiService.showMessage("Llene los campos marcados con asterisco (*)");
				return;
			} else if ((this.model.picture === null || this.model.picture === undefined || this.model.picture === "")) {
				this.uiService.showMessage("Seleccione una imagen.");
				return;
			}
		} else if (this.selectedTab == 2) {
			if(this.selectedId == null || this.selectedId == undefined || this.selectedId == "") {
				if ((this.model.licenseNumber === null || this.model.licenseNumber === undefined || this.model.licenseNumber === "") ||
					(this.model.licenseExpeditionDate === null || this.model.licenseExpeditionDate === undefined || this.model.licenseExpeditionDate === "") ||
					(this.model.licenseExpirationDate === null || this.model.licenseExpirationDate === undefined || this.model.licenseExpirationDate === "") ||
					(this.model.licenseCategory === null || this.model.licenseCategory === undefined || this.model.licenseCategory === "") ||
					(this.model.licenseCategoryExpirationDate === null || this.model.licenseCategoryExpirationDate === undefined || this.model.licenseCategoryExpirationDate === "")
				) {
					this.uiService.showMessage("Llene los campos marcados con asterisco (*)");
					return;
				}
			}
		} else if (this.selectedTab == 3) {
			if((this.model.carnetNumber === null || this.model.carnetNumber === undefined || this.model.carnetNumber === "") ||
				(this.model.carnetExpeditionDate === null || this.model.carnetExpeditionDate === undefined || this.model.carnetExpeditionDate === "") ||
				(this.model.carnetExpirationDate === null || this.model.carnetExpirationDate === undefined || this.model.carnetExpirationDate === "") 
			) {
				this.uiService.showMessage("Llene los campos marcados con asterisco (*)");
				return;
			}
		}  else if (this.selectedTab == 4) {
			if(this.selectedId == null || this.selectedId == undefined || this.selectedId == "") {
				if((this.model.trainingCreationDate === null || this.model.trainingCreationDate === undefined || this.model.trainingCreationDate === "") ||
					(this.model.trainingExpirationDate === null || this.model.trainingExpirationDate === undefined || this.model.trainingExpirationDate === "") ||
					(this.model.trainingName === null || this.model.trainingName === undefined || this.model.trainingName === "") ||
					(this.model.trainingInstitution === null || this.model.trainingInstitution === undefined || this.model.trainingInstitution === "") 
				) {
					this.uiService.showMessage("Llene los campos marcados con asterisco (*)");
					return;
				}
			}
		}
		this.selectedTab += 1;
		if (this.selectedTab >= 6) {
			this.selectedTab = 0;
		}
	}
	
	guardar() {
		if (!this.loading) {
			for(let i = 0; i < this.dataRequired.length; i++) {
				if(this.model.hasOwnProperty(this.dataRequired[i].key)) {
					if(this.model[this.dataRequired[i].key] === null || this.model[this.dataRequired[i].key] === undefined || this.model[this.dataRequired[i].key] === "") {
						this.uiService.showMessage("Llene el campo: "+ this.dataRequired[i].text);
						return;
					}
				} else {
					this.uiService.showMessage("Llene el campo: "+ this.dataRequired[i].text);
					return;
				}
			}
			if (this.model.hasOwnProperty('email')) {
				if(this.model.email !== null && this.model.email !== undefined && this.model.email !== "") {
					let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					let emailValidate = re.test(this.model.email);
					if (!emailValidate) {
						this.uiService.showMessage("El formato de correo electrónico en Datos básicos no es válido.");
						return;
					}
				}
			}
			if ((this.model.picture === null || this.model.picture === undefined || this.model.picture === "")) {
				this.uiService.showMessage("Seleccione una imagen en Ingreso conductor.");
				return;
			}
			if(!this.model.hasOwnProperty("lastName2")) {
				this.model.lastName2 = "";
			}
			this.loading = true;
			this.http
				.ejecutarServicioPost(environment.endpoints.adminDriver, this.model)
				.subscribe(
					res => {
						switch (res.status) {
							case 200:
							case 201:
							case 204:
								this.openModalCreado();
								this.loading = false;
								setTimeout(() => {
									this.router.navigateByUrl('/admin/dashboard/conductor/buscar');
								}, 3000);
								break;
							case 401:
								// nothing
								break;
							default:
								this.showError(res);
								break;
						}
						this.loading = false;
					},
					err => {
						this.showError(err);
						this.loading = false;
	
					}
				);
		}
	}

	reload(event) {
		this.selectedId = this.route.snapshot.paramMap.get('id');
		this.selectedType = this.route.snapshot.paramMap.get('type');
		if (this.selectedId) {
			this.showTable = true;
			this.loading = true;
			this.http
				.ejecutarServicioGet(`${environment.endpoints.adminDriver}document_type/${this.selectedType}/document/${this.selectedId}`)
				.subscribe(
					res => {
						switch (res.status) {
							case 200:
							case 201:
							case 204:
								this.model = res.json();
								this.loading = false;
								this.cdr.markForCheck();
								break;
							case 401:
								// nothing
								break;
							default:
								this.showError(res);
								break;
						}
					},
					err => {
						this.showError(err);
					}
				);
		}
	}

	actualizar() {
		this.loading = true;
		let url = `${environment.endpoints.adminDriver}document_type/${this.model.documentType}/document/${this.model.document}`;
		this.http
			.ejecutarServicioPut(url, this.model)
			.subscribe(
				res => {
					switch (res.status) {
						case 200:
						case 201:
						case 204:
							this.openModalCreado();
							this.loading = false;
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							break;
					}
				},
				err => {
					this.showError(err);
				}
			);
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	openModalCreado() {
		this.contentToShow = 1;
		this.configModal = {
			status: 1,
			icono: 'smart-icon-usuario',
			titulo: 'Conductor',
			textos: [`Se guardó exitosamente el conductor`],
			botones: [
				{
					label: 'Aceptar',
					class: '',
					// accion: !this.modificarDatos? 'creacionUsuario': 'modificarUsuario'
					action: 'creacionUsuario'
				}
			]
		};
		this.cdr.markForCheck();
	}

	changeStatusModal(event) {
		// if ( this.modificarDatos ) {
		//   // Modificar
		//   this.actionModificarUser.emit('cerrar');
		// } else {
		//   // Crear
		//   this.formModelUser = new AdminUserDTO();
		// }
	}

	/**
	 * Carga las variables requeridas para la creación de un conductor.
	 */
	loadDataRequiredToCreate() {
		this.dataRequired = [
			{ key: 'documentType' , text: 'tipo de documento en Datos básicos.'},
			{ key: 'document' , text: 'número de documento en Datos básicos.'},
			{ key: 'birthDate' , text: 'fecha de nacimiento en Datos básicos.'},
			{ key: 'name' , text: 'nombres en Datos básicos.'},
			{ key: 'lastName1' , text: 'primer apellido en Datos básicos.'},
			{ key: 'admissionDate' , text: 'fecha de creación en Datos básicos.'},
			{ key: 'dealership' , text: 'consecionario en Ingreso conductor'},
			{ key: 'linkingDate' , text: 'fecha de vinculación en Ingreso conductor'},
			{ key: 'vehicleType' , text: 'tipo de vehículo en Ingreso conductor'},
			{ key: 'licenseNumber' , text: 'número licencia de conductor en Registro de licencias'},
			{ key: 'licenseExpeditionDate' , text: 'fecha de expedición en Registro de licencias'},
			{ key: 'licenseExpirationDate' , text: 'fecha de expiración en Registro de licencias'},
			{ key: 'licenseCategory' , text: 'categoría en Registro de licencias'},
			{ key: 'licenseCategoryExpirationDate' , text: 'fecha de expiración caterogira en Registro de licencias'},
			{ key: 'carnetNumber' , text: 'número de carnet en Registro de carnet'},
			{ key: 'carnetExpeditionDate' , text: 'fecha de expedición en Registro de carnet'},
			{ key: 'carnetExpirationDate' , text: 'fecha de creación en Registro de carnet'},
			{ key: 'trainingCreationDate' , text: 'fecha de creación en Registro de capacitación'},
			{ key: 'trainingExpirationDate' , text: 'fecha de vencimiento en Registro de capacitación'},
			{ key: 'trainingName' , text: 'título de capacitación en Registro de capacitación'},
			{ key: 'trainingInstitution' , text: 'institución en Registro de capacitación'},
			{ key: 'noteDate' , text: 'fecha de nota en Registro de notas'},
			{ key: 'noteText' , text: 'nota en Registro de notas'}
		]
	}
}
