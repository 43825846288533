import {
	Component,
	OnInit,
	ChangeDetectorRef,
	Input,
	Output,
	EventEmitter
} from '@angular/core';

import { Response } from '@angular/http';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { PermissionDTO } from '../../../../shared/dto/admin-permission-dto';
import { AdminRolDTO } from '../../../../shared/dto/admin-rol-dto';
import { UIService } from '../../../_services/UI.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
	selector: 'kt-rol-crear',
	templateUrl: './rol-crear.component.html',
	styleUrls: ['./rol-crear.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class RolCrearComponent implements OnInit {
	rutasMenu: object;
	permisos: {}[];
	configDatos: {};
	listadoPermissions: PermissionDTO[];
	formModelRol: AdminRolDTO = new AdminRolDTO();
	@Input() modificarDatos: AdminRolDTO;
	@Output() actionModificarRol = new EventEmitter();
	maxCharacters: number = 0;
	maxCharactersNombre: number = 0;
	// Modales
	contentToShow: number = -1;
	configModal: {} = { status: -1 };
	loading: boolean = false;
  roleForm: FormGroup;

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {}

	ngOnInit() {
		this.roleForm = new FormGroup({
			name: new FormControl('', [Validators.required]),
			description: new FormControl('', [Validators.required]),
		});
		// Si trae parametros para EDITAR
		if (this.modificarDatos) {
			this.formModelRol = this.modificarDatos;
		}
		this.loading = true;
		this.http
			.ejecutarServicioGet(environment.endpoints.adminUserPermissionsList)
			.subscribe(
				(res: Response) => {
					switch (res.status) {
						case 200:
						case 201:
						case 202:
							this.listadoPermissions = res.json();
							if (this.modificarDatos) {
								let ruta = `${environment.endpoints.adminUserRole}${this.modificarDatos.id}/permissions`;
								this.http.ejecutarServicioGet(ruta).subscribe(
									(resPermisos: Response) => {
										switch (resPermisos.status) {
											case 200:
											case 201:
											case 204:
												let permisos = resPermisos.json();
												this.formModelRol.userPermissions = permisos
													.map(function(e) {
														return e.id;
													})
													.join(',');

												for (
													let index = 0;
													index <
													this.listadoPermissions
														.length;
													index++
												) {
													if (
														permisos
															.map(function(e) {
																return e.id;
															})
															.indexOf(
																this
																	.listadoPermissions[
																	index
																]['id']
															) !== -1
													) {
														this.listadoPermissions[
															index
														]['active'] = true;
													} else {
														this.listadoPermissions[
															index
														]['active'] = false;
													}
													if (
														index ===
														this.listadoPermissions
															.length -
															1
													) {
														this.cdr.markForCheck();
													}
												}
												this.loading = false;
												break;
											case 401:
												// nothing
												break;
											default:
												this.showError(resPermisos);
												break;
										}
									},
									(error: Response) => {
										this.showError(error);
									}
								); // END INNER GET
							} else {
								this.loading = false;
								this.resetPermisos(
									this.listadoPermissions,
									'iniciar'
								);
							}
							this.cdr.markForCheck();
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							break;
					}
				},
				(error: Response) => {
					this.showError(error);
				}
			); // END GET

		this.rutasMenu = [
			{
				label: 'Buscar Rol',
				icon: 'smart-icon-search',
				ruta: `${environment.folders.routeAdmin}rol/buscar`
			},
			{
				label: 'Crear Rol',
				icon: 'smart-icon-usuario',
				ruta: `${environment.folders.routeAdmin}rol/crear`
			}
		];
		// Select List
		this.configDatos = {
			icono: '',
			titulo: 'Detalles del Rol',
			parametro1: 'Permisos Disponibles',
			parametro2: 'Permisos Asignados al Rol',
			editar: this.formModelRol.id ? true : false
		};
	}

	get f() {
    return this.roleForm.controls;
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else if (typeof response === 'string') {
			this.uiService.showMessage(response);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	resetPermisos(perm, status) {
		if (status === 'iniciar') {
			for (let index = 0; index < perm.length; index++) {
				perm[index]['checked'] = false;
				perm[index]['active'] = false;
				if (index === perm[index].length - 1) {
					this.listadoPermissions = perm;
					this.cdr.markForCheck();
				}
			}
		}
	}

	changeListRol(event) {
		let permissionsArray = [];
		this.formModelRol.userPermissions = '';
		for (let index = 0; index < event.length; index++) {
			this.formModelRol.userPermissions = `${event[index].id}, `;
			permissionsArray.push(event[index].id);
			if (index === event.length - 1) {
				this.formModelRol.userPermissions = permissionsArray.join(',');
			}
		}
	}

	openModalRolCreado() {
		this.contentToShow = 1;
		let nombreRol: string = '';
		if (this.modificarDatos) {
			nombreRol = `<b>${this.formModelRol.name}</b>`;
		}

		this.configModal = {
			status: 1,
			icono: 'smart-icon-usuario',
			titulo: 'Rol',
			textos: [
				`Se guardó exitosamente la configuración del ROL ${nombreRol}`
			],
			botones: [
				{
					label: 'Aceptar',
					class: '',
					accion: !this.modificarDatos
						? 'creacionRol'
						: 'modificarRol'
				}
			]
		};
		this.cdr.markForCheck();
		setTimeout(() => {
			this.contentToShow = -1;
			this.configModal = { status: -1 };
		}, 300);
	}

	crearRol() {
		if (this.formModelRol.userPermissions) {
			this.loading = true;
			let formRol = this.formModelRol.toJS();
			delete formRol['id'];
			this.http
				.ejecutarServicioPost(
					environment.endpoints.adminUserRole,
					formRol
				)
				.subscribe(
					(res: Response) => {
						switch (res.status) {
							case 200:
							case 201:
							case 204:
								// Mostrar Modal
								this.openModalRolCreado();
								this.loading = false;
								break;
							case 401:
								// nothing
								break;
							default:
								this.showError(res);
								break;
						}
					},
					(error: Response) => {
						this.showError(error);
					}
				); // END POST
		} else {
			this.showError('Seleccione por lo menos un permiso');
		}
	}

	cerrarModal() {
		this.actionModificarRol.emit('cerrar');
	}

	modificarRol() {
		this.loading = true;
		let formRol = this.formModelRol.toJS();
		let ruta = `${environment.endpoints.adminUserRole}${formRol.id}`;
		this.http.ejecutarServicioPut(ruta, formRol).subscribe(
			(res: Response) => {
				switch (res.status) {
					case 200:
					case 201:
					case 204:
						// Mostrar Modal
						this.openModalRolCreado();
						this.loading = false;
						break;
					case 401:
						// nothing
						break;
					default:
						this.showError(res);
						break;
				}
			},
			(error: Response) => {
				this.showError(error);
			}
		); // END POST
	}

	changeStatusModal(event) {
		if (this.modificarDatos) {
			// Modificar
			this.actionModificarRol.emit('cerrar');
			this.maxCharacters = 0;
		} else {
			// Crear
			this.formModelRol = new AdminRolDTO();
			this.resetPermisos(this.listadoPermissions, 'iniciar');
			this.maxCharacters = 0;
		}
	}

	omit_special_char(event) {
		let k;
		k = event.charCode; //         k = event.keyCode;  (Both can be used)
		/*
		return (
			(k > 64 && k < 91) ||
			(k > 96 && k < 123) ||
			k === 8 ||
			k === 32 ||
			(k >= 48 && k <= 57) ||
			k === 241 ||
			k === 209
		);
		*/
	}
	cantCaracteres(event, max) {
		this.maxCharacters = max - event.length;
	}
}
