import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { HttpService } from '../../../../../shared/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '@angular/http';
import { UIService } from '../../../../_services/UI.service';

@Component({
  selector: 'kt-dispositivos-detalle',
  templateUrl: './dispositivos-detalle.component.html',
  styleUrls: [
                './dispositivos-detalle.component.scss',
                './../../categorias.component.scss'
              ],
  host: {'class': 'sths-tb-element'}
})
export class DispositivosDetalleComponent implements OnInit {
  configTable:Object;
  configTable2:Object;
  configTableDev: Object;

  idRegistry: string;
  idRegistryGroup: string;

  TotalTable: {};
  myvariable : any ;

  variablesMetadata: {};
  extraMetaDatas: {}[];
  metaDatas: {};
  
  private excludes = ['template'];

  // Paginador
  paginador: Object = {
    count: 30,
    page: 1
  };
  
  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  contentToShowDev: number = -1;
  tableName: string;
  configTablaDev: {} = { status: -1, data: [] };

  idDevice: string;

  constructor(private _route: ActivatedRoute,
      private router: Router,
      private http: HttpService,
      private cdr: ChangeDetectorRef,
      private uiService: UIService
  ) {
    this.idRegistry = this._route.snapshot.paramMap.get('registryId');
    this.idRegistryGroup = this._route.snapshot.paramMap.get('registryGroup');
  }

  ngOnInit() {
    let ruta = `${environment.endpoints.adminRegistryGroupGetMetadata}/${this.idRegistry}/${this.idRegistryGroup}/front`;
    this.http.ejecutarServicioGet( ruta , null ).subscribe( (res: Response) => {
      this.variablesMetadata = res.json();
      
      this.cdr.markForCheck();
    });

    this.cargarDevicesList();
  }

  cargarDevicesList(){
    let ruta = `${environment.endpoints.adminRegistryDevicesList}/${this.idRegistry}/${this.idRegistryGroup}`;
    this.http.ejecutarServicioGet( ruta , null ).subscribe( (res: Response) => {
      if ( res.json().length > 0 ) {
        console.log( res.json() );
        this.procesarJson(res.json());
      }
      this.cdr.markForCheck();
    });
  }

  procesarJson( data ) {
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['id'].toString(),
        data[index]['id'].toString()
      ]);

      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }

    if( !data.length ){
      this.armarTabla(dataArray);
    }
  }

  armarTabla( data ) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'ID Dispositivo'],
      typeColumn: [,],
      leftsColumns: [1],
      searchColumn: [1],
      hideColumns: [0],
      showPag: false,
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }
  
  eventLinkClick( event ){
    this.idDevice = event;
    let ruta = `${environment.endpoints.adminRegistryDevicesVars}/${this.idRegistry}/${this.idDevice}`;
    this.http.ejecutarServicioGet( ruta , null ).subscribe( (res: Response) => {
      if ( res.json().length > 0 ) {
        console.log( res.json() );
        this.procesarJsonDevice(res.json());
      }else{
        console.log( res.json(),'jason2' );
        this.procesarJsonDevice(res.json());
        //this.uiService.showMessage('No hay datos');
      }
      this.cdr.markForCheck();
    }); 

    ruta = `${environment.endpoints.adminRegistryDevicesGetMetadata}/${this.idRegistry}/${this.idDevice}`;
    this.http
        .ejecutarServicioGet(ruta , null )
        .subscribe( (res: Response) => {
          if ( res.status === 200 ) {
            this.extraMetaDatas = [];
            this.metaDatas = res.json();
            let metaDataList = Object.keys(this.metaDatas);
            for ( let i = 0; i < metaDataList.length; i++) {
              if ( this.excludes.includes(metaDataList[i]) ) {
                metaDataList.splice(i, 1);
                i--;
              }
            }

            for ( let i = 0; i < metaDataList.length; i++) {
              this.extraMetaDatas.push({ key: metaDataList[i] , value: this.metaDatas[metaDataList[i]] });
              this.metaDatas[metaDataList[i]] = '';
            }

            this.cdr.markForCheck();
          } else {
            this.uiService.showMessage('Error actualizando Dispositivo');
          }
        } , (e) => {
          this.uiService.showMessage('Error actualizando Dispositivo');
        }
    );
  }

  procesarJsonDevice( data ) {
    
  if(data.length <= 0){
    let dataArray = []; 
    console.log('no entro')
    dataArray.push([
      '1',
      '2',
      '3',
      '4'
    ]);
    this.buildTable(1,dataArray);
    this.TotalTable = dataArray;
  }
   let dataArray = [];
   this.TotalTable = data;

   //console.log(this.TotalTable,'contador');

    for (let index in data) {

      let item = data[index];
      let vars = item["vars"];
      let name = item["name"];

      this["tableName"+index] = name;
      console.log("------- xx ------");

      dataArray = [];
      for (let i in vars ){
        let row = vars[i];
        dataArray.push([
          row["name"],
          row["name"],
          row["type"],
          row["mode"]
        ]);
      }

      if(dataArray.length > 0){
        console.log('entro');
        this.buildTable(index,dataArray);
      }
      
  }
      
      //console.log(dataArray,'dataArray');
      //console.log('this',index,data[index]['vars'],);
    //}
    /*
    if( !data.length ){
      this.armarTablaDevice(dataindex,dataArray);
    }
    */
  }

  buildTable( index, data ) {
    console.log(index,'data',data,
    'dta')

    this["contentToShowDev"+index] = 1;
    
    this["configTablaDev"+index] = {
      status: 1,
      header: ['ID', 'Name', 'Type', 'Mode' ],
      typeColumn: [,,,],
      leftsColumns: [1],
      searchColumn: [1],
      hideColumns: [0],
      showPag: false,
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();

    //console.log('contentoshow',this["contentToShowDev"+index]);
    //console.log(this["configTablaDev"+index],'configTablaDev');
  }

  armarTablaDevice( data ) {
    this.contentToShowDev = 1;
    this.configTablaDev = {
      status: 1,
      header: ['ID', 'Name', 'Type', 'Mode' ],
      typeColumn: [,,,],
      leftsColumns: [1],
      searchColumn: [1],
      hideColumns: [0],
      showPag: false,
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }
  
  volver( ){
    this.router.navigateByUrl( `${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}/${this.idRegistryGroup}` );
  }

  editar( ){
    this.router.navigateByUrl( `${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}/${this.idRegistryGroup}/asociar` );
  }

  hideText(  text ) {
    let l = text.length;
    let hideText = '';
    for( let i = 0 ; i < l; i++ ) { hideText += '*'; }
    return hideText;
  }
}
