export class AdminDriverAggressive {
    
    numberOfAccelerations: string;
    numberOfSuddenBreaking: string;
    numberOfSuddenTurning: string;
    speedings: string;
    maximumSpeed: boolean = false;


    constructor(data?: any) {
        if (data !== undefined) {
            this.numberOfAccelerations = data['numberOfAccelerations'] !== undefined ? data['numberOfAccelerations'] : '';
            this.numberOfSuddenBreaking = data['numberOfSuddenBreaking'] !== undefined ? data['numberOfSuddenBreaking'] : '';
            this.numberOfSuddenTurning = data['numberOfSuddenTurning'] !== undefined ? data['numberOfSuddenTurning'] : '';
            this.speedings = data['speedings'] !== undefined ? data['speedings'] : '';
            this.maximumSpeed = data['maximumSpeed'] !== undefined ? data['maximumSpeed'] : false;
        }
    }

    static fromJS(data: any): AdminDriverAggressive {
        return new AdminDriverAggressive(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['numberOfAccelerations'] = this.numberOfAccelerations !== undefined ? this.numberOfAccelerations : '';
        data['numberOfSuddenBreaking'] = this.numberOfSuddenBreaking !== undefined ? this.numberOfSuddenBreaking : '';
        data['numberOfSuddenTurning'] = this.numberOfSuddenTurning !== undefined ? this.numberOfSuddenTurning : '';
        data['speedings'] = this.speedings !== undefined ? this.speedings : '';
        data['maximumSpeed'] = this.maximumSpeed !== undefined ? this.maximumSpeed : false;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['numberOfAccelerations'] = this.numberOfAccelerations !== undefined ? this.numberOfAccelerations : '';
        data['numberOfSuddenBreaking'] = this.numberOfSuddenBreaking !== undefined ? this.numberOfSuddenBreaking : '';
        data['numberOfSuddenTurning'] = this.numberOfSuddenTurning !== undefined ? this.numberOfSuddenTurning : '';
        data['speedings'] = this.speedings !== undefined ? this.speedings : '';
        data['maximumSpeed'] = this.maximumSpeed !== undefined ? this.maximumSpeed : false;
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminDriverAggressive(JSON.parse(json));
    }

}
