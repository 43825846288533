import { Injectable } from '@angular/core';
import { HttpService } from './../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { Response } from '@angular/http';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

export interface Images {
    loginLogo: any;
    loginImage: any;
    homeLogo: any;
}

@Injectable()
export class ImagenCorporativaService {

    private _images = new BehaviorSubject<Images>({loginLogo: '', loginImage: '', homeLogo: ''});
    public dataStore: Images = {loginLogo: '', loginImage: '', homeLogo: ''};
    readonly images = this._images.asObservable();

    constructor(
        private http: HttpService,
        private http2: HttpClient,
        private domSanitizer: DomSanitizer
    ) {}


    getImages() {
        return this.http2.get<Images>(environment.endpoints.adminImgCorporateList).toPromise().then((data: any) => {
                 //.subscribe( (data: any) => {
                    data.answers.forEach((element) => {
                        if (element.question === 31) {
                            this.dataStore.loginLogo = (element.answer !== '' ? this.domSanitizer.bypassSecurityTrustResourceUrl(element.answer) : '');
                        }
                        if (element.question === 32) {
                            // this.dataStore.loginImage = (element.answer !== '' ? element.answer : '');
                            this.dataStore.loginImage = (element.answer !== '' ? this.domSanitizer.bypassSecurityTrustStyle('url("' + element.answer + '")') : '');
                        }
                        if (element.question === 33) {
                            this.dataStore.homeLogo = (element.answer !== '' ? this.domSanitizer.bypassSecurityTrustResourceUrl(element.answer) : '');
                        }
                    });
                    // console.log(this.dataStore);
                    this._images.next(this.dataStore);
                    return this.dataStore;
                 });// , error => console.log('Could not load image coorporativa.') );
    }

    getImagesCorporativa() {
        return this.http.ejecutarServicioGet(environment.endpoints.adminImgCorporateList)
                        .pipe(
                            map((res: Response) => res.json()),
                            catchError(this.handleError)
                        );
    }

    updateImagesCorporativa(data) {
        return this.http.ejecutarServicioPut(environment.endpoints.adminImgCorporateUpdate, data);
    }

    saveImagesCorporativa(data) {
        return this.http.multipartPost(environment.endpoints.adminImgCorporateSave, data)
                        .pipe(
                            map((res: Response) => res.json()),
                            catchError(this.handleError)
                        );
    }

    private handleError(error: any): Promise<any> {
		console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
	}

}