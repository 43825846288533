import { NavegacionItem } from './../../../shared/dto/navegacion-item';
import { Router, ActivatedRoute } from '@angular/router';
import { NavegacionService } from './../_services/navegacion.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
// import { BreadcrumbComponent } from '../../../shared/components/breadcrumb/breadcrumb.component';

@Component({
	selector: 'kt-navegacion',
	templateUrl: './navegacion.component.html',
	styleUrls: ['./navegacion.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class NavegacionComponent implements OnInit {
	private actualItem: NavegacionItem;
	tarjetas: any = [];
	private item: any;
	private loaded = false;

	constructor(
		private route: ActivatedRoute,
		private navegacionService: NavegacionService,
		private router: Router,
		private cdr: ChangeDetectorRef
	) {
		this.navegacionService.setItems();
	}

	ngOnInit() {
		this.navegacionService.itemsNavegacion$.subscribe(
			(items: NavegacionItem[]) => {
				this.buildBox(items);
			}
		);

		this.navegacionService.toBackNav$.subscribe((item: NavegacionItem) => {
			this.go(item);
		});
	}

	buildBox(items: NavegacionItem[], order: boolean = true) {
		if (items) {
			this.tarjetas = [];
			
			this.tarjetas = items.map(itm => {
				let url = this.navegacionService.confRouting[itm.id];

				if (url == null) {
					url = `navegacion/${itm.id}`;
				}

				// Disable the button
				let disabled = this.navegacionService.disabledRoutes.includes( itm.id + '' );

				let message;
				if( disabled ) {
					message = this.navegacionService.disabledRoutesMessages[ itm.id + '' ];
				}

				return { ...itm, url, disabled, message };
			});

			// console.log("TARJETAS:", this.tarjetas );
			// console.log("ITEMS",items)

			if (order) {
				this.tarjetas = this.tarjetas.sort((a, b) => a.id - b.id);
			}
			if (!this.loaded) {
				this.loaded = true;
				this.item = this.route.snapshot.paramMap.get('item');
				this.navegacionService.boxSelected(null);
				if (this.item !== '' && this.item !== null) {
					this.actualItem = this.tarjetas.find(
						itm => itm.id === this.item
					);
					this.navegacionService.boxSelected(this.actualItem);
					this.go(this.actualItem);
				}
			}
			this.cdr.markForCheck();
		}
	}

	go(item: NavegacionItem) {
		let order = true;
		// if ( this.actualItem != item ) {
		if (item === undefined) {
			this.buildBox(this.navegacionService.getItems());
		} else {
			if (Number(item.id) === 10) {
				order = false;
				const items = [102, 101, 103].map(itm => {
					return item.items.find(i => Number(i.id) === itm);
				});
				item.items = items;
			}

			//Valida si ya se encuetra configurado el id dentro del servicio navegacionService
			//para evitar que no se ejecute la funcion que agrega a la miga de pan
			const urlToConfig = this.navegacionService.confRouting[item.id];
			if(urlToConfig){
				this.navegacionService.boxSelected(item);
				this.actualItem = item;
			}else{
				console.warn("El item "+item.id+" no está configurado en el servicio de navegación");
			}

			if (item.items) {
				this.buildBox(item.items, order);
			} else {
				if(urlToConfig){
					this.router.navigate([urlToConfig]).catch(e => {
						console.log(e);
					});
				}else{
					console.warn("El item "+item.id+" no está configurado en el servicio de navegación");

				}
			}
		}
	}
}
