import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges
} from '@angular/core';
import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '../../../../../../environments/environment';
import { Response } from '@angular/http';
import { UIService } from '../../../_services/UI.service';
import {
	NgbDateNativeAdapter,
	NgbDateAdapter
} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-conductor-crear',
	templateUrl: './conductor-crear.component.html',
	styleUrls: ['./conductor-crear.component.scss'],
	// providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ConductorCrearComponent implements OnInit, OnChanges {
	// formModelGroup: AdminGroupDto = new AdminGroupDto();
	@Input() model: any;
	@Output() modelChange = new EventEmitter();
	@Input() editing: boolean = false;

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {
	}

	public options: Options;
	loading: boolean = false;
	public selectFitroTipoId: Array<Select2OptionData>;

	ngOnInit() {
		// this.loading = true;
		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminParamTipoDocumento
			)
			.subscribe((res: Response) => {
				let data = res.json().answerGroups;
					this.selectFitroTipoId = data.map(i => {
						return { id: i[49], text: i[50] };
					});
					this.cdr.markForCheck();
			});
	}

	ngOnChanges(changes: SimpleChanges): void {
	}

	cerrarModal() {
		// this.actionModificarDriver.emit('cerrar');
	}
	dateChange(event){
		console.log(event);
		// model.admissionDate = $event.toISOString()
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	updateModel() {
		this.modelChange.emit(this.model);
	}

	/**
	 * Valida que la fecha de nacimiento sea menor a la fecha actual y menor a la fecha de creación.
	 */
	validateBirthDate() {
		let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
		+ (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
		setTimeout(()=>{
			if (this.model.birthDate !== null && this.model.birthDate !== undefined && this.model.birthDate !== "") {
				let fechaNace = new  Date(this.model.birthDate);
				let fechaNaceFormato = fechaNace.getFullYear() + "" + ((fechaNace.getMonth()+1) < 10 ? '0' + (fechaNace.getMonth()+1) : (fechaNace.getMonth()+1)) + "" 
				+ (fechaNace.getDate() < 10 ? '0' + fechaNace.getDate() : fechaNace.getDate());
				if(!(Number(fechaNaceFormato) < Number(fechaActualFormato))){
					this.uiService.showMessage("Seleccione una fecha menor a la fecha de hoy.")
					this.model.birthDate = null;
					this.cdr.markForCheck();
				} else if (this.model.admissionDate !== null && this.model.admissionDate !== undefined && this.model.admissionDate !== "") {
					let fechaIngreso = new Date (this.model.admissionDate);
					let fechaIngresoFormato = fechaIngreso.getFullYear() + "" + ((fechaIngreso.getMonth()+1) < 10 ? '0' + (fechaIngreso.getMonth()+1) : (fechaIngreso.getMonth()+1)) + "" 
					+ (fechaIngreso.getDate() < 10 ? '0' + fechaIngreso.getDate() : fechaIngreso.getDate());
					if(!(Number(fechaNaceFormato) < Number(fechaIngresoFormato))){
						this.uiService.showMessage("La fecha de nacimiento debe ser menor a la fecha de creación.")
						this.model.birthDate = null;
						this.cdr.markForCheck();
					}
				}
			}
		})
	}

	/**
	 * Valida que la fecha de creación sea menor a la fecha actual y mayor a la fecha de nacimiento.
	 */
	validateDateAdmission() {
		let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
		+ (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
		setTimeout(()=>{
			if (this.model.admissionDate !== null && this.model.admissionDate !== undefined && this.model.admissionDate !== "") {
				let fechaIngreso = new  Date(this.model.admissionDate);
				let fechaIngresoFormato = fechaIngreso.getFullYear() + "" + ((fechaIngreso.getMonth()+1) < 10 ? '0' + (fechaIngreso.getMonth()+1) : (fechaIngreso.getMonth()+1)) + "" 
				+ (fechaIngreso.getDate() < 10 ? '0' + fechaIngreso.getDate() : fechaIngreso.getDate());
				if(!(Number(fechaIngresoFormato) < Number(fechaActualFormato))){
					this.uiService.showMessage("Seleccione una fecha menor a la fecha de hoy.")
					this.model.admissionDate = null;
					this.cdr.markForCheck();
				} else if (this.model.birthDate !== null && this.model.birthDate !== undefined && this.model.birthDate !== "") {
					let fechaNace = new  Date(this.model.birthDate);
					let fechaNaceFormato = fechaNace.getFullYear() + "" + ((fechaNace.getMonth()+1) < 10 ? '0' + (fechaNace.getMonth()+1) : (fechaNace.getMonth()+1)) + "" 
					+ (fechaNace.getDate() < 10 ? '0' + fechaNace.getDate() : fechaNace.getDate());
					if(!(Number(fechaNaceFormato) < Number(fechaIngresoFormato))){
						this.uiService.showMessage("La fecha de creación debe ser mayor a la fecha de nacimiento.")
						this.model.admissionDate = null;
						this.cdr.markForCheck();
					}
				}
			}
		})
	}
}
