import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public static changeOption: EventEmitter<any> = new EventEmitter();
  public static collapseSidebar: EventEmitter<any> = new EventEmitter();
  public static refreshPopupMapa: EventEmitter<any> = new EventEmitter();
  public static refreshPopupMapaCapa: EventEmitter<any> = new EventEmitter();
  public static closePopupMapa: EventEmitter<any> = new EventEmitter();
  public static closePopupCamaras: EventEmitter<any> = new EventEmitter();
  public static resetInterval: EventEmitter<any> = new EventEmitter();
  public static filterConcesionario: EventEmitter<any> = new EventEmitter();
  public static refreshChart: EventEmitter<any> = new EventEmitter();

  public static showModelHistorical: EventEmitter<any> = new EventEmitter();
  public static clearModelHistorical: EventEmitter<any> = new EventEmitter();

  constructor() { }
}
