import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import axios, { AxiosResponse } from 'axios';

import {
	Headers,
	Http,
	Response,
	Request,
	RequestOptions,
	RequestOptionsArgs,
	XHRBackend
} from '@angular/http';
import { HttpParams } from '@angular/common/http';
import { map, catchError, concatMap, switchMap, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserDTO } from './../../../../app/views/shared/dto/user-dto';
import { AuthService } from '@app/core/auth';
import { AppState } from '@app/core/reducers';
import { Store } from '@ngrx/store';
import { RefreshToken } from '@app/core/auth/_actions/auth.actions';
import { Logout } from '../../../core/auth/_actions/auth.actions';
import { csurf } from 'csurf'

@Injectable()
export class HttpService extends Http {
	constructor(
		backend: XHRBackend,
		options: RequestOptions,
		public http: Http,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>
	) {
		super(backend, options);
	}

	public request(
		url: string | Request,
		options?: RequestOptionsArgs
	): Observable<Response> {
		// console.log(url);
		return super.request(url, options).pipe(
			catchError(response => {
				if (response.status === 401) {
					return this.auth.refreshToken().pipe(
						catchError(errToken => {
							this.logOut();
							// this.router.navigate(['auth/login']);
							return of(errToken);
						}),
						mergeMap((res: Response) => {
							if (res.status === 403 || res.status === 0) {
								this.logOut();
								return of(res);
							} else {
								let responseToken = res.json();
								localStorage.setItem(
									environment.authTokenKey,
									responseToken.access_token
								);
								this.store.dispatch(
									new RefreshToken({
										token: responseToken.refresh_token
									})
								);
								return this.request(url, {
								...options,
									headers: new Headers({
										'Content-Type': 'application/json; charset=UTF-8',
										responseType: 'application/json',
										'Access-Control-Allow-Origin': '*',
										'Access-Control-Allow-Headers':
											'Origin, X-Requested-With, Content-Type, Accept',
										'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
										Authorization: 'Bearer ' + this.getToken(),
										username: this.getUser()
									})
								});
							}
						})
					);
				} else {
					return of(response);
				}
			})
		);
	}

	public logOut(){
		this.auth.setUser(null);
		this.store.dispatch(new Logout());
	}

	public handleError(error: Response): Observable<any> {
		if (error.status === 401) {
			this.auth.refreshToken().pipe(
				map(res => {
					if (res.status >= 400 && res.status <= 499) {
						this.logOut();
						// this.router.navigate(['auth/login']);
						return of(error);
					} else {
						let response = res.json();
						localStorage.setItem(
							environment.authTokenKey,
							response.access_token
						);
						this.store.dispatch(
							new RefreshToken({ token: response.refresh_token })
						);
					}
				})
			);
		}
		return of(error);
	}

	private getToken() {
		return localStorage.getItem(environment.authTokenKey);
	}

	private getUser() {
		let currentUser: string = '';
		try {
			const user = new UserDTO(
				JSON.parse(localStorage.getItem(environment.authUserKey))
			);
			currentUser = user.username;
		} catch {
			currentUser = '';
		}
		return currentUser;
	}

	ejecutarServicioGet(
		url: string,
		params?: HttpParams
	): Observable<Response> {
		return this.request(url, {
			search: params,
			method: 'get',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				'X-XSRF-TOKEN': 'XSRF-TOKEN',
				'Access-Control-Allow-Origin': '*',
				'CSRFToken': 'Token',
				'Content-Security-Policy': 'frame-ancestors',
				'X-Content-Type-Options': 'nosniff',
				responseType: 'application/json',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}

	ejecutarServicioGetCORS(
		url: string,
		params?: HttpParams
	): Observable<Response> {
		return this.request(url, {
			search: params,
			method: 'get',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				responseType: 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
					'Origin, X-Requested-With, Content-Type, Accept',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}

	ejecutarServicioPost(url: string, data?): Observable<Response> {
		return this.request(url, {
			body: JSON.stringify(data),
			method: 'post',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				responseType: 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
				'Origin, X-Requested-With, Content-Type, Accept',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser(),
				'X-XSRF-TOKEN': 'XSRF-TOKEN',
				'Content-Security-Policy': 'frame-ancestors',
				'X-Content-Type-Options': 'nosniff'
			})
		});
	}

	ejecutarServicioPostConcat(endpoints): Observable<Response> {
		let req: Observable<Response> = this.ejecutarServicioPost(
			endpoints[0]['endpoint'],
			endpoints[0]['params']
		);
		console.log(endpoints[0]);

		for (let index = 1; index < endpoints.length; index++) {
			const element = endpoints[index];
			console.log(endpoints[index]);
			req.pipe(
				concatMap((res: Response) => {
					console.log(`ejecutando post: ${index}`);
					req = this.ejecutarServicioPost(
						element['endpoint'],
						element['params']
					);
					return of(res);
				}),
				catchError((res: any) => {
					console.log(`error post: ${index}`);
					req = this.ejecutarServicioPost(
						element['endpoint'],
						element['params']
					);
					return of(res);
				})
			).subscribe(res => {});
		}

		return req;
	}

	ejecutarServicioPut(url: string, data?): Observable<Response> {
		return this.request(url, {
			body: JSON.stringify(data),
			method: 'put',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				responseType: 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
					'Origin, X-Requested-With, Content-Type, Accept',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}

	ejecutarServicioDelete(url: string, data?): Observable<Response> {
		return this.request(url, {
			body: JSON.stringify(data),
			method: 'delete',
			headers: new Headers({
				'Content-Type': 'application/json; charset=UTF-8',
				responseType: 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Headers':
					'Origin, X-Requested-With, Content-Type, Accept',
				'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}

	multipartPost(url: string, data?): any {
		return this.post(url, data, {
			headers: new Headers({
				Accept: '*/*',
				// responseType: 'application/json',
				'Access-Control-Allow-Origin': '*',
				// 'Access-Control-Allow-Headers':
				// 	'Origin, X-Requested-With, Content-Type, Accept',
				// 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			})
		});
	}

	multipartPost2(url: string, data?): any {
		return axios.post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: 'Bearer ' + this.getToken(),
				username: this.getUser()
			}
		});
	}

	ejecutarServicioGet2(
		url: string,
		params?: HttpParams
	): Observable<Response> {
		return this.request(url, {

			method: 'get'
		});
	}

}
