import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdminRolDTO } from '../../dto/admin-rol-dto';
import { AdminProfileDTO } from '../../dto/admin-profile-dto';
import { AdminUserDTO } from '../../dto/admin-user-dto';
import { AdminCategoriaDto } from '../../dto/admin-categoria-dto';
import { AdminDriverDTO } from '../../dto/admin-driver-dto';

@Component({
	selector: 'kt-formulario-modal',
	templateUrl: './formulario-modal.component.html',
	styleUrls: [
		'./formulario-modal.component.scss',
		'./../modal-mensaje/modal-mensaje.component.scss',
	],
	providers: [NgbModalConfig, NgbModal]
})
export class FormularioModalComponent implements OnInit {
	datosRol: AdminRolDTO;
	datosProfile: AdminProfileDTO;
	datosUser: AdminUserDTO;
	datosDriver: AdminDriverDTO;
	datosCategoria: AdminCategoriaDto;
	datosParametroTramo:any;
	datosTramoLineString:any;
	datosCasoManual:any;
	@Input() idDispositivo: string;
	@Input() idEventosRedes: string;
	@Input() idAjCasos: string;
	@Input() idAnalisis: string;
	@Input() idCorreo: string;
	@Input() idCasoRes: string;


	@ViewChild('content') contentRef: any;
	datosAlarma: any;

	@Input('content')
	set content(num) {
		if (num > 0) {
			this.numero = num;
			this.open();
		}
	}

	private modalRef: NgbModalRef;

	// Editar ROL
	@Input('datosEditarRol')
	set datosEditarRol(varData) {
		this.datosRol = varData;
		// console.log(this.datosRol);
		this.cdr.markForCheck();
	}

	// Editar Perfil
	@Input('datosEditarProfile')
	set datosEditarProfile(varData) {
		this.datosProfile = varData;
		// console.log(this.datosProfile);
		this.cdr.markForCheck();
	}

	// Editar User
	@Input('datosEditarUser')
	set datosEditarUser(varData) {
		this.datosUser = varData;
		// console.log(this.datosUser);
		this.cdr.markForCheck();
	}

	// Editar Concecionario
	@Input('datosEditarCategorias')
	set datosEditarCategorias(varData) {
		this.datosCategoria = varData;
		// console.log(this.datosCategoria);
		this.cdr.markForCheck();
	}

	// Editar User
	@Input('datosEditarRango')
	set datosEditarRango(varData) {
		this.datosAlarma = varData;
		console.log(this.datosAlarma);
		this.cdr.markForCheck();
	}


	//Editar conductor
	@Input('datosEditarDriver')
	set datosEditarDriver(varData) {
		this.datosDriver = varData;
		console.log(this.datosDriver);
		this.cdr.markForCheck();
	}

	
	//Editar parametro troncal
	@Input('datosParametro')
	set datosParametro(varData) {
		this.datosParametroTramo = varData;
		console.log(this.datosParametroTramo);
		this.cdr.markForCheck();
	}
	
	//Editar parametro troncal
	@Input('datosTramo')
	set datosTramo(varData) {
		this.datosTramoLineString = varData;
		console.log(this.datosTramoLineString);
		this.cdr.markForCheck();
	}

	//Editar Reasignar Caso Manual
	@Input('casoManual')
	set casoManual(varData) {
		this.datosCasoManual = varData;
		this.cdr.markForCheck();
	}

	// Editar Contratista
	@Input('datosEditarContratistas')
	set datosEditarContratistas(varData) {
		this.datosCategoria = varData;
		// console.log(this.datosCategoria);
		this.cdr.markForCheck();
	}


	@Output() statusModal = new EventEmitter();

	configModal: {};
	numero: number = 0;
	configModalGlobal: {}[];
	options: any = {
		centered: true,
		size: 'lg',
		windowClass: 'sths-modal-style',
		backdropClass: 'sths-backdrop'
	};


	@Input() operadorStyle: boolean = true;

	// operadorStyles:boolean = false;

	constructor(config: NgbModalConfig,
		private modalService: NgbModal,
		private cdr: ChangeDetectorRef) {
		config.backdrop = 'static';
		config.keyboard = false;

		this.configModalGlobal = [
			// 1
			{
				icono: 'smart-icon-categorias',
				titulo: 'Registro de Operador',
			},
			// 2
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'Editar Operador',
			},
			// 3
			{
				icono: 'smart-icon-usuario',
				titulo: 'Información Operador'
			},
			// 4
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'Terminar Registro'
			},
			// 5
			{
				icono: 'smart-icon-usuario',
				titulo: 'Modificar Rol'
			},
			// 6
			{
				icono: 'smart-icon-usuario',
				titulo: 'Modificar Perfil'
			},
			// 7
			{
				icono: 'smart-icon-usuario',
				titulo: 'Modificar Usuario'
			},
			// 8
			{
				icono: 'smart-icon-categorias',
				titulo: 'Crear Bus'
			},
			// 9
			{
				icono: 'smart-icon-notificaciones',
				titulo: 'Editar Rango de Alarma'
			},
			// 10
			{
				icono: 'smart-icon-notificaciones',
				titulo: 'Alarma'
			},
			// 11
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'Dispositivo'
			},
			// 12
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'Modificar Conductor'
			},
			// 13
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'Modificar Evento'
			},
			// 14
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'No se puede editar un registro inactivo'
			},
			//15
			{

			},
			//16
			{
				icono: 'smart-icon-categorias',
				titulo: 'Editar Configuración'
			},
			//17
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'El bus no puede ser editado cuando esta completado'
			},
			//18
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'El dispositivo no puede ser eliminado cuando esta completado'
			},
			//19
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'El bus no puede ser editado cuando esta vinculado'
			},
			//20
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'El bus no puede ser eliminado cuando esta vinculado'
			},
			//21
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'El dispositivo no puede ser editado cuando esta completado o vinculado'
			},
			//22
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'El dispositivo no puede ser elimando cuando esta completado o vinculado'
			},
			//23
			{
					icono: 'smart-icon-editar-cuadro',
					titulo: 'Detalle del caso'
			},
			//24
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'Crear Tramo'
			},
			//25
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'Editar Parametro Tramo'
			},
			//26
			{
				icono: 'smart-icon-categorias',
          		titulo: 'Carga de Casos por Rol'
			},
			//27
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'Editar Contratista',
			},
			// 28
			{
				icono: 'smart-icon-usuario',
				titulo: 'Información contratista'
			},
			// 29
			{
				icono: 'smart-icon-categorias',
				titulo: 'Registro de contratista',
			},
			//30
			{
				icono: 'smart-icon-editar-cuadro',
				titulo: 'El dispositivo no puede ser editado cuando esta completado'
			},
		];
	}

	ngOnInit() {
	}

	open() {
		this.configModal = this.configModalGlobal[this.numero - 1];
		console.log("MODALLL ",this.configModal)
		setTimeout(() => {
			this.modalRef=this.modalService.open(this.contentRef, this.options);
			this.modalRef.result.then((result) => {
				// Cerrar
				this.statusModal.emit('');
			}, (reason) => {
				// Cerrar
				this.statusModal.emit(reason);
			});
		}, 300);
	}

	accionBoton(tipo: string) {
		console.log(tipo);
	}

	actionModificarRol(event) {
		this.modalService.dismissAll(event);
	}

	actionModificarProfile(event) {
		this.modalService.dismissAll(event);
	}

	actionModificarUser(event) {
		this.modalService.dismissAll(event);
	}

	actionModificarDriver(event) {
		this.modalService.dismissAll(event);
	}

	actionModificarCategoria(event) {
		this.modalService.dismissAll(event);
	}

	actionVerCategoria(event) {
		this.modalService.dismissAll(event);
	}

	actionCrearGrupo(event) {
		this.modalService.dismissAll(event);
	}

	actionModificarAlarm(event) {
		this.modalService.dismissAll(event);
		this.statusModal.emit(event);
	}

	actionModificarEventos(event) {
		this.modalService.dismissAll(event);
	}

	actionModificarCorreo(event) {
		this.modalService.dismissAll(event);
	}


	actionInfoAlarma(event) {
		this.modalService.dismissAll(event);
	}

	actionModificarParamentro(event){
		this.modalRef.close()		
		this.statusModal.emit(event);
	}

	actionModificarAjCasos(event) {
		this.modalService.dismissAll( event );
	}

	actionModificarContratista(event) {
		this.modalService.dismissAll(event);
	}

	onClose(event) {
		this.statusModal.emit(event);
	}
}
