export class AdminCategoriaDto {

    id: string;
    registryId: string;
    name: string;
    registryLabel: string;

    constructor(data?: any) {
        if (data !== undefined) {
            this.id = data['id'] !== undefined ? data['id'] : null;
            this.registryId = data['registryId'] !== undefined ? data['registryId'] : null;
            this.name = data['name'] !== undefined ? data['name'] : null;
            this.registryLabel = data['registryLabel'] !== undefined ? data['registryLabel'] : null;
        }
    }

    static fromJS(data: any): AdminCategoriaDto {
        return new AdminCategoriaDto(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : null;
        data['registryId'] = this.registryId !== undefined ? this.registryId : null;
        data['name'] = this.name !== undefined ? this.name : null;
        data['registryLabel'] = this.registryLabel !== undefined ? this.registryLabel : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : '';
        data['registryId'] = this.registryId !== undefined ? this.registryId : '';
        data['name'] = this.name !== undefined ? this.name : '';
        data['registryLabel'] = this.registryLabel !== undefined ? this.registryLabel : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminCategoriaDto(JSON.parse(json));
    }

}
