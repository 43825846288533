import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { Select2OptionData, Options } from 'select2';

@Component({
  selector: 'kt-form-mantenimiento',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {
  
  // Select2
  public selectModelos: Array<Select2OptionData>;
  public selectSensor: Array<Select2OptionData>;
  public options: Options;
  
  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  // DATOS pueden ser en un dto
  formModelMantenimiento: {} = {
    modelo: '',
    tiempo: '',
    sensor: '',
  };

  conditionalLoader: boolean;

  @Input() idMantenimiento: string;

  @Output() eventGuardar  = new EventEmitter();
  @Output() actionModificarMantenimiento  = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    console.log( this.idMantenimiento );
    // Si trae algo idMantenimiento, cargar los datos del webservice por ID

    // Select2
    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: "select2-dropdown-forms"
    };
    
    // selectModelos
    this.selectModelos = [
      {id:"-Sel", text: "Seleccionar Modelo"},
      {id:"1", text: "Modelo 1"},
      {id:"2", text: "Modelo 2"}
    ]

    // selectModelos
    this.selectSensor = [
      {id:"-Sel", text: "Seleccionar Sensor"},
      {id:"1", text: "Sensor 1"},
      {id:"2", text: "Sensor 2"}
    ]
  }

  guardarParametros(){
    // Servicio Guardar
    this.guardarConfgConductor();
  }

  guardarConfgConductor( ){
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-guardar',
      titulo: 'Los datos han sido guardados',
      textos: [
            'Los datos ingresados han sido guardados con éxito'
      ],
      botones: [
              {
                label: 'Ok',
                class: '',
                accion: 'guardarMantenimiento'
              }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModal = -1;
      this.configModal = { status: -1 }
    }, 300);
  }

  changeStatusModal( event ){
    switch (event) {
      case 'guardarMantenimiento':
        // Limpiar, si es un dto se instancia de nuevo la variable
        this.formModelMantenimiento = {
          modelo: '-Sel',
          tiempo: '',
          sensor: '-Sel',
        };
        // Refrescar Datos
        this.eventGuardar.emit( 'guardarMantenimiento' );
        // EMIT al PADRE
        break;

      default:
        // EMIT al PADRE
        this.eventGuardar.emit( 'guardarMantenimiento' );
        break;
    }
  }

}
