import { NgModule, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Select2OptionData, Options } from 'select2';
import { AdminGroupDto } from '../../../shared/dto/admin-group-dto';
import { Response } from '@angular/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UIService } from '../../../trans/_services/UI.service';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../shared/services/http.service';



@Component({
  selector: 'kt-configuracion-correos',
  templateUrl: './configuracion-correos.component.html',
  styleUrls: ['./configuracion-correos.component.scss'],
  host: { 'class': 'sths-tb-element' }
})


export class ConfiguracionCorreosComponent implements OnInit {
  nombre: string;
  descripcion: string;
  email: string;
  propietario: string;
  public selectFitroTipoId: Array<Select2OptionData>;
  formModelGroup: AdminGroupDto = new AdminGroupDto();
  formData: Object;
  selectedtipo_id: any;


  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  // Paginador
  paginador: Object = {
    count: 10,
    page: 1
  };

  // Tabla
  configTable: Object;
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  conditionalLoader: boolean;

  // Id Eliminar
  eliminarId: any;

  // Modal Modificar
  contentToModificar: number = -1;
  idCorreo: any;

  showModal: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private http: HttpService,
    private uiService: UIService) {


    this.formModelGroup.metaData = {
      tipo_id: ''
    };

    this.formData = {
      txtDescription: "",
      emailsList: [],
      emailSelected: {}
    }
  }

  ngOnInit() {
    this.getProcessList();
    // this.searchEmails();
  }
  onSearchEmails() {
    console.log("+++ onSearchEmails +++");
    console.log(this.formModelGroup);
    this.searchEmails();
  }
  eventGuardar(data) {
    data["46"] = this.formModelGroup.metaData["tipo_id"]
    this.http
      .ejecutarServicioPost(
        environment.endpoints.saveEmail,
        { answer: data }
      )
      .subscribe((res: Response) => {
        this.searchEmails();
      });
  }
  searchEmails() {
    let self = this;
    this.conditionalLoader = true;
    this.getEmailsList(function (emailsList, error) {
      self.formData["emailsList"] = emailsList;
      if (!error) {
        self.procesarJson(emailsList);
      }
      self.conditionalLoader = false;
    });
  }
  procesarJson(data) {
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let i = 0; i < data.length; i++) {
      dataArray.push([
        data[i]['answerGroup'] ? data[i]['answerGroup'] : '',
        data[i]['47'] ? data[i]['47'] : '',
        data[i]['48'] ? data[i]['48'] : '',
        ''
      ]);

      if (i === data.length - 1) {
        this.armarTabla(dataArray);
      }
    }

    if (!data.length) {
      this.armarTabla(dataArray);
    }
  }
  armarTabla(data) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Email', 'Nombre', 'Acciones'],
      typeColumn: [, , , { tipo: 'acciones', acciones: ['editar', 'eliminar'] }],
      showSearch: false,
      searchColumn: [1],
      searchTitle: 'Buscar Categoría',
      hideColumns: [0],
      showPag: false,
      leftsColumns: [1],
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador,
    }
    this.cdr.markForCheck();
  }
  editarCorreo(id) {
    console.log('editarCorreo', id);
    this.contentToModificar = 16;
    this.idCorreo = id;
    let element = this.formData["emailsList"].find(itm => itm.answerGroup === id);
    this.formData["emailSelected"] = element;
    this.showModal = true;
    this.cdr.markForCheck();
    console.log(this.formData["emailSelected"]);
  }
  eliminarCorreo(id) {
    // Guardar en variable de Eliminar Global
    this.eliminarId = id;
    this.contentToShowModal = 1;
    this.configModal = {
      status: 1,
      icono: 'smart-icon-eliminar',
      titulo: '¿Estas seguro que desea eliminar los datos?',
      textos: [
        'Los datos en la fila seleccionada serán eliminados permanentemente'
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelarCorreo'
        },
        {
          label: 'Eliminar',
          class: '',
          accion: 'eliminarCorreo'
        }
      ]
    };
    this.cdr.markForCheck();
  }
  changeStatusModal(event) {
    console.log(event,'evento');
    this.contentToShowModal = -1;
    this.configModal = { status: -1 }
    this.cdr.markForCheck();
    switch (event) {
      case 'eliminarCorreo':
        // Accion eliminar Correo
        // Webservice eliminar this.eliminarId.
        console.log('eliminarCorreo', this.eliminarId);
        this.conditionalLoader = true;
        this.http
          .ejecutarServicioDelete(
            environment.endpoints.removeEmail +
            this.eliminarId
          )
          .subscribe((res: Response) => {
            this.conditionalLoader = false;
            this.eliminarId = '';
            if (res.status === 200) {
              this.showModal = false;
              this.searchEmails();
            } else {
              this.showError(res);
            }
          });
        break;
      default:
        break;
    }
  }
  changeStatusEdit(event) {
    this.contentToModificar = -1;
    this.cdr.markForCheck();
    this.idCorreo = '';
    console.log('changeStatusEdit', event);
  }
  onProcessSelected(e: any): void {
    let data = e.data.shift();
    console.log(data,'data');
    this.formData["txtDescription"] = data.description;
  }
  /**
   * Consulta endpoint para cargar tabla
   */
  getProcessList() {
    this.http
      .ejecutarServicioGet(environment.endpoints.getProcessList, null)
      .subscribe((resEstado: Response) => {
        switch (resEstado.status) {
          case 200:
          case 201:
          case 204:

            let processList = resEstado.json().answerGroups;

            this.selectFitroTipoId = processList.map(i => {
              return { id: i["44"], text: i["44"], description: i["45"] };
            });

            this.selectedtipo_id = this.selectFitroTipoId[0]['description'];
            //this.formData["txtDescription"] = this.selectFitroTipoId[0]['description'];

            this.cdr.markForCheck();
            break;
          default:
            this.showError(resEstado);
            break;
        }
      }, (error: Response) => {
        this.showError(error);
      }
      );
  }
  getEmailsList(callback) {
    this.http
      .ejecutarServicioGet(environment.endpoints.getEmailsList, null)
      .subscribe((res: Response) => {
        switch (res.status) {
          case 200:
          case 201:
          case 204:
            let emailsList = res.json().answerGroups;
            this.formModelGroup.metaData["tipo_id"] = this.selectFitroTipoId[0]['id'];
            emailsList = emailsList.filter(i => i["46"] === this.formModelGroup.metaData["tipo_id"]);
            callback(emailsList, false);
            break;
          default:
            this.showError(res);
            break;
        }
      }, (error: Response) => {
        this.showError(error);
        callback([], true);
      }
      );
  }

  updateEmail() {
    // const dataForm = this.eventoForm.value;
    if (!this.validateEmail(this.formData['emailSelected']['47'])) {
      this.uiService.showMessage('El formato del correo electrónico no es válido');
			return false;
		}

    console.log('updateEmail', this.formData['emailSelected']);
    this.http
      .ejecutarServicioPut(
      	environment.endpoints.updateEmail +
        this.formData['emailSelected']['answerGroup'],
        {
          answer: {
            46: this.formData['emailSelected']['46'],
            47: this.formData['emailSelected']['47'],
            48: this.formData['emailSelected']['48']
          }
        }
      )
      .subscribe((res: Response) => {
        if (res.status === 200) {
          this.uiService.showMessage('Parámetros actualizados');
          this.showModal = false;
          this.searchEmails();
        } else {
          this.showError(res);
        }
      });
  }

  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage(response.json()['message']);
    } else {
      this.uiService.showMessage('Error del servidor');
    }
    this.cdr.markForCheck();
  }

  statusModal(event) {
    this.showModal = false;
  }

  validateEmail(email) {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

}
