import { ActivatedRoute } from '@angular/router';
import { Response } from '@angular/http';
import { UIService } from '@services/UI.service';
import { environment } from '@environments/environment';
import { HttpService } from '../../../../shared/services/http.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'kt-form-grupo',
	templateUrl: './form-grupo.component.html',
	styleUrls: [
		'./../dispositivos-grupos/dispositivos-formulario/dispositivos-formulario.component.scss',
		'./form-grupo.component.scss'
	],
	host: { class: 'sths-tb-element' }
})
export class FormGrupoComponent implements OnInit {
	private excludes = [
		'carroceria',
		'tipo_motor',
		'tipologia',
		'tipo_vehiculo',
		'placa',
		'name',
		'registry',
		'devices',
		'marca',
		'mchasis',
		'mcarroceria',
		'mmotor',
		'smotor',
		'mvehiculo',
		'nemicion',
		'deposito',
		'ubasica',
		'upeso',
		'pesovacio',
		'maxpasajeros',
		'date',
		'tipoFrenos',
		'capacidadBaterias',
		'unidadCarga'
	];

	// Select2
	public selectFitroTipoVehiculo: Array<Select2OptionData>;
	public selectFitroTipologia: Array<Select2OptionData>;
	public selectFitroTipoMotor: Array<Select2OptionData>;
	public selectFitroCarroceria: Array<Select2OptionData>;
	public selectFitroTipoFreno: Array<Select2OptionData>;

	public options: {};
	busForm: FormGroup;
	// Cargador
	loader: boolean;
	idRegistry: string;
	idRegistryGroup: string;
	rutasMenu: {};
	extraMetaDatas: {}[];
	metaDatas: any = {};
	idNumber: number = 1;
	NombreVacio: boolean = false;
	OperatorStyle: boolean = false;

	constructor(
		private _route: ActivatedRoute,
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {
		this.idRegistry = this._route.snapshot.paramMap.get('registryId');
		this.idRegistryGroup = this._route.snapshot.paramMap.get(
			'registryGroup'
		);
		// Rutas
		this.rutasMenu = [
			{
				label: 'Asociar dispositivos',
				icon: 'smart-icon-asociar-dispositivos',
				ruta: `${environment.folders.routeAdmin}registry/tipo2/groups/${
					this.idRegistry
				}/${this.idRegistryGroup}/asociar`
			},
			{
				label: 'Bus',
				icon: 'smart-icon-editar-cuadro',
				ruta: `${environment.folders.routeAdmin}registry/tipo2/groups/${
					this.idRegistry
				}/${this.idRegistryGroup}/editar`
			}
		];

		this.http
			.ejecutarServicioGet(environment.endpoints.adminBusesRegistry)
			.subscribe(
				(eventRecords: Response) => {
					switch (eventRecords.status) {
						case 200:
						case 201:
						case 202:
						case 204:
							let formConfig = eventRecords.json().questions;
							// Se llenan los combobox
							let select = formConfig.find(
								i => Number(i.id) === 55
							);
							if (select) {
								this.selectFitroTipoVehiculo = select.possibleAnswers.map(
									i => {
										return {
											id: this.eliminarDiacriticos(i.answerValue).toLowerCase(),
											text: i.answer
										};
									}
								);
							}

							select = formConfig.find(i => Number(i.id) === 56);
							if (select) {
								this.selectFitroTipologia = select.possibleAnswers.map(
									i => {
										return {
											id: i.answerValue,
											text: i.answer
										};
									}
								);
							}

							select = formConfig.find(i => Number(i.id) === 57);
							if (select) {
								this.selectFitroTipoMotor = select.possibleAnswers.map(
									i => {
										return {
											id: this.eliminarDiacriticos(i.answerValue).toLowerCase(),
											text: i.answer
										};
									}
								);
							}

							select = formConfig.find(i => Number(i.id) === 58);
							if (select) {
								this.selectFitroCarroceria = select.possibleAnswers.map(
									i => {
										return {
											id: i.answerValue,
											text: i.answer
										};
									}
								);
							}
							select = formConfig.find(i => Number(i.id) === 59);
							if (select) {
								this.selectFitroTipoFreno = select.possibleAnswers.map(
									i => {
										return {
											id: i.answerValue,
											text: i.answer
										};
									}
								);
							}
							this.cdr.markForCheck();
							break;
						default:
							this.uiService.showMessage(
								'Error actualizando Grupo'
							);
							this.cdr.markForCheck();
							break;
					}
				},
				e => {
					this.uiService.showMessage('Error actualizando Grupo');
					this.cdr.markForCheck();
				}
			);

		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};

		this.extraMetaDatas = [{ key: '', value: '' }];
	}

	ngOnInit() {
		this.busForm = new FormGroup({
			name: new FormControl('', [Validators.required]),
			placa: new FormControl('', [Validators.required]),
			tipo_vehiculo: new FormControl('', [Validators.required]),
			date: new FormControl('', [Validators.required]),
			carroceria: new FormControl('', [Validators.required]),
			deposito: new FormControl('', [Validators.required]),
			marca: new FormControl('', [Validators.required]),
			maxpasajeros: new FormControl('', [Validators.required]),
			capacidadBaterias: new FormControl('', [Validators.required]),
     		unidadCarga: new FormControl('', [Validators.required]),
			mcarroceria: new FormControl('', [Validators.required]),
			mchasis: new FormControl('', [Validators.required]),
			mmotor: new FormControl('', [Validators.required]),
			mvehiculo: new FormControl('', [Validators.required]),
			nemicion: new FormControl('', [Validators.required]),
			pesovacio: new FormControl('', [Validators.required]),
			smotor: new FormControl('', [Validators.required]),
			tipo_motor: new FormControl('', [Validators.required]),
			tipologia: new FormControl('', [Validators.required]),
			ubasica: new FormControl('', [Validators.required]),
			upeso: new FormControl('', [Validators.required]),
			tipoFrenos: new FormControl('', [Validators.required])
    });
		this.formControlValueChanged();

		let ruta = `${environment.endpoints.adminRegistryGroupGetMetadata}/${
			this.idRegistry
		}/${this.idRegistryGroup}`;
		this.http.ejecutarServicioGet(ruta, null).subscribe((res: Response) => {
			this.extraMetaDatas = [];
			this.metaDatas = res.json();
			console.log('this.metaDatas :>> ', this.metaDatas);
			this.metaDatas["tipo_motor"] = this.eliminarDiacriticos(this.metaDatas["tipo_motor"]).toLowerCase();
			this.metaDatas["tipo_vehiculo"] = this.eliminarDiacriticos(this.metaDatas["tipo_vehiculo"]).toLowerCase();
			this.busForm.patchValue(this.metaDatas);
			let metaDataList = Object.keys(this.metaDatas);
			for (let i = 0; i < metaDataList.length; i++) {
				if (this.excludes.includes(metaDataList[i])) {
					metaDataList.splice(i, 1);
					i--;
				}
			}
			for (let i = 0; i < metaDataList.length; i++) {
				this.idNumber++;
				this.extraMetaDatas.push({
					key: metaDataList[i],
					value: this.metaDatas[metaDataList[i]]
				});
				this.metaDatas[metaDataList[i]] = '';
			}
			if (this.extraMetaDatas.length === 0) {
				this.extraMetaDatas = [{ key: '', value: '' }];
			}
			this.cdr.markForCheck();
		});
	}


	formControlValueChanged() {
		const upesoControl = this.busForm.get('upeso');
		const ubasicaControl = this.busForm.get('ubasica');
		const depositoControl = this.busForm.get('deposito');
		const capacidadBateriasControl = this.busForm.get('capacidadBaterias');
		const unidadCargaControl = this.busForm.get('unidadCarga');
		const tipo_motorControl = this.busForm.get('tipo_motor');
		
	 
		tipo_motorControl.valueChanges.subscribe(
			(value: string) => {
			  if(value === 'electrico') {
				console.log('entre aqui :>> ', );
				upesoControl.clearValidators();
				ubasicaControl.clearValidators();
				depositoControl.clearValidators();
				this.f.upeso.disable();
				this.f.ubasica.disable();
				this.f.deposito.disable();
				this.f.upeso.setValue('');
				this.f.ubasica.setValue('');
				this.f.deposito.setValue('');
				capacidadBateriasControl.setValidators([Validators.required]);
				unidadCargaControl.setValidators([Validators.required]);
				this.f.capacidadBaterias.enable();
				this.f.unidadCarga.enable();
			  }
			  else {
				upesoControl.setValidators([Validators.required]);
				ubasicaControl.setValidators([Validators.required]);
				depositoControl.setValidators([Validators.required]);
				this.f.upeso.enable();
				this.f.ubasica.enable();
				this.f.deposito.enable();
				capacidadBateriasControl.clearValidators();
				unidadCargaControl.clearValidators();
				this.f.capacidadBaterias.disable();
				this.f.unidadCarga.disable();
				this.f.capacidadBaterias.setValue('');
				this.f.unidadCarga.setValue('');
			  }
	 
			  upesoControl.updateValueAndValidity();
			  ubasicaControl.updateValueAndValidity();
			  depositoControl.updateValueAndValidity();
			  capacidadBateriasControl.updateValueAndValidity();
			  unidadCargaControl.updateValueAndValidity();
			});
	  }

	eliminarMetadata(item) {
		const index: number = this.extraMetaDatas.indexOf(item);
		if (index !== -1) {
			this.extraMetaDatas.splice(index, 1);
		}
	}

	get f() {
		return this.busForm.controls;
	}

	agregarDato() {
		this.idNumber++;
		this.extraMetaDatas.push({
			key: '',
			value: ''
		});
	}

	capitalize(word: string) {
		return (word.charAt(0).toUpperCase() + word.slice(1)).replace('_', ' ');
	}

	validateForm(event) {
		setTimeout(() => {
			for (let metadata in this.metaDatas) {
				this.metaDatas[metadata] = this.metaDatas[metadata];
			}
			for (let metadata in this.extraMetaDatas) {
				this.extraMetaDatas[metadata]['key'] = this.extraMetaDatas[
					metadata
				]['key'];
				this.extraMetaDatas[metadata]['value'] = this.extraMetaDatas[
					metadata
				]['value'];
			}
			this.cdr.markForCheck();
		}, 100);
	}

	 eliminarDiacriticos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
	}

	save() {
		let registry = this.metaDatas['registry'];
		this.metaDatas = this.busForm.value;
		this.metaDatas.registry = registry;
		for (let index = 0; index < this.extraMetaDatas.length; index++) {
			this.metaDatas[
				this.extraMetaDatas[index]['key']
			] = this.extraMetaDatas[index]['value'];
		}
		this.loader = true;
		this.http.ejecutarServicioPost(environment.endpoints.adminRegistryGroupAddMetadata, this.metaDatas)
			.subscribe(
				(res: Response) => {
					this.loader = false;
					if (res.status === 200) {
						this.uiService.showMessage('Grupo actualizado');
					} else if (res.status === 500) {
						let message = JSON.parse(res['_body']);
						this.uiService.showMessage(message['message']);
					} else {
						this.uiService.showMessage('Error actualizando Grupo');
					}
					this.cdr.markForCheck();
				},
				e => {
					console.log('error', e);
					this.loader = false;
					this.uiService.showMessage('Error actualizando Grupo');
					this.cdr.markForCheck();
				}
			);
	}
}
