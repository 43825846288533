export const environment = {
	production: true,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	authUserKey: 'authus8g1465sdf4ew16gc222d5f7eg51h2c3',
	version: '1.1.2',
	ambiente: 'PROD',

	rol: {
		operario: 'Operativo',
		coordinadorDts: 'Coordinador Seguridad (DTS)',
		tecnicoSeguridad: 'Técnico de Seguridad',
		administrador: 'Administrativo',
		mapa: 'Mapa',
	},

	folders: {
		imgMarkers: '/assets/media/icons/markers/',
		routeAdmin: '/admin/dashboard/',
		routeMap: '/map/dashboard/'
	},

	endpoints: {
		parent: 'https://cdeg-reports-dot-smart-helios-cgtm.appspot.com',
		drivers: 'https://cdeg-drivers-dot-smart-helios-cgtm.appspot.com',
		views: 'https://cdeg-reports-dot-smart-helios-cgtm.appspot.com/cdg/views',
		navegacion: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user/menu',
		login: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/login',
		refreshToken: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/oauth/access_token',
		logout: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/logout',
		forgot: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user/',
		adminRegistries: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/listRegistries',
		adminUserRegistries: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/listUserRegistries',
		adminRegistryListAdmins: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/listAdminRegistries',
		adminRegistryCreate: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/createRegistry',
		adminRegistryGet: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getRegistry/',
		adminRegistryEdit: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/editRegistry',
		adminRegistryDelete: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/deleteRegistry',
		adminRegistryTemplatesGroup: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getGroupTemplates',
		adminRegistryTemplateGroupCreate: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/createGroupTemplate',
		adminRegistryTemplateGroupFormCreate: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/createGroupFromTemplate',
		adminRegistryGroup: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/listGroups',
		adminRegistryGroupCreate: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/createGroup',
		adminRegistryGroupAddMetadata: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/addGroupMetadataList',
		adminRegistryGroupDelete: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/deleteGroup',
		adminRegistryGroupGetMetadata: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getGroupMetadata',
		adminRegistryDevicesVars: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getDeviceVars',
		adminRegistryDevicesCreate: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/createDevice',
		adminRegistryDevicesAddMetadata: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/addDeviceMetadataList',
		adminRegistryDevicesGetMetadata: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getDeviceMetadata',
		adminRegistryDevicesComboDisp: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getVarTemplates',
		adminRegistryDevicesMetadata: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getGroupMetadata',
		adminRegistryDevicesList: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/listGroupIdDevices',
		adminRegistryDevicePK: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getDevicePK',
		adminRegistryDeviceUpdatePK: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/updateDevicePK',
		adminRegistryDeviceBlock: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/blockDevice',
		adminRegistryDeviceUnblock: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/unblockDevice',
		adminRegistryDeviceDelete: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/deleteDevice',
		adminUser: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user/',
		adminUserNewPassword: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user/newpassword',
		adminUserSearch: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/users/search',
		adminUserCount: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/users/count',
		adminUserState: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user/',
		adminUserExport: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/users/export/',
		adminUserProfiles: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user/',
		adminUserProfile: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_profile/',
		adminUsersProfiles: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_profiles/',
		adminUserProfileSearch: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_profiles/search',
		adminUserProfileCount: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_profiles/count',
		adminUserProfileState: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_profile/',
		adminUserProfileExport: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_profiles/export/',
		adminUserProfileRoles: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_profile/{id}/roles/',
		adminUserRole: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_role/',
		adminUserRoleSearch: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_roles/search',
		adminUserRoleCount: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_roles/count',
		adminUserRoleState: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_role/',
		adminUserRoleExport: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_roles/export/',
		adminUserRolePermissions: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_role/{id}/permissions/',
		adminUserPermissionsList: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_permissions/list',
		adminUserPermissions: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_permissions/',
		adminCasesManagement: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/inbox/search/',
		adminCasesManagementCount: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/count/inbox/search/',
		adminCasesManagementHeader: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/inbox/type/',
		adminCasesTypes: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/possible_answers/questionnaire/12/question/66',
		adminCasesStatus: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/case_status/list',
		adminCasesFilter: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/case/filter',
		adminCasesManualCrear: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/case/manual',
		adminCasesManualEntidades: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/possible_answers/questionnaire/12/question/38',
		adminCasesProfilesList: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/case_profiles/list',
		adminCasesManualArea: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/possible_answers/questionnaire/12/question/39',
		adminCasesManagementId: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/',
		adminCasesListIncident: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/possible_answers/questionnaire/12/question/40',
		adminCasesListImages: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/possible_answers/questionnaire/12/question/41',
		adminCasesAutoManagement: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/automatic/case_id/',
		// Ajustar Casos
		adminCasesAdjuster: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/adjuster_case_load/search',
		adminCasesAdjusterCount: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/adjuster_case_load/count',
		adminCasesUsersProfiles: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user_profiles/users',
		adminCasesSave: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/adjuster_case_load/reasign_manually',
		adminCasesSaveAuto: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/adjuster_case_load/reasign_automatically',
		adminUsersByRoleSearch: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/users/search/permissions',

		alarmsModule: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com',
		adminParamDetectionBlockList: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/4',
		adminParamDetectionBlockSave: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/4/answer',
		adminParamDetectionBlockUpdate: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/4/answerGroup',

		// Parametrización
		paramZonesCluster: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/3',
		paramSaveZones: ' https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/3/answerGroup/',
		adminParamNetworksEventList: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/6/',
		adminParamNetworksEvent: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/6/answer',
		adminParamNetworksEventUpdate: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/6/answerGroup/',
		paramPredictiveMaintenance: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/8',
		paramPredictiveMaintenanceSave: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/8/answer',
		paramPredictiveMaintenanceDelete: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/8/answerGroup/',
		paramPredictiveMaintenanceUpdate: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/8/answerGroup/',
		paramPredictiveDriver: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/1',
		paramPredictiveDriverUpdate: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/1/answerGroup/1',
		adminPredictiveMaintenanceList: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/5',
		adminPredictiveMaintenanceSave: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/5/answer',
		adminPredictiveMaintenanceDelete: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/5/answerGroup/',
		adminPredictiveMaintenanceUpdate: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/5/answerGroup/',
		filesUpload: 'https://cdeg-drivers-dot-smart-helios-cgtm.appspot.com/files/upload',
		adminDriver: 'https://cdeg-drivers-dot-smart-helios-cgtm.appspot.com/driver/',
		adminDriverSearch: 'https://cdeg-drivers-dot-smart-helios-cgtm.appspot.com/drivers/',
		adminIndicatorsList: 'https://cdeg-indicators-dot-smart-helios-cgtm.appspot.com/indicators/list',
		adminIndicator: 'https://cdeg-indicators-dot-smart-helios-cgtm.appspot.com/indicator/',
		adminLayersList: 'https://cdeg-indicators-dot-smart-helios-cgtm.appspot.com/layers/list',
		adminLayer: 'https://cdeg-indicators-dot-smart-helios-cgtm.appspot.com/layer/',
		adminIncidents: 'https://cdeg-incidents-dot-smart-helios-cgtm.appspot.com/incidents/',
		adminIncident: 'https://cdeg-incidents-dot-smart-helios-cgtm.appspot.com/incident/',

		// Modelo Alerta Conductor Agresivo
		adminParamAggressiveDriverFind: 'https://cdeg-modelsparameterizer-dot-smart-helios-cgtm.appspot.com/aggressive_driver_alert/find_parameterization',
		adminParamAggressiveDriverFindStret: 'https://cdeg-modelsparameterizer-dot-smart-helios-cgtm.appspot.com/aggressive_driver_alert/find_all_stretches',
		adminParamAggressiveDriverSave: 'https://cdeg-modelsparameterizer-dot-smart-helios-cgtm.appspot.com/aggressive_driver_alert/save_parameterization',

		// Modelo Detección Bus Parado
		adminBusStoppedExtendCount: 'https://cdeg-modelsparameterizer-dot-smart-helios-cgtm.appspot.com/bus_stopped_extended_time/count',
		adminBusStoppedExtendSearch: 'https://cdeg-modelsparameterizer-dot-smart-helios-cgtm.appspot.com/bus_stopped_extended_time/search',
		adminBusStoppedExtendStatus: 'https://cdeg-modelsparameterizer-dot-smart-helios-cgtm.appspot.com/bus_stopped_extended_time/save',

		// Modelo Detección Idling
		adminIdlingDetectionCount: 'https://cdeg-modelsparameterizer-dot-smart-helios-cgtm.appspot.com/idling_detection/count',
		adminIdlingDetectionSearch: 'https://cdeg-modelsparameterizer-dot-smart-helios-cgtm.appspot.com/idling_detection/search',
		adminIdlingDetectionStatus: 'https://cdeg-modelsparameterizer-dot-smart-helios-cgtm.appspot.com/idling_detection/save',

		// Correos
		getProcessList: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/13',
		getEmailsList: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/14',
		saveEmail: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/14/answer',
		updateEmail: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/14/answerGroup/',
		removeEmail: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/14/answerGroup/',
		adminParamTipoDocumento: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/15/',
		adminParamTipoVehiculos: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/16/',
		adminParamCategoriasLicencias: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/17/',
		adminParamConcesionarios: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/listRegistries',

		// Conductores
		driverBuscarCount: 'https://cdeg-drivers-dot-smart-helios-cgtm.appspot.com/drivers/search?documentType&document&birthDate&licenseNumber&licenseCategory&admissionDate&dealership&count&page',
		driverInfo: 'https://us-central1-smart-helios-cgtm.cloudfunctions.net/get-current-driver-by-bus',

		// Buses
		adminRegistryGroupPK: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getGroupPK',
		adminRegistryGroupUpdatePK: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/updateGroupPK',
		adminRegistryGroupLink: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/linkGroup/',
		adminRegistryGetP12: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getRegistryP12/',
		adminBusesRegistry: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/questionnaire/18',
		adminBusesComplete: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/completeGroup',

		// Troncal
		adminTroncal: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/possible_answers/questionnaire/20/question/62',

		// Imagen corporativa
		adminImgCorporateList: 'https://cdeg-customer-configs-dot-smart-helios-cgtm.appspot.com/corporate_image',
		adminImgCorporateUpdate: 'https://cdeg-customer-configs-dot-smart-helios-cgtm.appspot.com/corporate_image',
		adminImgCorporateSave: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/save_file/upload',

		// Gestión
		scalarCasesInternal: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/transfer/case_id/{{case_id}}/internal',
		scalarCasesExternal: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/transfer/case_id/{{case_id}}/to/{{entity_id}}',
		videoCameraCase: "https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/case_videos?concessionaire={{concessionaire}}&bus={{idBus}}&date={{date}}&idEvent={{id_alerta}}&idCamera={{idCamera}}",

		// Casos
		caseMonitorReport: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/supervise/{{profile_id}}?initDate={{initial_Date}}&finalDate={{final_Date}}',
		caseMonitorReportExport: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/supervise/report/{{REPORT_TYPE}}',
		caseResponse: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/cases_management/search/casesType/AUTOMATICO?count={{count}}&page={{page}}&initialDate={{initial_Date}}&finalDate={{final_Date}}&caseType={{type}}&caseConcessionaire={{concessionaire}}&caseBus={{bus}}',
		caseVideos: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/case_videos/list?concessionaire={{concessionaire}}&bus={{bus}}&idEvent={{idEvent}}',
		casePlayVideo: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/case_videos/videoByName?concessionaire={{concessionaire}}&name={{videoName}}',
		caseAttachDocument: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/official_response/upload/caseId/{manual_case_id}',
		caseManualSave: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/manual_case_association',
		caseAssociated: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/manual_case_association/case_management/{manual_case_id}',
		caseDownload: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/manual_case_association/case_management/{manual_case_id}/download',
		caseDownloadB64: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/manual_case_association/case_management/{manual_case_id}/downloadBase64',

		//tramos
		crearTramo: 'https://cdeg-stretch-dot-smart-helios-cgtm.appspot.com/stretch_config/',
		sentidoTramo: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/possible_answers/questionnaire/19/question/60',
		parametroTramos: 'https://cdeg-admin-dot-smart-helios-cgtm.appspot.com/possible_answers/questionnaire/19/question/61',
		buscarTramos: 'https://cdeg-stretch-dot-smart-helios-cgtm.appspot.com/stretches_config/search?',
		contarTramos: 'https://cdeg-stretch-dot-smart-helios-cgtm.appspot.com/stretches_config/count?',
		exportTramos: 'https://cdeg-stretch-dot-smart-helios-cgtm.appspot.com/stretches_config/export/',

		//mesa de ayuda
		Mesamodulos: 'https://cdeg-incidents-dot-smart-helios-cgtm.appspot.com/incidents/modules',
		Mesacategorias: 'https://cdeg-incidents-dot-smart-helios-cgtm.appspot.com/incidents/categories',
		Mesaprioridades: 'https://cdeg-incidents-dot-smart-helios-cgtm.appspot.com/incidents/priorities',
		Mesaestado: 'https://cdeg-incidents-dot-smart-helios-cgtm.appspot.com/incidents/states',

		// Disponibilidad usuario
		UserSetStatus: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user/{{user.name}}/available/{{status}}',
		UserStatus: 'https://cdeg-security-dot-smart-helios-cgtm.appspot.com/user/{{user.name}}',

		// incidentes
		incidentes: {
			sts: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/case/latest',
			userFiltered: 'https://cdeg-cases-dot-smart-helios-cgtm.appspot.com/case/latest/user'
		},

		panicButton:'https://get-panic-buttons-dot-smart-helios-cgtm.appspot.com/panic-buttons?limit=20',

		//Accidentes
		accidentsList:'https://get-panic-buttons-dot-smart-helios-cgtm.appspot.com/accidents?limit=20',


		videostream: {
			transformation: "https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/videostream/",
			getStreamURL: "registry/{registry_id}/bus/{bus_id}/camera/{camera_id}",
			execution: 'https://cdeg-videostreaming-dot-smart-helios-cgtm.appspot.com/image_feed?url=',
			recorder: 'https://cdeg-videostreaming-recorder-dot-smart-helios-cgtm.appspot.com/image_feed?',
			availability: 'https://videostreaming-availability-dot-smart-helios-cgtm-qa.uc.r.appspot.com/checkHealthyIp/?rtspURL='
		},

	    twitter: {
	    	trending: "https://us-central1-smart-helios-cgtm.cloudfunctions.net/get-trending-tweets", 
            frequency: "https://us-central1-smart-helios-cgtm.cloudfunctions.net/get-word-frequency-tweets", 
            historical: "https://us-central1-smart-helios-cgtm.cloudfunctions.net/get-historical-tweets",
            layer: "https://us-central1-smart-helios-cgtm.cloudfunctions.net/get-tweets-layer"
	    },

	    accidents: {
            historical: "https://us-central1-smart-helios-cgtm.cloudfunctions.net/get-historical-accidents",
            layer: "https://us-central1-smart-helios-cgtm.cloudfunctions.net/get-accidents-layer"
	    },

	    // Model utilities
	    modelUtils: {
	    	precipitation: "https://us-central1-smart-helios-cgtm.cloudfunctions.net/get-precipitation-values"
	    },

	    aggresiveDriver : {
	    	historicalLayer : 'https://us-central1-smart-helios-cgtm-qa.cloudfunctions.net/get-historical-layer-aggressive-drivers',
	    	historicalAD: 'https://us-central1-smart-helios-cgtm-qa.cloudfunctions.net/get-historical-aggressive-drivers'
	    },

		//Estaciones
		// estaciones: 'https://gis.transmilenio.gov.co/arcgis/rest/services/Troncal/consulta_esquemas_estaciones/FeatureServer/0/query?where=1=1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Kilometer&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=JSON',
		estaciones: 'https://gis.transmilenio.gov.co/arcgis/rest/services/Troncal/consulta_estaciones_vagones_puertas/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=pjson',
		crearEstacion: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/station/create',
		listEstaciones: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com//iot/station/list',
		editarEstacion: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/station/update',
		eliminarEstacion: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/station/delete',
		cargarEstacion: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/station/',
		estacionComplete: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/station/',
		
		//Puertas
		puertas: 'https://gis.transmilenio.gov.co/arcgis/rest/services/Troncal/consulta_estaciones_vagones_puertas/FeatureServer/5/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Kilometer&relationParam=&outFields=*&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnCentroid=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=pjson',
		
		//Dispositivos Puertas
		crearDispositivo: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/device/create',
		listDispositivos: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/device/?station=',
		eliminarDispositivo: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/device/',
		
		envioSTS:{
			urlConsecionarios: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/listRegistries',
			urlListBuses: 'https://iot-sts-skg-dot-smart-helios-cgtm.appspot.com/list_devices_by_all_registries',
			urlCodeMessage: 'https://iot-sts-skg-dot-smart-helios-cgtm.appspot.com/list_messages',
			urlSendMessage: 'https://iot-sts-skg-dot-smart-helios-cgtm.appspot.com/send_sts_message?concesionario_id=concesionarioSelected&busSelected=busesSelected&code=codeMesageSelected&message=messageInserted'

		},

		//Contratista
		adminRegistryCreateContratista: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/createContratistaRegistry',
		adminRegistriesContratista: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/listContratistas',
		adminRegistryContratistaGet: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/getRegistryContratista/',
		adminRegistryContratistaDelete: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/deleteContratista',
	    adminUserContratistas: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/listUserContratistas',
		adminContratistaEdit: 'https://cdeg-iot-manager-dot-smart-helios-cgtm.appspot.com/iot/editContratista',
		webConfig: "https://storage.googleapis.com/storage/v1/b/cdeg-web-config/o/config.json?alt=media"
	}
	
};