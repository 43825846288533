import { HttpService } from '../../../../../shared/services/http.service';
import { Response } from '@angular/http';
import { UIService } from '@app/views/trans/_services/UI.service';
import { environment } from '@environments/environment';

import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { Select2OptionData, Options } from 'select2';

@Component({
  selector: 'kt-form-ajcasos',
  templateUrl: './form-casos.component.html',
  styleUrls: ['./form-casos.component.scss']
})
export class FormCasosComponent implements OnInit {
  
  // Select2
  public selectUsuarios: Array<Select2OptionData>;
  public options: Options;

  loading: boolean;
  
  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  // DATOS pueden ser en un dto
  formModelCasos: {} = {
    usuario: '',
    cantidad: '',
  };

  conditionalLoader: boolean;

  @Input() casoManual: any;

  @Output() eventGuardar  = new EventEmitter();
  @Output() actionModificarAjCasos  = new EventEmitter();

  constructor(private http: HttpService,
              private uiService: UIService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    // Select2
    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: "select2-dropdown-forms"
    };
    
    // Usuarios por Perfil
    let ruta = `${environment.endpoints.adminUsersByRoleSearch}?username=&name=&lastName=&email=&entity=&available=&permissionList=118`;
    this.http.ejecutarServicioGet(ruta).subscribe((res: Response) => {
      switch (res.status) {
        case 200:
        case 201:
        case 204:
          let usuarios = res.json();
          let eliminarUSuario;
          for (let indexSta = 0; indexSta < usuarios.length; indexSta++) {
            usuarios[indexSta]['id'] = usuarios[indexSta]['username'];
            usuarios[indexSta]['text'] = usuarios[indexSta]['username'];
            if( usuarios[indexSta]['username'] == this.casoManual['user']){
              eliminarUSuario = indexSta;
            }

            if ( indexSta === usuarios.length - 1 ) {
              usuarios.unshift( {id:"-Sel", text: "Seleccionar Usuario"} );
              usuarios.splice(eliminarUSuario+1, 1);
              this.selectUsuarios = usuarios;

              break;
            }
          }
          this.cdr.markForCheck();
          break;
        case 401:
          // nothing
          break;
        default:
          this.showError(res);
          break;
      }
    }, (error: Response) => {
      this.showError(error);
    });// END GET

    // selectUsuarios
    // this.selectUsuarios = [
    //   {id:"-Sel", text: "Seleccionar Usuario"},
    //   {id:"1", text: "Usuario 1"},
    //   {id:"2", text: "Usuario 2"}
    // ]

  }

  validateForm( event ) {
    setTimeout( () => {
      this.formModelCasos['cantidad'] =  this.formModelCasos['cantidad'].replace(/[^0-9]/g, ' ').replace(' ' , '').trim();
      this.cdr.markForCheck();
    } , 100);
  }

  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage( response.json()['message'] );
    } else {
      this.uiService.showMessage( 'Error del servidor' );
    }
    this.cdr.markForCheck();
  }

  guardarParametros(){
    if(!this.formModelCasos['usuario']){
      // Error
      this.uiService.showMessage( 'Debes escoger el usuario a asignar' );
      return true;
    }
    
    if ( Number(this.formModelCasos['cantidad']) <= Number(this.casoManual['totalCase']) ){
    }else{
      // Error
      this.uiService.showMessage( 'La cantidad no puede ser mayor al número de casos asignados actualmente' );
      return true;
    }
  
    // Servicio Guardar
    this.loading = true;
    let casoManualSend = {
      currentUsername: this.casoManual['user'],
      usernameToAsign: this.formModelCasos['usuario'],
      casesToAsign: this.formModelCasos['cantidad'],
      caseTypeToAsign: this.casoManual['caseType']
    }
    this.http.ejecutarServicioPut( environment.endpoints.adminCasesSave, casoManualSend ).subscribe( (res: Response) => {        
      switch ( res.status ) {
        case 200:
        case 201:
        case 204:
          // Mostrar Modalre
          this.mostrarModal();
          this.loading = false;
          break;
        
        default:
          this.loading = false;
          this.showError(res);
          break;
      }
    }, (error: Response) => {
      this.loading = false;
      this.showError( error );
    });// END POST

  }

  mostrarModal( ){
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-guardar',
      titulo: 'Los datos han sido guardados',
      textos: [
            //'Los datos ingresados han sido guardados con éxito'
            'Se reasignaron correctamente los casos al usuario '+this.formModelCasos['usuario']
      ],
      botones: [
              {
                label: 'Ok',
                class: '',
                accion: 'guardarAjCasos'
              }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModal = -1;
      this.configModal = { status: -1 }
    }, 300);
  }

  changeStatusModal( event ){
    switch (event) {
      case 'guardarAjCasos':
        // Limpiar, si es un dto se instancia de nuevo la variable
        this.formModelCasos = {
          usuario: '-Sel',
          cantidad: ''
        };
        // Refrescar Datos
        this.actionModificarAjCasos.emit( 'guardarAjCasos' );
        // EMIT al PADRE
        break;

      default:
        // EMIT al PADRE
        this.actionModificarAjCasos.emit( 'guardarAjCasos' );
        break;
    }
  }

}
