import { UIService } from './../../../_services/UI.service';
import { Response } from '@angular/http';
import { environment } from './../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from './../../../../shared/services/http.service';
import { Component, Output, EventEmitter, OnInit } from '@angular/core';

import { Select2OptionData, Options } from 'select2';

@Component({
    selector: 'kt-form-creargrupo',
    templateUrl: './form-creargrupo.component.html',
    styleUrls: []
})
export class FormCrearGrupoComponent implements OnInit {
    // Select2
    conditionalLoader: boolean;
    selectPlantillas: Array<Select2OptionData>;
    options: Options;
    selectedTemplate: any;
    params = {
        registry: '',
        name: '',
        template: '',
    };

    @Output() actionCrearGrupo  = new EventEmitter();

    constructor( private http: HttpService,
                 private _route: ActivatedRoute,
                 private ui: UIService ) {
        this.params.registry = this._route.snapshot.paramMap.get('registryId');
        this.selectPlantillas = [];
        this.options = {
    			language: 'es',
    			closeOnSelect: true,
         	dropdownCssClass: 'select2-dropdown-forms'
        };

    }

    ngOnInit(): void {
        const url = environment.endpoints.adminRegistryTemplatesGroup + '/' + this.params.registry;
        this.http.ejecutarServicioGet( url ).subscribe( (res: Response) => {
            this.selectPlantillas = res.json();
        } , (error) => {
            this.ui.showMessage( error.message );
        });
    }

    crearGrupo() {
        const url = environment.endpoints.adminRegistryTemplateGroupFormCreate;
        this.conditionalLoader = true;
        this.http.ejecutarServicioPost( url, this.params ).subscribe( (res: Response) => {
            this.ui.showMessage( 'Grupo creado con éxito' );
            this.cerrarModal();
        } , (error) => {
            this.ui.showMessage( error.message );
        });
    }

    cerrarModal() {
        this.conditionalLoader = false;
        this.actionCrearGrupo.emit('cerrar');
    }

    changeStatusModal(event) {
        // if( this.modificarDatos && this.modificarDatos.registryId ){
        //     // Modificar
        //     this.actionCrearGrupo.emit('cerrar');
        // }else{
        //     // Crear
        //     this.formModelCategoria = new AdminCategoriaDto;
        // }
    }
}
