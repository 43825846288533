import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { AdminUserDTO } from '../../../../shared/dto/admin-user-dto';
import { UIService } from '../../../_services/UI.service';
import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { UtilService } from "../../../../trans/administrador/_services/util.service";
import { MatSnackBarRef } from '@angular/material';
import { LayoutUtilsService, MessageType } from './../../../../../core/_base/crud/utils/layout-utils.service';

@Component({
  selector: 'kt-crear-tramo-popup',
  templateUrl: './tramos-crear-popup.component.html',
  styleUrls: ['./tramos-crear-popup.component.scss']
})
export class CrearTramoPopUpComponent implements OnInit {

  formModelUser: AdminUserDTO = new AdminUserDTO();

  @Input() verDatos: any;

  @Output() actionModificarUser = new EventEmitter();
  // Modales
  contentToShow: number = -1;
  nombre: string;
  selectSentidoTramo: Array<Select2OptionData>;
  public options: Options;
  sentidotramo = "All";
  datosParametro: {};

  contentToShowModalRango: number = -1;

  baseUrl = environment.endpoints.parent;

  configTabla: {} = { status: -1 };

  contentToShow2: number = -1;
  configTabla2: {} = { status: -1 };

  conditionalLoader: boolean = false;
  loading: boolean = false;

  configModalRango: {} = { status: -1 };

  data: any;

  contentToShowFormModal: number = -1;
  lastRequest: any;

  private _actualDialog: MatSnackBarRef<any>;

  constructor(
    private cdr: ChangeDetectorRef,
    private uiService: UIService,
    private layoutUtilsService: LayoutUtilsService,
    private http: HttpService
  ) {
    UtilService.editParametroTroncal.subscribe(data => {

      this.data.map(parametro => {
        if (parametro.id == data.id) {
          parametro.valor = data.valor;

        }
      });
      this.procesarJson();
    });

    let urlSentidoTroncales = environment.endpoints.sentidoTramo;
    this.http.ejecutarServicioGet(urlSentidoTroncales).subscribe(res => {
      const self = this;
      self.selectSentidoTramo = Array<Select2OptionData>();
      if (res.json().length > 0) {
        let data = res.json();
        let selectTypes = data.map(i => {
          return { id: i['answerValue'].toString(), text: i['answer'] };
        });
        selectTypes.unshift({ id: 'All', text: 'Seleccione' });
        this.selectSentidoTramo = selectTypes;
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    let urlParametroTroncales = environment.endpoints.parametroTramos;
    this.http.ejecutarServicioGet(urlParametroTroncales).subscribe(res => {

      if (res.json().length > 0) {
        let data = res.json();
        let parametersTypes = data.map(i => {
          return { "id": i['id'].toString(), "parametro": i['answer'].toString(), "valor": "" };
        });

        this.data = parametersTypes;
        this.procesarJson();
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });
  }



  ngOnInit() {
    // Si trae parametros para EDITAR
    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: 'select2-dropdown-forms',
      allowClear: true,
    };

  }

  showError(response) {

    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage(response.json()['message']);
    } else {
      this.uiService.showMessage('Error del servidor');
    }
    this.loading = false;
    this.cdr.markForCheck();

  }

  cerrarModal() {
    this.actionModificarUser.emit('cerrar');
  }

  guardarTramo() {

    let urlGetReport = environment.endpoints.crearTramo;
    let params = [];
    if (this.nombre === undefined || this.nombre === "") {

      this.uiService.showMessage("El nombre del tramo es requerido.");
      this.cdr.markForCheck();

    }
    else if (this.sentidotramo === "All") {

      this.uiService.showMessage("Por favor seleccione el sentido del tramo.");
      this.cdr.markForCheck();

    } else {
      this.data.map(p => {
        if (p.valor !== undefined && p.valor !== "") {
          params.push({
            "name": p.parametro,
            "value": p.valor
          });
        }
      });
      this.lastRequest = {
        "name": this.nombre,
        "direction": this.sentidotramo,
        'trunkLine': this.verDatos.nombreTroncal,
        "linestream": JSON.stringify(this.verDatos.tramo.geometry.coordinates),
        "params": params
      }
      this.conditionalLoader = true;
      this.http.ejecutarServicioPost(urlGetReport, this.lastRequest).subscribe(res => {
        this.conditionalLoader = false;
        let responseMessage = {
          title: "",
          message: ""
        };
        if (res.status === 200) {
          //this.limpiar();
          responseMessage.title = "Tramo Creado Exitosamente";
          responseMessage.message = "Los datos del tramo han sido creados con exito"
          this.operacionExitosa(responseMessage);
        }
        else {
          responseMessage.title = "HA OCURRIDO UN ERROR AL CREAR EL TRAMO";
          responseMessage.message = "No se pudo crear el tramo"
          this.operacionExitosa(responseMessage);
        }
      }, (error: Response) => {
        if (this._actualDialog) { this._actualDialog.dismiss(); }
        const message = 'Error cargando datos ';
        this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
      });
    }
  }

  operacionExitosa(responseMessage) {
    this.contentToShowFormModal = -1;
    this.configModalRango = {
      status: 1,
      icono: 'smart-icon-notificaciones',
      titulo: responseMessage.title,
      textos: [
        responseMessage.message
      ],
      botones: [
        {
          label: 'Ok',
          class: '',
          accion: 'Ok'
        }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModalRango = -1;
      this.configModalRango = { status: -1 };
    }, 300);
  }

  changeStatusModal(event) {
    this.contentToShowFormModal = -1;
    this.cdr.markForCheck();
  }

  procesarJson() {


    let dataArray = [];
    for (let index = 0; index < this.data.length; index++) {
      dataArray.push([
        this.data[index]['id'].toString(),
        this.data[index]['parametro'].toString(),
        this.data[index]['valor'].toString(),
        ''
      ]);

      if (index === this.data.length - 1) {
        this.armarTabla(dataArray);
      }
    }

    if (!this.data.length) {
      this.armarTabla(dataArray);
    }
  }

  armarTabla(data) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Parámetro', 'Valor', 'Acciones'],
      typeColumn: [, , , { tipo: 'acciones', acciones: ['editar'] }],
      searchColumn: [0, 1],
      hideColumns: [0],
      showPag: false,
      showExport: false,
      configColumns: {
        id: 0,
        columnClick: 0,
      },
      data: data
    };
    this.loading = false;
    this.cdr.markForCheck();
  }

  editarElemento(event) {

    this.data.map(parametro => {
      if (parametro.id == event) {
        this.datosParametro = {
          "id": parametro.id,
          "nombre": parametro.parametro,
          "valor": parametro.valor
        }
      }
    });
    this.contentToShowFormModal = 25;
    this.cdr.markForCheck();

  }

  statusModal(event) {
    setTimeout(() => {
      this.contentToShowFormModal = -1;
      this.cdr.markForCheck();
    }, 300);
  }

}
