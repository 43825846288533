import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdministradorComponent } from './administrador.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { DispositivosGruposComponent } from './categorias/dispositivos-grupos/dispositivos-grupos.component';
import { DispositivosDetalleComponent } from './categorias/dispositivos-grupos/dispositivos-detalle/dispositivos-detalle.component';
import { DispositivosFormularioComponent } from './categorias/dispositivos-grupos/dispositivos-formulario/dispositivos-formulario.component';
import { DatosAdicionalesComponent } from '../../shared/components/datos-adicionales/datos-adicionales.component';
import { AsociarComponent } from './categorias/asociar/asociar.component';
import { NavegacionComponent } from './navegacion/navegacion.component';
import { FormularioModalComponent } from '../../shared/components/formularios/formulario-modal.component';
import { UsuarioBuscarComponent } from './usuarios/usuario-buscar/usuario-buscar.component';
import { UsuarioCrearComponent } from './usuarios/usuario-crear/usuario-crear.component';
import { UsuarioOffComponent } from './usuarios/usuario-off/usuario-off.component';
import { ConductorCrearComponent } from './conductor/conductor-crear/conductor-crear.component';
import { ConductorBuscarComponent } from './conductor/conductor-buscar/conductor-buscar.component';
import { ConfiguracionCorreosComponent } from './configuracion-correos/configuracion-correos.component';
import { FormCorreosComponent } from './configuracion-correos/form-correos/form-correos.component';
import { ConductorRegistroLicenciasComponent } from './conductor/conductor-registro-licencias/conductor-registro-licencias.component';
import { ConductorRegistroCarnetsComponent } from './conductor/conductor-registro-carnets/conductor-registro-carnets.component';
import { ConductorIngresoComponent } from './conductor/conductor-ingreso/conductor-ingreso.component';
import { ConductorRegistroCapacitacionesComponent } from './conductor/conductor-registro-capacitaciones/conductor-registro-capacitaciones.component';
import { ConductorRegistroNotasComponent } from './conductor/conductor-registro-notas/conductor-registro-notas.component';
import { CargaMasivaComponent } from './conductor/carga-masiva/carga-masiva.component';
import { TramosBuscarComponent } from './tramos/tramos-buscar/tramos-buscar.component';
import { TramosCrearComponent } from './tramos/tramos-crear/tramos-crear.component';
import { PerfilBuscarComponent } from './perfil/perfil-buscar/perfil-buscar.component';
import { PerfilCrearComponent } from './perfil/perfil-crear/perfil-crear.component';
import { RolBuscarComponent } from './rol/rol-buscar/rol-buscar.component';
import { RolCrearComponent } from './rol/rol-crear/rol-crear.component';
import { GestionManualComponent } from './gestion/gestion-manual/gestion-manual.component';
import { AlarmaComponent } from './alarma/alarma.component';
import { BuscarAlarmaComponent } from './alarma/buscar-alarma/buscar-alarma.component';
import { ConductorAgresivoComponent } from './parametros/conductor-agresivo/conductor-agresivo.component';
import { ClusteringComponent } from './parametros/clustering/clustering.component';
import { MantenimientoPreventivoComponent } from './parametros/mantenimiento-preventivo/mantenimiento-preventivo.component';
import { EventosRedesComponent } from './parametros/eventos-redes/eventos-redes.component';
import { CrearIncidenteComponent } from './incidentes/crear-incidente/crear-incidente.component';
import { GestionIncidenteComponent } from './incidentes/gestion-incidente/gestion-incidente.component';
import { BuscarIncidenteComponent } from './incidentes/buscar-incidente/buscar-incidente.component';
import { RecibirCasosComponent } from './gestion/recibir-casos/recibir-casos.component';
import { GestionarCasoComponent } from './gestion/recibir-casos/gestionar-caso/gestionar-caso.component';
import { SupervisarCasoComponent } from './gestion/supervisar-caso/supervisar-caso.component';
import { AjustarCasosComponent } from './gestion/ajustar-casos/ajustar-casos.component';
import { ResponderCasoManualComponent } from './gestion/responder-caso-manual/responder-caso-manual.component';
import { FormGrupoComponent } from './categorias/form-grupo/form-grupo.component';
import { DeteccionBloqueosComponent } from './parametros/deteccion-bloqueos/deteccion-bloqueos.component';
import { MovilDetenidoComponent } from './parametros/movil-detenido/movil-detenido.component';
import { AnalisisPredectivoComponent } from './parametros/analisis-predectivo/analisis-predectivo.component';
import { DeteccionIdlingComponent } from './parametros/deteccion-idling/deteccion-idling.component';
import { ConductorComponent } from './conductor/conductor.component';
import Reportes, { Components as ReportesComponents } from '@admin/reportes/index';
import { EditarIndicadoresComponent } from './indicadores/editar-indicadores/editar-indicadores.component';
import { DefinirIndicadoresRolComponent } from './indicadores/definir-indicadores-rol/definir-indicadores-rol.component';
import { DefinirIndicadoresPerfilComponent } from './indicadores/definir-indicadores-perfil/definir-indicadores-perfil.component';
import { ImagenCorporativaComponent } from './imagen-corporativa/imagen-corporativa.component';
import { EnvioStsComponent } from './envio-sts/envio-sts.component';
import { CrearEstacionComponent } from './puertas/crear-estacion/crear-estacion.component';
import { CrearContratistaComponent } from './puertas/crear-contratista/crear-contratista.component';
import { DispositivosComponent } from './puertas/dispositivos/dispositivos.component';
import { AsociarPuertasComponent } from './puertas/asociar-puertas/asociar-puertas.component'
import { GenerarEstacionComponent } from "./puertas/crear-estacion/generar-estacion/generar-estacion/generar-estacion.component";

const routes: Routes = [
	{
		path: '',
		component: AdministradorComponent,
		children: [
			{
				path: '',
				redirectTo: 'navegacion',
				pathMatch: 'full'
			},
			{
				path: 'usuario/buscar',
				component: UsuarioBuscarComponent,
			},
			{
				path: 'usuario/crear',
				component: UsuarioCrearComponent,
				data: { titulo: 'Crear Usuario' }
			},
			{
				path: 'usuario/modificar',
				component: UsuarioCrearComponent,
			},
			{
				path: 'usuario/modificar',
				component: UsuarioOffComponent,
			},
			{
				path: 'conductor',
				component: ConductorComponent,
			},
			// {
			// 	path: 'conductor/:id',
			// 	component: ConductorComponent,
			// },
			{
				path: 'conductor/crear',
				component: ConductorCrearComponent,
			},
			{
				path: 'conductor/modificar/:type/:id',
				component: ConductorComponent,
			},
			{
				path: 'correos',
				component: ConfiguracionCorreosComponent
			},
			{
				path: 'correos',
				component: FormCorreosComponent,
			},
			{
				path: 'conductor/buscar',
				component: ConductorBuscarComponent,
			},
			{
				path: 'conductor/ingreso',
				component: ConductorIngresoComponent,
			},
			{
				path: 'conductor/registro-licencias',
				component: ConductorRegistroLicenciasComponent,
			},
			{
				path: 'conductor/registro-carnets',
				component: ConductorRegistroCarnetsComponent,
			},
			{
				path: 'conductor/registro-capacitaciones',
				component: ConductorRegistroCapacitacionesComponent,
			},
			{
				path: 'conductor/registro-notas',
				component: ConductorRegistroNotasComponent,
			},
			{
				path: 'conductor/carga-masiva',
				component: CargaMasivaComponent,
			},
			{
				path: 'tramos/buscar',
				component: TramosBuscarComponent,
			},
			{
				path: 'tramos/crear/:idTramo',
				component: TramosCrearComponent,
			},
			{
				path: 'perfil/buscar',
				component: PerfilBuscarComponent,
			},
			{
				path: 'perfil/crear',
				component: PerfilCrearComponent,
			},
			{
				path: 'rol/buscar',
				component: RolBuscarComponent,
			},
			{
				path: 'rol/crear',
				component: RolCrearComponent,
			},
			{
				path: 'gestion/manual',
				component: GestionManualComponent,
			},
			{
				path: 'gestion/recibir',
				component: RecibirCasosComponent,
			},
			{
				path: 'gestion/ajustar',
				component: AjustarCasosComponent,
			},
			{
				path: 'gestion/recibir/gestionar/:idCase',
				component: GestionarCasoComponent,
			},
			{
				path: 'gestion/supervisar',
				component: SupervisarCasoComponent,
			},
			{
				path: 'gestion/responder/:idCase',
				component: ResponderCasoManualComponent,
			},
			{
				path: 'registry/tipo1',
				component: CategoriasComponent,
				data: {
					tipo: 1
				}
			},
			{
				path: 'registry/tipo2',
				component: CategoriasComponent,
				data: {
					tipo: 2
				}
			},
			{
				path: 'registry/tipo2/groups/:registryId',
				component: DispositivosGruposComponent
			},
			{
				path: 'registry/tipo2/groups/:registryId/create',
				component: DispositivosFormularioComponent
			},
			{
				path: 'registry/tipo2/groups/:registryId/:registryGroup/asociar',
				component: AsociarComponent,
				data: {
					form: 2
				}
			},
			{
				path: 'registry/tipo2/groups/:registryId/:registryGroup/editar',
				component: FormGrupoComponent
			},
			{
				path: 'registry/tipo2/groups/:registryId/:registryGroup/detalle',
				component: DispositivosDetalleComponent
			},
			{
				path: 'parametros/conductor-agresivo',
				component: ConductorAgresivoComponent
			},
			{
				path: 'parametros/clustering',
				component: ClusteringComponent
			},
			{
				path: 'parametros/mantenimiento-preventivo',
				component: MantenimientoPreventivoComponent
			},
			{
				path: 'parametros/movil-detenido',
				component: MovilDetenidoComponent
			},
			{
				path: 'parametros/analisis-predectivo',
				component: AnalisisPredectivoComponent
			},
			{
				path: 'parametros/deteccion-idling',
				component: DeteccionIdlingComponent
			},
			{
				path: 'parametros/eventos-redes',
				component: EventosRedesComponent
			},
			{
				path: 'parametros/deteccion-bloqueos',
				component: DeteccionBloqueosComponent
			},
			{
				path: 'incidentes/crear',
				component: CrearIncidenteComponent
			},
			{
				path: 'incidente/:id',
				component: GestionIncidenteComponent
			},
			{
				path: 'incidentes/gestion',
				component: GestionIncidenteComponent
			},
			{
				path: 'incidentes/buscar',
				component: BuscarIncidenteComponent
			},
			{
				path: 'navegacion',
				component: NavegacionComponent,
			},
			{
				path: 'navegacion/:item',
				component: NavegacionComponent,
			},
			// {
			// 	path: 'navegacion/:item/:child',
			// 	component: NavegacionComponent,
			// },
			{
				path: 'formularios',
				component: FormularioModalComponent,
			},
			{
				path: 'categorias',
				component: CategoriasComponent
			},
			{
				path: 'categorias/operador/grupos-dispositivos',
				component: DispositivosGruposComponent,
			},
			{
				path: 'datos-adicionales',
				component: DatosAdicionalesComponent,
			},
			{
				path: 'categorias/operador/grupos-dispositivos/crear',
				component: DispositivosFormularioComponent,
			},
			{
				path: 'categorias/operador/asociar',
				component: AsociarComponent,
			},
			{
				path: 'alarma',
				component: AlarmaComponent,
			},
			{
				path: 'alarma/buscar-alarma',
				component: BuscarAlarmaComponent,
			},
			{
				path: 'reportes',
				children: [
					{ path: 'boton-panico', component: Reportes.EstadisticosComponent },
					{ path: 'dano-vandalismo', component: Reportes.ReporteDanoVandalismoComponent },
					{ path: 'incidentes-seguridad', component: Reportes.ReporteIncidentesSeguridadComponent },
					{ path: 'sensores-motor', component: Reportes.ReporteSensoresMotorComponent },
					{ path: 'sensores-peso', component: Reportes.ReporteSensoresPesoComponent },
					{ path: 'sensores-umbrales', component: Reportes.ReporteSensoresUmbralesComponent },
					{ path: 'sensores-umbrales-peso', component: Reportes.ReporteSensoresUmbralesPesoComponent },
					{ path: 'validacion-conductor', component: Reportes.ReporteValidacionConductorComponent },
					{ path: 'validaciones-incorrectas-conductor', component: Reportes.ReporteValidacionesIncorrectasConductorComponent },
					{ path: 'recaudo-pago', component: Reportes.ReporteRecaudoPagoComponent },
				]
			},
			{
				path: 'indicadores/editar',
				component: EditarIndicadoresComponent,
			},
			{
				path: 'indicadores/definir-rol',
				component: DefinirIndicadoresRolComponent,
			},
			{
				path: 'indicadores/definir-perfil',
				component: DefinirIndicadoresPerfilComponent,
			},
			{
				path: 'configurar/imagen-corporativa',
				component: ImagenCorporativaComponent,
			},
			{
				path: 'enviar-mensaje-sts',
				component: EnvioStsComponent
			},
			{
				path: 'puertas/crear-estacion',
				component: CrearEstacionComponent
			},
			{
				path: 'puertas/crear-contratista',
				component: CrearContratistaComponent
			},
			{
				path: 'puertas/dispositivos/:idContratista/:idPuerta',
				component: DispositivosComponent
			},
			{
				path: 'puertas/asociar-puertas/:idContratista/:idPuerta',
				component: AsociarPuertasComponent
			},
			{
				path: 'puertas/generar-estacion',
				component: GenerarEstacionComponent
			},
			{
				path: 'puertas/generar-estacion/:idContratista/:idPuerta',
				component: GenerarEstacionComponent
			},
			// {
			// 	path: '**',
			// 	redirectTo: 'navegacion',
			// 	pathMatch: 'full'
			// },
		]
	}
];
export const PagesRouting = RouterModule.forChild( routes );
