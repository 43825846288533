import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {NgbDateStruct, NgbCalendar, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData, Options } from 'select2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {HttpService} from '../../../../shared/services/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UIService} from './../../../../trans/_services/UI.service';
import {environment} from './../../../../../../environments/environment';
import {Response} from '@angular/http';

import { AdminGestionCrearDto } from '../../../../shared/dto/admin-gestion-crear-dto';
import { AdminGestionFilterDto } from '../../../../shared/dto/admin-gestion-filter-dto';

/**
 * Clase que sirve de interface para controlar el documento a subir
 */
class DocumentSnippet {
	pending: boolean = false;
	status: string = 'init';

	constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'kt-responder-caso-manual',
  templateUrl: './responder-caso-manual.component.html',
  styleUrls:  ['./responder-caso-manual.component.scss'],
  host: {'class': 'sths-tb-element'}
})
export class ResponderCasoManualComponent implements OnInit {
  public selectFitro1: Array<Select2OptionData>;

  // Fecha
  sthsQSModel1: NgbDateStruct;
  sthsQSModel2: NgbDateStruct;
  sthsQSModel3: NgbDateStruct;
  sthsQSModel4: NgbDateStruct;
  sthsQSTime = {hour: 8, minute: 10};
  sthsQSTime2 = {hour: 14, minute: 0};
  public options: Options;

  // Paginador
  paginador: Object = {
    count: 15,
    page: 1,
    maxPages: 5
  };
  
  // Tabla
  configTable: Object;
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };
  
  conditionalLoader: boolean;

  idCasoRes: string;

  /*
   * Id del caso por rol
   */
  idCaso: string;
  //
  contentToForm: number = -1;
  
  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  // TablaRespuesta
  contentToShowRes: number = -1;
  configTablaRes: {} = { status: -1, data: [] };
  
  conditionalLoaderRes: boolean;

  // data View
  formGestionManual: AdminGestionCrearDto = new AdminGestionCrearDto;
  dateEvent : any;

  public selectConcesionarios: Array<Select2OptionData>;
  public selectBuses: Array<Select2OptionData>;
  public selectTypes: Array<Select2OptionData>;

  formModelGestion: AdminGestionFilterDto = new AdminGestionFilterDto;
  
  // Filtros, Columnas, Actions
  actions: {} = {
    verCaso: '',
    videos: '',
    adjuntar: ''
  };

  dataArray = [];
  dataRecords = [];
  dataVideos = [];

  /*
   * Registro seleccionado para las acciones de la tabla principal
   */
  record: any;

  /*
   * Llena los registros de la tabla Casos/Videos Adjuntos
   */
  dataCasos = [];
  /*
   * Llena la tabla Casos/Videos Adjuntos
   */
  dataCasosTable = [];

  /*
   * Registro a eliminar de la tabla Casos/Videos Adjuntos
   */
  idEliminar: number = 0;

  /*
   * Carga la ventana modal para listar vídeos
   */
  showModal: boolean = false;

  /*
   * Tabla de Vídeos que se carga en el modal
   */
  contentToShowVideo: number = -1;
  configTablaVideos: {} = { status: -1, data: [] };

  /*
   * Vídeo a tocar
   */
  sources: Array<Object>;

  /*
   * Necesaria para agregar en el WS del vídeo
   */
  concessionaire: string;
      
  /*
   * Controla el documento a cargar
   */
  public selectedFile: DocumentSnippet;
  
  /*
   * Nombre del documento retornado por el ws de upload
   */
  officialResponseName: string = "";

  /*
   * Url del documento retornado por el ws de upload
   */
  officialResponseUrl: string = "";

  /*
   * Descripción del caso a adjuntar
   */ 
  descriptionCase: string = "";

  /*
   * Si un caso tiene casos/vídeos asociados
   */
  hasCases: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private http: HttpService,
    private uiService: UIService,
    private router: Router
    ) { 

    this.idCaso = this._route.snapshot.paramMap.get('idCase');
  }

  ngOnInit() {
    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: "select2-dropdown-forms"
    };

    this.http
      .ejecutarServicioGet(environment.endpoints.adminCasesManagementId + this.idCaso, null)
      .subscribe((res: Response) => {
        let dataArray = [];
        switch (res.status) {
          case 200:
          case 201:
          case 204:
            let response = res.json();
            
            this.idCasoRes = response.case_id;

            this.formGestionManual = new AdminGestionCrearDto(res.json());

            this.dateEvent = (response.casesObj.eventOccurrenceDate!=undefined?response.casesObj.eventOccurrenceDate.substr(0, 10):'');
            this.dateEvent = this.dateEvent.split("-");
            this.dateEvent = this.dateEvent[2] +"-"+this.dateEvent[1]+"-"+this.dateEvent[0];

            this.formGestionManual.fechaHecho = (response.casesObj.eventOccurrenceDate!=undefined?response.casesObj.eventOccurrenceDate.substr(0, 10):'');
            this.formGestionManual.horaHecho = (response.casesObj.eventOccurrenceDate!=undefined?response.casesObj.eventOccurrenceDate.substr(11, 5):'');

            let hourEvent = this.formGestionManual.horaHecho .split(":");
            
            this.sthsQSTime = { hour : parseInt(hourEvent[0]), minute:parseInt(hourEvent[1])};

            this.descriptionCase = response.casesObj.responseDescription;
            this.officialResponseName = (response.casesObj.officialResponseName!=undefined?response.casesObj.officialResponseName:'');
            this.officialResponseUrl = (response.casesObj.officialResponse!=undefined?response.casesObj.officialResponse:'');

            /*
             * Si hay casos y vídeos adjuntos, se debe consumir el endpoint 
             * correspondiente, para llenar la tabla Casos/Videos Adjuntos
             */
            this.hasCases = response.casesObj.haveAssociatedCases;
            if (this.hasCases) {
                let ruta = environment.endpoints.caseAssociated.replace('{manual_case_id}', this.idCaso);
                this.http
                    .ejecutarServicioGet(ruta, null)
                    .subscribe((resCases: Response) => {
                      let dataArray = [];
                      switch (resCases.status) {
                        case 200:
                        case 201:
                        case 204:
                          let resAssocCase = resCases.json();

                          for (let index=0;index<resAssocCase.manualCasesAssoc.length;index++) {
                            this.dataCasosTable.push(
                              { 
                                id: resAssocCase.manualCasesAssoc[index].caseAssociatedId, 
                                nombre: resAssocCase.manualCasesAssoc[index].caseAssociatedId, 
                                estado: resAssocCase.manualCasesAssoc[index].caseAssociatedStatus, 
                                tipo: resAssocCase.manualCasesAssoc[index].caseAssociatedType 
                              }
                            );
                          }
                          
                          for (let index=0;index<resAssocCase.manualVideosAssoc.length;index++) {
                            this.dataCasosTable.push(
                              { 
                                id: resAssocCase.manualVideosAssoc[index].videoName, 
                                nombre: resAssocCase.manualVideosAssoc[index].videoName, 
                                estado: resAssocCase.manualVideosAssoc[index].caseAssociatedStatus, 
                                tipo: resAssocCase.manualVideosAssoc[index].caseAssociatedType 
                              }
                            );
                          }
                          
                          setTimeout(() => {
                            this.conditionalLoader = false;
                            this.procesarJsonRes(this.dataCasosTable);
                          }, 400);
                      
                          this.cdr.markForCheck();

                          break;
                        default:
                          this.showError(res);
                          break;
                      }
                    }, (error: Response) => {
                      this.showError(error);
                    }
                );

            }
            break;
          default:
            this.showError(res);
            break;
        }
      }, (error: Response) => {
        this.showError(error);
      }
    );

    // Tipo de Casos
    this.http.ejecutarServicioGet( environment.endpoints.adminCasesTypes, null ).subscribe( (resCases: Response) => {
      switch (resCases.status) {
        case 200:
        case 201:
        case 204:
          let typesCase = resCases.json();
          for (let indexType = 0; indexType < typesCase.length; indexType++) {
            typesCase[indexType]['text'] = typesCase[indexType]['answer'];
            typesCase[indexType]['id'] = typesCase[indexType]['answer'];
            if ( indexType === typesCase.length - 1 ) {
              typesCase.unshift( {id:"-Sel", text: "Seleccionar Tipo de Caso"} );
              this.selectTypes = typesCase;
              break;
            }
          }
          this.cdr.markForCheck();
          break;
        default:
            this.showError( resCases );
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    })

    // Concesionarios
    this.http.ejecutarServicioGet( environment.endpoints.adminRegistries, null ).subscribe( (resRegistry: Response) => {
      switch (resRegistry.status) {
        case 200:
        case 201:
        case 204:
          let registry = resRegistry.json();
          for (let indexReg = 0; indexReg < registry.length; indexReg++) {
            registry[indexReg]['text'] = registry[indexReg]['id'];
            if ( indexReg === registry.length - 1 ) {
              registry.unshift( {id:"-Sel", text: "Seleccionar Concesionario"} );
              this.selectConcesionarios = registry;
              break;
            }
          }
          this.cdr.markForCheck();
          break;
        default:
            this.showError( resRegistry );
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    })
  }

  updateBuses(event) {
    // Buses
    this.http.ejecutarServicioGet( `${environment.endpoints.adminRegistryGroup}?registry=`+event['value'], null ).subscribe( (resBuses: Response) => {
      switch (resBuses.status) {
        case 200:
        case 201:
        case 204:
          if (resBuses.json().hasOwnProperty('groups')) {
            let buses = resBuses.json().groups;
            // se valida que vengan registros en el WS
            if (buses.length>0) {
              for (let indexBus = 0; indexBus < buses.length; indexBus++) {
                buses[indexBus]['id'] = buses[indexBus]['name'];
                buses[indexBus]['text'] = buses[indexBus]['name'];
                if ( indexBus === buses.length - 1 ) {
                  buses.unshift( { id:"-Sel", text: "Seleccionar Bus"} );
                  this.selectBuses = buses;
                  break;
                }
              }
            }
            this.cdr.markForCheck();
          }
          break;
        default:
            this.showError( resBuses );
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    })
  }  

  buscarResponder(){
    let final_date = "";
    let initial_date = "";
    if (this.formModelGestion.fechaCreacion!=null) {
      initial_date = this.formModelGestion.fechaCreacion.day.toString().padStart(2, "0")+"-"+
                        this.formModelGestion.fechaCreacion.month.toString().padStart(2, "0")+"-"+
                        this.formModelGestion.fechaCreacion.year+" "+
                        this.formModelGestion.horaCreacion.hour.toString().padStart(2, "0")+":"+
                        this.formModelGestion.horaCreacion.minute.toString().padStart(2, "0")+":00";
    }

    if (this.formModelGestion.fechaFinal!=null) {
      final_date = this.formModelGestion.fechaFinal.day.toString().padStart(2, "0")+"-"+
                      this.formModelGestion.fechaFinal.month.toString().padStart(2, "0")+"-"+
                      this.formModelGestion.fechaFinal.year+" "+
                      this.formModelGestion.horaFinal.hour.toString().padStart(2, "0")+":"+
                      this.formModelGestion.horaFinal.minute.toString().padStart(2, "0")+":00";
    }

    this.conditionalLoader = true;
    let ruta = environment.endpoints.caseResponse
                          .replace('{{count}}', '10')
                          .replace('{{page}}', '1');


    // Se validan que los campos no lleguen vacios para agregarlos al queryparam
    if (initial_date!="") {
      ruta = ruta.replace('{{initial_Date}}', initial_date);
    } else {
      ruta = ruta.replace('&initialDate={{initial_Date}}', '');
    }

    if (final_date!="") {
      ruta = ruta.replace('{{final_Date}}', final_date);
    } else {
      ruta = ruta.replace('&finalDate={{final_Date}}', '');
    }
    
    if (this.formModelGestion.tipoCaso!=null) {
      ruta = ruta.replace('{{type}}', this.formModelGestion.tipoCaso);
    } else {
      ruta = ruta.replace('&caseType={{type}}', '');
    }

    if (this.formModelGestion.concesionario!=null) {
      ruta = ruta.replace('{{concessionaire}}', this.formModelGestion.concesionario)
    } else {
      ruta = ruta.replace('&caseConcessionaire={{concessionaire}}', '')
    }
    
    if (this.formModelGestion.bus!=null) {
      ruta = ruta.replace('{{bus}}', this.formModelGestion.bus);
    } else {
      ruta = ruta.replace('&caseBus={{bus}}', '');
    }

    this.http
        .ejecutarServicioGet(ruta, null)
        .subscribe((res: Response) => {
          switch (res.status) {
            case 200:
            case 201:
              let response = res.json();
              
              this.dataArray = [];
              this.dataRecords = [];
              for (let index=0;index<response.length;index++) {
                let date = response[index].cases.casesObj.creationDate.split(' ');
                this.dataArray.push([
                    response[index].cases.casesObj.id.toString(), 
                    response[index].cases.casesObj.id.toString(), 
                    date[0], 
                    date[1], 
                    response[index].cases.caseStatusObj.description, 
                    response[index].cases.casesObj.alertType, 
                    response[index].cases.casesObj.trunk, 
                    response[index].cases.casesObj.route, 
                    response[index].cases.casesObj.bus,
                    ''
                ]);

                this.dataRecords.push([
                    (response[index].cases.casesObj.id!=undefined?response[index].cases.casesObj.id.toString():''),
                    date[0]+' '+date[1],
                    (response[index].cases.casesObj.concessionaire!=undefined?response[index].cases.casesObj.concessionaire:''),
                    (response[index].cases.casesObj.route!=undefined?response[index].cases.casesObj.route:''), 
                    (response[index].cases.casesObj.bus!=undefined?response[index].cases.casesObj.bus:''),
                    (response[index].cases.casesObj.busType!=undefined?response[index].cases.casesObj.busType:''),
                    (response[index].cases.casesObj.driver!=undefined?response[index].cases.casesObj.driver:''), 
                    (response[index].cases.falseAlert!=undefined?response[index].cases.falseAlert!=true?'Falsa Alarma':'Confirma Alarma':''),
                    (response[index].cases.incidentType!=undefined?response[index].cases.incidentType:''),
                    (response[index].cases.incidentClass!=undefined?response[index].cases.incidentClass:''),
                    (response[index].cases.imageClassification!=undefined?response[index].cases.imageClassification:''),
                    (response[index].cases.observations!=undefined?response[index].cases.observations:''),
                    (response[index].cases.caseStatusObj.description!=undefined?response[index].cases.caseStatusObj.description:''),
                    (response[index].cases.userId!=undefined?response[index].cases.userId:''),
                    (response[index].cases.casesObj.alertType!=undefined?response[index].cases.casesObj.alertType:''), 
                    (response[index].cases.casesObj.alertId!=undefined?response[index].cases.casesObj.alertId:''), 
                ]);

                if ( index===response.length - 1 ) {
                  this.conditionalLoader = false;
                  this.armarTabla(this.dataArray);
                }
              }

              if( !response.length ){
                this.conditionalLoader = false;
                this.armarTabla(this.dataArray);
              }          
              break;
            case 204:
              this.showError("Sin contenido");
              this.conditionalLoader = false;
              break;
            default:
              this.showError(res);
              this.conditionalLoader = false;
              break;
          }
        }, (error: Response) => {
          this.showError(error);
          this.conditionalLoader = false;
        }
    );
  }

  procesarJson( data ) {
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let index=0;index<data.length;index++) {
      dataArray.push([
        data[index]['id'].toString(),
        data[index]['codigo'].toString(),
        data[index]['fechaCreacion'].toString(),
        data[index]['horaCreacion'].toString(),
        data[index]['estado'].toString(),
        data[index]['tipo'].toString(),
        data[index]['troncal'].toString(),
        data[index]['ruta'].toString(),
        data[index]['bus'].toString(),
        ''
      ]);

      if ( index === data.length - 1 ) {
        console.log("si "+dataArray);
        this.armarTabla(dataArray);
      }
    }

    if( !data.length ){
      console.log("otro si "+dataArray);
      this.armarTabla(dataArray);
    }
  }

  armarTabla( data ) {
    console.log('tabla', data);
    this.contentToShow = 2;

    this.configTabla = {
      status: 1,
      header: ['ID', 'Código caso', 'Fecha de creación', 'Hora de creación', 'Estado caso', 'Tipo caso', 'Troncal', 'Ruta', 'Bus', 'Acciones'],
      typeColumn: [,,,,,,,,,{ tipo: 'acciones' , acciones: ['verCaso', 'verVideos', 'adjuntar'] }],
      showSearch: false,
      leftsColumns: [1],
      searchColumn: [1],
      searchTitle: 'Buscar grupo de dispositivos',
      hideColumns: [0],
      showPag: true,
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }

  verCaso( id ){
    this.record = this.dataRecords.find(function(element) {
      if (element[0]===id) {
        return element;
      }
    });
    console.log(this.record);
    // this.contentToForm = 23;
    this.idCasoRes = id;

    this.contentToShowModal = 1;
    this.configModal = {
      status: 1,
      closeOnly: true,
      icono: 'smart-icon-descargar',
      titulo: 'Detalle del caso',
      textos: [
                '<span class="capamodal" style="width: 100%;height: 125px;background-color: #FFF;float: left;text-align: left;">' +
                '  <strong>Fecha y hora de creación del caso</strong>: ' + this.record[1] +
                '  <br /><strong>Concesionario</strong>: ' + this.record[2] +
                '  <br /><strong>Ruta</strong>: ' + this.record[3] +
                '  <br /><strong>Bus</strong>: ' + this.record[4] +
                '  <br /><strong>Tipo de articulado</strong>: ' + this.record[5] +
                '  <br /><strong>Nombre del conductor</strong>: ' + this.record[6] +
                '</span><br /><br />' +

                '<span class="capamodal" style="width: 100%;height: 125px;background-color: #FFF;float: left;text-align: left;">' +
                '  <strong>Validación alarma</strong>: ' + this.record[7] +
                '  <br /><strong>Tipo de incidente</strong>: ' + this.record[8] +
                '  <br /><strong>Clase de incidente</strong>: ' + this.record[9] +
                '  <br /><strong>Clasificación de imágenes</strong>: ' + this.record[10] +
                '  <br /><strong>Observaciones</strong>: ' + this.record[11] +
                '</span><br /><br />' +

                '<span class="capamodal" style="width: 100%;height: 125px;background-color: #FFF;float: left;text-align: left;">' +
                '  <strong>Estado del caso</strong>: ' + this.record[12] +
                '  <br /><strong>Escalado a</strong>: ' + this.record[13] +
                '</span>'
              ],
      botones: [
              {
                label: 'OK',
                class: '',
                accion: 'ok'
              }
      ]
    };

    this.cdr.markForCheck();
  }

  /**
   * Carga un modal con la lista de vídeos del caso seleccionado
   * 
   * @param id 
   */
  videos( id ){
    this.showModal = true;
    this.record = this.dataRecords.find(function(element) {
      if (element[0]===id) {
        return element;
      }
    });
    
    this.concessionaire = this.record[2];
    let bus = this.record[4];
    let date = this.record[1].split(' ')[0].split('-');
    let idEvent = this.record[15];

    let ruta = environment.endpoints.caseVideos
                  .replace('{{concessionaire}}', this.concessionaire)
                  .replace('{{bus}}', bus)
                  //.replace('{{date}}', date[2]+date[1]+date[0])
                  .replace('{{idEvent}}', idEvent);

    this.http.ejecutarServicioGet(ruta, null ).subscribe( (resVideos: Response) => {
      switch (resVideos.status) {
        case 200:
        case 201:
        case 204:
          let videos = resVideos.json();

          this.dataVideos = [];
          for (let index=0;index<videos.length;index++) {
            this.dataVideos.push([
                id+'||'+videos[index].videoName.toString(), 
                videos[index].videoName.toString(),
                ''
            ]);

            if ( index===videos.length - 1 ) {
              this.conditionalLoader = false;
              this.paginador['total'] = videos.length;
              this.armarTablaVideos(this.dataVideos);
            }
          }

          if( !videos.length ){
            this.conditionalLoader = false;
            this.armarTablaVideos(this.dataVideos);
          }

          this.cdr.markForCheck();
          break;
        default:
            this.showError( resVideos );
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    })

    this.cdr.markForCheck();
  }

  armarTablaVideos(data) {
    console.log('data', data);
    this.contentToShowVideo = 2;
    this.configTablaVideos = {
      status: 1,
      header: ['ID', 'Vídeo', 'Acciones'],
      typeColumn: [,,{ tipo: 'acciones' , acciones: ['verVideos', 'adjuntar'] }],
      showSearch: false,
      leftsColumns: [1],
      searchColumn: [1],
      searchTitle: 'Vídeos del caso',
      hideColumns: [0],
      showPag: true,
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
  }

  /**
   * Carga el vídeo del caso solicitado dentro del control
   * 
   * @param id
   */
  mostrarVideo(id) {
    let video = id.split('||'); 
    let videoUrl = environment.endpoints.casePlayVideo
                      .replace('{{concessionaire}}', this.concessionaire)
                      .replace('{{videoName}}', video[1]);
		
    this.sources = new Array<Object>();
    this.sources.push({
      src: videoUrl
    });  
  }
  
  /**
   * Adjunta un registro de la tabla principal a la tabla "Casos/Vídeos adjuntos"
   * 
   * @param id 
   */
  adjuntar( id ){
    this.record = this.dataRecords.find(function(element) {
      if (element[0]===id) {
        return element;
      }
    });

    let existe = this.dataCasos.findIndex(v => v.id === id);
    if (existe==-1) {
        this.dataCasos.push(
          { id: this.record[0], nombre: this.record[0], estado: this.record[12], tipo: this.record[14] }
        );
        this.dataCasosTable.push(
          { id: this.record[0], nombre: this.record[0], estado: this.record[12], tipo: this.record[14] }
        );
    
        setTimeout(() => {
          this.conditionalLoader = false;
          this.procesarJsonRes(this.dataCasosTable);
        }, 400);
    
        this.cdr.markForCheck();
    } else {
      this.uiService.showMessage("Ya se ingresó a la tabla el registro ", this.record[0]);
    }
  }
  
  changeStatusForm( event ){
    this.contentToForm = -1;
    this.idCasoRes = '';
    this.cdr.markForCheck();
  }
  
  respuesta(){
    this.conditionalLoader = true;
    
    setTimeout(() => {
      // Cambiar por el Servicio
      // Procesar colocando un antecesor al id para saber si es un caso o un video
      let data = [
        { id: `case-${1}`, nombre: 'Caso 1', estado: 'Lorem', tipo: 'Lorem' },
        { id: `video-${1}`, nombre: 'Video 1', estado: 'Lorem', tipo: 'Lorem' },
        { id: `video-${2}`, nombre: 'Video 2', estado: 'Lorem', tipo: 'Lorem' },
      ]
      this.conditionalLoader = false;
      this.procesarJsonRes(data);
    }, 400);
  }

  procesarJsonRes( data ) {
    console.log('tabla', data);
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['id'].toString(),
        data[index]['nombre'].toString(),
        data[index]['estado'].toString(),
        data[index]['tipo'].toString(),
        ''
      ]);

      if ( index === data.length - 1 ) {
        this.armarTablaRes(dataArray);
      }
    }

    if( !data.length ){
      this.armarTablaRes(dataArray);
    }
  }

  armarTablaRes( data ) {
    this.contentToShowRes = 2;
    this.configTablaRes = {
      status: 1,
      header: ['ID', 'Archivo Adjunto', 'Estado', 'Tipo de caso', 'Acciones'],
      typeColumn: [,,,,{ tipo: 'acciones' , acciones: ['eliminar'] }],
      showSearch: false,
      leftsColumns: [1],
      searchColumn: [1],
      searchTitle: 'Buscar grupo de dispositivos',
      hideColumns: [0],
      showPag: true,
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }

  eliminarRes( id ){
    this.idEliminar = id;
    // procesar id quitando 'case-' o 'video-'

    this.contentToShowModal = 1;
    this.configModal = {
      status: 1,
      closeOnly: true,
      icono: 'smart-icon-eliminar',
      titulo: 'Eliminar Caso/Video Adjunto',
      textos: [
                `El caso/video seleccionado será eliminado`
              ],
      botones: [
              {
                label: 'Cancelar',
                class: 'sths-btn-cancel',
                accion: 'cancelar'
              },
              {
                label: 'Aceptar',
                class: '',
                accion: 'eliminarCasoVideo'
              }
      ]
    };
    this.cdr.markForCheck();
  }

  eliminarCasoVideo () {
    // Se elimina el registro del array object y se actualiza la tabla
    this.dataCasos.splice(this.dataCasos.findIndex(v => v.id === this.idEliminar), 1);
    this.dataCasosTable.splice(this.dataCasosTable.findIndex(v => v.id === this.idEliminar), 1);
    console.log(this.idEliminar);

    setTimeout(() => {
      this.conditionalLoader = false;
      this.procesarJsonRes(this.dataCasosTable);
    }, 400);

    this.cdr.markForCheck();
  }

  descargarRespuesta(){
    // Si no hay casos asociados, no se consume el endpoint
    if (this.hasCases) {
      let ruta = environment.endpoints.caseDownloadB64.replace('{manual_case_id}', this.idCaso)
      this.http
          .ejecutarServicioGet(ruta, null)
          .subscribe(
            data => this.uiService.downloadFileBase64(data['_body'], 'zip', 'DescargaRespuestaCodCaso'+this.idCaso+'.zip'),
            (e) => {
              this.uiService.showMessage('Error descagando el archivo zip');
          }
      );

      this.contentToShowModal = 1;
      this.configModal = {
        status: 1,
        closeOnly: true,
        icono: 'smart-icon-descargar',
        titulo: 'Se ha descargado la respuesta',
        textos: [
                  `La respuesta se ha exportado en formato Zip`
                ],
        botones: [
                {
                  label: 'OK',
                  class: '',
                  accion: 'ok'
                }
        ]
      };
      this.cdr.markForCheck();
    } else {
      this.uiService.showMessage('No hay información asociada al caso.');
    }
  }
  
  cerrarCaso(){
    this.contentToShowModal = 1;
    this.configModal = {
      status: 1,
      closeOnly: true,
      icono: 'smart-icon-guardar',
      titulo: 'Respuesta guardada',
      textos: [
                `La respuesta con los casos adjuntados ha sido guardada de manera exitosa`
              ],
      botones: [
              {
                label: 'OK',
                class: '',
                accion: 'ok'
              }
      ]
    };
    this.cdr.markForCheck();
  }

	/**
	 * Método encargado de enviar los datos al endpoint "Gestionar Caso manual"
	 * 
	 * @param caseStatus 
	 */
  guardarCaso(caseStatus: number) {
    // Sólo si se cargo un archivo se puede guardar el caso
    // No quitar la validación cuando caseStatus = 4
    //if (this.officialResponseName!="") {
      let message = "Se guardó la información diligenciada del caso.\n"+
      "El estado del caso es \"00\"";

      let cases = [];
      let videos = [];
      for (let index=0;index<this.dataCasos.length;index++) {
        let caseId = this.dataCasos[index].id.split('/');
        if (caseId.length==1) {
          cases.push(this.dataCasos[index].id);
        } else {
          videos.push(this.dataCasos[index].id);
        }
      }

      let formGestion = {
        "manualCaseId" : this.idCasoRes,
        "caseStatusId" : caseStatus,
        "responseDescription" : this.descriptionCase,
        "officialResponse" : this.officialResponseUrl,
        "officialResponseName" : this.officialResponseName,
        "associatedCases" : cases,
        "associatedVideos" : videos
      };

      this.http
          .ejecutarServicioPost( environment.endpoints.caseManualSave, formGestion )
          .subscribe( (res: Response) => {
            switch ( res.status ) {
              case 200:
              case 201:
              case 204:
                console.log(res.json());
                let estado = caseStatus==2?'guardado':'cerrado';
                this.uiService.showMessage(message.replace("\"00\"", estado));
                break;
              default:
                this.showError(res);
                break;
            }
          }, (error: Response) => {
            this.showError( error );
          }
      );

      this.cdr.markForCheck();
    /*} else {
      this.uiService.showMessage('Debe adjuntar un archivo antes de Guardar o Cerrar el caso');
    }*/
  }

  changeStatusModal( event ) {
    // Limpiar Modal
    this.contentToShowModal = -1;
    this.configModal = { status: -1 };
    this.cdr.markForCheck();
  
    // Respuesta de los Modales 
    switch(event) {
      case 'eliminarCasoVideo':
        this.eliminarCasoVideo();
        break;
    }
  }

  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage(response.json()['message']);
    } else {
      this.uiService.showMessage('Error del servidor');
    }
    this.cdr.markForCheck();
  }

  statusModal(event) {
    this.showModal = false;
  }
  
  /**
   * Adjunta un registro de la tabla principal a la tabla "Casos/Vídeos adjuntos"
   * 
   * @param video
   */
  adjuntarVideo( video ){
    let id = video.split('||');
    console.log(id);
    this.record = this.dataRecords.find(function(element) {
      if (element[0]===id[0]) {
        return element;
      }
    });

    let existe = this.dataCasos.findIndex(v => v.id === id[1]);
    if (existe==-1) {
        this.dataCasos.push(
          { id: id[1], nombre: id[1], estado: this.record[12], tipo: this.record[14] }
        );
        this.dataCasosTable.push(
          { id: id[1], nombre: id[1], estado: this.record[12], tipo: this.record[14] }
        );
    
        setTimeout(() => {
          this.conditionalLoader = false;
          this.procesarJsonRes(this.dataCasosTable);
        }, 400);
    
        this.cdr.markForCheck();
    } else {
      this.uiService.showMessage("Ya se ingresó a la tabla el registro ", this.record[0]);
    }
  }

  /**
   * Carga archivo al webservice, retornando un objeto con el nombre y url de descarga
   * 
   * @param respuestaInput
   */
	processFile(respuestaInput: any) {
		const file: File = respuestaInput.files[0];
		const reader = new FileReader();
		reader.addEventListener('load', (event: any) => {
      console.log(event);
			this.selectedFile = new DocumentSnippet(event.target.result, file);
			this.selectedFile.pending = true;
			const data = new FormData();
      data.append('file', this.selectedFile.file);

      let ruta = environment.endpoints.caseAttachDocument.replace('{manual_case_id}', this.idCasoRes);
			this.http
        .multipartPost(ruta, data)
        .subscribe((res: Response) => {
          console.log('before', res);
          switch (res.status) {
            case 200:
            case 201:
            case 204:
              let file = res.json();

              this.officialResponseName = file.officialResponseName;
              this.officialResponseUrl = file.officialResponseUrl;
  
              this.cdr.markForCheck();
              break;
            case 401:
              // nothing
              break;
            default:
              this.showError(res);
            break;
          }
        }, (error: Response) => {
          this.showError(error);
      });
    
    });
		reader.readAsDataURL(file);
  }

  backCaso() {
		this.router.navigate(['admin/dashboard/gestion/recibir']);
	}

}