import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges
} from '@angular/core';

import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { environment } from '@environments/environment';
import { Response } from '@angular/http';
import { UIService } from '../../../_services/UI.service';
import {
	NgbDateNativeAdapter,
	NgbDateAdapter
} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-conductor-registro-notas',
	templateUrl: './conductor-registro-notas.component.html',
	styleUrls: ['./conductor-registro-notas.component.scss'],
	providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ConductorRegistroNotasComponent implements OnInit, OnChanges {
	@Input() model: any;
	@Output() modelChange = new EventEmitter();
	@Output() reload = new EventEmitter();
	@Input() showForm: boolean = false;
  private empty: any = { id: null, noteDate: '', noteText: '' };
	currentNote: any = this.empty;

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {}
	@Input() showTable: boolean = false;
	public options: Options;
	contentToModificar: number = -1;
	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1 };
	// formModelDriver: AdminDriverDTO = new AdminDriverDTO();
	// @Input() modificarDatos: AdminDriverDTO;
	loading: boolean = false;

	ngOnInit() {
		this.showForm = !this.showTable;
		// this.loading = true;
		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.model.currentValue) {
			let notes = changes.model.currentValue.notes;
			if (notes) {
				this.procesarJson(notes);
			}
		}
	}
	procesarJson(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				data[index]['id'].toString(),
				data[index]['noteDate'].toString(),
				data[index]['noteText'].toString(),
				''
			]);
		}
		this.armarTabla(dataArray);
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: ['ID', 'Fecha Nota', 'Nota', 'Acciones'],
			typeColumn: [
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'eliminar'] }
			],
			searchColumn: [0, 1, 2],
			hideColumns: [0],
			showPag: false,
			showExport: false,
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data
		};
		this.loading = false;
		this.cdr.markForCheck();
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	updateModel() {
		this.model.noteDate = this.currentNote.noteDate;
		this.model.noteText = this.currentNote.noteText;
	}

	agregarNota() {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/note`;
		this.http
			.ejecutarServicioPost(url, this.currentNote)
			.subscribe((res: Response) => {
				let data = res.json();
				if (res.status === 200 || res.status === 201) {
					this.currentNote  = this.empty;
					this.reload.emit(true);
					this.cdr.markForCheck();
				} else { this.showError(res); }
			});
	}

	borrarNota(event) {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/note/${event}`;
		this.http.ejecutarServicioDelete(url).subscribe((res: Response) => {
			if (res.status === 200) {
				this.reload.emit(true);
			} else { this.showError(res); }
		});
	}

	cancelarNota() {
		this.showForm = false;
		this.currentNote = this.empty;
		this.cdr.markForCheck();
	}

	guardarNota() {
		if((this.currentNote.noteDate === null || this.currentNote.noteDate === undefined || this.currentNote.noteDate === "") ||
			(this.currentNote.noteText === null || this.currentNote.noteText === undefined || this.currentNote.noteText === "")
		) {
			this.uiService.showMessage("Llene los campos marcados con asterisco (*)");
			return;
		}
		this.showForm = false;
		if (this.currentNote.id) {
			this.actualizarNota();
		} else {
			this.agregarNota();
		}
	}

	editarElemento(event) {
		let item = this.model.notes.find(itm => itm.id === parseInt(event, 10));
		this.currentNote = item;
		this.showForm = true;
		this.cdr.markForCheck();
	}

	actualizarNota() {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/note/${this.currentNote.id}`;
		this.http
			.ejecutarServicioPut(url, this.currentNote)
			.subscribe((res: Response) => {
				let data = res.json();
				if (res.status === 200 || res.status === 201) {
					this.currentNote  = this.empty;
					this.reload.emit(true);
					this.cdr.markForCheck();
				} else { this.showError(res); }
			});
	}
}
