import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { AdminDriverDTO } from '../../../../shared/dto/admin-driver-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2OptionData, Options } from 'select2';
import { UIService } from '../../../_services/UI.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from './../../../../../../environments/environment';
import { HttpService } from './../../../../shared/services/http.service';
import { Response } from '@angular/http';
import { ConfigDTO } from './../../../../shared/dto/config-dto';
import { BasicLayerDTO } from '../../../../shared/dto/basic-layer-dto';
import { LayerDTO } from './../../../../shared/dto/layer-dto';
import * as turf from '@turf/turf'
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import * as mapboxgl from 'mapbox-gl';
import { MatSnackBarRef } from '@angular/material';
import { LayoutUtilsService, MessageType } from './../../../../../core/_base/crud/utils/layout-utils.service';
import { UtilService } from "../../../../trans/administrador/_services/util.service";

@Component({
  selector: 'kt-tramos-crear',
  templateUrl: './tramos-crear.component.html',
  styleUrls: ['./tramos-crear.component.scss']
})


export class TramosCrearComponent implements OnInit {

  idTramo: string;
  contentToShow: number = -1;
  configTabla: {} = { status: -1 };

  contentToShow2: number = -1;
  configTabla2: {} = { status: -1 };

  public selectFitroTipoCategoria: Array<Select2OptionData>;
  public options: Options;

  private map: any;

  datosTramo: any;

  private _mapSources: {} = {};

  mousepointer = { lat: 0, lng: 0 };
  tooltip = { top: 0, left: 0 };

  private _parentUrl: string = environment.endpoints.parent;

  center: any;
  zoom: any;

  geojson: any;

  textoBusqueda: string = "";
  limpiarBuscar: string;
  mouseoveritem: boolean = false;
  private _icons = [];

  latitude: string;
  longitude: string;

  pointFinded: any = [];

  layers: any = [];

  isEditing: boolean = false;

  private geocenter = new BehaviorSubject<any>(this.center);
  public geocenter$ = this.geocenter.asObservable();
  private mapzoom = new BehaviorSubject<any>(this.zoom);
  public mapzoom$ = this.mapzoom.asObservable();

  nombre: string;
  sentidotramo = "All";
  conditionalLoader: boolean = false;

  datosParametro: {};

  markers = [];
  draw = new MapboxDraw({
    // Instead of showing all the draw tools, show only the line string and delete tools
    displayControlsDefault: false,
    controls: {
      line_string: true
    },
    title: 'hola',
    styles: [
      // Set the line style for the user-input coordinates
      {
        "id": "gl-draw-line",
        "type": "line",
        "filter": ["all", ["==", "$type", "LineString"],
          ["!=", "mode", "static"]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-color": "#438EE4",
          "line-dasharray": [0.2, 2],
          "line-width": 4,
          "line-opacity": 0.7
        }
      },
      // Style the vertex point halos
      {
        "id": "gl-draw-polygon-and-line-vertex-halo-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["!=", "mode", "static"]
        ],
        "paint": {
          "circle-radius": 12,
          "circle-color": "#FFF"
        }
      },
      // Style the vertex points
      {
        "id": "gl-draw-polygon-and-line-vertex-active",
        "type": "circle",
        "filter": ["all", ["==", "meta", "vertex"],
          ["==", "$type", "Point"],
          ["!=", "mode", "static"]
        ],
        "paint": {
          "circle-radius": 8,
          "circle-color": "#438EE4",
        }
      },
    ]
  });

  private _views: ConfigDTO;

  private _viewsUrl: string = environment.endpoints.views;

  @Input() model = {};
  @Output() modelChange = new EventEmitter();
  @Input() showTable: boolean = false;

  line: any;

  paginador: object = {
    count: 10,
    page: 1,
    total: 0,
    maxPages: 5
  };

  lastRequest: any;

  tramo = {
    "name": "",
    "direction": "All",
    "linestream": ""
  };

  latini: string;
  lonini: string;
  latfin: string;
  longfi: string;

  lineStreamTramo: any;

  parametros;

  formModelTramosEditar: AdminDriverDTO = new AdminDriverDTO();
  contentToModificar: number = -1;

  selectSentidoTramo: Array<Select2OptionData>;

  private _actualDialog: MatSnackBarRef<any>;

  configModalRango: {} = { status: -1 };

  nombreTroncal: string;

  data: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private uiService: UIService,
    private layoutUtilsService: LayoutUtilsService,
    private http: HttpService,
    private router: Router
  ) {

    UtilService.editParametroTroncal.subscribe(data => {

      this.parametros.map(parametro => {
        if (parametro.id == data.id) {
          parametro.valor = data.valor;

        }
      });
      this.procesarJson();
    });


  }

  contentToShowFormModal: number = -1;
  contentToShowFormModalParametros: number = -1;
  configModal: {} = { status: -1 };
  conditionalLoaderTable: boolean;
  loading: boolean = false;
  rutasMenu: object;
  rutasMenu2: object;


  ngOnInit() {
    //this.procesarJson();
    //this.loading = true;

    this.rutasMenu = [
      {
        label: 'Editar Tramo',
        icon: 'smart-icon-usuario',
        ruta: `${environment.folders.routeAdmin}tramos/buscar`
      }
    ];

    this.rutasMenu2 = [
      {
        label: 'Crear Tramo',
        icon: 'smart-icon-usuario',
        ruta: `${environment.folders.routeAdmin}tramos/crear`
      }
    ];

    this.center = [-74.1082373, 4.6386259];
    this.geocenter.next(this.center);
    this.zoom = 11;
    this.mapzoom.next(this.zoom);

    this.http.ejecutarServicioGet(this._viewsUrl).subscribe((res: Response) => {
      if (res.status === 200) {
        this._views = ConfigDTO.fromJS(res.json());
        this._views.basics.map((capa: BasicLayerDTO, index) => {
          capa.layers.map((layer: any) => {
            if (layer.layer_id === "Estaciones") {
              this.layers.push(layer);
            }
            if (layer.layer_id === "Troncales") {
              this.layers.push(layer);
            }

          });
        });
      } else {
        // TODO :: MANEJAR EXCEPCION
      }
    });

    let urlSentidoTroncales = environment.endpoints.sentidoTramo;
    this.http.ejecutarServicioGet(urlSentidoTroncales).subscribe(res => {
      const self = this;
      self.selectSentidoTramo = Array<Select2OptionData>();
      if (res.json().length > 0) {
        let data = res.json();
        let selectTypes = data.map(i => {
          return { id: i['answerValue'].toString(), text: i['answer'] };
        });
        selectTypes.unshift({ id: 'All', text: 'Seleccione' });
        this.selectSentidoTramo = selectTypes;
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    this.idTramo = this._route.snapshot.paramMap.get('idTramo');

    if (this.idTramo !== ":idTramo") {
      this.isEditing = true;
      let urlBuscarTramo = environment.endpoints.crearTramo + this.idTramo;
      this.http.ejecutarServicioGet(urlBuscarTramo).subscribe(res => {

        let data = res.json();
        /*let parametersTypes = data.map(i => {
          return { "id": i['id'].toString(), "paramenametro": i['name'].toString(), "direction": i['direction'].toString(), "linestream": i['linestream'].toString() };
        });*/
        this.tramo = data;//parametersTypes;
        let lineStreamPoints = JSON.parse(this.tramo.linestream);
        this.lineStreamTramo = {
          "type": "Feature",
          "geometry": {
            "coordinates": lineStreamPoints,
            "type": "LineString"
          },
          "properties": {
            "type": "Feature"
          }
        };


        this.cdr.markForCheck();
      }, (error: Response) => {
        if (this._actualDialog) { this._actualDialog.dismiss(); }
        const message = 'Error cargando datos ';
        this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
      });



      let urlParametroTroncales = environment.endpoints.parametroTramos;
      this.http.ejecutarServicioGet(urlParametroTroncales).subscribe(res => {

        if (res.json().length > 0) {
          let data = res.json();
          let parametersTypes = data.map(i => {
            return { "id": i['id'].toString(), "parametro": i['answer'].toString(), "valor": "" };
          });

          this.parametros = parametersTypes;

          let urlParametrosTramo = environment.endpoints.crearTramo + this.idTramo + "/params";
          let self = this;
          this.http.ejecutarServicioGet(urlParametrosTramo).subscribe(res => {
            if (res.json().length > 0) {
              let data = res.json();
              let parametersTypes = data.map(i => {
                return { "id": i['id'].toString(), "parametro": i['name'].toString(), "valor": i['value'].toString() };
              });
              self.parametros.map(params => {
                parametersTypes.map(param => {
                  if (params.parametro === param.parametro) {
                    params.valor = param.valor;
                  }
                });
              });
              self.procesarJson();
            }
            else {
              self.procesarJson();
            }
            this.cdr.markForCheck();
          }, (error: Response) => {
            if (this._actualDialog) { this._actualDialog.dismiss(); }
            const message = 'Error cargando datos ';
            this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
          });

        }
        this.cdr.markForCheck();
      }, (error: Response) => {
        if (this._actualDialog) { this._actualDialog.dismiss(); }
        const message = 'Error cargando datos ';
        this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
      });

    }
    else {

      this.consultarParametros();
    }

  }

  consultarParametros() {
    let urlParametroTroncales = environment.endpoints.parametroTramos;
    this.http.ejecutarServicioGet(urlParametroTroncales).subscribe(res => {

      if (res.json().length > 0) {
        let data = res.json();
        let parametersTypes = data.map(i => {
          return { "id": i['id'].toString(), "parametro": i['answer'].toString(), "valor": "" };
        });

        this.parametros = parametersTypes;
        this.procesarJson();
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });
  }

  centerMap(point) {
    //this.capaService.featureSelected.next(point);
    //this.capasService.featureSelected$.subscribe(feature => {
    this.lineStreamTramo = undefined;
    if (point !== undefined) {
      if (point.geometry.type === "Polygon") {
        this.center = [point.properties.centroid_lon, point.properties.centroid_lat];
        this.zoom = 12;
      }
      else if (point.geometry.type === "LineString") {
        this.line = turf.lineString(point.geometry.coordinates);
        if (this.map.getLayer("line")) {
          this.map.removeLayer("line");
          this.map.removeSource("line");
        }

        this.nombreTroncal = point.properties["TTRATRONCA"];

        this.map.addLayer({
          "id": "line",
          "type": "line",
          "source": {
            "type": "geojson",
            "data": {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "LineString",
                "coordinates": point.geometry.coordinates
              }
            }
          },
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
          "paint": {
            "line-color": "#ffed00",
            "line-width": 8
          }
        });
        this.center = point.geometry.coordinates[0];
        this.zoom = 15;
      }
      else {
        this.limpiarCapas();
        if (this.map.getLayer("estacion")) {
          this.map.removeLayer("estacion");
          this.map.removeSource("estacion");
        }
        this.map.loadImage(environment.folders.imgMarkers + "estaciones/tm-estaciones.png", (error, image) => {
          if (error) throw error;
          this.map.addImage("estaciones/tm-estaciones.png", image);
          this._icons.push("estaciones/tm-estaciones.png");
        });
        this.map.addLayer({
          "id": "estacion",
          "type": "symbol",
          "source": {
            "type": "geojson",
            "data": {
              "type": "Feature",
              "properties": {
                'icon': "estaciones/tm-estaciones.png"
              },
              "geometry": {
                "type": "Point",
                "coordinates": point.geometry.coordinates
              }
            }
          },
          "layout": {
            "icon-image": ['get', 'icon']
          }
        });
        this.center = point.geometry.coordinates;
        this.calcularDistancias(this.center);
        this.zoom = 20;
      }

      this.mapzoom.next(this.zoom);
      this.geocenter.next(this.center);
      this.map.setZoom(this.zoom, null);
      this.limpiarBusqueda();
      this.cdr.markForCheck();
    }
    //});
  }

  cargarCapa(urlCapa, index) {
    const url = this._parentUrl + urlCapa;

    if (!this._mapSources[url]) {

      this._mapSources[url] = true;
      this.http.ejecutarServicioGet(url, null).subscribe((res: Response) => {


        if (res.status === 200 && this._mapSources[url]) {
          this.geojson = res.json();

          this.map.addSource(url, {
            'type': 'geojson',
            'data': this.geojson
          });

          if (this.isEditing && index == 1) {
            this.calcularDistancias();
          }

        }

      }, (error: Response) => {
        this.uiService.showMessage('Error cargando capa "' + urlCapa + '"', 'top');
      });// END GET
    }
  }


  calcularDistancias(estacion = null) {
    var lineTroncal;
    var lineTramo;
    if (estacion !== null) {
      lineTramo = turf.point(estacion);
    }
    else
      lineTramo = turf.point(this.lineStreamTramo.geometry.coordinates[0]);
    let distances = [];
    for (var i = 1; i < this.layers.length; i++) {
      const url = this._parentUrl + this.layers[i].resource.endpoint;
      let dataSource = this.map.getSource(url);
      if (dataSource !== undefined) {

        for (var j = 0; j < dataSource._options.data.features.length; j++) {
          var feature = dataSource._options.data.features[j];
          lineTroncal = turf.lineString(feature.geometry.coordinates);

          var distance = turf.pointToLineDistance(lineTramo, lineTroncal);
          distances.push(distance);

        }
      }
    }
    let menorDistancia = Math.min.apply(null, distances);
    let index = distances.indexOf(menorDistancia);
    for (var i = 1; i < this.layers.length; i++) {
      const url = this._parentUrl + this.layers[i].resource.endpoint;
      let dataSource = this.map.getSource(url);
      if (dataSource !== undefined) {

        var feature = dataSource._options.data.features[index];
        this.line = turf.lineString(feature.geometry.coordinates);
        this.nombreTroncal = feature.properties["TTRATRONCA"];
        this.map.addLayer({
          "id": "line",
          "type": "line",
          "source": {
            "type": "geojson",
            "data": {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "LineString",
                "coordinates": feature.geometry.coordinates
              }
            }
          },
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
          "paint": {
            "line-color": "#ffed00",
            "line-width": 8
          }
        });
      }
    }

    if (this.lineStreamTramo !== undefined && this.isEditing === true) {
      this.pintarTramo();
    }
  }

  limpiarBusqueda() {
    this.limpiarBuscar = "";
    this.pointFinded = [];
  }

  buscar(event) {
    this.textoBusqueda = event.target.value;

    if (this.textoBusqueda !== undefined && this.textoBusqueda !== "") {
      var matchingFeatures = [];
      for (var i = 0; i < this.layers.length; i++) {
        const url = this._parentUrl + this.layers[i].resource.endpoint;
        let dataSource = this.map.getSource(url);
        if (dataSource !== undefined) {

          for (var j = 0; j < dataSource._options.data.features.length; j++) {
            var feature = dataSource._options.data.features[j];
            // handle queries with different capitalization than the source data by calling toLowerCase()
            if (this.layers[i].hasOwnProperty('search')) {
              if (this.layers[i].search === "TTRACTTRAN") {
                this.layers[i].search = "TTRATRONCA";
              }
              let searchFields = [];
              if (this.layers[i].search.indexOf(".") >= 0) {
                searchFields = this.layers[i].search.split(".");
              }
              if (searchFields.length > 0) {
                if (feature.properties[searchFields[0]][searchFields[1]].toString().toLowerCase().indexOf(this.textoBusqueda.toLowerCase()) >= 0) {
                  feature.name = feature.properties[searchFields[0]][searchFields[1]];
                  matchingFeatures.push(feature);
                }
              } else {
                if (feature.properties[this.layers[i].search] !== undefined && feature.properties[this.layers[i].search].toString().toLowerCase().indexOf(this.textoBusqueda.toLowerCase()) >= 0) {
                  feature.name = feature.properties[this.layers[i].search];
                  matchingFeatures.push(feature);
                }
              }
            }
          }
        }

        this.pointFinded = matchingFeatures;
      }
    }
  }

  setMap($map: any) {
    this.map = $map;
    this.map.on('mousemove', ($event) => this.mousemove($event));
    // Add the draw tool to the map
    this.map.addControl(this.draw);

    this.layers.map((layer, index) => {
      this.cargarCapa(layer.resource.endpoint, index);
    });
    /*this.cargarCapa("/cdg/basics/1/layers/Troncales");
    this.cargarCapa("/cdg/basics/1/layers/Estaciones");*/
    let self = this;
    this.map.on('mousemove', function (e) {

      self.latitude = "Lat: " + e.lngLat.lat;
      self.longitude = "Lng: " + e.lngLat.lng;
    });

    this.map.on('draw.create', ($event) => this.updateRoute($event));
    this.map.on('draw.update', ($event) => this.updateRoute($event));
    this.map.on('draw.delete', ($event) => this.removeRoute($event));

    let drawElement: any = document.getElementsByClassName("mapbox-gl-draw_ctrl-draw-btn")[1];
    drawElement.title = "Dibujar Tramo";
  }

  pintarTramo() {

    this.lonini = this.lineStreamTramo.geometry.coordinates[0][0];
    this.latini = this.lineStreamTramo.geometry.coordinates[0][1];
    this.longfi = this.lineStreamTramo.geometry.coordinates[this.lineStreamTramo.geometry.coordinates.length - 1][0];
    this.latfin = this.lineStreamTramo.geometry.coordinates[this.lineStreamTramo.geometry.coordinates.length - 1][1];
    var beginpt: any = [this.lonini, this.latini];//data.features[lastFeature].geometry.coordinates[0];
    var endpt: any = [this.longfi, this.latfin];//data.features[lastFeature].geometry.coordinates[data.features[lastFeature].geometry.coordinates.length - 1];

    // removeRoute();
    // map.fire('draw.delete');

    console.log(beginpt);
    console.log(endpt);

    var snapped_begin = turf.nearestPointOnLine(this.line, beginpt, { units: 'kilometers' }).geometry.coordinates;
    var snapped_end = turf.nearestPointOnLine(this.line, endpt, { units: 'kilometers' }).geometry.coordinates;

    var sliced = turf.lineSlice(snapped_begin, snapped_end, this.line);


    var snapped_begin_mapbox: mapboxgl.LngLatLike = { lng: snapped_begin[0], lat: snapped_begin[1] };
    var snapped_end_mapbox: mapboxgl.LngLatLike = { lng: snapped_end[0], lat: snapped_end[1] };
    // console.log(snapped_begin);
    // console.log(snapped_end);
    let _self = this;
    let b_marker = new mapboxgl.Marker({ draggable: true })
      .setLngLat(snapped_begin_mapbox)
      .addTo(this.map);
    this.markers.push(b_marker);
    b_marker.on('dragend', ($event) => this.onDragEnd($event));

    let e_marker = new mapboxgl.Marker({ draggable: true })
      .setLngLat(snapped_end_mapbox)
      .addTo(this.map);
    this.markers.push(e_marker);
    e_marker.on('dragend', ($event) => this.onDragEnd($event));

    this.map.addLayer({
      "id": "route",
      "type": "line",
      "source": {
        "type": "geojson",
        "data":
          this.lineStreamTramo
      },
      "layout": {
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-color": "#DD77DD",
        "line-width": 10
      }
    });

    this.center = beginpt;
    this.zoom = 14;


    this.mapzoom.next(this.zoom);
    this.geocenter.next(this.center);
    this.map.setZoom(this.zoom, null);
  }

  openModal() {
    this.contentToShowFormModal = 24;
    this.cdr.markForCheck();
    /*setTimeout(() => {
      this.contentToShowFormModal = -1;
      this.configModal = { status: -1 };
      this.conditionalLoaderTable = false;
      this.cdr.markForCheck();
    }, 300);*/
  }

  mousemove(e) {
    this.mousepointer.lat = e.lngLat.lat;
    this.mousepointer.lng = e.lngLat.lng;
    this.tooltip.top = e.point.y - 60;
    this.tooltip.left = e.point.x + 10;
    this.cdr.markForCheck();
  }

  updateRoute(event) {
    var data = this.draw.getAll();
    var lastFeature = data.features.length - 1;
    var coords = data.features[lastFeature].geometry.coordinates;

    this.lonini = data.features[lastFeature].geometry.coordinates[0][0];
    this.latini = data.features[lastFeature].geometry.coordinates[0][1];
    this.longfi = data.features[lastFeature].geometry.coordinates[data.features[lastFeature].geometry.coordinates.length - 1][0];
    this.latfin = data.features[lastFeature].geometry.coordinates[data.features[lastFeature].geometry.coordinates.length - 1][1];

    var radius = [];
    coords.forEach(element => {
      radius.push(25);
    });

    // console.log(data);

    var beginpt = data.features[lastFeature].geometry.coordinates[0];
    var endpt = data.features[lastFeature].geometry.coordinates[data.features[lastFeature].geometry.coordinates.length - 1];

    // removeRoute();
    // map.fire('draw.delete');

    console.log(beginpt);
    console.log(endpt);

    var snapped_begin = turf.nearestPointOnLine(this.line, beginpt, { units: 'kilometers' }).geometry.coordinates;
    var snapped_end = turf.nearestPointOnLine(this.line, endpt, { units: 'kilometers' }).geometry.coordinates;

    var sliced = turf.lineSlice(snapped_begin, snapped_end, this.line);
    console.log(sliced);
    this.datosTramo = {
      'nombreTroncal': this.nombreTroncal,
      'tramo': sliced
    };


    var snapped_begin_mapbox: mapboxgl.LngLatLike = { lng: snapped_begin[0], lat: snapped_begin[1] };
    var snapped_end_mapbox: mapboxgl.LngLatLike = { lng: snapped_end[0], lat: snapped_end[1] };
    // console.log(snapped_begin);
    // console.log(snapped_end);
    let _self = this;
    let b_marker = new mapboxgl.Marker({ draggable: true })
      .setLngLat(snapped_begin_mapbox)
      .addTo(this.map);
    this.markers.push(b_marker);
    b_marker.on('dragend', ($event) => this.onDragEnd($event));

    let e_marker = new mapboxgl.Marker({ draggable: true })
      .setLngLat(snapped_end_mapbox)
      .addTo(this.map);
    this.markers.push(e_marker);
    e_marker.on('dragend', ($event) => this.onDragEnd($event));

    this.map.addLayer({
      "id": "route",
      "type": "line",
      "source": {
        "type": "geojson",
        "data":
          sliced
      },
      "layout": {
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-color": "#DD77DD",
        "line-width": 10
      }
    });
    this.draw.deleteAll();
    //this.displayInstructions(snapped_begin, snapped_end, distance, sliced.geometry.coordinates);
    /*if (this.isEditing === false)
      this.openModal();*/

  }

  validateRange(e) {
    // var tecla = (document.all) ? e.keyCode : e.which;
    // if (tecla == 8 || tecla == 46) {
    //   return true;
    // }
    // if (tecla == 101) {
    //   return false;
    // }
    // var patron = /[A-Za-z0-9]/;
    // var tecla_final = String.fromCharCode(tecla);
    // return patron.test(tecla_final);

    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);
    
    if (!charStr.match(/^[0-9-.,]+$/)) {
      if (charStr != ".") {
        e.preventDefault();
      }
    }
  }

  dibujarTramo() {

    /*var data = this.draw.getAll();
    var lastFeature = data.features.length - 1;
    var coords = data.features[lastFeature].geometry.coordinates;

    var radius = [];
    coords.forEach(element => {
      radius.push(25);
    });*/

    // console.log(data);

    var beginpt: any = [this.lonini, this.latini];//data.features[lastFeature].geometry.coordinates[0];
    var endpt: any = [this.longfi, this.latfin];//data.features[lastFeature].geometry.coordinates[data.features[lastFeature].geometry.coordinates.length - 1];

    let punto = turf.point(beginpt);
    var distance = turf.pointToLineDistance(punto, this.line);
    if (distance < 1.5) {
      // removeRoute();
      // map.fire('draw.delete');

      console.log(beginpt);
      console.log(endpt);

      var snapped_begin = turf.nearestPointOnLine(this.line, beginpt, { units: 'kilometers' }).geometry.coordinates;
      var snapped_end = turf.nearestPointOnLine(this.line, endpt, { units: 'kilometers' }).geometry.coordinates;

      var sliced = turf.lineSlice(snapped_begin, snapped_end, this.line);
      console.log(sliced);
      this.datosTramo = {
        'nombreTroncal': this.nombreTroncal,
        'tramo': sliced
      };


      var snapped_begin_mapbox: mapboxgl.LngLatLike = { lng: snapped_begin[0], lat: snapped_begin[1] };
      var snapped_end_mapbox: mapboxgl.LngLatLike = { lng: snapped_end[0], lat: snapped_end[1] };
      // console.log(snapped_begin);
      // console.log(snapped_end);
      let _self = this;
      let b_marker = new mapboxgl.Marker({ draggable: true })
        .setLngLat(snapped_begin_mapbox)
        .addTo(this.map);
      this.markers.push(b_marker);
      b_marker.on('dragend', ($event) => this.onDragEnd($event));

      let e_marker = new mapboxgl.Marker({ draggable: true })
        .setLngLat(snapped_end_mapbox)
        .addTo(this.map);
      this.markers.push(e_marker);
      e_marker.on('dragend', ($event) => this.onDragEnd($event));

      this.map.addLayer({
        "id": "route",
        "type": "line",
        "source": {
          "type": "geojson",
          "data":
            sliced
        },
        "layout": {
          "line-join": "round",
          "line-cap": "round"
        },
        "paint": {
          "line-color": "#DD77DD",
          "line-width": 10
        }
      });
    }
    else {
      const message = 'Los puntos no estan cerca a la troncal.';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    }
  }

  onDragEnd(event) {
    this.lonini = this.markers[0].getLngLat().lng;
    this.latini = this.markers[0].getLngLat().lat;
    this.longfi = this.markers[1].getLngLat().lng;
    this.latfin = this.markers[1].getLngLat().lat;
    if (this.map.getLayer("route")) {
      this.map.removeLayer("route");
      this.map.removeSource("route");
    }

    this.markers.map(marker => {
      marker.remove();
    });
    this.markers = [];
    this.dibujarTramo();
  }

  displayInstructions(begin, end, distance, linestring) {
    // Target the sidebar to add the instructions
    var directions = document.getElementById('directions');

    var results = [];

    results.push('<br><li>BEGIN: ' + begin + '</li>');
    results.push('<br><li>END: ' + end + '</li>');
    results.push('<br><li>DISTANCE: ' + distance + ' kilometers</li>');
    results.push('<br><li>LINESTRING: ' + linestring + '</li>');

    //directions.innerHTML = '<br><h2>TRAMA </h2>' + results;
  }

  // If the user clicks the delete draw button, remove the layer if it exists
  removeRoute(event) {
    //clearMarkers();
    if (this.map.getSource('route')) {
      this.map.removeLayer('route');
      this.map.removeSource('route');
    } else {
      return;
    }
  }

  changeStatusModal(event) {
    this.contentToShowFormModal = -1;
    this.limpiarCapas();
  }

  changeStatusModal2(event) {
    this.contentToShowFormModalParametros = -1;
  }

  statusModal(event) {
    if (this.isEditing)
      this.cancelar();
  }

  limpiarCapas() {
    if (this.map.getLayer("line")) {
      this.map.removeLayer("line");
      this.map.removeSource("line");
    }
    if (this.map.getLayer("route")) {
      this.map.removeLayer("route");
      this.map.removeSource("route");
    }
    this.markers.map(marker => {
      marker.remove();
    });
    this.draw.deleteAll();
    this.latfin = "";
    this.latini = "";
    this.lonini = "";
    this.longfi = "";
    this.datosTramo = undefined;
  }

  editarElemento(event) {

    this.parametros.map(parametro => {
      if (parametro.id == event) {
        this.datosParametro = {
          "id": parametro.id,
          "nombre": parametro.parametro,
          "valor": parametro.valor
        }
      }
    });
    this.contentToShowFormModalParametros = 25;
    this.cdr.markForCheck();

  }

  procesarJson() {
    if (this.parametros !== undefined) {
      let dataArray = [];
      for (let index = 0; index < this.parametros.length; index++) {
        dataArray.push([
          this.parametros[index]['id'].toString(),
          this.parametros[index]['parametro'].toString(),
          this.parametros[index]['valor'].toString(),
          ''
        ]);

        if (index === this.parametros.length - 1) {
          this.armarTabla(dataArray);
        }
      }

      if (!this.parametros.length) {
        this.armarTabla(dataArray);
      }
    }
  }

  armarTabla(data) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Parámetro', 'Valor', 'Acciones'],
      typeColumn: [, , , { tipo: 'acciones', acciones: ['editar'] }],
      searchColumn: [0, 1],
      hideColumns: [0],
      showPag: false,
      showExport: false,
      configColumns: {
        id: 0,
        columnClick: 0,
      },
      data: data
    };
    this.loading = false;
    this.cdr.markForCheck();
  }

  cancelar() {
    this.router.navigate([`${environment.folders.routeAdmin}tramos/buscar`]);
  }

  modificarTramo() {
    if (this.datosTramo === undefined) {

      this.uiService.showMessage("Por favor pinte un tramo.");
      this.cdr.markForCheck();

    } else {
      let urlGetReport = environment.endpoints.crearTramo + this.idTramo;
      let params = [];

      this.parametros.map(p => {
        if (p.valor !== undefined && p.valor !== "") {
          params.push({
            "name": p.parametro,
            "value": p.valor
          });
        }
      });
      this.lastRequest = this.tramo;
      this.lastRequest["params"] = params;
      if (this.datosTramo !== undefined) {
        this.lastRequest["linestream"] = JSON.stringify(this.datosTramo.tramo.geometry.coordinates);
        this.lastRequest.trunkLine = this.datosTramo.nombreTroncal;
      }
      else
        this.lastRequest["linestream"] = this.tramo.linestream;
      //this.conditionalLoader = true;
      this.http.ejecutarServicioPut(urlGetReport, this.lastRequest).subscribe(res => {
        //this.conditionalLoader = false;
        let responseMessage = {
          title: "",
          message: ""
        };
        if (res.status === 200) {
          //this.limpiar();
          responseMessage.title = "Tramo modificado Exitosamente";
          responseMessage.message = "Los datos del tramo han sido modificados con exito"
          this.operacionExitosa(responseMessage);

        }
        else {
          responseMessage.title = "HA OCURRIDO UN ERROR AL MODIFICAR EL TRAMO";
          responseMessage.message = "No se pudo modificado el tramo"
          this.operacionExitosa(responseMessage);
        }

      }, (error: Response) => {
        if (this._actualDialog) { this._actualDialog.dismiss(); }
        const message = 'Error cargando datos ';
        this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
        this.cancelar();
      });
    }
  }

  operacionExitosa(responseMessage) {
    this.contentToShowFormModal = -1;
    this.configModalRango = {
      status: 1,
      icono: 'smart-icon-notificaciones',
      titulo: responseMessage.title,
      textos: [
        responseMessage.message
      ],
      botones: [
        {
          label: 'Ok',
          class: '',
          accion: 'Ok'
        }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowFormModal = -1;
      this.configModalRango = { status: -1 };
    }, 300);
  }

  guardarTramo() {

    let urlGetReport = environment.endpoints.crearTramo;
    let params = [];
    if (this.nombre === undefined || this.nombre === "") {

      this.uiService.showMessage("El nombre del tramo es requerido.");
      this.cdr.markForCheck();

    }
    else if (this.sentidotramo === "All") {

      this.uiService.showMessage("Por favor seleccione el sentido del tramo.");
      this.cdr.markForCheck();

    }
    else if (this.datosTramo === undefined) {

      this.uiService.showMessage("Por favor pinte un tramo.");
      this.cdr.markForCheck();

    } else {
      this.parametros.map(p => {
        if (p.valor !== undefined && p.valor !== "") {
          params.push({
            "name": p.parametro,
            "value": p.valor
          });
        }
      });
      this.lastRequest = {
        "name": this.nombre,
        "direction": this.sentidotramo,
        'trunkLine': this.datosTramo.nombreTroncal,
        "linestream": JSON.stringify(this.datosTramo.tramo.geometry.coordinates),
        "params": params
      }
      this.conditionalLoader = true;
      this.http.ejecutarServicioPost(urlGetReport, this.lastRequest).subscribe(res => {
        this.conditionalLoader = false;
        let responseMessage = {
          title: "",
          message: ""
        };
        if (res.status === 200) {
          this.limpiar();
          responseMessage.title = "Tramo Creado Exitosamente";
          responseMessage.message = "Los datos del tramo han sido creados con exito"
          this.operacionExitosa(responseMessage);
        }
        else {
          responseMessage.title = "HA OCURRIDO UN ERROR AL CREAR EL TRAMO";
          responseMessage.message = "No se pudo crear el tramo"
          this.operacionExitosa(responseMessage);
        }
      }, (error: Response) => {
        if (this._actualDialog) { this._actualDialog.dismiss(); }
        const message = 'Error cargando datos ';
        this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
      });
    }
  }

  limpiar() {
    this.nombre = "";
    this.sentidotramo = "";
    this.limpiarCapas();
    this.consultarParametros();
  }
}
