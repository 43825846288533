import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';

import { AdminCategoriaDto } from '../../../../../shared/dto/admin-categoria-dto';
import { environment } from '../../../../../../../environments/environment';
import { HttpService } from '../../../../../shared/services/http.service';
import { UIService } from '../../../../_services/UI.service';
import { Response } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'kt-generar-contratista',
  templateUrl: './generar-contratista.component.html',
  styleUrls: ['./generar-contratista.component.scss']
})
export class GenerarContratistaComponent implements OnInit {
  formModelCategoria: AdminCategoriaDto = new AdminCategoriaDto;
  conditionalLoader: boolean;

  @Input() modificarDatos: AdminCategoriaDto;
  @Output() actionModificarCategoria  = new EventEmitter();

  contentToShow: number = -1;
  configModal: {} = { status: -1 };
  statusMostrar: boolean = true;

  constructor(private http: HttpService,
    private cdr: ChangeDetectorRef,
    private uiService: UIService,
    private router: Router         
    ) {
    
   }

   ngOnInit() {
    if( this.modificarDatos && this.modificarDatos.registryId ){
      this.statusMostrar = false;
      this.formModelCategoria = this.modificarDatos;
    }
  }

  guardarRegistryContratista(){
    let formCategoria = this.formModelCategoria.toJS();
    delete formCategoria['id'];
    if( formCategoria.registryId && formCategoria.name && formCategoria.registryLabel ){
      this.conditionalLoader = true;
      let rutaRegistry = environment.endpoints.adminRegistryCreateContratista;
      if( this.modificarDatos && this.modificarDatos.registryId ){
         rutaRegistry = environment.endpoints.adminContratistaEdit;
      }
      this.http.ejecutarServicioPost( rutaRegistry, formCategoria ).subscribe( (res: Response) => {
        this.conditionalLoader = false;
        if ( res.ok === true ) {

          let resPost = res.json();
          
          // Mostrar Modal
          this.openModalRegistryCreado();
  
        } else {
          // TODO :: MANEJAR EXCEPCION
          this.uiService.showMessage( res.json()['message'] );
        }
      }, (error: Response) => {
        this.uiService.showMessage( error );
      });
    }
  }

  openModalRegistryCreado(){
    this.contentToShow = 1;
    let nombreProfile:string = '';
    if( this.modificarDatos && this.modificarDatos.registryId ){
      nombreProfile = `<b>${this.formModelCategoria.registryId}</b>`;
    }

    this.configModal = {
      status: 1,
      icono: 'smart-icon-categorias',
      titulo: 'Contratista',
      textos: [
                `Se guardó exitosamente el Contratista ${nombreProfile}`
              ],
      botones: [
              {
                label: 'Aceptar',
                class: '',
                accion: !this.modificarDatos ? 'creacionCategoria' : 'modificarCategoria'
              }
      ]
    }
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShow = -1;
      this.configModal = { status: -1 }
    }, 300);
  }
    
  cerrarModal(){
    this.router.navigateByUrl(`${environment.folders.routeAdmin}puertas/crear-contratista`);
  }

  changeStatusModal(event){
    if( this.modificarDatos && this.modificarDatos.registryId ){
      // Modificar
      this.actionModificarCategoria.emit('cerrar');
    }else{
      // Crear
      this.formModelCategoria = new AdminCategoriaDto;
    }
  }

}
