export class AdminGroupDto {

    name: string;
    registry: string;
    metaData: {};

    constructor(data?: any) {
        if (data !== undefined) {
            this.name = data['name'] !== undefined ? data['name'] : null;
            this.registry = data['registry'] !== undefined ? data['registry'] : null;
            this.metaData = data['metaData'] !== undefined ? data['metaData'] : [];
        }
    }

    static fromJS(data: any): AdminGroupDto {
        return new AdminGroupDto(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['name'] = this.name !== undefined ? this.name : null;
        data['registry'] = this.registry !== undefined ? this.registry : null;
        data['metaData'] = this.metaData !== undefined ? this.metaData : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['name'] = this.name !== undefined ? this.name : '';
        data['registry'] = this.registry !== undefined ? this.registry : '';
        data['metaData'] = this.metaData !== undefined ? this.metaData : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        // return new AdminCategoriaDto(JSON.parse(json));
    }

}
