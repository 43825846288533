import { NavegacionItem } from '../../dto/navegacion-item';
import { NavegacionService } from '../../../trans/administrador/_services/navegacion.service';
import { AuthService } from '../../../../core/auth/_services/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'kt-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() breadcrumbs$: Observable<any[]>;
  breadcrumbs: any[] = [];
  userName: string = 'Usuario';

  constructor( auth: AuthService,
                private router: Router,
                private location: PlatformLocation,
                private navegacionService: NavegacionService) {
    this.userName = auth.getUser() !==undefined && auth.getUser().username;
    this.getLocalStore();
    location.onPopState((breadcrumbs) => {
      console.log('pressed back!');
      this.breadcrumbs.pop();
    });
    
  }

  getLocalStore(){
    this.breadcrumbs = JSON.parse( localStorage.getItem( "breadcrumbsSH") );
  }

  setLocalStore(){
    localStorage.setItem( "breadcrumbsSH", JSON.stringify( this.breadcrumbs ) );
  }

  ngOnInit() {    

    this.navegacionService.itemSelected$.subscribe( (item: NavegacionItem) => {      
      
      if(item == null){
        this.breadcrumbs = [];
        this.setLocalStore();
      }

      if ( item && this.breadcrumbs.findIndex(x => x.id==item.id) === -1 ) {
        this.breadcrumbs.push( item );
        this.setLocalStore();
      }
      
    });

    /*
    this.router.events
    .subscribe((ruta) => {
        console.log("RUTA=>",ruta);
    });
    */
  }


  home(){
    this.router.navigate(['/']);
    this.navegacionService.boxSelected(null);
    this.breadcrumbs = [];
    this.setLocalStore();
    this.breadcrumbs.pop();
  }

  itemClick(url){
    this.breadcrumbs = [];
    console.log( url );
    console.log("AMBIENTE",environment)
    console.log("VA HACIA=>",[environment.folders.routeAdmin + url])
    this.router.navigate([environment.folders.routeAdmin + url]);
    this.setLocalStore();
  }

  

  toBack() {
    const item: NavegacionItem = this.breadcrumbs.pop();
    if ( !item.items ) {
      // ITEM FINAL NO MAS CAJAS
        const actualLoc = window.location.href;
      if ( actualLoc.indexOf('navegacion') === -1 ) {
        if ( item && this.breadcrumbs.findIndex(x => x.id==item.id) === -1 ) {
          this.breadcrumbs.push( item );
          this.setLocalStore();

        }

        history.back();
        setTimeout( () => {
          if ( window.location.href.indexOf('navegacion') > -1) {
            this.toBack();
          }
        }, 100);
      } else {
        this.router.navigate([`${environment.folders.routeAdmin}navegacion`]);
        setTimeout( () => {
          this.navegacionService.toBack( this.breadcrumbs[this.breadcrumbs.length - 1]);
        }, 100);
      }
    } else {
      this.navegacionService.toBack( this.breadcrumbs[this.breadcrumbs.length - 1]);
    }
    this.setLocalStore();
  }

}