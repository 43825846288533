import { UIService } from './../../../../_services/UI.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Select2OptionData, Options } from 'select2';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminGroupDto } from '../../../../../shared/dto/admin-group-dto';
import { environment } from '@environments/environment';
import { HttpService } from '../../../../../shared/services/http.service';
import { Response } from '@angular/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'kt-dispositivos-formulario',
  templateUrl: './dispositivos-formulario.component.html',
  styleUrls: ['./dispositivos-formulario.component.scss'],
  host: { 'class': 'sths-tb-element' }
})
export class DispositivosFormularioComponent implements OnInit {
  // Select2
  public selectFitroTipoVehiculo: Array<Select2OptionData>;
  public selectFitroTipologia: Array<Select2OptionData>;
  public selectFitroTipoMotor: Array<Select2OptionData>;
  public selectFitroCarroceria: Array<Select2OptionData>;
  public selectFitroTipoFreno: Array<Select2OptionData>;

  public options: Options;

  // Cargador
  conditionalLoader: boolean;
  busForm: FormGroup;
  rutasMenu: {};
  OperatorStyle: boolean = false;
  idRegistry: string;

  formModelGroup: AdminGroupDto = new AdminGroupDto();
  idNumber: number = 1;
  metaDatas: {}[] = [];

  // Modales
  contentToShow: number = -1;
  configModal: {} = { status: -1 };

  constructor(private _route: ActivatedRoute,
    private http: HttpService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private uiService: UIService) {
    this.formModelGroup.metaData = {
      placa: '',
      tipo_vehiculo: '',
      tipologia: '',
      tipo_motor: '',
      carroceria: ''
    };

    this.idRegistry = this._route.snapshot.paramMap.get('registryId');

    // Select2
    this.http
			.ejecutarServicioGet(environment.endpoints.adminBusesRegistry)
			.subscribe(
				(eventRecords: Response) => {
					switch (eventRecords.status) {
						case 200:
						case 201:
						case 202:
						case 204:
							let formConfig = eventRecords.json().questions;
							// Se llenan los combobox
							let select = formConfig.find(
								i => Number(i.id) === 55
							);
							if (select) {
								this.selectFitroTipoVehiculo = select.possibleAnswers.map(
									i => {
										return {
											id: this.eliminarDiacriticos(i.answerValue).toLowerCase(),
											text: i.answer
										};
									}
								);
							}

							select = formConfig.find(i => Number(i.id) === 56);
							if (select) {
								this.selectFitroTipologia = select.possibleAnswers.map(
									i => {
										return {
											id: i.answerValue,
											text: i.answer
										};
									}
								);
							}

							select = formConfig.find(i => Number(i.id) === 57);
							if (select) {
								this.selectFitroTipoMotor = select.possibleAnswers.map(
									i => {
										return {
											id: this.eliminarDiacriticos(i.answerValue).toLowerCase(),
											text: i.answer
										};
									}
								);
							}

							select = formConfig.find(i => Number(i.id) === 58);
							if (select) {
								this.selectFitroCarroceria = select.possibleAnswers.map(
									i => {
										return {
											id: i.answerValue,
											text: i.answer
										};
									}
								);
              }
              select = formConfig.find(i => Number(i.id) === 59);
							if (select) {
								this.selectFitroTipoFreno = select.possibleAnswers.map(
									i => {
										return {
											id: i.answerValue,
											text: i.answer
										};
									}
								);
							}
							this.cdr.markForCheck();
							break;
						default:
							this.uiService.showMessage(
								'Error actualizando Grupo'
							);
							this.cdr.markForCheck();
							break;
					}
				},
				e => {
					this.uiService.showMessage('Error actualizando Grupo');
					this.cdr.markForCheck();
				}
			);


    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: 'select2-dropdown-forms'
    };

    this.rutasMenu = [
      {
        label: 'Crear Bus',
        icon: 'smart-icon-grupo-dispositivos',
        ruta: `${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}/create`
      }
    ];
    // {
    //   label: 'Asociar dispositivos',
    //   icon: 'smart-icon-asociar-dispositivos',
    //   ruta: `${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}/asociar`
    // }

    // Armar MetaData Basicos
    // this.formModelGroup.metaData.push(placa);

  }

  ngOnInit() {
    this.busForm = new FormGroup({
			name: new FormControl('', [Validators.required]),
			placa: new FormControl('', [Validators.required]),
			tipo_vehiculo: new FormControl('', [Validators.required]),
			date: new FormControl('', [Validators.required]),
			carroceria: new FormControl('', [Validators.required]),
			deposito: new FormControl('', [Validators.required]),
			marca: new FormControl('', [Validators.required]),
      maxpasajeros: new FormControl('', [Validators.required]),
      capacidadBaterias: new FormControl('', [Validators.required]),
      unidadCarga: new FormControl('', [Validators.required]),
			mcarroceria: new FormControl('', [Validators.required]),
			mchasis: new FormControl('', [Validators.required]),
			mmotor: new FormControl('', [Validators.required]),
			mvehiculo: new FormControl('', [Validators.required]),
			nemicion: new FormControl('', [Validators.required]),
			pesovacio: new FormControl('', [Validators.required]),
			smotor: new FormControl('', [Validators.required]),
			tipo_motor: new FormControl('', [Validators.required]),
			tipologia: new FormControl('', [Validators.required]),
			ubasica: new FormControl('', [Validators.required]),
			upeso: new FormControl('', [Validators.required]),
			tipoFrenos: new FormControl('', [Validators.required])
    });
    this.formControlValueChanged();
  }

  formControlValueChanged() {
    const upesoControl = this.busForm.get('upeso');
    const ubasicaControl = this.busForm.get('ubasica');
    const depositoControl = this.busForm.get('deposito');
    const capacidadBateriasControl = this.busForm.get('capacidadBaterias');
    const unidadCargaControl = this.busForm.get('unidadCarga');
    const tipo_motorControl = this.busForm.get('tipo_motor');
    
 
    tipo_motorControl.valueChanges.subscribe(
        (value: string) => {
          if(value === 'electrico') {
            console.log('entre aqui :>> ', );
            upesoControl.clearValidators();
            ubasicaControl.clearValidators();
            depositoControl.clearValidators();
            this.f.upeso.disable();
            this.f.ubasica.disable();
            this.f.deposito.disable();
            capacidadBateriasControl.setValidators([Validators.required]);
            unidadCargaControl.setValidators([Validators.required]);
            this.f.capacidadBaterias.enable();
            this.f.unidadCarga.enable();
          }
          else {
            upesoControl.setValidators([Validators.required]);
            ubasicaControl.setValidators([Validators.required]);
            depositoControl.setValidators([Validators.required]);
            this.f.upeso.enable();
            this.f.ubasica.enable();
            this.f.deposito.enable();
            capacidadBateriasControl.clearValidators();
            unidadCargaControl.clearValidators();
            this.f.capacidadBaterias.disable();
            this.f.unidadCarga.disable();
          }
 
          upesoControl.updateValueAndValidity();
          ubasicaControl.updateValueAndValidity();
          depositoControl.updateValueAndValidity();
          capacidadBateriasControl.updateValueAndValidity();
          unidadCargaControl.updateValueAndValidity();
        });
  }

  agregarDato() {
    this.idNumber++;
    this.metaDatas.push({
      key: '',
      value: ''
    });
  }

  eliminarMetadata(item) {
    const index: number = this.metaDatas.indexOf(item);
    if (index !== -1) {
      this.metaDatas.splice(index, 1);
    }
  }

  get f() {
    return this.busForm.controls;
  }

  createGroup() {
    this.conditionalLoader = true;
    let paramsGroup = this.busForm.value;
    paramsGroup['registry'] = this.idRegistry;
    this.http.ejecutarServicioPost(environment.endpoints.adminRegistryGroupCreate, paramsGroup).subscribe((res: Response) => {
      if (res.ok === true) {
        let paramsMetaData = {
         ...this.busForm.value,
          registry: this.idRegistry
        };

        for (let index = 0; index < this.metaDatas.length; index++) {
          paramsMetaData[this.metaDatas[index]['key']] = this.metaDatas[index]['value'];
        }

        this.http.ejecutarServicioPost(environment.endpoints.adminRegistryGroupAddMetadata, paramsMetaData).subscribe((resMeta: Response) => {
          console.log('adminRegistryGroupAddMetadata II', resMeta);
        });

        setTimeout(() => {
          this.conditionalLoader = false;
          this.openModalGroupCreado();
          this.cdr.markForCheck();
        }, 3000);

      } else {
        this.uiService.showMessage(res.json()['message']);
        this.conditionalLoader = false;
        this.cdr.markForCheck();
      }
    }, (error: Response) => {
      this.conditionalLoader = false;
      this.uiService.showMessage(error);
      this.cdr.markForCheck();
    });
  }

  openModalGroupCreado() {
    this.contentToShow = 1;
    let nombreUser: string = '';
    this.configModal = {
      status: 1,
      icono: 'smart-icon-grupo-dispositivos',
      titulo: 'Bus',
      textos: [
                `Se guardó exitosamente el Bus ${nombreUser}`
              ],
      botones: [
        {
          label: 'Aceptar',
          class: '',
          accion: 'creacionGroup'
          // accion: !this.modificarDatos ? 'creacionUsuario' : 'modificarUsuario'
        }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShow = -1;
      this.configModal = { status: -1 };
    }, 300);
  }

  changeStatusModal(event) {
    this.router.navigateByUrl(`${environment.folders.routeAdmin}registry/tipo2/groups/${this.idRegistry}`);
  }

  eliminarDiacriticos(texto) {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
}

  validateForm(event) {
    setTimeout(() => {
      if (this.formModelGroup.name) {
        this.formModelGroup.name = this.formModelGroup.name.replace(/[^a-zA-Z0-9_]/g, ' ').replace(' ', '').trim();
      }
      this.formModelGroup.metaData['placa'] = this.formModelGroup.metaData['placa'].replace(/[^a-zA-Z0-9-]/g, ' ').replace(' ', '').trim();
      this.cdr.markForCheck();
      for (let metadata in this.metaDatas) {
        //this.metaDatas[metadata]['key'] = this.metaDatas[metadata]['key'].replace(/[^a-zA-Z0-9_]/g, ' ').replace(' ', '').trim();
        this.metaDatas[metadata]['key'] = this.metaDatas[metadata]['key'].replace(/[^a-zA-Z0-9_-]/g, ' ').replace(' ', '').trim();
        //this.metaDatas[metadata]['value'] = this.metaDatas[metadata]['value'].replace(/[^a-zA-Z0-9_]/g, ' ').replace(' ', '').trim();
        this.metaDatas[metadata]['value'] = this.metaDatas[metadata]['value'].trim();
      }
    }, 100);
  }
}
