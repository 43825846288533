import { LayerDTO } from './../../shared/dto/layer-dto';
import { BasicLayerDTO } from './../../shared/dto/basic-layer-dto';
import { ConfigDTO } from './../../shared/dto/config-dto';
import { environment } from './../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Response } from '@angular/http';
import { HttpService } from './../../shared/services/http.service';
import { Injectable } from '@angular/core';
import { TopicLayerDTO } from '../../shared/dto/topic-layer-dto';
import { FilterDTO } from '../../shared/dto/filter-dto';
import { debug } from 'util';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UtilService } from '../_services/util.service';
import { Subject } from 'rxjs'


@Injectable({
  providedIn: 'root'
})
export class CapaService implements OnInit {

  private _filters: FilterDTO[] = [];
  private _views: ConfigDTO;
  private _filtersTM: Array<any> = [];
  private _topic: TopicLayerDTO;
  private _basicLayers: LayerDTO[] = [];
  private _busqueda: any;
  private _resultadobusqueda: any;
  private _featureSelected: any;
  private _capasPorDefecto: any;
  private _viewsUrl: string = environment.endpoints.views;
  private _filterUrl = environment.endpoints.adminRegistries;

  private filtersSource = new BehaviorSubject<any>(this._filters);
  public filtersSource$ = this.filtersSource.asObservable();
  private viewsSource = new BehaviorSubject<ConfigDTO>(this._views);
  public viewsSource$ = this.viewsSource.asObservable();

  private filterData = new BehaviorSubject<any>([]);
  public filterData$ = this.filterData.asObservable();

  private topicSource = new BehaviorSubject<TopicLayerDTO>(this._topic);
  public topicSource$ = this.topicSource.asObservable();

  private capasBasicasService = new BehaviorSubject<LayerDTO[]>(this._basicLayers);
  public capasBasicasService$ = this.capasBasicasService.asObservable();

  public busquedaService = new BehaviorSubject<any>(this._busqueda);
  public busquedaService$ = this.busquedaService.asObservable();

  private capasPorDefecto = new BehaviorSubject<any>(this._capasPorDefecto);
  public capasPorDefecto$ = this.capasPorDefecto.asObservable();

  public resultadoBusquedaService = new BehaviorSubject<any>(this._resultadobusqueda);
  public resultadoBusquedaService$ = this.resultadoBusquedaService.asObservable();

  public featureSelected = new BehaviorSubject<any>(this._featureSelected);
  public featureSelected$ = this.featureSelected.asObservable();

  public currentBuses = new Subject<any>();

  currentBuses$ = this.currentBuses.asObservable();

  constructor(private httpService: HttpService) {
    this.updateLayers();
    this.getFilterList();
  }

  updateLayers() {

    this.httpService.ejecutarServicioGet(this._viewsUrl).subscribe((res: Response) => {

      if (res.status === 200) {
		    this._views = ConfigDTO.fromJS(res.json());
        // console.log('vistas',this._views);
        this.viewsSource.next(this._views);
      } else {
        // TODO :: MANEJAR EXCEPCION
      }
    });
  }

  getFilterList() {
    this.httpService.ejecutarServicioGet(`${this._filterUrl}`).subscribe((res: any) => {
      const info = res.json();

      const filterInfo = info.reduce((acc, el) => ({
        ...acc,
        [el.id]: el
      }), {});

      this.httpService.ejecutarServicioGet(`${this._filterUrl}/id`).subscribe((res: any) => {
        // console.log('res-> filter' ,res);
        if (res.status === 200) {
          const data = res.json();
          const values = [];
          // console.log(data);
          // console.log(filterInfo);
          for (const key in data) {
            // console.log(data[key]);
            if (key) {
              if (filterInfo[data[key]]) {
                const value = {
                  name : filterInfo[data[key]].name,
                  id: key,
                  checked: true
                };
                values.push(value);
              }
            }
            this._filtersTM = [{
              name: 'Operador',
              values
            }];
            this.filterData.next(this._filtersTM);
          }
        }
      });
    });
  }

  setActualTopic(topic: TopicLayerDTO) {

    if (this._topic === topic) {
      this._topic = null;
    } else {
      this._topic = topic;
    }
    
    this.topicSource.next(this._topic);
  }

  emitActualTopic(topic: TopicLayerDTO) {
    this._topic = topic;
    this.topicSource.next(this._topic);
  }

  getFilters() {
    return this._filters;
  }

  getActualTopic() {
    return this._topic;
  }

  addFilter(filter: FilterDTO) {

    let layerExist = false;
    for (let i = 0; i < this._filters.length; i++) {

      if (filter.layer_url === this._filters[i].layer_url) {
        this._filters[i].filters_by.push(filter.filters_by[0]);
        layerExist = true;
      }

    }

    if (!layerExist) {
      this._filters.push(filter);
    }
    // console.log('filters added', this._filters)
    this.filtersSource.next(this._filters);
  }


  updateFilter(filter: FilterDTO) {

    let layerExist = false;

    for (let i = 0; i < this._filters[0].filters_by.length; i++) {

      if (filter.filters_by[0].value === this._filters[0].filters_by[i].value) {
        this._filters[0].filters_by[i].checked = filter.filters_by[0].checked;
      }

    }


    this.filtersSource.next(this._filters);
  }

  addBasicLayer(capa: LayerDTO) {
    let find = false;
    if (this._basicLayers.length > 0) {
      if (this._basicLayers[0].hasOwnProperty("disabled")) {
        if (this._basicLayers[0].hasOwnProperty("resource") === false) {
          this._basicLayers = [];
        }
      }
    }
    for (let i = 0; i < this._basicLayers.length; i++) {
      if (this._basicLayers[i].layer_id === capa.layer_id) {
        find = true
      }
    }
    if (find === false) {
      this._basicLayers.push(capa);
      this.capasBasicasService.next(this._basicLayers);
    }
    // console.log('capas añadidas', this._basicLayers);
  }

  getBasicLayer() {
    return this._basicLayers;
  }

  emitAddBasicLayer() {
    this.capasBasicasService.next(this._basicLayers);
  }


  removeBasicLayer(capa: LayerDTO) {
    for (let i = 0; i < this._basicLayers.length; i++) {
      if (this._basicLayers[i].layer_id === capa.layer_id) {
        this._basicLayers.splice(i, 1);
        i--;
      }
    }
    this.capasBasicasService.next(this._basicLayers);
  }
  removeFilter(filter: FilterDTO) {

    for (let i = 0; i < this._filters.length; i++) {

      if (filter.layer_url === this._filters[i].layer_url) {

        for (let j = 0; j < this._filters[i].filters_by.length; j++) {

          if (this._filters[i].filters_by[j].value === filter.filters_by[0].value) {
            this._filters[i].filters_by.splice(j, 1);
            j--;
          }
        }

        if (this._filters[i].filters_by.length === 0) {
          this._filters.splice(i, 1);
          i--;
        }
      }
    }

    this.filtersSource.next(this._filters);
  }

  removeBasicLayerByArr(capa: LayerDTO[]) {
    for (let i = 0; i < this._basicLayers.length; i++) {
      let finded: boolean = false;
      for (let j = 0; j < capa.length; j++) {
        if (this._basicLayers[i].layer_id === capa[j].layer_id) {
          finded = true;
        }
      }
      if (finded === false) {
        this._basicLayers.splice(i, 1);
        i--;
      }
    }
    this.capasBasicasService.next(this._basicLayers);
  }

  resetBasicLayer() {
    this._basicLayers = [];
    this.capasBasicasService.next(this._basicLayers);
  }

  resetFeatureSelected(){
    this._featureSelected = undefined;
    this.featureSelected.next(this._featureSelected);
  }

  resetAll() {
    this.resetBasicLayer();
    this.setActualTopic(null);
    this.resetFeatureSelected();
  }

  ngOnInit() {
  }

  capaIsSelected(name: string) {
    let find = false;
    for (let i = 0; i < this._basicLayers.length; i++) {
      if (this._basicLayers[i].name === name) {
        find = true
      }
    }
    return find;
  }
  
  changeBusesEmmit(buses){
    return this.currentBuses.next(buses);
  }

}
