import {
  Component, OnInit, ChangeDetectorRef, Input
} from '@angular/core';
import { LayoutUtilsService, MessageType } from './../../../../core/_base/crud/utils/layout-utils.service';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'select2';
import { HttpService } from '../../../shared/services/http.service';
import { MatSnackBarRef } from '@angular/material';
import { AuthService } from './../../../../core/auth/_services/auth.service';
import * as _ from 'lodash';
import { UIService } from '../../_services/UI.service';
import { UtilService } from '../_services/util.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'kt-alarma',
  templateUrl: './alarma.component.html',
  styleUrls: ['./alarma.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})

export class AlarmaComponent implements OnInit {

  public selectFitroTipologia: Array<Select2OptionData>;
  baseUrl = environment.endpoints.alarmsModule;
  titleComponent: string = "Crear Alarma";
  alarmTypes: any;
  isEditRango: boolean = false;
  isModificarAlarma: boolean = false;
  isBuscarAlarma: boolean = false;
  submitIsDisabled: boolean = true;
  disabledFields: boolean = false;
  breakTypeOptions: any;
  vehicleTypeOptions: any;
  signalTypeOptions: any;
  rangoInicialMax: any = [];
  possibleSignalOptions: any;
  //possibleUnitOptions: any;
  groupedData: any;
  idGroupedData: any;
  idToModify: number = 0;
  loading: boolean = false;

  public signalOptions: any = [];
  public rangeOptions: any = [];
  public tmpItemToEdit: any = {};
  private _actualDialog: MatSnackBarRef<any>;

  @Input() datosEditarAlarma: {
    alarmTypes: '',
    alarma: any,
    rangos: any,
    motorTypeOptions: [],
    breakTypeOptions: [],
    possibleSignalOptions: [],
    possibleUnitOptions: any,
    signalTypeOptions: [],
    signalOptions: [],
    rangeOptions: [],
    vehicleTypeOptions: [],
    baseUrl: "",
    id: "",
    title: "",
    isModificarAlarma: false
  };

  public tmpItem = {
    name: '',
    alarmType: '',
    motorType: '',
    signalType: '',
    signal: '',
    breakType: '',
    initialRange: '',
    finalRange: '',
    measureUnit: '',
    range: '',
    id: '',
    prioridad: "",
    vehicleType: ''
  };

  datosEditarRango = {
    alarma: [],
    alarmType: '',
    rango: [],
    motorTypeOptions: {},
    breakTypeOptions: [],
    possibleSignalOptions: {},
    possibleUnitOptions: {},
    signalTypeOptions: {},
    signalOptions: [],
    rangeOptions: {},
    title: '',
    id: '',
    actualRange: '',
    baseUrl: this.baseUrl
  };

  // Select2
  public motorTypeOptions: Array<Select2OptionData>;
  public selectPrioridad: Array<Select2OptionData>;
  options: any = {
    centered: true,
    size: 'lg',
    windowClass: 'sths-modal-style',
    backdropClass: 'sths-backdrop'
  };

  // modal
  contentToShowModalRango: number = -1;
  configModalRango: {} = { status: -1 };

  // Tabla
  data: any;
  dataSet: any = [];
  count = 0;
  contentToShow: number = -1;
  configTabla: {} = { status: -1 };
  paginador: object = {
    count: 5,
    page: 1,
    total: 0,
    maxPages: 5
  };

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private cdr: ChangeDetectorRef,
    private http: HttpService,
    private auth: AuthService,
    private layoutUtilsService: LayoutUtilsService,
    private uiService: UIService) {
    UtilService.editarAlarma.subscribe(data => {
      if (this.groupedData != null) {
        this.isEditRango = true;
        this.tmpItemToEdit = data;
        this.operacionRango(data);
        //this.resetView();
      }
    });

    this.data = [];

    let urlTipoAlarma = this.baseUrl + '/alarmTypes/list';
    this.http.ejecutarServicioGet(urlTipoAlarma).subscribe(res => {
      const self = this;
      self.alarmTypes = [];
      if (res.json().length > 0) {
        let dataAlarmTypes = res.json();
        for (var i = 0; i < dataAlarmTypes.length; i++) {
          self.alarmTypes.push({
            id: dataAlarmTypes[i].id,
            text: dataAlarmTypes[i].name,
            answer: dataAlarmTypes[i].name,
            answerValue: dataAlarmTypes[i].name,
          })
        }
      }
      if (this.tmpItem.alarmType === "") {
        this.tmpItem.alarmType = this.alarmTypes.find(x => x.text === "CDEG");
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    let urlMotor = this.baseUrl + '/motors/list';
    this.http.ejecutarServicioGet(urlMotor).subscribe(res => {
      const self = this;
      self.motorTypeOptions = [];
      if (res.json().length > 0) {
        let dataMotor = res.json();
        for (var i = 0; i < dataMotor.length; i++) {
          self.motorTypeOptions.push({
            id: dataMotor[i].id,
            text: dataMotor[i].name,
          })
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });


    let urlVehicle = this.baseUrl + '/vehicleTypes/list';
    console.log("URL", urlVehicle)
    this.http.ejecutarServicioGet(urlVehicle).subscribe(res => {
      const self = this;
      self.vehicleTypeOptions = [];
      if (res.json().length > 0) {
        let dataVehicle = res.json();
        for (var i = 0; i < dataVehicle.length; i++) {
          self.vehicleTypeOptions.push({
            id: dataVehicle[i].id,
            text: dataVehicle[i].name,
          })
        }
      }
      this.cdr.markForCheck();

      
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });


    let urlBrake = this.baseUrl + '/brakes/list';
    this.http.ejecutarServicioGet(urlBrake).subscribe(res => {
      const self = this;
      self.breakTypeOptions = [];
      if (res.json().length > 0) {
        let dataBrake = res.json();
        for (var i = 0; i < dataBrake.length; i++) {
          self.breakTypeOptions.push({
            id: dataBrake[i].id,
            text: dataBrake[i].name,
          })
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });


    let urlSignalTypes = this.baseUrl + '/signalTypes/list';
    this.http.ejecutarServicioGet(urlSignalTypes).subscribe(res => {
      const self = this;
      self.signalTypeOptions = [];
      if (res.json().length > 0) {
        let dataSignalType = res.json();
        for (var i = 0; i < dataSignalType.length; i++) {
          self.signalTypeOptions.push({
            id: dataSignalType[i].id,
            text: dataSignalType[i].name,
          })
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    let urlSignal = this.baseUrl + '/signals/list';
    this.http.ejecutarServicioGet(urlSignal).subscribe(res => {
      const self = this;
      if (res.json().length > 0) {
        self.possibleSignalOptions = [];
        if (res.json().length > 0) {
          let dataSignal = res.json();
          for (var i = 0; i < dataSignal.length; i++) {
            self.possibleSignalOptions.push({
              id: dataSignal[i].id,
              text: dataSignal[i].name,
              unit: dataSignal[i].unit,
              signalType: dataSignal[i].signalType
            })
          }
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    let urlRanges = this.baseUrl + '/rangeTypes/list';
    this.http.ejecutarServicioGet(urlRanges).subscribe(res => {
      const self = this;
      var nivelNumber = 0;
      var textToShow = "";
      var dynamicClass = "";
      var colors = ["#ff110f", "#ffb700", "#009d00", "#ffb700", "#ff110f"]
      self.rangeOptions = [];
      if (res.json().length > 0) {
        let dataRange = res.json();
        for (var i = 0; i < dataRange.length; i++) {
          var positionRange = "center";
          if (i === 0) {
            positionRange = "first";
          }
          if (i === (dataRange.length - 1)) {
            positionRange = "last";
          }
          dynamicClass = "ranges ranges-" + positionRange + " btn sths-btn-color btn-pill btn" + dataRange[i].name + "Range";
          if (dataRange[i].text === "Normal") {
            textToShow = dataRange[i].name + " (No genera alarma)";
          }
          else {
            textToShow = dataRange[i].name;
          }
          nivelNumber++;
          self.rangeOptions.push({
            id: dataRange[i].id,
            text: textToShow,
            isDisabled: false,
            nivel: nivelNumber,
            class: dynamicClass,
            color: colors[i]
          })
        }
      }
      this.cdr.markForCheck();
    }, (error: Response) => {
      if (this._actualDialog) { this._actualDialog.dismiss(); }
      const message = 'Error cargando datos ';
      this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
    });

    this.armarTabla(this.data);
  }

  getItemText(data?) {
    if (data == null) {
      if (this.isEditRango === true) {
        data = this.tmpItemToEdit;
      }
      else {
        data = this.tmpItem;
      }
    }

    var text = {
      range: "",
      motor: "",
      señal: "",
      tipoSeñal: "",
      tipoFreno: "",
      vehicleType: ""
    };

    for (var i = 0; i < this.rangeOptions.length; i++) {
      if (data.range == this.rangeOptions[i].id) {
        text.range = this.rangeOptions[i].text;
      }
    }
    for (var j = 0; j < this.motorTypeOptions.length; j++) {
      if (data.motorType == this.motorTypeOptions[j].id) {
        text.motor = this.motorTypeOptions[j].text
      }
    }
    for (var k = 0; k < this.possibleSignalOptions.length; k++) {
      if (data.signal == this.possibleSignalOptions[k].id) {
        text.señal = this.possibleSignalOptions[k].text;
      }
    }
    for (var n = 0; n < this.signalTypeOptions.length; n++) {
      if (data.signalType == this.signalTypeOptions[n].id) {
        text.tipoSeñal = this.signalTypeOptions[n].text;
      }
    }
    for (var l = 0; l < this.breakTypeOptions.length; l++) {
      if (data.breakType == this.breakTypeOptions[l].id) {
        text.tipoFreno = this.breakTypeOptions[l].text;
      }
    }
    for (var l = 0; l < this.vehicleTypeOptions.length; l++) {
      if (data.vehicleType == this.vehicleTypeOptions[l].id) {
        text.vehicleType = this.vehicleTypeOptions[l].text;
      }
    }
    return text;
  }

  createGroupData(data) {
    var values = this.getItemText();
    if (this.groupedData == null && this.isEditRango === false) {
      this.groupedData = {
        'nombreAlarma': {
          'text': data.name,
          'id': data.name
        },
        'prioridad': {
          'id': data.prioridad
        },
        'tipoAlarma': {
          'id': data.alarmType.id
        },
        'tipoMotor': {
          'text': values.motor,
          'id': data.motorType
        },
        'tipoSeñal': {
          'text': values.tipoSeñal,
          'id': data.signalType
        },
        'señal': {
          'text': values.señal,
          'id': data.signal
        },
        'vehicleType': {
          'text': values.vehicleType,
          'id': data.vehicleType
        },
        'tipoFreno': {
          'text': values.tipoFreno,
          'id': data.breakType
        },
        'unidad': {
          'text': data.measureUnit,
          'id': data.measureUnit
        },
        'rangos': [
        ],
      };
    }

    if (this.groupedData != null) {
      if (this.isEditRango === true) {
        for (var i = 0; i < this.groupedData.rangos.length; i++) {
          if (this.tmpItemToEdit.id == this.groupedData.rangos[i].idTable) {
            this.groupedData.rangos[i] = {
              "idTable": data.id,
              "rangoInicial": data.initialRange,
              "rangoFinal": data.finalRange,
              "rangeType": {
                "text": values.range,
                "id": data.range
              }
            }
          }
        }
      }
      else {
        this.groupedData.rangos.push({
          "idTable": data.id,
          "rangoInicial": data.initialRange,
          "rangoFinal": data.finalRange,
          "rangeType": {
            "text": values.range,
            "id": data.range
          }
        })
      }
    }
  }

  limpiar() {
    this.dataSet = [];
    this.groupedData = null;
    this.rangoInicialMax = [];
    this.armarTabla(this.dataSet);
    this.submitIsDisabled = true;
    this.tmpItem.name = "";
    this.tmpItem.motorType = "";
    this.tmpItem.signalType = "";
    this.tmpItem.signal = "";
    this.tmpItem.breakType = "";
    this.tmpItem.measureUnit = "";
    this.signalOptions = [];
    this.tmpItem.prioridad = "";
    this.tmpItem.vehicleType = "";
    this.resetView();
  }

  saveAlarm() {
    var responseMessage = {
      title: "Alarma añadida satisfactoriamente",
      message: 'Los datos de la alarma han sido adicionados satisfactoriamente'
    };
    this.operacionExitosa(responseMessage);
  }

  operacionExitosa(responseMessage) {
    this.contentToShowModalRango = -1;
    this.configModalRango = {
      status: 1,
      icono: 'smart-icon-notificaciones',
      titulo: responseMessage.title,
      textos: [
        responseMessage.message
      ],
      botones: [
        {
          label: 'Ok',
          class: '',
          accion: 'Ok'
        }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModalRango = -1;
      this.configModalRango = { status: -1 };
    }, 300);
  }

  confirmEliminarRango(event) {
    this.idGroupedData = event;
    this.contentToShowModalRango = -1;

    this.configModalRango = {
      status: 1,
      icono: 'smart-icon-eliminar',
      titulo: 'Eliminar Rango',
      textos: [
        '¿Esta seguro que quiere eliminar este Rango?'
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Eliminar',
          class: '',
          accion: 'eliminar'
        }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowModalRango = -1;
      this.configModalRango = { status: -1 };
    }, 300);
  }

  operacionRango(data?) {
    this.loading = true;
    var responseMessage = {
      title: "",
      message: ""
    };
    this.contentToShowModalRango = -1;

    if (data == null) {
      data = this.tmpItem;
    }

    if (data.id === "") {
      data.id = this.dataSet.length;
      this.tmpItem.id = this.dataSet.length;
    }
    else {
      this.tmpItem.id = data.id;
    }

    var resValidations = this.validations();
    if (!resValidations.result) {
      this.loading = false;
      this.uiService.showMessage(resValidations.message);
      this.cdr.markForCheck();
      this.isEditRango = false;
      return;
    }
    else {
      this.createGroupData(data);

      if (this.isEditRango === true) {
        this.modalService.dismissAll();
        responseMessage.title = "Rango Editado Exitosamente";
        responseMessage.message = "Los datos del rango han sido editados con exito"
      }
      else {
        responseMessage.title = "Rango Adicionado Exitosamente";
        responseMessage.message = 'Los datos del rango han sido adicionados con éxito';
      }

      this.setDataToTable();
      this.isEditRango = false;

      if (this.dataSet.length !== 0) {
        this.submitIsDisabled = false;
      }
      else {
        this.submitIsDisabled = true;
      }
    }
    this.loading = false;
    this.operacionExitosa(responseMessage);
    this.resetView();
  }

  resetView() {
    this.tmpItem.initialRange = "";
    this.tmpItem.finalRange = "";
    this.tmpItem.range = "";

    this.tmpItem.id = "";
    for (var j = 0; j < this.rangeOptions.length; j++) {
      this.rangeOptions[j].isDisabled = false;
    }

    if (this.dataSet.length > 0) {
      this.disabledFields = true;
    }
    else {
      this.disabledFields = false;
    }
  }

  setRange(id) {
    this.tmpItem.range = id;
    for (var j = 0; j < this.rangeOptions.length; j++) {
      this.rangeOptions[j].isDisabled = false;
    }
    for (var i = 0; i < this.rangeOptions.length; i++) {
      if (this.rangeOptions[i].id == id) {
        this.rangeOptions[i].isDisabled = true;
      }
    }
  }


  changeSignalType(event) {
    this.tmpItem.measureUnit = "";
    this.signalOptions = [];
    for (let item of this.possibleSignalOptions) {
      if (event == item.signalType.id) {
        this.signalOptions.push({
          'id': item.id,
          'text': item.text,
        });
      }
    }
    this.cdr.markForCheck();
  }


  selectSignal(event) {
    if (event !== null && event !== undefined && event !== "") {
      this.possibleSignalOptions.map(item => {
        if (item.id == event) {
          this.tmpItem.measureUnit = item.unit;
        }
      });
    }
  }

  volver() {
    UtilService.volverBuscarAlarma.emit("volver");
  }

  editarRango(event) {
    this.contentToShowModalRango = 9;
    this.datosEditarRango.alarma = [];
    this.datosEditarRango.rango = [];
    this.datosEditarRango.rango = this.getRango(event);
    this.datosEditarRango.alarma = this.groupedData;
    this.datosEditarRango.rangeOptions = this.rangeOptions;
    this.datosEditarRango.id = event;
    this.datosEditarRango.actualRange = this.tmpItem.range;
    this.datosEditarRango.alarmType = this.tmpItem.alarmType;
    this.cdr.markForCheck();
  }

  statusModal(event) {
    this.contentToShowModalRango = -1;
    if (event == "eliminar") {
      this.eliminarRango();
    }
  }

  eliminarRango() {
    var length = this.dataSet.length;
    for (let i = length - 1; i >= 0; i) {
      if (this.dataSet[i][0] === this.idGroupedData) {
        this.dataSet.splice(i, 1);
      }
      if (this.idGroupedData === this.groupedData.rangos[i].idTable) {
        this.groupedData.rangos.splice(i, 1)
        break;
      }
      i--;
    }

    this.armarTabla(this.dataSet);

    if (this.dataSet.length !== 0) {
      this.submitIsDisabled = false;
    }
    else {
      this.submitIsDisabled = true;
    }

    if (this.dataSet.length === 0) {
      this.disabledFields = false;
    }
  }

  getRango(id) {
    let rangeToReturn: any;
    for (let i = 0; i < this.groupedData.rangos.length; i++) {
      if (parseInt(id) === parseInt(this.groupedData.rangos[i].idTable)) {
        rangeToReturn = this.groupedData.rangos[i];
        return rangeToReturn;
      }
    }
  }

  setDataToTable() {
    this.dataSet = [];
    if (this.groupedData != null) {
      for (let i = 0; i < this.groupedData.rangos.length; i++) {
        this.dataSet.push([
          this.groupedData.rangos[i].idTable,
          this.groupedData.nombreAlarma.text.toString(),
          this.groupedData.prioridad.id,
          this.groupedData.tipoFreno.text.toString(),
          this.groupedData.tipoMotor.text.toString(),
          this.groupedData.vehicleType.text.toString(),
          this.groupedData.tipoSeñal.text.toString(),
          this.groupedData.señal.text.toString(),
          this.groupedData.unidad.text.toString(),
          this.groupedData.rangos[i].rangeType.text,
          this.groupedData.rangos[i].rangoInicial,
          this.groupedData.rangos[i].rangoFinal,
          ''
        ]);
      }
      this.paginador['total'] = this.dataSet.length;
      this.armarTabla(this.dataSet);
    }
  }

  armarTabla(dataSet) {
    console.log("Paginador: ", this.paginador)
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Nombre de alarma', 'Prioridad', 'Tipo de frenos', ' Tipo de motor', ' Tipo de vehículo', 'Tipo de señal', 'Señal', 'Unidad de medida', 'Rango', 'Valor inicial', 'Valor final', 'Acciones'],
      typeColumn: [, , , , , , , , , , , , { tipo: 'acciones', acciones: ['editar', 'eliminar'] }],
      searchColumn: [1, 2],
      hideColumns: [0],
      showPag: false,
      showExport: false,
      configColumns: {
        id: 0,
        columnClick: 0,
      },
      data: dataSet,
      paginador: this.paginador
    };
  }

  validationsGuardarAlarma() {
    for (var i = 0; i < Object.values(this.groupedData).length; i++) {
      let value: any = Object.values(this.groupedData)[i];
      if (value.text === "") {
        return {
          message: "Todos los campos son obligatorios",
          result: false
        }
      }
      else {
        return {
          result: true
        };
      }
    }
  }

  validations() {
    var tmpDataToValidate: any;
    if (this.isEditRango === true) {
      tmpDataToValidate = this.tmpItemToEdit;
    }
    else {
      tmpDataToValidate = this.tmpItem;
      this.tmpItemToEdit = {};
    }

    if ((this.isEditRango === true && this.dataSet.length === 1) === false) {
      if (this.getItemText(tmpDataToValidate).range !== "") {
        for (var n = 0; n < this.dataSet.length; n++) {
          if (this.dataSet[n] != null) {
            if (this.dataSet[n].indexOf(this.getItemText(tmpDataToValidate).range) > 0 && this.tmpItemToEdit.actualRange !== tmpDataToValidate.range) {
              return {
                message: "Ya existe el rango '" + this.getItemText(tmpDataToValidate).range + "' adicionado",
                result: false
              }
            }
          }
        }
      }
    }

    this.dataSet.forEach(element => {
      if ((tmpDataToValidate.initialRange >= element[10] && tmpDataToValidate.initialRange <= element[11]) || (tmpDataToValidate.finalRange >= element[10] && tmpDataToValidate.finalRange <= element[11])) {
        return {
          message: "El rango esta contenido en '" + element[9] + "'",
          result: false
        }
      }
    });

    var resultRange = {
      message: "",
      result: true
    }

    this.dataSet.forEach(element => {
      let dataGridRangeIndex = this.returnIndexRange(element[9]);
      let rangeIndex;

      for (var i = 0; i < this.rangeOptions.length; i++) {
        if (tmpDataToValidate.range == this.rangeOptions[i].id) {
          rangeIndex = this.returnIndexRange(this.rangeOptions[i].text);
        }
      }

      if (rangeIndex < dataGridRangeIndex) {
        if (tmpDataToValidate.actualRangeText != element[9]) {
          if (tmpDataToValidate.finalRange >= element[10]) {
            resultRange = {
              message: "El rango ingresado debe ser menor al rango '" + element[9] + "'",
              result: false
            }
          }
        }
      }

      else {
        if (this.dataSet.length > 1 || tmpDataToValidate.actualRangeText == null) {
          if (tmpDataToValidate.actualRangeText != element[9]) {
            if (tmpDataToValidate.initialRange <= element[11]) {
              if (this.tmpItemToEdit.actualRange !== tmpDataToValidate.range) {
                resultRange = {
                  message: "El rango ingresado debe ser mayor al rango '" + element[9] + "'",
                  result: false
                }
              }
              else {
                if (rangeIndex > dataGridRangeIndex) {
                  resultRange = {
                    message: "El rango ingresado debe ser mayor al rango '" + element[9] + "'",
                    result: false
                  }
                }
              }
            }
          }
        }
      }
    });

    if (tmpDataToValidate.initialRange == null || tmpDataToValidate.finalRange == null) {
      return {
        message: "Los rangos deben contener valores numéricos",
        result: false
      }
    }

    if (this.isEditRango === false) {
      if (Object.values(tmpDataToValidate).indexOf("") > -1) {
        return {
          message: "Todos los campos son obligatorios",
          result: false
        }
      }
    }

    if (tmpDataToValidate.initialRange !== "" && tmpDataToValidate.finalRange !== "") {
      if (parseFloat(tmpDataToValidate.initialRange) >= parseFloat(tmpDataToValidate.finalRange)) {
        return {
          message: "El rango inicial debe ser menor al rango final",
          result: false
        }
      }
    }

    if (resultRange.result === false) {
      return resultRange;
    }
    return {
      result: true
    };
  }

  returnIndexRange(rangeName) {
    let index;
    switch (rangeName) {
      case "Crítico Inferior":
        index = 1;
        break;
      case "Tolerable Inferior":
        index = 2;
        break;
      case "Normal":
        index = 3;
        break;
      case "Tolerable Superior":
        index = 4;
        break;
      case "Crítico Superior":
        index = 5;
        break;
    }
    return index;
  }

  modificarAlarma() {
    let alarmToModify = {
      "id": this.idToModify,
      "priority": parseInt(this.tmpItem.prioridad),
      "ranges": [],
    }

    for (var i = 0; i < this.groupedData.rangos.length; i++) {
      alarmToModify.ranges.push({
        "minValue": parseFloat(this.groupedData.rangos[i].rangoInicial),
        "maxValue": parseFloat(this.groupedData.rangos[i].rangoFinal),
        "rangeType": {
          id: this.groupedData.rangos[i].rangeType.id
        }
      })
    }

    let urlToModify = `${this.baseUrl}/alarms/${this.idToModify}`;
    this.http.ejecutarServicioPut(urlToModify, alarmToModify).subscribe(res => {

      let responseMessage = {
        title: "",
        message: ""
      };
      if (res.status === 204) {
        responseMessage.title = "Alarma Modificada Exitosamente";
        responseMessage.message = "Los datos de la alarma han sido modificados con exito"
        this.operacionExitosa(responseMessage);
        this.groupedData.prioridad.id = this.tmpItem.prioridad;
        this.setDataToTable();
      }
      else {
        responseMessage.title = "HA OCURRIDO UN ERROR AL MODIFICAR LA ALARMA";
        responseMessage.message = "No se pudo modificar la alarma"
        this.operacionExitosa(responseMessage);
      }
    })

  }

  guardarAlarma() {
    this.loading = true;
    var resValidations = this.validationsGuardarAlarma();
    if (!resValidations.result) {
      this.uiService.showMessage(resValidations.message);
      this.loading = false;
      this.cdr.markForCheck();
      return;
    }
    else {
      let alarmToSave = {
        "name": this.groupedData.nombreAlarma.text,
        "priority": parseInt(this.groupedData.prioridad.id),
        "alarmType": {
          "id": parseInt(this.groupedData.tipoAlarma.id)
        },
        "vehicleType": {
          "id": this.groupedData.vehicleType.id
        },
        "motor": {
          "id": parseInt(this.groupedData.tipoMotor.id)
        },
        "brakes": {
          "id": parseInt(this.groupedData.tipoFreno.id)
        },
        "signal": {
          "id": parseInt(this.groupedData.señal.id)
        },
        "ranges": [],
      }

      for (var i = 0; i < this.groupedData.rangos.length; i++) {
        alarmToSave.ranges.push({
          "minValue": parseFloat(this.groupedData.rangos[i].rangoInicial),
          "maxValue": parseFloat(this.groupedData.rangos[i].rangoFinal),
          "rangeType": {
            id: this.groupedData.rangos[i].rangeType.id
          }
        })
      }

      let urlSaveAlarm = this.baseUrl + "/alarms/";
      this.http.ejecutarServicioPost(urlSaveAlarm, alarmToSave).subscribe(res => {
        let responseMessage = {
          title: "",
          message: ""
        };
        if (res.status === 201) {
          this.limpiar();
          responseMessage.title = "Alarma Creada Exitosamente";
          responseMessage.message = "Los datos de la alarma han sido creados con exito"
          this.operacionExitosa(responseMessage);
        }
        else {
          responseMessage.title = "HA OCURRIDO UN ERROR AL CREAR LA ALARMA";
          responseMessage.message = "No se pudo crear la alarma"
          this.operacionExitosa(responseMessage);
        }
        this.loading = false;
      }, (error: Response) => {
        this.loading = false;
        if (this._actualDialog) { this._actualDialog.dismiss(); }
        const message = 'Error cargando datos ';
        this._actualDialog = this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false, 0, 'top');
      });
    }
  }

  validateRange(e) {
    // var tecla = (document.all) ? e.keyCode : e.which;
    // if (tecla == 8 || tecla == 46) {
    //   return true;
    // }
    // if (tecla == 101) {
    //   return false;
    // }
    // var patron = /[A-Za-z0-9]/;
    // var tecla_final = String.fromCharCode(tecla);
    // return patron.test(tecla_final);

    e = e || window.event;
    var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    var charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/)) {
      if (charStr != ".") {
        e.preventDefault();
      }
    }
  }

  updateNombreAlarma(nombreAlarma: any) {
    if (this.groupedData != null) {
      this.groupedData.nombreAlarma.text = nombreAlarma;
    }
    for (let i = 0; i < this.dataSet.length; i++) {
      this.dataSet[i].splice(1, 1, nombreAlarma)
    }
    this.armarTabla(this.dataSet);
  }

  changePrioridad(event) {
    for (let i = 0; i < this.dataSet.length; i++) {
      this.dataSet[i].splice(2, 1, event)
    }
    this.armarTabla(this.dataSet);
  }

  ngOnInit() {
    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: 'select2-dropdown-forms'
    };
    

    if (this.datosEditarAlarma) {
    
      this.isModificarAlarma = this.datosEditarAlarma.isModificarAlarma;
      this.titleComponent = "Modificar la alarma '" + this.datosEditarAlarma.alarma.name + "'";

      this.alarmTypes = this.datosEditarAlarma.alarmTypes;
      this.motorTypeOptions = this.datosEditarAlarma.motorTypeOptions;
      this.breakTypeOptions = this.datosEditarAlarma.breakTypeOptions;
      this.possibleSignalOptions = this.datosEditarAlarma.possibleSignalOptions;
      this.signalTypeOptions = this.datosEditarAlarma.signalTypeOptions;
      this.vehicleTypeOptions = this.datosEditarAlarma.vehicleTypeOptions;

      this.tmpItem.name = this.datosEditarAlarma.alarma.name;
      this.tmpItem.prioridad = this.datosEditarAlarma.alarma.priority;
      this.tmpItem.alarmType = this.datosEditarAlarma.alarma.alarmType.id.toString();
      this.tmpItem.motorType = this.datosEditarAlarma.alarma.motor.id.toString();
      this.tmpItem.signalType = this.datosEditarAlarma.alarma.signal.signalType.id.toString();
      this.tmpItem.signal = this.datosEditarAlarma.alarma.signal.id.toString();
      this.tmpItem.breakType = this.datosEditarAlarma.alarma.brakes.id.toString();
      this.tmpItem.vehicleType = this.datosEditarAlarma.alarma.vehicleType.id.toString();
      this.idToModify = this.datosEditarAlarma.alarma.id;

      this.changeSignalType(this.tmpItem.signalType);
      this.selectSignal(this.tmpItem.signal);
      this.tmpItem.measureUnit = this.datosEditarAlarma.alarma.signal.unit

      this.createGroupData(this.tmpItem);

      this.groupedData.rangos = [];
      for (var i = 0; i < this.datosEditarAlarma.rangos.length; i++) {
        this.groupedData.rangos.push({
          "idTable": i,
          "rangoInicial": this.datosEditarAlarma.rangos[i].minValue,
          "rangoFinal": this.datosEditarAlarma.rangos[i].maxValue,
          "rangeType": {
            "text": this.datosEditarAlarma.rangos[i].rangeType.name,
            "id": this.datosEditarAlarma.rangos[i].rangeType.id
          }
        })
      }

      this.setDataToTable();

      if (this.dataSet.length !== 0) {
        this.submitIsDisabled = false;
      }
      else {
        this.submitIsDisabled = true;
      }

      if (this.dataSet.length === 0) {
        this.disabledFields = false;
      }
    }

    this.selectPrioridad = [
      {
        text: "10",
        id: 10
      },
      {
        text: "20",
        id: 20,
      },
      {
        text: "30",
        id: 30
      }
    ]
  }
}