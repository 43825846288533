import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '@app/views/shared/services/http.service';
import { Response } from '@angular/http';
import { Select2OptionData, Options } from 'select2';
import { UIService } from '@app/views/trans/_services/UI.service';
@Component({
	selector: 'kt-eventos-redes',
	templateUrl: './eventos-redes.component.html',
	styleUrls: ['./eventos-redes.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class EventosRedesComponent implements OnInit {
	configTable: any;

	public selectRedes: Array<Select2OptionData>;
	public options: Options;

	// Paginador
	paginador: any = {
		count: 10,
		page: 1
	};

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1, data: [] };

	// Modales
	contentToShowModal: number = -1;
	configModal: {} = { status: -1 };

	conditionalLoader: boolean;
	data: Array<any>;
	// Id Eliminar
	eliminarId: any;
	showModal: boolean = false;
	// Modal Modificar
	contentToModificar: number = -1;
	idEventoRedes: any;
	selectedItem: any = {
		12: '',
		13: '',
		14: ''
	};
	msjErrorPalabra: boolean = false;
	msjErrorHashtag: boolean = false;

	constructor(
		private cdr: ChangeDetectorRef,
		private http: HttpService,
		private uiService: UIService
	) {}

	ngOnInit() {
		this.obtenerDatos();
		// Select2
		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
		// selectRedes
		this.selectRedes = [];
	}

	obtenerDatos() {
		this.conditionalLoader = true;
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminParamNetworksEventList
			)
			.subscribe((eventRecords: Response) => {
				this.conditionalLoader = false;
				let data = eventRecords.json().answerGroups;
				let formConfig = eventRecords.json().questions;

				let select = formConfig.find(i => Number(i.id) === 12);
				if (select) {
					this.selectRedes = select.possibleAnswers.map(i => {
						return { id: i.answerValue, text: i.answer };
					});
				}
				this.data = data;
				this.procesarJson(data);
			});
	}

	procesarJson(data) {
		let dataArray = [];
		data.sort((a: any, b: any) => {
			if (a['12'] < b['12']) {
				return -1;
			}
			if (a['12'] > b['12']) {
				return 1;
			}
			return 0;
		});
		dataArray = data.map(itm => {
			return [itm.answerGroup, itm['12'], itm['13'], itm['14'], ''];
		});
		this.armarTabla(dataArray);
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: ['ID', 'Red social', 'Palabra', 'Hashtag', 'Acciones'],
			typeColumn: [
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'eliminar'] }
			],
			showSearch: false,
			searchColumn: [1],
			searchTitle: 'Buscar Categoría',
			hideColumns: [0],
			showPag: false,
			leftsColumns: [1],
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data,
			paginador: this.paginador
		};
		this.cdr.markForCheck();
	}

	editarEventos(id) {
		let element = this.data.find(itm => itm.answerGroup === id);
		this.selectedItem = { ...element };
		this.idEventoRedes = id;
		this.showModal = true;
		this.cdr.markForCheck();
	}

	eliminarEventos(id) {
		// Guardar en variable de Eliminar Global
		this.eliminarId = id;
		this.contentToShowModal = 1;

		this.configModal = {
			status: 1,
			icono: 'smart-icon-eliminar',
			titulo: '¿Estas seguro que desea eliminar los datos?',
			textos: [
				'Los datos en la fila seleccionada serán eliminados permanentemente'
			],
			botones: [
				{
					label: 'Cancelar',
					class: 'sths-btn-cancel',
					accion: 'cancelarEventos'
				},
				{
					label: 'Eliminar',
					class: '',
					accion: 'eliminar'
				}
			]
		};
		this.cdr.markForCheck();
		setTimeout(() => {
			this.contentToShowModal = -1;
			this.configModal = { status: -1 };
		}, 300);
	}

	eventGuardar(event) {
		this.http
			.ejecutarServicioPost(
				environment.endpoints.adminParamNetworksEvent,
				{ answer: event }
			)
			.subscribe((res: Response) => {
				this.obtenerDatos();
			});
	}
	updateEvent() {
		if (this.selectedItem[13] === "") {
			this.msjErrorPalabra = true;
			this.cdr.markForCheck();
			return;
		} else {
			this.msjErrorPalabra = false;
			this.cdr.markForCheck();
		}
		if (this.selectedItem[14] === "") {
			this.msjErrorHashtag = true;
			this.cdr.markForCheck();
			return;
		} else {
			this.msjErrorHashtag = false;
			this.cdr.markForCheck();
		}
		this.http
			.ejecutarServicioPut(
				environment.endpoints.adminParamNetworksEventUpdate +
					this.selectedItem.answerGroup,
				{
					answer: {
						12: this.selectedItem[12],
						13: this.selectedItem[13],
						14: this.selectedItem[14]
					}
				}
			)
			.subscribe((res: Response) => {
				if (res.status === 200) {
					this.uiService.showMessage('Parámetros actualizados');
					this.showModal = false;
					this.obtenerDatos();
				} else {
					this.showError(res);
				}
			});
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		// this.loading = false;
	}

	statusModal(event) {
		this.showModal = false;
		this.msjErrorPalabra = false;
		this.msjErrorHashtag = false;
		this.selectedItem = {};
		//this.cdr.markForCheck();
	}
	changeStatusModal(event) {
		// switch (event) {
		// case 'eliminarEventos':
		if (event === 'eliminar') {
			this.conditionalLoader = true;
			this.http
				.ejecutarServicioDelete(
					environment.endpoints.adminParamNetworksEventUpdate +
						this.eliminarId
				)
				.subscribe((res: Response) => {
					this.conditionalLoader = false;
					if (res.status === 200) {
						this.showModal = false;
						this.obtenerDatos();
					} else {
						this.showError(res);
					}
				});
		}
	}
	// changeStatusEdit( event ) {
	//   this.contentToModificar = -1;
	//   this.idEventosRedes = '';
	//   console.log( 'changeStatusEdit', event );
	// }
}
