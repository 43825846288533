import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges
} from '@angular/core';

import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { AdminDriverDTO } from '../../../../shared/dto/admin-driver-dto';
import { AdminGroupDto } from '../../../../shared/dto/admin-group-dto';
import { UIService } from '../../../_services/UI.service';
import {
	NgbDateNativeAdapter,
	NgbDateAdapter
} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-conductor-registro-carnets',
	templateUrl: './conductor-registro-carnets.component.html',
	styleUrls: ['./conductor-registro-carnets.component.scss'],
	providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ConductorRegistroCarnetsComponent implements OnInit, OnChanges {
	formModelGroup: AdminGroupDto = new AdminGroupDto();

	@Input() model: any;
	@Output() modelChange = new EventEmitter();
	@Input() showTable: boolean = false;

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {
	}

	public options: Options;

	contentToModificar: number = -1;
	// Paginador
	paginador: object = {
		count: 10,
		page: 1,
		total: 0,
		maxPages: 5
	};

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1 };

	// formModelDriver: AdminDriverDTO = new AdminDriverDTO();
	// @Input() modificarDatos: AdminDriverDTO;

	loading: boolean = false;
	ngOnInit() {
		// this.loading = true;
	}

	ngOnChanges(changes: SimpleChanges) {
		// let trainings = changes.model.currentValue.trainings;
		// this.procesarJson(trainings);
	}

	procesarJson(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				data[index]['id'].toString(),
				data[index]['id'].toString(),
				data[index]['fechaexp'].toString(),
				data[index]['fechaingreso'].toString(),
				'',
				data[index]['active']
			]);

			if (index === data.length - 1) {
				this.armarTabla(dataArray);
			}
		}

		if (!data.length) {
			this.armarTabla(dataArray);
		}
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: [
				'ID',
				'Númerocarnet',
				'FechaExpedición',
				'FechaCreación',
				'Acciones',
				'Estado'
			],
			typeColumn: [
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'eliminar'] },
				{ tipo: 'estado' }
			],
			searchColumn: [0, 1, 2],
			hideColumns: [0],
			showPag: false,
			showExport: false,
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data,
			paginador: this.paginador
		};
		this.loading = false;
		this.cdr.markForCheck();
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	/**
	 * Valida que la fecha de expedición sea menor o igual a la fecha de creación si ésta última está seleccionada.
	 */
	validateCarnetExpeditionDate() {
		setTimeout(()=>{
			if(this.model.carnetExpeditionDate !== null && this.model.carnetExpeditionDate !== undefined && this.model.carnetExpeditionDate !== "") {
				if (this.model.carnetExpirationDate !== null && this.model.carnetExpirationDate !== undefined && this.model.carnetExpirationDate !== "") {
					let fechaCreacion = new  Date(this.model.carnetExpirationDate);
					let fechaCreacionFormato = fechaCreacion.getFullYear() + "" + ((fechaCreacion.getMonth()+1) < 10 ? '0' + (fechaCreacion.getMonth()+1) : (fechaCreacion.getMonth()+1)) + "" 
					+ (fechaCreacion.getDate() < 10 ? '0' + fechaCreacion.getDate() : fechaCreacion.getDate());
					let fechaExpedicionCarnet = new  Date(this.model.carnetExpeditionDate);
					let fechaExpedicionCarnetFormato = fechaExpedicionCarnet.getFullYear() + "" + ((fechaExpedicionCarnet.getMonth()+1) < 10 ? '0' + (fechaExpedicionCarnet.getMonth()+1) : (fechaExpedicionCarnet.getMonth()+1)) + "" 
					+ (fechaExpedicionCarnet.getDate() < 10 ? '0' + fechaExpedicionCarnet.getDate() : fechaExpedicionCarnet.getDate());
					if(Number(fechaExpedicionCarnetFormato) > Number(fechaCreacionFormato)){
						this.uiService.showMessage("Seleccione una fecha menor o igual a la fecha de creación.")
						this.model.carnetExpeditionDate = null;
						this.cdr.markForCheck();
					}
				}
			}
		})
	}

	/**
	 * Valida que la fecha de creación debe ser mayor o igual a la fecha de expedición.
	 */
	validateCarnetExpirationDate() {
		setTimeout(()=>{
			if (this.model.carnetExpeditionDate !== null && this.model.carnetExpeditionDate !== undefined && this.model.carnetExpeditionDate !== "") {
				if(this.model.carnetExpeditionDate !== null && this.model.carnetExpeditionDate !== undefined && this.model.carnetExpeditionDate !== "") {
					let fechaCreacion = new  Date(this.model.carnetExpirationDate);
					let fechaCreacionFormato = fechaCreacion.getFullYear() + "" + ((fechaCreacion.getMonth()+1) < 10 ? '0' + (fechaCreacion.getMonth()+1) : (fechaCreacion.getMonth()+1)) + "" 
					+ (fechaCreacion.getDate() < 10 ? '0' + fechaCreacion.getDate() : fechaCreacion.getDate());
					let fechaExpedicionCarnet = new  Date(this.model.carnetExpeditionDate);
					let fechaExpedicionCarnetFormato = fechaExpedicionCarnet.getFullYear() + "" + ((fechaExpedicionCarnet.getMonth()+1) < 10 ? '0' + (fechaExpedicionCarnet.getMonth()+1) : (fechaExpedicionCarnet.getMonth()+1)) + "" 
					+ (fechaExpedicionCarnet.getDate() < 10 ? '0' + fechaExpedicionCarnet.getDate() : fechaExpedicionCarnet.getDate());
					if(Number(fechaExpedicionCarnetFormato) > Number(fechaCreacionFormato)){
						this.uiService.showMessage("Seleccione una fecha mayor o igual a la fecha de expedición.")
						this.model.carnetExpirationDate = null;
						this.cdr.markForCheck();
					}
				}
			}
		})
	}
}
