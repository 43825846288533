import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'kt-datos-adicionales',
  templateUrl: './datos-adicionales.component.html',
  styleUrls: ['./datos-adicionales.component.scss']
})
export class DatosAdicionalesComponent implements OnInit {

	addDataForm:FormGroup;

  	constructor(private sthsFormBuilder: FormBuilder) {
  	}

  	ngOnInit() {
  		this.addDataForm = this.sthsFormBuilder.group({
	      sthsDatosAdicionales: this.sthsFormBuilder.array([this.sthsCrearDatosAdicionales()])
	    })
  	}

  	sthsCrearDatosAdicionales() {
	  	return this.sthsFormBuilder.group({
    	    titulo: [''],
	        valor: ['']
      	})
	}

  	sthsAgregarDatosAdicionales() {
  		(this.addDataForm.controls['sthsDatosAdicionales'] as FormArray).push(this.sthsCrearDatosAdicionales())
  	}

  	sthsRemoverDatosAdicionales() {
  	}

  	guardarCambios() {
  		console.log( this.addDataForm.value );
  	}

}
