export class AdminUserDTO {
	id: number;
	username: string;
	name: string;
	email: string;
	userProfiles: string;
	entity: string;
	password: string;
	passwordSalt: string;
	lastName: string;
	creationDate : String;
	creationUser : String;

	constructor(data?: any) {
		if (data !== undefined) {
			this.id = data['id'] !== undefined ? data['id'] : null;
			this.username = data['username'] !== undefined ? data['username'] : null;
			this.name = data['name'] !== undefined ? data['name'] : null;
			this.lastName = data['lastName'] !== undefined ? data['lastName'] : null;
			this.email = data['email'] !== undefined ? data['email'] : null;
			this.userProfiles =
				data['userProfiles'] !== undefined
					? data['userProfiles']
					: null;
			this.entity = data['entity'] !== undefined ? data['entity'] : null;
			this.password =
				data['password'] !== undefined ? data['password'] : null;
			this.passwordSalt =
				data['passwordSalt'] !== undefined
					? data['passwordSalt']
					: null;
			this.creationDate = data['creationDate'] !== undefined ? data['creationDate'] : null;
			this.creationUser = data['creationUser'] !== undefined ? data['creationUser'] : null;
		}
	}

	static fromJS(data: any): AdminUserDTO {
		return new AdminUserDTO(data);
	}

	toJS(data?: any) {
		data = data === undefined ? {} : data;
		data['id'] = this.id !== undefined ? this.id : null;
		data['username'] = this.username !== undefined ? this.username : null;
		data['name'] = this.name !== undefined ? this.name : null;
		data['lastName'] = this.lastName !== undefined ? this.lastName : null;
		data['email'] = this.email !== undefined ? this.email : null;
		data['userProfiles'] =
			this.userProfiles !== undefined ? this.userProfiles : null;
		data['entity'] = this.entity !== undefined ? this.entity : null;
		data['password'] = this.password !== undefined ? this.password : null;
		data['passwordSalt'] =
			this.passwordSalt !== undefined ? this.passwordSalt : null;
		data['creationDate'] = this.creationDate !== undefined ? this.creationDate : null;
		data['creationUser'] = this.creationUser !== undefined ? this.creationUser : null;
		return data;
	}

	toStrings(data?: any) {
		data = data === undefined ? {} : data;
		data['id'] = this.id !== undefined ? this.id : '';
		data['username'] = this.username !== undefined ? this.username : '';
		data['name'] = this.name !== undefined ? this.name : '';
		data['lastName'] = this.lastName !== undefined ? this.lastName : '';
		data['email'] = this.email !== undefined ? this.email : '';
		data['userProfiles'] =
			this.userProfiles !== undefined ? this.userProfiles : '';
		data['entity'] = this.entity !== undefined ? this.entity : '';
		data['password'] = this.password !== undefined ? this.password : '';
		data['passwordSalt'] =
			this.passwordSalt !== undefined ? this.passwordSalt : '';
		data['creationDate'] = this.creationDate !== undefined ? this.creationDate : '';
		data['creationUser'] = this.creationUser !== undefined ? this.creationUser : '';
		return data;
	}

	toJSON() {
		return JSON.stringify(this.toJS());
	}

	clone() {
		const json = this.toJSON();
		return new AdminUserDTO(JSON.parse(json));
	}
}
