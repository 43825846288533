import { Response } from '@angular/http';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Select2OptionData, Options } from 'select2';

import { AdminDriverAggressive } from '../../../../shared/dto/admin-conductor-agresivo-dto';

import { UIService } from './../../../../trans/_services/UI.service';
import { environment } from './../../../../../../environments/environment';
import { HttpService } from '../../../../shared/services/http.service';

@Component({
  selector: 'kt-conductor-agresivo',
  templateUrl: './conductor-agresivo.component.html',
  styleUrls: ['./conductor-agresivo.component.scss'],
  host: { 'class': 'sths-tb-element' }
})
export class ConductorAgresivoComponent implements OnInit {
  configTable: Object;
  sthsQSTime = { hour: 13, minute: 30 };
  showTableTramos: boolean = false;

  // Paginador
  paginador: Object = {
    count: 10,
    page: 1
  };

  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  conditionalLoader: boolean;

  dataTable: Array<any>;

  /*
   * Campos formulario
   */
  formModelDriverAggressive: AdminDriverAggressive = new AdminDriverAggressive;

  /*
   * Array con los datos del endpoint
   */
  selectedAnalisis: any = {
    1: '',
    2: '',
    3: ''
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private http: HttpService,
    private uiService: UIService, ) { }

  ngOnInit() {
    this.obtenerDatos();
    this.obtenerTabla();
  }

  obtenerTabla() {

    this.conditionalLoader = true;
    this.http.ejecutarServicioGet(environment.endpoints.adminParamAggressiveDriverFindStret, null).subscribe((res: Response) => {
      switch (res.status) {
        case 200:
        case 201:

          if (res.json() && res.json().length > 0) {
            this.procesarJson(res.json());
          }
          this.cdr.markForCheck();
          break;
        case 204:
          this.procesarJson([]);
          this.cdr.markForCheck();
          break;

        default:
          this.procesarJson([]);
          this.cdr.markForCheck();
          this.showError(res);
          break;
      }
      this.conditionalLoader = false;
    }, (error: Response) => {
      this.conditionalLoader = false;
      this.procesarJson([]);
      this.cdr.markForCheck();
      this.showError(error);
    });
  }

  changeCheckbox(value) {
    this.showTableTramos = value;
  }

  /**
   * Consulta endpoint para carga de campos
   */
  obtenerDatos() {
    this.conditionalLoader = true;

    this.http.ejecutarServicioGet(environment.endpoints.adminParamAggressiveDriverFind).subscribe((res: Response) => {
      switch (res.status) {
        case 200:
        case 201:
        case 204:
          let resGet = res.json();

          if (resGet) {
            this.formModelDriverAggressive.numberOfAccelerations = (resGet['numberOfAccelerations']) ? resGet['numberOfAccelerations'].toString() : '';
            this.formModelDriverAggressive.numberOfSuddenBreaking = (resGet['numberOfSuddenBreaking']) ? resGet['numberOfSuddenBreaking'].toString() : '';
            this.formModelDriverAggressive.numberOfSuddenTurning = (resGet['numberOfSuddenTurning']) ? resGet['numberOfSuddenTurning'].toString() : '';
            this.formModelDriverAggressive.speedings = (resGet['speedings']) ? resGet['speedings'].toString() : '';
            // this.formModelDriverAggressive.maximumSpeed = (resGet['maximumSpeed']) ? resGet['maximumSpeed'] : false;
          }

          this.cdr.markForCheck();
          break;
        case 401:
          // nothing
          break;
        default:
          this.showError(res);
          break;
      }
      this.conditionalLoader = false;
    }, (error: Response) => {
      this.conditionalLoader = false;
      this.showError(error);
    });// END GET
  }


  procesarJson(data) {
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['name'].toString(),
        data[index]['name'].toString(),
        data[index]['speed'].toString()
      ]);

      if (index === data.length - 1) {
        this.armarTabla(dataArray);
      }
    }

    if (!data.length) {
      this.armarTabla(dataArray);
    }
  }

  armarTabla(data) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'Tramo', 'Velocidad'],
      typeColumn: [, ,],
      showSearch: false,
      searchColumn: [1],
      searchTitle: '',
      hideColumns: [0],
      showPag: false,
      leftsColumns: [1],
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data
    }
    this.cdr.markForCheck();
  }

  validateForm(event) {
    setTimeout(() => {
      if (this.formModelDriverAggressive.numberOfAccelerations) {
        this.formModelDriverAggressive.numberOfAccelerations = this.formModelDriverAggressive['numberOfAccelerations'].toString().replace(/[^0-9]/g, ' ').replace(' ', '').trim();
      }
      if (this.formModelDriverAggressive.numberOfSuddenBreaking) {
        this.formModelDriverAggressive.numberOfSuddenBreaking = this.formModelDriverAggressive['numberOfSuddenBreaking'].toString().replace(/[^0-9]/g, ' ').replace(' ', '').trim();
      }
      if (this.formModelDriverAggressive.numberOfSuddenTurning) {
        this.formModelDriverAggressive.numberOfSuddenTurning = this.formModelDriverAggressive['numberOfSuddenTurning'].toString().replace(/[^0-9]/g, ' ').replace(' ', '').trim();
      }
      if (this.formModelDriverAggressive.speedings) {
        this.formModelDriverAggressive.speedings = this.formModelDriverAggressive['speedings'].toString().replace(/[^0-9]/g, ' ').replace(' ', '').trim();
      }
      this.cdr.markForCheck();
    }, 100);
  }

  guardarConfgConductor() {
    this.contentToShowModal = 1;

    // let data = {
    //   "answer": {
    //       "1": this.aceleraciones,
    //       "2": this.frenadas,
    //       "3": this.giros,
    //       "42": this.tiempo,
    //       "43": (this.velocidad==true?'true':'false'),
    //   }
    // };

    console.log(this.formModelDriverAggressive);

    if (
      Number(this.formModelDriverAggressive.numberOfAccelerations) > 0 &&
      Number(this.formModelDriverAggressive.numberOfSuddenBreaking) > 0 &&
      Number(this.formModelDriverAggressive.numberOfSuddenTurning) > 0 &&
      Number(this.formModelDriverAggressive.speedings) > 0
    ) {
      this.http
        .ejecutarServicioPost(
          environment.endpoints.adminParamAggressiveDriverSave,
          this.formModelDriverAggressive.toJS()
        )
        .subscribe((res: Response) => {
          switch (res.status) {
            case 200:
              this.contentToShowModal = 1;

              this.configModal = {
                status: 1,
                icono: 'smart-icon-guardar',
                titulo: 'Los datos han sido guardados',
                textos: [
                  'Los datos ingresados han sido guardados con éxito'
                ],
                botones: [
                  {
                    label: 'Ok',
                    class: '',
                    accion: 'guardarConductor'
                  }
                ]
              };
              this.cdr.markForCheck();
              setTimeout(() => {
                this.contentToShowModal = -1;
                this.configModal = { status: -1 }
              }, 300);
              break;
            default:
              console.log(res);
              this.showError(res);
              break;
          }
        }, (error: Response) => {
          this.showError(error);
        }
        );
    } else {
      this.uiService.showMessage('Todos los campos son obligatorios y deben ser enteros positivos');
    }
  }

  changeStatusModal(event) {
    this.obtenerTabla();

    if (event === 'guardarConductor') {

    }
  }

  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage(response.json()['message']);
    } else {
      this.uiService.showMessage('Error del servidor');
    }
    this.cdr.markForCheck();
  }
}
