import { NgModule, Component, OnInit, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData, Options } from 'select2';
// NgBootstrap
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Select2
import { NgSelect2Module } from 'ng-select2';
import { AuthService } from './../../../../../core/auth/_services/auth.service';

@Component({
  selector: 'kt-form-alarm-info',
  templateUrl: './form-alarm-info.component.html',
  styleUrls: ['./form-alarm-info.component.scss']
})


@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgSelect2Module,
    NgbModule
  ],
  

})


export class FormAlarmInfoComponent implements OnInit {
  conditionalLoader: boolean;
  @Input() modificarDatos: {title:""
};  
@Output() actionInfoAlarma: EventEmitter<any> = new EventEmitter();
  user = this.auth.getUser().username;
  
  constructor(private auth: AuthService,
              private cdr: ChangeDetectorRef, ) { 
              this.conditionalLoader = false;
  }
  
  closeModal() {
      this.actionInfoAlarma.emit(null);
  }
  
  ngOnInit() {
    if ( this.modificarDatos ) {
      console.log
    }
  }

}
