import { environment } from '@environments/environment';
import { HttpService } from '../../../../shared/services/http.service';
import { UIService } from './../../../../trans/_services/UI.service';

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
	NgbDateStruct,
	NgbDate,
	NgbCalendar
} from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData, Options } from 'select2';
import { Response } from '@angular/http';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { AdminUserDTO } from '../../../../shared/dto/admin-user-dto';
import { AdminGestionFilterDto } from '../../../../shared/dto/admin-gestion-filter-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
@Component({
	selector: 'kt-crear-incidente',
	templateUrl: './crear-incidente.component.html',
	styleUrls: ['./crear-incidente.component.scss']
})
export class CrearIncidenteComponent implements OnInit {
	// Select2
	public selectModules: Array<Select2OptionData>;
	public selectCategories: Array<Select2OptionData>;
	public selectPriorities: Array<Select2OptionData>;
	private currentIncident: any = { openingDate: moment().toISOString(true) };
	private files: any[] = [];
	private documents: string[] = [];
	fromDate: NgbDate;
	toDate: NgbDate;
	sthsQSModel1: NgbDateStruct;
	sthsQSTime1 = { hour: 9, minute: 0 };
	sthsQSModel2: NgbDateStruct;
	sthsQSTime2 = { hour: 10, minute: 0 };
	public options: Options;
	public incidentForm: FormGroup;
	configModal: {} = { status: -1 };
	// Paginador
	paginador: object = {
		count: 30,
		page: 1
	};
	loadingFile: boolean = false;

	rutasMenu = [
		{
			label: 'Buscar Incidentes',
			icon: 'smart-icon-asociar-dispositivos',
			ruta: `${environment.folders.routeAdmin}incidentes/buscar`
		},
		{
			label: 'Crear Incidente',
			icon: 'smart-icon-asociar-dispositivos',
			ruta: `${environment.folders.routeAdmin}incidentes/crear`
		}
	];

	// Table Test
	configTable: object;

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1, data: [] };
	contentToShowModal: number = -1;
	// Cargadores Información
	conditionalLoader: boolean = true;
	formModelUser: AdminUserDTO = new AdminUserDTO();
	codigo: string;
	isCode: boolean;
	formModelGestion: AdminGestionFilterDto = new AdminGestionFilterDto();

	/*
	 * Array que llenará la tabla
	 */
	dataArray = [];

	/*
	 * Muestra o esconde el botón "Adjuntar Archivos"
	 */
	mostrarBoton = true;

	constructor(
		auth: AuthService,
		private cdr: ChangeDetectorRef,
		private http: HttpService,
		private uiService: UIService,
		calendar: NgbCalendar,
		private router: Router
	) {
		this.fromDate = calendar.getToday();
		this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
	}

	onDateSelection(date: NgbDate) {
		// console.log( date );
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

	openModalGroupCreado() {
    this.contentToShow = 1;
    let nombreUser: string = '';
    this.configModal = {
      status: 1,
      icono: 'smart-icon-grupo-dispositivos',
      titulo: 'Incidente',
      textos: [
                `Incidente creado correctamente`
              ],
      botones: [
        {
          label: 'Aceptar',
          class: '',
          accion: 'creacionGroup'
          // accion: !this.modificarDatos ? 'creacionUsuario' : 'modificarUsuario'
        }
      ]
		};
		this.incidentForm.reset();
		this.incidentForm.patchValue({openingDate: moment().toISOString(true) });
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShow = -1;
      this.configModal = { status: -1 };
    }, 300);
 	}

	ngOnInit() {
		this.incidentForm = new FormGroup({
			openingDate: new FormControl(moment().toISOString(true), null),
			documents: new FormControl(null, null),
			module: new FormControl(null, null),
			category: new FormControl(null, null),
			priority: new FormControl(null, null),
			title: new FormControl(null, [
				Validators.required,
				Validators.maxLength(200)
			]),
			description: new FormControl(null, [Validators.required])
		});

		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
		this.http
			.ejecutarServicioGet(
				`${environment.endpoints.adminIncidents}modules`
			)
			.subscribe((res: Response) => {
				let data = res.json();
				this.selectModules = data.map(i => {
					return {
						id: i.value,
						text: i.text
					};
				});
				this.cdr.markForCheck();
			});
		this.http
			.ejecutarServicioGet(
				`${environment.endpoints.adminIncidents}categories`
			)
			.subscribe((res: Response) => {
				let data = res.json();
				this.selectCategories = data.map(i => {
					return {
						id: i.value,
						text: i.text
					};
				});
				this.cdr.markForCheck();
			});
		this.http
			.ejecutarServicioGet(
				`${environment.endpoints.adminIncidents}priorities`
			)
			.subscribe((res: Response) => {
				let data = res.json();
				this.selectPriorities = data.map(i => {
					return {
						id: i.value,
						text: i.text
					};
				});
				this.cdr.markForCheck();
			});
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}

	async processFile(filesInput: any) {
		this.loadingFile = true;
		this.files = Array.from(filesInput.files);
		this.files.forEach(async element => {
			const data = new FormData();
			data.append('file', element);
			await this.http
			.multipartPost2(environment.endpoints.filesUpload, data).then(response =>{
				console.log("RES: ", response);
				this.dataArray.push([
					element.name+'||'+response.data, 
					element.name + ' (' + (element['size'] / 1024).toFixed(2) + ' KB)', 
					''
				]);
				this.documents.push(response.data);
				this.loadingFile = false;
			  }).catch(err =>{
				console.log("ERR: ", err)
				this.loadingFile = false;
				this.uiService.showMessage("Ocurrió un error al cargar el archivo.");
			  })
			if (this.dataArray.length<5) {
				this.mostrarBoton = true;
			} else {
				this.mostrarBoton = false;
			}

			if(this.dataArray.length > 0) {
				this.armarTabla();
			}
			this.cdr.markForCheck();
		});
	}

	/**
	 * Eliminará registro de la tabla y del array documents
	 *
	 * @param item
	 */
	removeItem(item) {
		let doc = item.split('||');
		this.dataArray.splice(this.dataArray.findIndex(v => v[0] === item), 1);
		this.documents.splice(this.documents.findIndex(v => v === doc[1]), 1);
		this.armarTabla();

		if (this.dataArray.length<5) {
			this.mostrarBoton = true;
		} else {
			this.mostrarBoton = false;
		}

		this.cdr.markForCheck();
	}

	validarFecha() {
		let formGestion = this.formModelGestion.toStrings();
		if (
			!formGestion['fechaCreacion'] &&
			!formGestion['horaCreacion'] &&
			!formGestion['fechaFinal'] &&
			!formGestion['horaFinal']
		) {
		} else {
			if (
				!formGestion['fechaCreacion'] ||
				!formGestion['horaCreacion'] ||
				!formGestion['fechaFinal'] ||
				!formGestion['horaFinal']
			) {
				this.uiService.showMessage(
					'Debes seleccionar la fecha - hora inicial y la fecha - hora final'
				);
				return false;
			} else {
				// Validar
				let dateIni = new Date(
					formGestion['fechaCreacion']['year'],
					formGestion['fechaCreacion']['month'] - 1,
					formGestion['fechaCreacion']['day'],
					formGestion['horaCreacion']['hour'],
					formGestion['horaCreacion']['minute'],
					formGestion['horaCreacion']['second']
				);
				let dateFin = new Date(
					formGestion['fechaFinal']['year'],
					formGestion['fechaFinal']['month'] - 1,
					formGestion['fechaFinal']['day'],
					formGestion['horaFinal']['hour'],
					formGestion['horaFinal']['minute'],
					formGestion['horaFinal']['second']
				);
				if (dateIni > dateFin) {
					this.uiService.showMessage(
						'La fecha inicial es mayor a la fecha final'
					);
					return false;
				}
			}
		}
		return true;
	}

	limpiar() {

	}
	changeStatusModal(event) {

	}

	save() {
		let data = this.incidentForm.value;
		this.documents.forEach((itm, index) => {
			data['file' + (index + 1)] = itm;
		});
		this.http
			.ejecutarServicioPost(environment.endpoints.adminIncident, data)
			.subscribe((res: Response) => {
				if (res.status === 201 || res.status === 200) {
					this.openModalGroupCreado();
				}
			});

		this.dataArray = [];
		this.documents = [];
		if (this.dataArray.length<5) {
			this.mostrarBoton = true;
		} else {
			this.mostrarBoton = false;
		}
		this.armarTabla();

		this.cdr.markForCheck();
	}

	armarTabla() {
		this.contentToShow = 2;

		this.configTabla = {
		  status: 1,
		  header: ['ID', 'Nombre', 'Acciones'],
		  typeColumn: [,,{ tipo: 'acciones' , acciones: ['eliminar'] }],
		  showSearch: false,
		  // leftsColumns: [1],
		  searchColumn: [1],
		  searchTitle: 'Adjuntos',
		  hideColumns: [0],
		  showPag: false,
		  configColumns: {
			id: 0,
			columnClick: 1,
		  },
		  data: this.dataArray,
		  paginador: this.paginador
		};
		this.cdr.markForCheck();
	}

}
