import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../../../../environments/environment';
import { HttpService } from '../../../../shared/services/http.service';
import { AdminCategoriaEstacionDto } from '../../../../shared/dto/admin-categoria-dto-estacion';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from '@angular/http';
import { UIService } from './../../../_services/UI.service';
import { UserDTO } from '../../../../shared/dto/user-dto';
import { AuthService } from '../../../../../core/auth/_services/auth.service';

@Component({
  selector: 'kt-crear-estacion',
  templateUrl: './crear-estacion.component.html',
  styleUrls: ['./crear-estacion.component.scss']
})
export class CrearEstacionComponent implements OnInit {
  titulo: string;
  configTable: {};

  configModal: {} = { status: -1 };
  idDevicesActive: string;
  listadoEstaciones: AdminCategoriaEstacionDto[];


  idRegistry: string;
  idPuerta: string;
  idActive : any;

  rolAdmin: boolean = true;
  iDatos: number = 1;

  registryGroupIdDelete: any;
  selected:any;
  complete: boolean
  rolUser: any;
  entityUser: any;

  idRegistryGroup: string;
  conditionalLoaderTable: boolean;
  estacionIdDelete: any;
  configModalDelete: {} = { status: -1 };

  user$: Observable<UserDTO>;
  user: UserDTO;

  // Paginador
  paginador: {} = {
    count: 30,
    page: 1
  };

  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  // Cargadores Información
  conditionalLoader: boolean = true;

  // Modales
  contentToShowDelete: number = -1;
  contentToShowModal: {} = { status: -1 };

  contentToShowFormModal: number = -1;
  dataArray = [];


  dataResponse = [];

  canDoActions: boolean = true;
  datosEditarContratistas: AdminCategoriaEstacionDto;

  constructor(private _route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private http: HttpService,
    private uiService: UIService,
    private authService: AuthService,
  ) {
    //this.idRegistry = this._route.snapshot.paramMap.get('registryId');
    this.titulo = 'Registro de Estaciones';
  }

  ngOnInit() {
    this.cargarEstacionesTable();
    //Verificacion de rol de usuario 
    this.user$ = this.authService.userService$;
    this.user = this.authService.getUser();
    let ruta = environment.endpoints.UserStatus.replace('{{user.name}}', this.user['username']);
    this.http.ejecutarServicioGet(ruta).subscribe( (res: Response) => {
      let status = res.json();
      this.rolUser = status.userProfiles
      this.entityUser = status.entity
      console.log("USUARIO ENTIDAD",this.entityUser)
      //this.rolUser = status.
    } )
  }

  cargarEstacionesTable() {
    this.conditionalLoaderTable = true;
    this.cdr.markForCheck();
    let ruta = environment.endpoints.listEstaciones;
    
    this.http.ejecutarServicioGet(ruta, null).subscribe((res: Response) => {
      this.conditionalLoader = false;
      this.conditionalLoaderTable = false;
      //console.log("Data endpoint:",res.json())
      this.canDoActions = res.json()['canDoActions']=='true'?true:false;
      //console.log("Actions",this.canDoActions)
      if ( res.json().groups.length > 0 ) {
        this.listadoEstaciones = res.json().groups;
        this.procesarJson(this.listadoEstaciones);
        
      }
      this.cdr.markForCheck();

      

      //console.log(res.json(),'res');

      this.cdr.markForCheck();
    });
  }


  editarElemento( event ){
    console.log("event",event);
    let selected = this.dataResponse.find(element =>{
      return element['nombre'] == event['values'];
    }
    );
    if(selected['complete'] == 'true'){
      this.contentToShowFormModal = 30;
      //completado
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowFormModal = -1;
        this.contentToShowModal = { status: -1 };
      }, 400);
    }else{
      this.router.navigateByUrl(`${environment.folders.routeAdmin}puertas/generar-estacion/${selected.contratista}/${selected.id}`);
    }
  }

  procesarJson(data) {
        // data[index]['versionTrama'].toString
    //console.log("Data procesarJSON: ",data)
    this.dataResponse = data;
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      let switchValue = {};
      let complete = data[index]['complete'].toString()=='true' ? true : false;
      let link = data[index]['link'].toString()=='true' ? true : false;
      if (this.canDoActions){
        //esto es para la columna completar
        switchValue["values"] = complete;
        switchValue["show"] =   ( !link) ? true : false;
      } else {
        //esto es para la columna vincular
        switchValue["values"] =  link;
        switchValue["show"] =   ( complete) ? true : false;
      }
      console.log("Contratista DATA",data[index]['contratista'])
      if(data[index]['contratista'] == this.entityUser){
        dataArray.push([
          {values: data[index]['nombre'].toString(), show: true},
          {values: data[index]['nombre'].toString(), show: true},
          '',
          switchValue
        ]);
      }
      else if (this.rolUser == '678' || this.rolUser == '3'){
        dataArray.push([
          {values: data[index]['nombre'].toString(), show: true},
          {values: data[index]['nombre'].toString(), show: true},
          '',
          switchValue
        ]);
      }
      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }
    if( !data.length ){
      this.armarTabla(dataArray);
    }
 
  }

  armarTabla(data) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: [
        'ID', 'Nombre',
        (this.canDoActions?'Acciones':''),
        (this.canDoActions?'Completo':'Vincular')
      ],
      typeColumn_old: [, , { tipo: 'acciones', acciones: ['editar', 'eliminar','credenciales'] }, { tipo: 'estado' }],
      typeColumn: [, ,
        {
          tipo: 'acciones',
          acciones: (this.canDoActions?['editar', 'eliminar','credenciales',{ tipo: 'estado' }]:[])
        },
        { tipo: (this.canDoActions?'estado':'estado') }
      ],
      hideColumns: [0],
      showPag: false,
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador
    };
    this.cdr.markForCheck();
  }

  crearEstacion() {
    if(this.rolUser == "679" || this.rolUser == "3"){
      this.router.navigateByUrl('/admin/dashboard/puertas/generar-estacion');
    } else {
      this.configModal = {
        status: 1,
        icono: 'smart-icon-nombre-categoria',
        titulo: 'No tienes permisos',
        textos: [
          'No cuentas con los permisos para crear una estación'
        ],
        botones: [
          {
            label: 'Cancelar',
            class: 'sths-btn-cancel',
            accion: 'cancelar'
          }
        ]
      };
    }
  }

  eventEliminarClick( event ){
    // Eliminar
    this.selected = this.dataResponse.find(element =>{
      return element['nombre'] == event['values'];
    }
    );
    console.log('selected',this.selected,'event',event['values']);
    if (this.selected['link'] == 'true'){
      this.contentToShowFormModal = 20;
      //vinculado
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowFormModal = -1;
        this.contentToShowModal = { status: -1 };
      }, 400);
    }else if (this.selected['complete'] == 'true'){
      this.contentToShowFormModal = 18;
      //completado
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowFormModal = -1;
        this.contentToShowModal = { status: -1 };
      }, 400);
    }else{
    //console.log(event);
    this.registryGroupIdDelete = event;
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-eliminar',
      titulo: 'Eliminar Estación',
      textos: [
        '¿Esta seguro que quiere eliminar este Estación'
      ],
      botones: [
        {
          label: 'Cancelar',
          class: 'sths-btn-cancel',
          accion: 'cancelar'
        },
        {
          label: 'Eliminar',
          class: '',
          accion: 'eliminar'
        }
      ]
      }
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShowDelete = -1;
      this.contentToShowModal = { status: -1 };
    }, 300);
  }

  eventCredencialesClick(id, type?: string) {
    console.log("EVENT",id)
    let selected = this.dataResponse.find(element =>{
      return element['nombre'] == id['values'];
    }
    );
    console.log("SDSDCS", selected)
    if(selected.complete == 'true'){
      this.idDevicesActive = id;
      this.contentToShowModal = 1;
      this.idRegistry = selected.contratista
      this.idPuerta = selected.id
  
      this.configModal = {
        status: 1,
        icono: 'smart-icon-nombre-categoria',
        titulo: 'Credenciales',
        textos: [
          ''
        ],
        botones: [
          {
            label: 'Cancelar',
            class: 'sths-btn-cancel',
            accion: 'cancelar'
          },
          {
            label: 'Descargar rsa',
            class: '',
            accion: 'descargarCredencial'
          }
        ]
      };
  
      if (type === 'regenerado') {
        this.configModal['textos'].push(' Se ha regenerado correctamente...');
      } else {
        this.configModal['botones'].push({
          label: 'Regenerar rsa',
          class: '',
          accion: 'regenerarCredencial'
        }
        // {
        //   label: 'Descargar p12',
        //   class: '',
        //   accion: 'descargarp12'
        // }
        );
      }
  
      this.cdr.markForCheck();
      setTimeout(() => {
        this.contentToShowModal = -1;
        this.configModal = { status: -1 };
      }, 300);
    }else{
      this.configModal = {
        status: 1,
        icono: 'smart-icon-nombre-categoria',
        titulo: 'Puerta no completa',
        textos: [
          'El dispositivo debe estar completado para descargar las credenciales'
        ],
        botones: [
          {
            label: 'Cancelar',
            class: 'sths-btn-cancel',
            accion: 'cancelar'
          }
        ]
      };
    }

  }

  changeStatusModalTemplate(event) {
    this.contentToShowModal = -1;
  }

  changeStatusModalDelete( event ){
    if ( event === 'eliminar' ){
      
      let formModelCategoria = new AdminCategoriaEstacionDto;
      formModelCategoria.id = this.estacionIdDelete;
      
      let formCategoria = formModelCategoria.toJS();
      console.log("DELETE:", formCategoria)
      
      this.conditionalLoader = true;
      this.cdr.markForCheck();
      this.http.ejecutarServicioDelete( environment.endpoints.eliminarEstacion, formCategoria ).subscribe( (res: Response) => {
        if ( res.ok === true ) {
          // this.buscarRegistries();
          // Realizar algo con el ID
          let resPost = res.json();
  
        } else {
          // TODO :: MANEJAR EXCEPCION
        }
        
        this.cargarEstacionesTable();
        this.conditionalLoader = false;
        this.cdr.markForCheck();
      });

      this.estacionIdDelete = '';
    }
  }

  eventEstadoClick(item) {
    if(this.canDoActions){
      
      //console.log("item",item)
      let links = document.getElementsByTagName("a");
      let inputs = document.getElementsByTagName("input");
      
  
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].disabled = true;
      }
      for (let i = 0; i < links.length; i++) {
        links[i].setAttribute("style", "pointer-events: none;cursor: default;");
      }

      let selected = this.dataResponse.find(element =>{
        return element['nombre'] == item['id'];
      })

      
      if(selected.complete == 'true' && (this.rolUser == '678' || this.rolUser == '3')){
        this.complete = false
      }else if (selected.complete == 'true' && this.rolUser != '678') {
        this.configModal = {
          status: 1,
          icono: 'smart-icon-nombre-categoria',
          titulo: 'Estacion',
          textos: [
            'Solo el administrador de puertas puede realizar esta acción'
          ],
          botones: [
            {
              label: 'Cancelar',
              class: 'sths-btn-cancel',
              accion: 'cancelar'
            }
          ]
        };
      }else{
        this.complete = true
      }
      let ruta = `${environment.endpoints.estacionComplete}${selected.id}/complete/${this.complete}`

  
      this.conditionalLoaderTable = true;
      this.http.ejecutarServicioPost(ruta)
          .subscribe((res: Response) => {
  
        if (res.status === 200) {
          setTimeout(() => {
            this.dataArray = [];
            this.cargarEstacionesTable();
            this.conditionalLoaderTable = false;
            this.uiService.showMessage('Dispositivo actualizado');
  
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].disabled = false;
            }
            // for (let i = 0; i < links.length; i++) {
            //     links[i].setAttribute("style", "");
            // }
            this.cdr.markForCheck();
          }, 1000);
        }else if (res.status === 500) {
          //console.log(res);
          let message = JSON.parse(res['_body']);
          this.uiService.showMessage(message['message']);
          setTimeout(() => {
            this.dataArray = [];
            this.cargarEstacionesTable();
            this.conditionalLoaderTable = false;
            /* for (let i = 0; i < inputs.length; i++) {
                inputs[i].disabled = false;
            } */
            for (let i = 0; i < links.length; i++) {
                links[i].setAttribute("style", "");
            }
            this.cdr.markForCheck();
          }, 1000);
        }
        else {
          this.conditionalLoaderTable = false;
          this.uiService.showMessage('Error actualizando Dispositivo1');
        }
        this.cdr.markForCheck();
      }, (e) => {
        this.uiService.showMessage('Error actualizando Dispositivo2');
        this.conditionalLoaderTable = false;
        this.cdr.markForCheck();
      });
  
    }else{
      //console.log('entra');
  
            // Se deshabilitan todos los links de la tabla
            let links = document.getElementsByTagName("a");
            let inputs = document.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].disabled = true;
            }
            for (let i = 0; i < links.length; i++) {
                links[i].setAttribute("style", "pointer-events: none;cursor: default;");
            }
  
            let ruta = environment.endpoints.adminRegistryGroupLink;
            let params = {
              registry: this.idRegistry,
              name: item['id'],
              link: (item['value']==true ? 'true' : 'false')
            };
  
  
             //console.log(params,'params');
  
            this.conditionalLoaderTable = true;
            this.http.ejecutarServicioPost(ruta, params)
                .subscribe((res: Response) => {
              if (res.status === 200) {
                setTimeout(() => {
                  this.dataArray = [];
                  this.cargarEstacionesTable();
                  //this.conditionalLoaderTable = false;
                  this.uiService.showMessage('Dispositivo actualizado');
  
                  /* for (let i = 0; i < inputs.length; i++) {
                      inputs[i].disabled = false;
                  } */
                  for (let i = 0; i < links.length; i++) {
                      links[i].setAttribute("style", "");
                  }
                  this.cdr.markForCheck();
                }, 1000);
              }else if (res.status === 500) {
                //console.log(res);
                let message = JSON.parse(res['_body']);
                this.uiService.showMessage(message['message']);
                setTimeout(() => {
                  this.dataArray = [];
                  this.cargarEstacionesTable();
                  this.conditionalLoaderTable = false;
                  /* for (let i = 0; i < inputs.length; i++) {
                      inputs[i].disabled = false;
                  } */
                  for (let i = 0; i < links.length; i++) {
                      links[i].setAttribute("style", "");
                  }
                  this.cdr.markForCheck();
                }, 1000);
              }
              else {
                this.conditionalLoaderTable = false;
                this.uiService.showMessage('Error actualizando Dispositivo1');
              }
              this.cdr.markForCheck();
            }, (e) => {
              this.uiService.showMessage('Error actualizando Dispositivo2');
              this.conditionalLoaderTable = false;
              this.cdr.markForCheck();
            });
        }
  
    }

  changeStatusModal(event) {
    if(this.canDoActions){
    //console.log(this.registryGroupIdDelete);
    let ruta;
    if (event === 'eliminar') {
      this.conditionalLoader = true;
      let params = {
        id: this.selected.id,
        name: this.registryGroupIdDelete.values,
        contratista: this.selected.contratista,
        trama: this.selected.versionTrama,
        vagones: this.selected.numeroVagones,
        accesos: this.selected.numeroAccesos,
        tipo: this.selected.tipoEstacion,
        troncal: this.selected.troncal,
        puertas: this.selected.numeroPuertas
      };
      this.http.ejecutarServicioDelete(environment.endpoints.eliminarEstacion, params).subscribe((res: Response) => {
        // this.conditionalLoader = false;
        if (res.ok === true) {
          this.dataArray = [];
          this.cargarEstacionesTable();
          // Realizar algo con el ID
          let resPost = res.json();
        } else {
          // TODO :: MANEJAR EXCEPCION
        }

        this.conditionalLoader = false;
        this.cdr.markForCheck();
      });

      this.registryGroupIdDelete = '';
    } else if (event == 'descargarCredencial') {
     //console.log(this.idDevicesActive,'idDevicesActive',this.idRegistry);
        this.conditionalLoaderTable = true;
        this.cdr.markForCheck();
        if (this.idDevicesActive === Object(this.idDevicesActive)){
          //console.log('object',this.idDevicesActive);
          this.idActive = this.idDevicesActive['values'];
        }else{
          //console.log('no object');
          this.idActive = this.idDevicesActive;
        }
        ruta = `${environment.endpoints.adminRegistryGroupPK}/${this.idRegistry}/${this.idPuerta}`;
        console.log("Ruta :", ruta)
        //console.log(ruta,'descargarCredencial');
        this.http.ejecutarServicioGet(ruta, null).subscribe(
          data => this.uiService.downloadFile(data['_body'], 'pem', `${this.idActive}.rsa.pem`),
          (e) => {
            this.uiService.showMessage('Error descagando las Credenciales');
          });
        this.conditionalLoaderTable = false;
        this.cdr.markForCheck();
    // } else if (event == 'descargarp12') {
    //     this.conditionalLoaderTable = true;
    //     this.cdr.markForCheck();
    //     ruta = `${environment.endpoints.adminRegistryGetP12}${this.idRegistry}`;
    //     //console.log(ruta,'descargarp12');
    //     this.http.ejecutarServicioGet(ruta, null).subscribe(
    //       data => this.uiService.downloadFile(data['_body'], 'p12', `${this.idRegistry}-video-upload.p12`),
    //       (e) => {
    //         this.uiService.showMessage('Error descagando las Credenciales');
    //       });
    //     this.conditionalLoaderTable = false;
    //     this.cdr.markForCheck();
    } else if(event == 'regenerarCredencial') {
      this.conditionalLoaderTable = true;
      this.cdr.markForCheck();
      ruta = `${environment.endpoints.adminRegistryGroupUpdatePK}/${this.idRegistry}/${this.idPuerta}`;
      console.log("RUTA", ruta)
      //console.log(ruta,'regenerarCredencial');
      this.http.ejecutarServicioPost(ruta, null).subscribe((res: Response) => {
        console.log("STATUS", res.status)
        if (res.status === 200) {
          this.uiService.showMessage('Credenciales listas');
          this.eventCredencialesClick(this.idDevicesActive['values'], 'regenerado');
        } else {
          this.uiService.showMessage('Error generando las Credenciales');
        }
        this.conditionalLoaderTable = false;
        this.cdr.markForCheck();
      }, (e) => {
          this.uiService.showMessage('Error generando las Credenciales');
      });
    } else if(event =='cerrar'){
      this.dataArray = [];
      this.cargarEstacionesTable();
    }
  }
}
}
