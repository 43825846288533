import { environment } from './../../../../../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { Response } from '@angular/http';
import { HttpService } from './../../../shared/services/http.service';
import { Injectable } from '@angular/core';
import { AdminUserDTO } from '../../../shared/dto/admin-user-dto';

@Injectable()
export class UsuariosService {
	formModelUser: AdminUserDTO = new AdminUserDTO();

	constructor(private http: HttpService) {}

	getUsuariosSearch(user: AdminUserDTO, count = 10, page = 1) {
		let formUser = user.toStrings();
		delete formUser['userRoles'];

		let username = encodeURIComponent(formUser['username']);
		let name = encodeURIComponent(formUser['name']);
		let lastName = encodeURIComponent(formUser['lastName']);
		let email = encodeURIComponent(formUser['email']);
		let entity = '';
		if (formUser['entity'] && formUser['entity'] !== 'all') {
			entity = encodeURIComponent(formUser['entity']);
		}
		let ruta = `${environment.endpoints.adminUserSearch}?username=${username}&name=${name}&lastName=${lastName}&email=${email}&entity=${entity}&count=${count}&page=${page}`;
		return this.http.ejecutarServicioGet(ruta, null);
	}

	getUsuarioID(id: number) {
		let ruta = `${environment.endpoints.adminUser}${id}`;
		return this.http.ejecutarServicioGet(ruta, null);
	}
}
