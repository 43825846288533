import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '@app/views/shared/services/http.service';
import { UIService } from '@app/views/trans/_services/UI.service';

class FileSnippet {
	pending: boolean = false;
	status: string = 'init';

	constructor(
		public src: string,
		public file: File
	) {}
}

@Component({
	selector: 'kt-carga-masiva',
	templateUrl: './carga-masiva.component.html',
	styleUrls: ['./carga-masiva.component.scss']
})
export class CargaMasivaComponent implements OnInit {
	selectedFile: FileSnippet;
	enableDownload: boolean = false;
	reportId: string = '';
	results: string = '';
	file: File;
	constructor(private http: HttpService, private cdr: ChangeDetectorRef, private uiService: UIService) {}

	ngOnInit() {}
	async processFile(fileInput: any) {
			const data = new FormData();
			data.append('file', fileInput.target.files[0]);
			const res = await this.http.multipartPost2(environment.endpoints.adminDriverSearch + 'import',data);
						switch (res.status) {
							case 200:
								const dataRes = res.data;
								this.results = dataRes.results;
								this.uiService.downloadFileBase64(
									dataRes.file,
									'xlsx',
									'reporte'
								);
								this.cdr.markForCheck();
								break;
							case 201:
							case 202:
							case 401:
								// nothing
								break;
							default:
								this.showError(res);
								break;
						}
	}

	download() {
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminDriverSearch +
					'import/' +
					this.reportId
			)
			.subscribe(
				res => {
					switch (res.status) {
						case 200:
						case 201:
						case 202:
							this.uiService.downloadFileBase64(
								res['_body'],
								'xlsx',
								'reporte'
							);
							this.cdr.markForCheck();
							break;
						case 401:
							// nothing
							break;
						default:
							this.showError(res);
							break;
					}
				},
				err => {}
			);
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		// this.loading = false;
		this.cdr.markForCheck();
	}
}
