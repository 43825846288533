import { environment } from './../../../../../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import { NavegacionItem } from './../../../shared/dto/navegacion-item';
import { Response } from '@angular/http';
import { HttpService } from './../../../shared/services/http.service';
import { Injectable } from '@angular/core';

@Injectable()
export class NavegacionService {
  private _items: NavegacionItem[];
  public confRouting: {} = {
    '101': environment.folders.routeAdmin + 'perfil/buscar',
    '102': environment.folders.routeAdmin + 'rol/buscar',
    '103': environment.folders.routeAdmin + 'usuario/buscar',
    '104': environment.folders.routeAdmin + 'perfil/crear',
    '105': environment.folders.routeAdmin + 'rol/crear',
    '106': environment.folders.routeAdmin + 'usuario/crear',
    '107': environment.folders.routeAdmin + 'usuario/modificar',
    '143': environment.folders.routeAdmin + 'conductor',
    '144': environment.folders.routeAdmin + 'conductor/buscar',
    '145': environment.folders.routeAdmin + 'conductor/modificar',
    '146': environment.folders.routeAdmin + 'conductor/carga-masiva',
    '111': environment.folders.routeAdmin + 'correos',
    '113': environment.folders.routeAdmin + 'alarma',
    '152': environment.folders.routeAdmin + 'alarma/buscar-alarma',
    '142': environment.folders.routeAdmin + 'incidentes/gestion',
    '115': environment.folders.routeAdmin + 'incidentes/buscar',
    '116': environment.folders.routeAdmin + 'incidentes/crear',
    '117': environment.folders.routeAdmin + 'gestion/manual',
    '118': environment.folders.routeAdmin + 'gestion/recibir',
    '122': environment.folders.routeAdmin + 'gestion/supervisar',
    '119': environment.folders.routeAdmin + 'gestion/ajustar',
    '301': environment.folders.routeAdmin + 'registry/tipo1',
    '302': environment.folders.routeAdmin + 'registry/tipo2',
    '303': environment.folders.routeAdmin + 'registry/tipo2',
    '121': environment.folders.routeAdmin + 'gestion/responder',
    '108': environment.folders.routeAdmin + 'parametros/eventos-redes',
    '109': environment.folders.routeAdmin + 'parametros/analisis-predectivo',
    '123': environment.folders.routeAdmin + 'parametros/clustering',
    '124': environment.folders.routeAdmin + 'parametros/deteccion-idling',
    '125': environment.folders.routeAdmin + 'parametros/conductor-agresivo',
    '126': environment.folders.routeAdmin + 'parametros/deteccion-bloqueos',
    '127': environment.folders.routeAdmin + 'parametros/movil-detenido',
    '129': environment.folders.routeAdmin + 'parametros/mantenimiento-preventivo',

    // Reportes
    '136': environment.folders.routeAdmin + 'reportes/sensores-umbrales-peso',
    '135': environment.folders.routeAdmin + 'reportes/sensores-umbrales',
    '157': environment.folders.routeAdmin + 'reportes/recaudo-pago',
    '134': environment.folders.routeAdmin + 'reportes/sensores-peso',
    '133': environment.folders.routeAdmin + 'reportes/sensores-motor',
    '137': environment.folders.routeAdmin + 'reportes/validacion-conductor',
    '132': environment.folders.routeAdmin + 'reportes/incidentes-seguridad',
    '138': environment.folders.routeAdmin + 'reportes/validaciones-incorrectas-conductor',
    '130': environment.folders.routeAdmin + 'reportes/boton-panico',
    '131': environment.folders.routeAdmin + 'reportes/dano-vandalismo',
    // Indicadores
    '110': environment.folders.routeAdmin + 'indicadores/editar',
    '114': environment.folders.routeAdmin + 'indicadores/definir-rol',

    // Tramos
    '147': environment.folders.routeAdmin + 'tramos/buscar',
    '148': environment.folders.routeAdmin + 'tramos/crear/:idTramo',
    // Imagen Corporativa
    '112': environment.folders.routeAdmin + 'configurar/imagen-corporativa',
    // Envio STS
    '599': environment.folders.routeAdmin + 'enviar-mensaje-sts',
    // Puertas
    '600': environment.folders.routeAdmin + 'puertas/crear-contratista',
    '601': environment.folders.routeAdmin + 'puertas/crear-estacion'
  };

  public disabledRoutes = [ '109', '123', '124', '126', '127', '129' ];

  public disabledRoutesMessages = {
    '109': 'No hay datos para la construcción del modelo',
    '123': 'No requiere parámetros',
    '124': 'No hay datos para la construcción del modelo',
    //'125': 'Modelo en construcción',
    '126': 'No requiere parámetros',
    '127': 'No requiere parámetros',
    '129': 'No hay datos para la construcción del modelo'
  };

  private itemsNavegacionSource = new Subject<NavegacionItem[]>();
  public itemsNavegacion$ = this.itemsNavegacionSource.asObservable();

  public itemSelectedSource = new Subject<NavegacionItem>();
  public itemSelected$ = this.itemSelectedSource.asObservable();

  public toBackNavSource = new Subject<NavegacionItem>();
  public toBackNav$ = this.toBackNavSource.asObservable();

  constructor(
    private http: HttpService
  ) {
    this.setItems();
  }

  setItems() {
    this.http.ejecutarServicioGet( environment.endpoints.navegacion ).subscribe( (res: Response) => {
      this._items = res.json()['menu'];
      this.itemsNavegacionSource.next( this._items );
    });
  }

  getItems() {
    return this._items;
  }

  boxSelected( item: NavegacionItem ) {
    //console.log("Antes de ejecutar",item);
    //if(item){
      this.itemSelectedSource.next(item);
    //}

  }

  toBack( lastItem ) {
    this.toBackNavSource.next( lastItem );
  }

}
