export class AdminProfileDTO {

    id: number;
    name: string;
    description: string;
    userRoles: string;

    constructor(data?: any) {
        if (data !== undefined) {
            this.id = data['id'] !== undefined ? data['id'] : null;
            this.name = data['name'] !== undefined ? data['name'] : null;
            this.description = data['description'] !== undefined ? data['description'] : null;
            this.userRoles = data['userRoles'] !== undefined ? data['userRoles'] : null;
        }
    }

    static fromJS(data: any): AdminProfileDTO {
        return new AdminProfileDTO(data);
    }

    toJS(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : null;
        data['name'] = this.name !== undefined ? this.name : null;
        data['description'] = this.description !== undefined ? this.description : null;
        data['userRoles'] = this.userRoles !== undefined ? this.userRoles : null;
        return data;
    }

    toStrings(data?: any) {
        data = data === undefined ? {} : data;
        data['id'] = this.id !== undefined ? this.id : '';
        data['name'] = this.name !== undefined ? this.name : '';
        data['description'] = this.description !== undefined ? this.description : '';
        data['userRoles'] = this.userRoles !== undefined ? this.userRoles : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminProfileDTO(JSON.parse(json));
    }
}
