import {
	Component,
	OnInit,
	Input,
	ChangeDetectorRef,
	EventEmitter,
	Output,
	SimpleChanges,
	OnChanges
} from '@angular/core';

import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
// import { AdminDriverDTO } from '../../../../shared/dto/admin-driver-dto';
// import { AdminGroupDto } from '../../../../shared/dto/admin-group-dto';
import { environment } from '@environments/environment';
import { Response } from '@angular/http';
import { UIService } from '../../../_services/UI.service';
import {
	NgbDateNativeAdapter,
	NgbDateAdapter
} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'kt-conductor-registro-licencias',
	templateUrl: './conductor-registro-licencias.component.html',
	styleUrls: ['./conductor-registro-licencias.component.scss'],
	providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class ConductorRegistroLicenciasComponent implements OnInit, OnChanges {
	// formModelGroup: AdminGroupDto = new AdminGroupDto();

	@Input() model: any;
	@Output() modelChange = new EventEmitter();
	@Input() showTable: boolean = false;
	@Output() reload = new EventEmitter();

	constructor(
		private http: HttpService,
		private cdr: ChangeDetectorRef,
		private uiService: UIService
	) {
		this.selectFitroTipoCategoria = [];
	}
	@Input() showForm: boolean = false;
	@Input() showForm2: boolean = false;
	public options: Options;
	private emptyLicense = {
		id: null,
		documentType: '',
		document: '',
		licenseNumber: '',
		licenseExpeditionDate: '',
		licenseExpirationDate: ''
	};
	private emptyCategory = {
		id: null,
		licenseCategory: null,
		licenseCategoryExpirationDate: null
	};
	currentLicenseCategory: any = this.emptyCategory;
	currentLicense: any = this.emptyLicense;
	// formModelDriver: AdminDriverDTO = new AdminDriverDTO();
	// @Input() modificarDatos: AdminDriverDTO;
	contentToModificar: number = -1;
	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1 };
	configTabla2: {} = { status: -1 };
	loading: boolean = false;
	public selectFitroTipoCategoria: Array<Select2OptionData>;

	ngOnInit() {
		this.showForm = !this.showTable;
		this.showForm2 = !this.showTable;
		this.loading = true;
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminParamCategoriasLicencias
			)
			.subscribe((res: Response) => {
				let data = res.json().answerGroups;
				this.selectFitroTipoCategoria = data.map(i => {
					return { id: i[53], text: i[54] };
				});
			});
		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.model.currentValue) {
			let licenses = changes.model.currentValue.licenses;
			if (licenses) {
				this.procesarJson2(licenses);
			}
			let licenseCategories =
				changes.model.currentValue.licenseCategories;
			if (licenseCategories) {
				this.procesarJson(licenseCategories);
			}
		}
	}

	procesarJson(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				data[index]['id'].toString(),
				data[index]['licenseCategory'].toString(),
				data[index]['licenseCategoryExpirationDate'].toString(),
				''
			]);

			if (index === data.length - 1) {
				this.armarTablaCategorias(dataArray);
			}
		}

		if (!data.length) {
			this.armarTablaCategorias(dataArray);
		}
	}

	procesarJson2(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				data[index]['id'].toString(),
				data[index]['licenseExpeditionDate'].toString(),
				data[index]['licenseExpirationDate'].toString(),
				data[index]['licenseNumber'].toString(),
				''
			]);

			if (index === data.length - 1) {
				this.armarTabla(dataArray);
			}
		}

		if (!data.length) {
			this.armarTabla(dataArray);
		}
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: [
				'ID',
				'Fecha de Expedicion',
				'Fecha de Expiracion',
				'Numero de Licencia',
				'Acciones'
			],
			typeColumn: [
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'eliminar'] }
			],
			searchColumn: [0, 1, 2],
			hideColumns: [0],
			showPag: false,
			showExport: false,
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data
		};
		this.loading = false;
		this.cdr.markForCheck();
	}

	armarTablaCategorias(data) {
		this.contentToShow = 1;
		this.configTabla2 = {
			status: 1,
			header: ['ID', 'Categoria', 'Fecha de Expiracion', 'Acciones'],
			typeColumn: [
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'eliminar'] }
			],
			searchColumn: [0, 1, 2],
			hideColumns: [0],
			showPag: false,
			showExport: false,
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data
		};
		this.loading = false;
		this.cdr.markForCheck();
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.loading = false;
		this.cdr.markForCheck();
	}

	editarLicencia(event) {
		let item = this.model.licenses.find(
			itm => itm.id === parseInt(event, 10)
		);
		this.currentLicense = item;
		this.showForm = true;
		this.cdr.markForCheck();
	}

	editarCategoria(event) {
		let item = this.model.licenseCategories.find(
			itm => itm.id === parseInt(event, 10)
		);
		this.currentLicenseCategory = item;
		this.showForm2 = true;
		this.cdr.markForCheck();
	}

	agregarLicencia() {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/license`;
		this.http
			.ejecutarServicioPost(url, this.currentLicense)
			.subscribe((res: Response) => {
				if (res.status === 200 || res.status === 201) {
					this.currentLicense = this.emptyLicense;
					this.reload.emit(true);
				}
				else { this.showError(res); }
			});
	}

	borrarLicencia(event) {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/license/${event}`;
		this.http.ejecutarServicioDelete(url).subscribe((res: Response) => {
			if (res.status === 200 || res.status === 201) {
				this.reload.emit(true);
			}
			else { this.showError(res); }
		});
	}

	actualizarLicencia() {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/license/${this.currentLicense.id}`;
		this.http
			.ejecutarServicioPut(url, this.currentLicense)
			.subscribe((res: Response) => {
				if (res.status === 200 || res.status === 201) {
					this.currentLicense = this.emptyLicense;
					this.reload.emit(true);
				}
				else { this.showError(res); }
			});
	}

	agregarCategoriaLicencia() {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/licensecategory`;
		this.http
			.ejecutarServicioPost(url, this.currentLicenseCategory)
			.subscribe((res: Response) => {
				if (res.status === 200 || res.status === 201) {
					this.currentLicenseCategory = this.emptyCategory;
					this.reload.emit(true);
				}
				else { this.showError(res); }
			});
	}

	borrarCategoriaLicencia(event) {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/licensecategory/${event}`;
		this.http.ejecutarServicioDelete(url).subscribe((res: Response) => {
			if (res.status === 200 || res.status === 201) {
				this.reload.emit(true);
			}
			else { this.showError(res); }
		});
	}

	actualizarCategoriaLicencia() {
		let url = `${environment.endpoints.adminDriver}document_type/${
			this.model['documentType']
		}/document/${this.model['document']}/licensecategory/${
			this.currentLicenseCategory.id
		}`;
		this.http
			.ejecutarServicioPut(url, this.currentLicenseCategory)
			.subscribe((res: Response) => {
				if (res.status === 200 || res.status === 201) {
					this.currentLicenseCategory = this.emptyCategory;
					this.reload.emit(true);
				}
				else { this.showError(res); }
			});
	}
	updateModel() {
		this.model.licenseNumber = this.currentLicense.licenseNumber;
		this.model.licenseExpeditionDate = this.currentLicense.licenseExpeditionDate;
		this.model.licenseExpirationDate = this.currentLicense.licenseExpirationDate;
		this.model.licenseCategory = this.currentLicenseCategory.licenseCategory;
		this.model.licenseCategoryExpirationDate = this.currentLicenseCategory.licenseCategoryExpirationDate;
	}

	guardarLicencia() {
		if ((this.currentLicense.licenseNumber === null || this.currentLicense.licenseNumber === undefined || this.currentLicense.licenseNumber === "") ||
			(this.currentLicense.licenseExpeditionDate === null || this.currentLicense.licenseExpeditionDate === undefined || this.currentLicense.licenseExpeditionDate === "") ||
			(this.currentLicense.licenseExpirationDate === null || this.currentLicense.licenseExpirationDate === undefined || this.currentLicense.licenseExpirationDate === "")
		) {
			this.uiService.showMessage("Llene los campos marcados con asterisco (*)");
			return;
		}
		this.showForm = false;
		if (this.currentLicense.id) {
			this.actualizarLicencia();
		} else {
			this.agregarLicencia();
		}
	}

	guardarCategoria() {
		if((this.currentLicenseCategory.licenseCategory === null || this.currentLicenseCategory.licenseCategory === undefined || this.currentLicenseCategory.licenseCategory === "") ||
		(this.currentLicenseCategory.licenseCategoryExpirationDate === null || this.currentLicenseCategory.licenseCategoryExpirationDate === undefined || this.currentLicenseCategory.licenseCategoryExpirationDate === "")){
			this.uiService.showMessage("Llene los campos marcados con asterisco (*)");
			return;
		}
		this.showForm2 = false;
		if (this.currentLicenseCategory.id) {
			this.actualizarCategoriaLicencia();
		} else {
			this.agregarCategoriaLicencia();
		}
	}

	cancelarLicencia() {
		this.showForm = false;
		this.currentLicense = this.emptyLicense;
		this.cdr.markForCheck();
	}

	cancelarCategoria() {
		this.showForm2 = false;
		this.currentLicenseCategory = this.emptyCategory;
		this.cdr.markForCheck();
	}

	/**
	 * Valida que la fecha de expedición de licencia sea menor a la fecha de expedición.
	 */
	validateLicenseExpeditionDate() {
		setTimeout(()=>{
			if (this.currentLicense.licenseExpeditionDate !== null && this.currentLicense.licenseExpeditionDate !== undefined && this.currentLicense.licenseExpeditionDate !== "") {
				let fechaExpedicionLic = new  Date(this.currentLicense.licenseExpeditionDate);
				let fechaExpedicionLicFormato = fechaExpedicionLic.getFullYear() + "" + ((fechaExpedicionLic.getMonth()+1) < 10 ? '0' + (fechaExpedicionLic.getMonth()+1) : (fechaExpedicionLic.getMonth()+1)) + "" 
					+ (fechaExpedicionLic.getDate() < 10 ? '0' + fechaExpedicionLic.getDate() : fechaExpedicionLic.getDate());
				if (this.currentLicense.licenseExpirationDate !== null && this.currentLicense.licenseExpirationDate !== undefined && this.currentLicense.licenseExpirationDate !== "") {
					let fechaExpiracionLic = new  Date(this.currentLicense.licenseExpirationDate);
					let fechaExpiracionLicFormato = fechaExpiracionLic.getFullYear() + "" + ((fechaExpiracionLic.getMonth()+1) < 10 ? '0' + (fechaExpiracionLic.getMonth()+1) : (fechaExpiracionLic.getMonth()+1)) + "" 
						+ (fechaExpiracionLic.getDate() < 10 ? '0' + fechaExpiracionLic.getDate() : fechaExpiracionLic.getDate());
					if(Number(fechaExpedicionLicFormato) >= Number(fechaExpiracionLicFormato)){
						this.uiService.showMessage("Seleccione una fecha menor a la fecha de expiración.")
						this.currentLicense.licenseExpeditionDate = null;
						this.cdr.markForCheck();
					}
				}
			}
		})
	}

	/**
	 * Valida que la fecha de expiración de licencia sea mayor a la fecha actual y mayor a la fecha de expedición.
	 */
	validateLicenseExpirationDate() {
		let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
		+ (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
		setTimeout(()=>{
			if (this.currentLicense.licenseExpirationDate !== null && this.currentLicense.licenseExpirationDate !== undefined && this.currentLicense.licenseExpirationDate !== "") {
				let fechaExpiracionLic = new  Date(this.currentLicense.licenseExpirationDate);
				let fechaExpiracionLicFormato = fechaExpiracionLic.getFullYear() + "" + ((fechaExpiracionLic.getMonth()+1) < 10 ? '0' + (fechaExpiracionLic.getMonth()+1) : (fechaExpiracionLic.getMonth()+1)) + "" 
				+ (fechaExpiracionLic.getDate() < 10 ? '0' + fechaExpiracionLic.getDate() : fechaExpiracionLic.getDate());
				if(Number(fechaExpiracionLicFormato) <= Number(fechaActualFormato)){
					this.uiService.showMessage("Seleccione una fecha mayor a la fecha de hoy.")
					this.currentLicense.licenseExpirationDate = null;
					this.cdr.markForCheck();
				} else if (this.currentLicense.licenseExpeditionDate !== null && this.currentLicense.licenseExpeditionDate !== undefined && this.currentLicense.licenseExpeditionDate !== "") {
					let fechaExpedicionLic = new  Date(this.currentLicense.licenseExpeditionDate);
					let fechaExpedicionLicFormato = fechaExpedicionLic.getFullYear() + "" + ((fechaExpedicionLic.getMonth()+1) < 10 ? '0' + (fechaExpedicionLic.getMonth()+1) : (fechaExpedicionLic.getMonth()+1)) + "" 
					+ (fechaExpedicionLic.getDate() < 10 ? '0' + fechaExpedicionLic.getDate() : fechaExpedicionLic.getDate());
					if(Number(fechaExpiracionLicFormato) <= Number(fechaExpedicionLicFormato)){
						this.uiService.showMessage("La fecha de expiración debe ser mayor a la fecha de expedición.")
						this.currentLicense.licenseExpirationDate = null;
						this.cdr.markForCheck();
					}
				}
			}
		})
	}

	/**
	 * Valida que la fecha de expiración de la categoría de licencia sea mayor a la fecha actual y mayor a la fecha de expedición.
	 */
	validateLicenseCategoryExpirationDate() {
		console.log("validateLicenseCategoryExpirationDate")
		let fechaActual = new Date();
		let fechaActualFormato = fechaActual.getFullYear() + "" + ((fechaActual.getMonth()+1) < 10 ? '0' + (fechaActual.getMonth()+1) : (fechaActual.getMonth()+1)) + "" 
		+ (fechaActual.getDate() < 10 ? '0' + fechaActual.getDate() : fechaActual.getDate());
		setTimeout(()=>{
			if (this.currentLicenseCategory.licenseCategoryExpirationDate !== null && this.currentLicenseCategory.licenseCategoryExpirationDate !== undefined && this.currentLicenseCategory.licenseCategoryExpirationDate !== "") {
				let fechaExpiracionCategoria = new  Date(this.currentLicenseCategory.licenseCategoryExpirationDate);
				let fechaExpiracionCategoriaFormato = fechaExpiracionCategoria.getFullYear() + "" + ((fechaExpiracionCategoria.getMonth()+1) < 10 ? '0' + (fechaExpiracionCategoria.getMonth()+1) : (fechaExpiracionCategoria.getMonth()+1)) + "" 
				+ (fechaExpiracionCategoria.getDate() < 10 ? '0' + fechaExpiracionCategoria.getDate() : fechaExpiracionCategoria.getDate());
				if(Number(fechaExpiracionCategoriaFormato) <= Number(fechaActualFormato)){
					this.uiService.showMessage("Seleccione una fecha mayor a la fecha de hoy.")
					this.currentLicenseCategory.licenseCategoryExpirationDate = null;
					this.cdr.markForCheck();
				} else if (this.currentLicense.licenseExpeditionDate !== null && this.currentLicense.licenseExpeditionDate !== undefined && this.currentLicense.licenseExpeditionDate !== "") {
					let fechaExpedicionLic = new  Date(this.currentLicense.licenseExpeditionDate);
					let fechaExpedicionLicFormato = fechaExpedicionLic.getFullYear() + "" + ((fechaExpedicionLic.getMonth()+1) < 10 ? '0' + (fechaExpedicionLic.getMonth()+1) : (fechaExpedicionLic.getMonth()+1)) + "" 
					+ (fechaExpedicionLic.getDate() < 10 ? '0' + fechaExpedicionLic.getDate() : fechaExpedicionLic.getDate());
					if(Number(fechaExpiracionCategoriaFormato) <= Number(fechaExpedicionLicFormato)){
						this.uiService.showMessage("La fecha de expiración debe ser mayor a la fecha de expedición.")
						this.currentLicenseCategory.licenseCategoryExpirationDate = null;
						this.cdr.markForCheck();
					}
				}
			}
		})
	}
}
