import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AdminBusParado } from '../../../../shared/dto/admin-bus-parado-dto';
import { Select2OptionData, Options } from 'select2';
import { HttpService } from '../../../../shared/services/http.service';
import { UIService } from './../../../../trans/_services/UI.service';
import { environment } from '../../../../../../environments/environment';
import { Response } from '@angular/http';

@Component({
  selector: 'kt-movil-detenido',
  templateUrl: './movil-detenido.component.html',
  styleUrls: ['./movil-detenido.component.scss'],
  host: {'class': 'sths-tb-element'}
})
export class MovilDetenidoComponent implements OnInit {
  public selectTroncal: Array<Select2OptionData>;
  tramo: string;

  configTable: Object;
  public options: Options;
  
  // Tabla
  contentToShow: number = -1;
  configTabla: {} = { status: -1, data: [] };

  // Paginador
  paginador: Object = {
    count: 10,
    page: 1,
    total: 0,
    maxPages: 5
  };

  // Modales
  contentToShowModal: number = -1;
  configModal: {} = { status: -1 };

  conditionalLoader: boolean;

  // Id Eliminar
  eliminarId: any;

  // Asignar Caso
  itemAsignar: any;

  // Modal Modificar
  contentToModificar: number = -1;
  casoManual: any;

  formModelBusParado: AdminBusParado = new AdminBusParado;

  constructor(private http: HttpService,
    private uiService: UIService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.buscarModelosDeteccion();

    this.options = {
      language: 'es',
      closeOnSelect: true,
      dropdownCssClass: "select2-dropdown-forms"
    };

    // Troncal
    this.http.ejecutarServicioGet( environment.endpoints.adminTroncal, null ).subscribe( (resTroncal: Response) => {
      switch (resTroncal.status) {
        case 200:
        case 201:
        case 204:
          let troncal = resTroncal.json();
          for (let indexSta = 0; indexSta < troncal.length; indexSta++) {
            troncal[indexSta]['id'] = troncal[indexSta]['answer'];
            troncal[indexSta]['text'] = troncal[indexSta]['answer'];
            if ( indexSta === troncal.length - 1 ) {
              troncal.unshift( {id:"-Sel", text: "Seleccionar Troncal"} );
              this.selectTroncal = troncal;
              break;
            }
          }

          this.cdr.markForCheck();
          break;
        default:
            this.showError( resTroncal );
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    })
  }

  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage( response.json()['message'] );
    } else {
      this.uiService.showMessage( 'Error del servidor' );
    }
    this.cdr.markForCheck();
  }

  buscarModelosDeteccionBtn(){
    this.paginador['page'] = 1;
    this.buscarModelosDeteccion();  
  }

  buscarModelosDeteccion(){
    let formGestion = this.formModelBusParado.toStrings();
    let searchFields = ``;
    
    searchFields += ( formGestion['tramo'] == "" || formGestion['tramo'] == null ) ?  `&stretchName=` : `&stretchName=${formGestion['tramo']}`;
    searchFields += ( formGestion['troncal'] == "-Sel" || formGestion['troncal'] == null ) ?  `&trunkLine=` : `&trunkLine=${formGestion['troncal']}`;

    let count = this.paginador['count'];
    let page = this.paginador['page'];

    let ruta = '';
    let rutaCount = '';

    ruta = `${environment.endpoints.adminBusStoppedExtendSearch}?count=${count}&page=${page}${searchFields}`;
    rutaCount = `${environment.endpoints.adminBusStoppedExtendCount}?count=${count}&page=${page}${searchFields}`;
    
    this.conditionalLoader = true;
    this.http.ejecutarServicioGet( ruta, null ).subscribe( (res: Response) => {
      switch (res.status) {
         case 200:
         case 201:
            this.http.ejecutarServicioGet( rutaCount, null ).subscribe( (response: Response) => {
              switch (response.status) {
                case 200:
                case 201:
                case 204:
                  this.paginador['total'] = response.json();
                  if ( res.json() && res.json().length > 0 ) {
                    this.procesarJson( res.json() );
                  }else{
                    this.procesarJson( [] );
                  }
                  break;
                case 401:
                  break;
                default:
                  this.showError( response );
                  break;
              }
            }, (error: Response) => {
              this.showError( error );
            });
 
           this.cdr.markForCheck();
           break;
           case 204:
             this.procesarJson( [] );
             this.cdr.markForCheck();
           break;
 
         default:
             this.procesarJson( [] );
             this.cdr.markForCheck();
             this.showError( res );
           break;
       }
       this.conditionalLoader = false;
     }, (error : Response) => {
       this.conditionalLoader = false;
       this.procesarJson( [] );
       this.cdr.markForCheck();
       this.showError( error );
     });
  }

  procesarJson( data ) {
    /*
      @ID (Referencia)
      @ID,
    */
    let dataArray = [];
    for (let index = 0; index < data.length; index++) {
      dataArray.push([
        data[index]['stretchId'].toString(),
        data[index]['stretchId'].toString(),
        (data[index]['trunkLine']) ? data[index]['trunkLine'].toString() : '',
        (data[index]['stretchName']) ? data[index]['stretchName'].toString() : '',
        (data[index]['time']) ? data[index]['time'].toString() : '',
        data[index]['enabled'],
      ]);

      if ( index === data.length - 1 ) {
        this.armarTabla(dataArray);
      }
    }

    if( !data.length ){
      this.armarTabla(dataArray);
    }
  }

  armarTabla( data ) {
    this.contentToShow = 1;
    this.configTabla = {
      status: 1,
      header: ['ID', 'ID', 'Troncal', 'Tramo', 'Tiempo', 'Estado'],
      typeColumn: [,,,,,{ tipo: 'estado' }],
      showSearch: false,
      searchColumn: [1],
      searchTitle: 'Buscar Categoría',
      hideColumns: [0],
      showPag: true,
      leftsColumns: [1],
      configColumns: {
        id: 0,
        columnClick: 1,
      },
      data: data,
      paginador: this.paginador,
    }
    this.cdr.markForCheck();
  }

  buscadorPagina(event) {
    this.paginador['page'] = event;
    this.buscarModelosDeteccion();
  }

  eventEstadoClick(item) {
    this.conditionalLoader = true;

    let busquedaBusParado = {
      stretchId: item.id,
      enabled: item.value,
    }

    this.http.ejecutarServicioPost( environment.endpoints.adminBusStoppedExtendStatus, busquedaBusParado).subscribe((res: Response) => {
      switch (res.status) {
        case 200:
        case 201:
        case 202:
          this.conditionalLoader = false;
          this.cdr.markForCheck();
          this.buscarModelosDeteccion();
          break;
        case 401:
          // nothing
          break;
        default:
          this.showError(res);
          this.buscarModelosDeteccion();
          break;
      }
    }, (error: Response) => {
      this.showError(error);
      this.buscarModelosDeteccion();
    });// END PUT
  }

  guardarMovilDetenido( ){
    // Modal Guardó
    this.showModal();
  }

  showModal(){
    this.contentToShowModal = 1;

    this.configModal = {
      status: 1,
      icono: 'smart-icon-guardar',
      titulo: 'Los datos han sido guardados',
      textos: [
            'Los datos ingresados han sido guardados con éxito'
      ],
      botones: [
              {
                label: 'Ok',
                class: '',
                accion: 'guardarMovilDetenido'
              }
      ]
    };
    this.cdr.markForCheck();
  }
  
  changeStatusModal( event ){
    this.contentToShowModal = -1;
    this.configModal = { status: -1 }
    this.cdr.markForCheck();
    if ( event === 'guardarMovilDetenido' ){
      
    }
  }

}
