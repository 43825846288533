import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

export class AdminGestionCrearAutoDto {

    falseAlert: boolean = false;
    alerta: string;
    incidentType: string;
    incidentClass: string;
    imageClassification: string;
    observations: string;
    caseStatus: number;
    entity: string;
    // modificationUser: string;
    
    constructor(data ? : any) {
        if (data !== undefined) {
            this.falseAlert = data['falseAlert'] !== undefined ? data['falseAlert'] : null;
            this.incidentType = data['incidentType'] !== undefined ? data['incidentType'] : null;
            this.incidentClass = data['incidentClass'] !== undefined ? data['incidentClass'] : null;
            this.imageClassification = data['imageClassification'] !== undefined ? data['imageClassification'] : null;
            this.observations = data['observations'] !== undefined ? data['observations'] : null;
            this.caseStatus = data['caseStatus'] !== undefined ? data['caseStatus'] : null;
            // this.modificationUser = data['modificationUser'] !== undefined ? data['modificationUser'] : null;
        }
    }

    static fromJS(data: any): AdminGestionCrearAutoDto {
        return new AdminGestionCrearAutoDto(data);
    }

    toJS(data ? : any) {
        data = data === undefined ? {} : data;
        data['falseAlert'] = this.falseAlert !== undefined ? this.falseAlert : null;
        data['incidentType'] = this.incidentType !== undefined ? this.incidentType : null;
        data['incidentClass'] = this.incidentClass !== undefined ? this.incidentClass : null;
        data['imageClassification'] = this.imageClassification !== undefined ? this.imageClassification : null;
        data['observations'] = this.observations !== undefined ? this.observations : null;
        data['caseStatus'] = this.caseStatus !== undefined ? this.caseStatus : null;
        // data['modificationUser'] = this.modificationUser !== undefined ? this.modificationUser : null;        
        return data;
    }

    toStrings(data ? : any) {
        data = data === undefined ? {} : data;
        data['falseAlert'] = this.falseAlert !== undefined ? this.falseAlert : '';
        data['incidentType'] = this.incidentType !== undefined ? this.incidentType : '';
        data['incidentClass'] = this.incidentClass !== undefined ? this.incidentClass : '';
        data['imageClassification'] = this.imageClassification !== undefined ? this.imageClassification : '';
        data['observations'] = this.observations !== undefined ? this.observations : '';
        data['caseStatus'] = this.caseStatus !== undefined ? this.caseStatus : '';
        // data['modificationUser'] = this.modificationUser !== undefined ? this.modificationUser : '';
        return data;
    }

    toJSON() {
        return JSON.stringify(this.toJS());
    }

    clone() {
        const json = this.toJSON();
        return new AdminGestionCrearAutoDto(JSON.parse(json));
    }

}