import {environment} from './../../../../../../environments/environment';
import {HttpService} from '../../../../shared/services/http.service';
import {UIService} from './../../../../trans/_services/UI.service';

import {Response} from '@angular/http';

import {Component, OnInit, ChangeDetectorRef} from '@angular/core';

@Component({
	selector: 'kt-deteccion-bloqueos',
	templateUrl: './deteccion-bloqueos.component.html',
	styleUrls: ['./deteccion-bloqueos.component.scss'],
	host: {'class': 'sths-tb-element'}
})
export class DeteccionBloqueosComponent implements OnInit {

	cantidad: string;
	tiempo: string;

	model: any;
	loading: boolean = false;

	// Modales
	contentToShowModal: number = -1;
	configModal: {} = {status: -1};

	constructor(private cdr: ChangeDetectorRef,
				private http: HttpService,
				private uiService: UIService) {
	}

	ngOnInit() {
		this.http.ejecutarServicioGet(environment.endpoints.adminParamDetectionBlockList, null).subscribe((response: Response) => {
			switch (response.status) {
				case 200:
				case 201:
				case 204:
					this.model = {...response.json()};
					this.model.questions.forEach(row => {
						row.value = this.getAnswer(row.id);
					});

					this.cdr.markForCheck();
					break;
				case 401:
					// nothing
					break;
				default:
					this.showError(response);
					break;
			}

		}, (error: Response) => {
			this.showError(error);
		});
	}

	getAnswer(id_question) {
		return this.model.answers.find(f => f.question === id_question)['answer'] || '';
	}

	getQuestionAnswer(id_question) {
		return this.model.questions.find(f => f.id === id_question)['value'] || '';
	}

	getObjectToPOST() {
		let answer = {};
		this.model.answers.forEach(row => {
			answer[`${row.question}`] = this.getQuestionAnswer(row.question).toString();
		});
		return {'answer': answer};
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.cdr.markForCheck();
	}

	guardarDeteccionIdling() {
		this.loading = true;
		const obj = this.getObjectToPOST();

		if (this.model.answers.length > 0) {
			this.update(obj);
		} else {
			this.save(obj);
		}
	}

	update(obj) {
		const group = this.model.answers[0].answerGroup;
		this.http.ejecutarServicioPut(`${environment.endpoints.adminParamDetectionBlockUpdate}/${group}`, obj).subscribe((res: Response) => {
			switch (res.status) {
				case 200:
				case 201:
				case 204:
					// Modal Guardó
					this.showModal();
					this.loading = false;
					break;

				default:
					this.loading = false;
					this.showError(res);
					break;
			}
		}, (error: Response) => {
			this.loading = false;
			this.showError(error);
		});// END POST
	}

	save(obj) {
		this.http.ejecutarServicioPost(environment.endpoints.adminParamDetectionBlockSave, obj).subscribe((res: Response) => {
			switch (res.status) {
				case 200:
				case 201:
				case 204:
					// Modal Guardó
					this.showModal();
					this.loading = false;
					break;

				default:
					this.loading = false;
					this.showError(res);
					break;
			}
		}, (error: Response) => {
			this.loading = false;
			this.showError(error);
		});// END POST
	}

	showModal() {
		this.contentToShowModal = 1;

		this.configModal = {
			status: 1,
			icono: 'smart-icon-guardar',
			titulo: 'Los datos han sido guardados',
			textos: [
				'Los datos ingresados han sido guardados con éxito'
			],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'guardarDeteccionIdling'
				}
			]
		};
		this.cdr.markForCheck();
	}

	changeStatusModal(event) {
		this.contentToShowModal = -1;
		this.configModal = {status: -1};
		this.cdr.markForCheck();
		if (event === 'guardarDeteccionIdling') {
		}
	}

	validateForm(event) {
	}
}
