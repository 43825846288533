import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { environment } from '@environments/environment';
import { HttpService } from '@app/views/shared/services/http.service';
import { Response } from '@angular/http';

import {
	Component,
	OnInit,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	Input
} from '@angular/core';
import { Select2OptionData, Options } from 'select2';

@Component({
	selector: 'kt-form-eventos',
	templateUrl: './form-eventos.component.html',
	styleUrls: ['./form-eventos.component.scss']
})
export class FormEventosComponent implements OnInit {
	// Select2
	public selectRedes: Array<Select2OptionData>;
	public options: Options;

	// Modales
	contentToShowModal: number = -1;
	configModal: {} = { status: -1 };
	eventoForm: FormGroup;
	submitted = false;
	// DATOS pueden ser en un dto
	formModelEventos: any = {
		12: '',
		13: '',
		14: ''
	};

	conditionalLoader: boolean;
	private _id: string;
	@Input()
	set idEventoRedes(id: string) {
		this._id = id;
		this.loadEvent();
	}

	@Output() eventGuardar = new EventEmitter();
	@Output() actionModificarEventos = new EventEmitter();

	constructor(
		private cdr: ChangeDetectorRef,
		private http: HttpService,
		private formBuilder: FormBuilder
	) { }

	ngOnInit() {
		// Select2
		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
		// selectRedes
		this.selectRedes = [];
		this.loadEvent();
		this.eventoForm = this.formBuilder.group({
			12: ['', Validators.required],
			13: [
				'',
					Validators.maxLength(100)
			],
			14: [
				'',
					Validators.maxLength(100)
			]
		});
	}
	get f() {
		return this.eventoForm.controls;
	}

	loadEvent() {
		this.conditionalLoader = true;
		// Si trae algo idEventosRedes, cargar los datos del webservice por ID
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminParamNetworksEventList
			)
			.subscribe((eventRecords: Response) => {
				this.conditionalLoader = false;
				// let data = eventRecords.json().answerGroups;
				let formConfig = eventRecords.json().questions;
				let select = formConfig.find(i => Number(i.id) === 12);
				if (select) {
					this.selectRedes = select.possibleAnswers.map(i => {
						return { id: i.answerValue, text: i.answer };
					});
				}
				this.cdr.markForCheck();
			});
	}

	guardarEventos() {
		this.submitted = true;
		// Servicio Guardar
		this.contentToShowModal = 1;

		if(!this.eventoForm.value[13] && !this.eventoForm.value[14] ){
			let field1 = this.eventoForm.get('13');
			let field2 = this.eventoForm.get('14');
			field1.setValidators([Validators.required, Validators.maxLength(100)]);
			field2.setValidators([Validators.required, Validators.maxLength(100)]);
			field1.updateValueAndValidity();
			field2.updateValueAndValidity();
			return;
		}
		if (this.eventoForm.invalid) {
			return;
		}
		this.configModal = {
			status: 1,
			icono: 'smart-icon-guardar',
			titulo: 'Los datos han sido guardados',
			textos: ['Los datos ingresados han sido guardados con éxito'],
			botones: [
				{
					label: 'Ok',
					class: '',
					accion: 'guardarEventos'
				}
			]
		};
		this.cdr.markForCheck();
		var result = {
			'12': this.eventoForm.value[12] ? this.eventoForm.value[12] : '',
			'13': this.eventoForm.value[13] ? this.eventoForm.value[13] : '',
			'14': this.eventoForm.value[14] ? this.eventoForm.value[14] : '',
		}
		this.eventGuardar.emit(result);

		setTimeout(() => {
			this.contentToShowModal = -1;
			this.configModal = { status: -1 };
		}, 300);
	}

	onChange(field:string){
		this.submitted = false;
		this.f[field].setValidators(Validators.maxLength(100));
		this.f[field].updateValueAndValidity();
	}

	changeStatusModal(event) {
		this.eventoForm.reset();
		this.submitted = false;

		// 	switch (event) {
		// 		case "guardarEventos":
		// 			// Limpiar, si es un dto se instancia de nuevo la variable
		// 			this.formModelEventos = {
		// 				modelo: "-Sel",
		// 				tiempo: "",
		// 				sensor: "-Sel"
		// 			};
		// 			// Refrescar Datos
		// 			this.eventGuardar.emit("guardarEventos");
		// 			// EMIT al PADRE
		// 			break;

		// 		default:
		// 			// EMIT al PADRE
		// 			this.eventGuardar.emit("guardarEventos");
		// 			break;
		// 	}
	}
}
