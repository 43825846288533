// Angular
import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
// Layout
import { LayoutConfigService } from '../../../core/_base/layout';

@Component({
	selector: 'kt-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
	// Public properties
	today: number = Date.now();
	handle: any;

	@Input() colorLogo: string = 'white';

	/**
	 * Component constructor
	 *
	 * @param layoutConfigService: LayouConfigService
	 */
	constructor(
		private layoutConfigService: LayoutConfigService,
		private _cd: ChangeDetectorRef,
	) {
		this.handle = setInterval(() => {
			this.today = Date.now();
			this._cd.detectChanges();
		}, 1000);
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {
		const config = this.layoutConfigService.getConfig();
	}

	ngOnDestroy(): void {
		clearInterval(this.handle);
	}

	getHora() {
		return Date.now();
	}

}
