import { Component, OnInit, EventEmitter,Input,Output,ChangeDetectorRef } from '@angular/core';
import { AdminRolDTO } from '../../../../shared/dto/admin-rol-dto';
import { HttpService } from '../../../../shared/services/http.service';
import { PermissionDTO } from '../../../../shared/dto/admin-permission-dto';
import { UIService } from '../../../_services/UI.service';
import { environment } from './../../../../../../environments/environment';
import { Response } from '@angular/http';


@Component({
  selector: 'kt-definir-indicadores-perfil',
  templateUrl: './definir-indicadores-perfil.component.html',
  styleUrls: ['./definir-indicadores-perfil.component.scss']
})
export class DefinirIndicadoresPerfilComponent implements OnInit {
  rutasMenu: object;
  permisos: {}[];
  configDatos: {};
  listadoPermissions: PermissionDTO[];

  formModelRol: AdminRolDTO = new AdminRolDTO();
  data: AdminRolDTO = new AdminRolDTO();

  @Input() modificarDatos: AdminRolDTO;

  @Output() actionModificarRol = new EventEmitter();

  // Modales
  contentToShow: number = -1;
  configModal: {} = { status: -1 };

  loading: boolean = false;


  constructor(private http: HttpService,
    private uiService: UIService,
    private cdr: ChangeDetectorRef,
    ) { }

  ngOnInit() {
    // Si trae parametros para EDITAR
    if (this.modificarDatos) {
      this.formModelRol = this.modificarDatos;
    }
    this.loading = true;
    this.http.ejecutarServicioGet(environment.endpoints.adminUserPermissionsList).subscribe((res: Response) => {

      switch (res.status) {
        case 200:
        case 201:
        case 202:
            let data: PermissionDTO[];
             data = [
              new PermissionDTO ({id: 125, name: "Capa1", checked: false, active: false}),
              new PermissionDTO ({id: 126, name: "Capa2", checked: false, active: false})
            ];
          this.listadoPermissions = data;
          if (this.modificarDatos) {
            let ruta = `${environment.endpoints.adminUserRole}${this.modificarDatos.id}/permissions`;
            this.http.ejecutarServicioGet(ruta).subscribe((resPermisos: Response) => {

              switch (resPermisos.status) {
                case 200:
                case 201:
                case 204:
                  let permisos = resPermisos.json();
                  this.formModelRol.userPermissions = permisos.map(function (e) { return e.id; }).join(',');

                  for (let index = 0; index < this.listadoPermissions.length; index++) {

                    if (permisos.map(function (e) { return e.id; }).indexOf(this.listadoPermissions[index]['id']) !== -1) {
                      this.listadoPermissions[index]['active'] = true;
                    } else {
                      this.listadoPermissions[index]['active'] = false;
                    }
                    if (index === this.listadoPermissions.length - 1) {
                      this.cdr.markForCheck();
                    }
                  }
                  this.loading = false;
                  console.log(this.listadoPermissions);
                  break;
                case 401:
                  // nothing
                  break;
                default:
                  this.showError(resPermisos);
                  break;
              }
            }, (error: Response) => {
              this.showError(error);
            });// END INNER GET

          } else {
            this.loading = false;
            this.resetPermisos(this.listadoPermissions, 'iniciar');
          }
          this.cdr.markForCheck();
          break;
        case 401:
          // nothing
          break;
        default:
          this.showError(res);
          break;
      }
    }, (error: Response) => {
      this.showError(error);
    });// END GET

    this.rutasMenu = [
      {
        label: 'Asignar indicadores a capas',
        ruta: `${environment.folders.routeAdmin}indicadores/definir-rol`
      },
      {
        label: 'Asignar Capas a perfiles',
        ruta: `${environment.folders.routeAdmin}indicadores/definir-perfil`
      }
    ];
    // Select List
    this.configDatos = {
      icono: '',
      titulo: 'Capas',
      parametro1: 'Capas Disponibles',
      parametro2: 'Capas Asignados al capa',
      editar: (this.formModelRol.id) ? true : false
    };
  }

  showError(response) {

    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage(response.json()['message']);
    } else {
      this.uiService.showMessage('Error del servidor');
    }
    this.loading = false;
    this.cdr.markForCheck();

  }

  resetPermisos(perm, status) {
    if (status === 'iniciar') {
      for (let index = 0; index < perm.length; index++) {
        perm[index]['checked'] = false;
        perm[index]['active'] = false;
        if (index === perm[index].length - 1) {
          this.listadoPermissions = perm;
          this.cdr.markForCheck();
        }
      }
    }
  }

}
