import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../../../../environments/environment';
import { HttpService } from '../../../../shared/services/http.service';
import { UIService } from './../../../../trans/_services/UI.service';
import { AuthService } from '../../../../../core/auth/_services/auth.service';
import { Response } from '@angular/http';
import { Select2OptionData, Options } from 'select2';

import { AdminGestionCrearDto } from '../../../../shared/dto/admin-gestion-crear-dto';

@Component({
  selector: 'kt-gestion-manual',
  templateUrl: './gestion-manual.component.html',
  styleUrls: ['./gestion-manual.component.scss'],
  host: {'class': 'sths-tb-element'}
})
export class GestionManualComponent implements OnInit {
  // Select2
	public selectEntidades: Array<Select2OptionData>;
	public selectArea: Array<Select2OptionData>;
  public options: Options;
  
  sthsQSModel: NgbDateStruct;
  sthsQSDate: {year: number, month: number};
  sthsQSTime = {hour: 13, minute: 30};
  actualDate: any;

  opcionesCaso: string = 'Entidad Externa';
  
  formGestionManual: AdminGestionCrearDto = new AdminGestionCrearDto;
  
  // Modales
  contentToShow: number = -1;
  configModal: {} = { status: -1 };
  
  // Cargando
  loading: boolean = false;

  // Máxima cantidad de carácteres en textarea Observaciones
  maxCharacters: number = 0;

  constructor(
            auth: AuthService,
            private cdr: ChangeDetectorRef,
            private http: HttpService,
            private uiService: UIService
            ) 
  {
  }


  ngOnInit() {
    // Select2
    let d=new Date();
    this.actualDate={ year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate() };
    this.cdr.markForCheck();

    this.http.ejecutarServicioGet( environment.endpoints.adminCasesManualEntidades, null ).subscribe( (resEnt: Response) => {
      switch (resEnt.status) {
        case 200:
        case 201:
        case 204:
          let entidades = resEnt.json();
          for (let indexSta = 0; indexSta < entidades.length; indexSta++) {
            entidades[indexSta]['id'] = entidades[indexSta]['answer'];
            entidades[indexSta]['text'] = entidades[indexSta]['answer'];
            if ( indexSta === entidades.length - 1 ) {
              entidades.unshift( {id:"-Sel", text: "Seleccionar Entidad"} );
              this.selectEntidades = entidades;
              break;
            }
          }
          this.cdr.markForCheck();
        break;

        default:
          this.showError(resEnt);
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    });

    this.http.ejecutarServicioGet( environment.endpoints.adminCasesManualArea, null ).subscribe( (resArea: Response) => {
      switch (resArea.status) {
        case 200:
        case 201:
        case 204:
          let area = resArea.json();
          for (let indexSta = 0; indexSta < area.length; indexSta++) {
            area[indexSta]['id'] = area[indexSta]['answer'];
            area[indexSta]['text'] = area[indexSta]['answer'];
            if ( indexSta === area.length - 1 ) {
              area.unshift( {id:"-Sel", text: "Seleccionar Area"} );
              this.selectArea = area;
              break;
            }
          }
          this.cdr.markForCheck();
        break;

        default:
          this.showError(resArea);
          break;
      }
    }, (error: Response) => {
      this.showError( error );
    });
    
    this.options = {
			language: 'es',
			closeOnSelect: true,
     	dropdownCssClass: "select2-dropdown-forms"
    }
  }
  
  crearCasoManual(){
    if( this.formGestionManual.requestBy == "Entidad externa" ){
      this.formGestionManual.requestByValue = this.formGestionManual.entidad;
    }

    if( this.formGestionManual.requestBy == "Area interna" ){
      this.formGestionManual.requestByValue = this.formGestionManual.area;
    }

    if( this.formGestionManual.requestBy == "Persona natural" ){
      this.formGestionManual.requestByValue = this.formGestionManual.name;
    }

    if( this.formGestionManual.fechaHecho && this.formGestionManual.horaHecho ){
      this.formGestionManual.eventOccurrenceDate = this.uiService.changeFormatFecha( this.formGestionManual.fechaHecho, this.formGestionManual.horaHecho);
    }
    
    let formGestionCrear = this.formGestionManual.toJS();
    if( !this.formGestionManual.requestByValue || this.formGestionManual.requestByValue == "-Sel" ){
      this.uiService.showMessage( `Debes completar el campo ${this.formGestionManual.requestBy}` );
    }else if ( !this.formGestionManual.eventOccurrenceDate ) {
      this.uiService.showMessage( `Debes seleccionar una fecha y hora` );
    }else if (this.formGestionManual.requestByDescription===undefined || this.formGestionManual.requestByDescription===""){
      this.uiService.showMessage( `Debes completar el campo Descripción` );
    }
    else if (this.formGestionManual.requestByDescription.length>2000){
      this.uiService.showMessage( `El campo datos de operación no debe tener mas de 2000 caracteres` );
    }
    else if( this.formGestionManual.requestBy == "Persona natural" && this.formGestionManual.name.length>200){
      this.uiService.showMessage( `El campo nombre persona natural no debe tener mas de 200 caracteres` );
    }
    else{
      // Guardar
      delete formGestionCrear['entidad'];
      delete formGestionCrear['area'];
      delete formGestionCrear['name'];
      delete formGestionCrear['fechaHecho'];
      delete formGestionCrear['horaHecho'];
      
      this.loading = true;
      this.http.ejecutarServicioPost( environment.endpoints.adminCasesManualCrear, formGestionCrear ).subscribe( (res: Response) => {        
        switch ( res.status ) {
          case 200:
          case 201:
          case 204:
            // Mostrar Modalre
            let casId=res.json().id;
            console.log(casId);
            this.openModalCasoCreado(casId);
            this.loading = false;
            break;
          
          default:
            this.loading = false;
            this.showError(res);
            break;
        }
      }, (error: Response) => {
        this.loading = false;
        this.showError( error );
      });// END POST
    }
  }

  openModalCasoCreado(casId) {
    this.contentToShow = 1;
    this.configModal = {
      status: 1,
      icono: 'smart-icon-advertencia',
      titulo: 'Gestión Caso Manual',
      textos: [
                `El Caso Manual con Cod. Caso ${casId} fue Creado Exitosamente`
              ],
      botones: [
              {
                label: 'Aceptar',
                class: '',
                accion: 'creacionCaso'
              }
      ]
    };
    this.cdr.markForCheck();
    setTimeout(() => {
      this.contentToShow = -1;
      this.configModal = { status: -1 };
    }, 300);
  }

  changeStatusModal(event) {
    // Crear
    switch (event) {
      case 'creacionCaso':
        this.formGestionManual = new AdminGestionCrearDto();
        this.formGestionManual.entidad = '-Sel';
        this.formGestionManual.area = '-Sel';
        this.cdr.markForCheck();
        break;
    
      default:
        break;
    }
  }

  showError(response) {
    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage( response.json()['message'] );
    } else {
      this.uiService.showMessage( 'Error del servidor' );
    }
    this.cdr.markForCheck();
  }

	/**
	 * Cuenta la cantidad de carácteres del textbox observaciones,
	 * se debe evitar que hayan más de 2000
	 * 
	 * @param event 
	 */
	cantCaracteres(event) {
		this.maxCharacters = 2000 - event.length;
	}
}
