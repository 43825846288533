import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { UIService } from '@app/views/trans/_services/UI.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImagenCorporativaService } from '@app/views/trans/administrador/_services/imagen-corporativa.service';

@Component({
	selector: 'kt-imagen-corporativa',
	templateUrl: './imagen-corporativa.component.html',
	styleUrls: ['./imagen-corporativa.component.scss']
})
export class ImagenCorporativaComponent implements OnInit {

	conditionalLoader = false;
	eventoForm: FormGroup;
	@ViewChild('fileInput') el: ElementRef;
	imageUrl: any = {
		31: '/assets/img/dummy.jpg',
		32: '/assets/img/dummy.jpg',
		33: '/assets/img/dummy.jpg'
	};
	editFile: any = {
		31: true,
		32: true,
		33: true
	};
	removeUpload: any = {
		31: false,
		32: false,
		33: false
	};

	constructor(
		private cd: ChangeDetectorRef,
		private formBuilder: FormBuilder,
		private uiService: UIService,
		private imagenCorporativaService: ImagenCorporativaService
	) {
		this.eventoForm = this.formBuilder.group({
			31: ['', Validators.required],
			32: ['', Validators.required],
			33: ['', Validators.required],
		});
	}

	ngOnInit() {
		this.loadImagenes();
	}

	/**
	 * Cargar imagenes del servidor
	 * @param event 
	 */
	loadImagenes() {
		this.conditionalLoader = true;
		this.imagenCorporativaService
        .getImagesCorporativa()
        .subscribe((res) => {
          // console.log(res);
          res.answers.forEach(row => {
            this.eventoForm.controls[row.question].setValue(row.answer);
            this.imageUrl[row.question] = row.answer;
            this.editFile[row.question] = false;
            this.removeUpload[row.question] = true;
          });
          this.conditionalLoader = false;
          this.cd.markForCheck();
        });
	}

	/**
	 * Cargar images
	 * @param event 
	 */
	uploadFile(event) {
		const reader = new FileReader(); // HTML5 FileReader API
		const file = event.target.files[0];
    	const name = event.target.getAttribute('id');
		if (event.target.files && event.target.files[0]) {
		  reader.readAsDataURL(file);

		  // When file uploads set it to file formcontrol
		  reader.onload = () => {
				const data = new FormData();
				data.append('file', file);
			  this.imagenCorporativaService.saveImagesCorporativa(data).subscribe(res => {
					this.imageUrl[name] = res.fileUrl;
					this.eventoForm.controls[name].setValue(res.fileUrl);
					this.editFile[name] = false;
					this.removeUpload[name] = true;
					// ChangeDetectorRef since file is loading outside the zone
					this.cd.markForCheck();
				});
		  };
		}
	}

	/**
	 * Remove uploaded file
	 * @param name 
	 */
	removeUploadedFile(name) {
		this.imageUrl[name] = '/assets/img/dummy.jpg';
		this.editFile[name] = true;
		this.removeUpload[name] = false;
		this.eventoForm.controls[name].setValue(null);
    	this.cd.markForCheck();
	}

	/**
	 * Enviar datos de imagenes al servidor
	 */
	onSubmit() {
		if (this.eventoForm.invalid) {
			return;
		}

		this.conditionalLoader = true;
		const dataForm = this.eventoForm.value;

		let result = {
			loginLogo: dataForm[31] ? dataForm[31] : '',
			loginImage: dataForm[32] ? dataForm[32] : '',
			homeLogo: dataForm[33] ? dataForm[33] : '',
		};

		this.imagenCorporativaService
			.updateImagesCorporativa(result)
			.subscribe((res: any) => {
			this.conditionalLoader = false;
			this.cd.markForCheck();
			if (res.status === 200 || res.status === 201 || res.status === 204) {
				this.uiService.showMessage('Parámetros actualizados');
				window.location.reload();
			} else {
				this.showError(res);
			}
			}, (err: any) => {
				this.showError(err);
			});
	}

	/**
	 * Respuesta de error del servidor
	 * @param response
	 */
	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage("Ha ocurrido un error ",response['message']);
		} else {
			this.uiService.showMessage("Ha ocurrido un error ",response['message']);
		}
	}

}

