import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpService } from '@app/views/shared/services/http.service';
import { Response } from '@angular/http';
import { Select2OptionData, Options } from 'select2';
import { UIService } from '@app/views/trans/_services/UI.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'kt-mantenimiento-preventivo',
	templateUrl: './mantenimiento-preventivo.component.html',
	styleUrls: ['./mantenimiento-preventivo.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class MantenimientoPreventivoComponent implements OnInit {
	configTable: Object;
	sthsQSTime = { hour: 13, minute: 30 };

	data: Array<any>;

	// Paginador
	paginador: Object = {
		count: 10,
		page: 1
	};

	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1, data: [] };

	// Modales
	contentToShowModal: number = -1;
	configModal: {} = { status: -1 };

	conditionalLoader: boolean;

	// Id Eliminar
	eliminarId: any;

	// Modal Modificar
	contentToModificar: boolean = false;
	idMantenimiento: any;
	item: any = {
		9: '',
		10: '',
		11: ''
	};
	selectedItem: any = {
		9: '',
		10: '',
		11: ''
	};

	submitted = false;
	eventoForm: FormGroup;

	public selectModelo: Array<Select2OptionData>;
	public selectSensor: Array<Select2OptionData>;
	public options: Options;

	constructor(
		private cdr: ChangeDetectorRef,
		private http: HttpService,
		private formBuilder: FormBuilder,
		private uiService: UIService
	) {
		this.eventoForm = this.formBuilder.group({
			9: ['', Validators.required],
			10: ['', Validators.required],
			11: ['', Validators.required]
		});
	}

	get f() {
		return this.eventoForm.controls;
	}

	ngOnInit() {
		this.obtenerDatos();
		// Select2
		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
		// selectRedes
		this.selectModelo = [];
		this.selectSensor = [];
	}

	obtenerDatos() {
		this.conditionalLoader = true;
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminPredictiveMaintenanceList
			)
			.subscribe((eventRecords: Response) => {
				this.conditionalLoader = false;
				let data = eventRecords.json().answerGroups;
				let formConfig = eventRecords.json().questions;

				let select = formConfig.find(i => Number(i.id) === 9);
				if (select) {
					this.selectModelo = select.possibleAnswers.map(i => {
						return { id: i.answerValue.toString(), text: i.answer };
					});
				}

				select = formConfig.find(i => Number(i.id) === 11);
				if (select) {
					this.selectSensor = select.possibleAnswers.map(i => {
						return { id: i.answerValue.toString(), text: i.answer };
					});
				}

				this.data = data;
				this.procesarJson(data);
			});
	}

	procesarJson(data) {
		data.sort((a: any, b: any) => {
			if (a['9'] < b['9']) {
				return -1;
			}
			if (a['9'] > b['9']) {
				return 1;
			}
			return 0;
		});
		let dataArray = data.map(itm => {
			return [itm.answerGroup, itm['9'], itm['10'], itm['11'], ''];
		});
		this.armarTabla(dataArray);
	}

	armarTabla(data) {
		this.contentToShow = 1;
		this.configTabla = {
			status: 1,
			header: ['ID', 'Tipo de Model', 'Tiempo', 'Sensor', 'Acciones'],
			typeColumn: [
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar', 'eliminar'] }
			],
			showSearch: false,
			searchColumn: [1],
			searchTitle: 'Buscar Categoría',
			hideColumns: [0],
			showPag: false,
			leftsColumns: [1],
			configColumns: {
				id: 0,
				columnClick: 1
			},
			data: data,
			paginador: this.paginador
		};
		this.cdr.markForCheck();
	}

	editarMantenimiento(id) {
		console.log('editarMantenimiento', id);
		let element = this.data.find(itm => itm.answerGroup === id);
		this.selectedItem = {...element};
		this.contentToModificar = true;
		this.idMantenimiento = id;
		this.cdr.markForCheck();
	}

	eliminarMantenimiento(id) {
		// Guardar en variable de Eliminar Global
		this.eliminarId = id;

		this.contentToShowModal = 1;

		this.configModal = {
			status: 1,
			icono: 'smart-icon-eliminar',
			titulo: '¿Estas seguro que desea eliminar los datos?',
			textos: [
				'Los datos en la fila seleccionada serán eliminados permanentemente'
			],
			botones: [
				{
					label: 'Cancelar',
					class: 'sths-btn-cancel',
					accion: 'cancelarMantenimiento'
				},
				{
					label: 'Eliminar',
					class: '',
					accion: 'eliminarMantenimiento'
				}
			]
		};
		this.cdr.markForCheck();
		setTimeout(() => {
			this.contentToShowModal = -1;
			this.configModal = { status: -1 };
		}, 300);
	}

	eventGuardar(event) {
		console.log('eventGuardar', event);
		this.obtenerDatos();
	}

	changeStatusModal(event) {
		this.eventoForm.reset();
		this.submitted = false;
		switch (event) {
			case 'eliminarMantenimiento':
				console.log('eliminarMantenimiento', this.eliminarId);

				this.conditionalLoader = true;
				this.http
					.ejecutarServicioDelete(
						environment.endpoints.adminPredictiveMaintenanceDelete +
							this.eliminarId
					)
					.subscribe((res: Response) => {
						this.conditionalLoader = false;
						if (res.status === 200) {
							this.uiService.showMessage(
								'Registro eliminado con éxito.'
							);
							this.eliminarId = '';
							this.obtenerDatos();
						} else {
							this.showError(res);
						}
					});
				break;

			default:
				break;
		}
	}

	changeStatusEdit(event) {
		this.contentToModificar = false;
		this.idMantenimiento = '';
		this.selectedItem = {};
	}

	save() {
		this.submitted = true;

		if (this.eventoForm.invalid) {
			return;
		}

		let result = {
			'9': this.eventoForm.value[9] ? this.eventoForm.value[9] : '',
			'10': this.eventoForm.value[10] ? this.eventoForm.value[10] : '',
			'11': this.eventoForm.value[11] ? this.eventoForm.value[11] : ''
		};

		this.conditionalLoader = true;
		this.http
			.ejecutarServicioPost(
				environment.endpoints.adminPredictiveMaintenanceSave,
				{
					answer: {
						...result
					}
				}
			)
			.subscribe((res: Response) => {
				this.conditionalLoader = false;
				this.submitted = false;
				if (res.status === 200) {
					this.eventoForm.reset();
					this.uiService.showMessage(
						'Los datos ingresados han sido guardados con éxito.'
					);
					this.obtenerDatos();
				} else {
					this.showError(res);
				}
			});
	}

	update() {
		this.conditionalLoader = true;
		this.http
			.ejecutarServicioPut(
				environment.endpoints.adminPredictiveMaintenanceUpdate +
					this.selectedItem.answerGroup,
				{
					answer: {
						9: this.selectedItem[9],
						10: this.selectedItem[10].toString(),
						11: this.selectedItem[11]
					}
				}
			)
			.subscribe((res: Response) => {
				this.conditionalLoader = false;
				if (res.status === 200) {
					this.uiService.showMessage('Parámetros actualizados');
					this.contentToModificar = false;
					this.obtenerDatos();
				} else {
					this.showError(res);
				}
			});
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		// this.loading = false;
	}
}
