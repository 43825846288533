import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { AdminUserDTO } from '../../../../shared/dto/admin-user-dto';
import { UIService } from '../../../_services/UI.service';

@Component({
  selector: 'kt-usuario-off',
  templateUrl: './usuario-off.component.html',
  styleUrls: ['./usuario-off.component.scss']
})
export class UsuarioOffComponent implements OnInit {

  formModelUser: AdminUserDTO = new AdminUserDTO();
  @Input() modificarDatos: AdminUserDTO;

  @Output() actionModificarUser  = new EventEmitter();
  // Modales
  contentToShow: number = -1;
  configModal: {} = { status: -1 };

  loading: boolean = false;

  constructor(
      private cdr: ChangeDetectorRef,
      private uiService: UIService
  ) {}

  ngOnInit() {
    // Si trae parametros para EDITAR
    
  }

  showError(response) {

    if (response['_body'] && response.json()['message']) {
      this.uiService.showMessage( response.json()['message'] );
    } else {
      this.uiService.showMessage( 'Error del servidor' );
    }
    this.loading = false;
    this.cdr.markForCheck();

  }

  cerrarModal() {
    this.actionModificarUser.emit('cerrar');
  }
  
  changeStatusModal(event) {
    if ( this.modificarDatos ) {
      // Modificar
      this.actionModificarUser.emit('cerrar');
    } else {
      // Crear
      this.formModelUser = new AdminUserDTO();
    }
  }

}
