import { ConfigService } from './../../services/config.service';
// Angular
import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService } from '../../../../core/_base/layout';
// Auth
import { AuthNoticeService } from '../../../../core/auth';
import { ImagenCorporativaService, Images } from '../../../trans/administrador/_services/imagen-corporativa.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'kt-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class AuthComponent implements OnInit {
	// Public properties
	today: number = Date.now();
	headerLogo: string;
	imagenCooporativa: Images;

	/**
	 * Component constructor
	 *
	 * @param el
	 * @param render
	 * @param layoutConfigService: LayoutConfigService
	 * @param authNoticeService: authNoticeService
	 * @param translationService: TranslationService
	 * @param splashScreenService: SplashScreenService
	 */
	constructor(
		private el: ElementRef,
		private render: Renderer2,
		private configService: ConfigService,
		private layoutConfigService: LayoutConfigService,
		public authNoticeService: AuthNoticeService,
		private translationService: TranslationService,
		private splashScreenService: SplashScreenService,
		private imagenCorporativaService: ImagenCorporativaService,
		private ref: ChangeDetectorRef
	) {	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		this.translationService.setLanguage( this.configService.getLanguage() );
		this.headerLogo = this.layoutConfigService.getLogo();

		this.splashScreenService.hide();

		// load default styles
		this.loadCSS('./assets/css/style.bundle.css');
    
    // load imagen coorporativa
    this.imagenCooporativa = this.imagenCorporativaService.dataStore;
		this.imagenCorporativaService.getImages();
		this.awaitImage();
	}

	/**
	 * Se suscribe al cambio del observable en el servicio -imagenCorporativaService-
	 */
	awaitImage():void{
		this.imagenCorporativaService.images.subscribe( (dataStore:any) => {			
			this.imagenCooporativa = dataStore;
			this.ref.detectChanges();
		});
	}

	/**
	 * Load CSS for this specific page only, and destroy when navigate away
	 * @param styleUrl
	 */
	private loadCSS(styleUrl: string) {
		return new Promise((resolve, reject) => {
			const styleElement = document.createElement('link');
			styleElement.href = styleUrl;
			styleElement.type = 'text/css';
			styleElement.rel = 'stylesheet';
			styleElement.onload = resolve;
			this.render.appendChild(this.el.nativeElement, styleElement);
		});
	}
}
