import { HttpService } from '../../../../shared/services/http.service';
import { UIService } from './../../../../trans/_services/UI.service';
import { environment } from './../../../../../../environments/environment';
import { MatSnackBarRef } from '@angular/material';
import {
	LayoutUtilsService,
	MessageType
} from './../../../../../core/_base/crud/utils/layout-utils.service';
import { Response } from '@angular/http';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
	NgbDateStruct,
	NgbDate,
	NgbCalendar
} from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData, Options } from 'select2';
import * as moment from 'moment';
import { AdminGestionFilterDto } from '../../../../shared/dto/admin-gestion-filter-dto';

@Component({
	selector: 'kt-buscar-incidente',
	templateUrl: './buscar-incidente.component.html',
	styleUrls: ['./buscar-incidente.component.scss'],
	host: { class: 'sths-tb-element' }
})
export class BuscarIncidenteComponent implements OnInit {
	// Select2
	public selectConcesionarios: Array<Select2OptionData>;
	public selectBuses: Array<Select2OptionData>;
	public selectTypes: Array<Select2OptionData>;
	public selectEstados: Array<Select2OptionData>;
	public selectCategorias: Array<Select2OptionData>;
	public selectModule: Array<Select2OptionData>;
	public selectPrioridad: Array<Select2OptionData>;

	baseUrl = environment.endpoints.adminIncidents;
	lastRequest: any;

	params: string = '';

	fromDate: NgbDate;
	toDate: NgbDate;
	data: [];
	sthsQSModel1: NgbDateStruct;
	sthsQSTime1 = { hour: 9, minute: 0 };
	sthsQSModel2: NgbDateStruct;
	sthsQSTime2 = { hour: 10, minute: 0 };
	public options: Options;
	private _parentUrl: string = environment.endpoints.parent;
	private _actualDialog: MatSnackBarRef<any>;
	formModelBIncidente: AdminGestionFilterDto = new AdminGestionFilterDto();
	stateSelected: string = 'all';
	prioritySelected: string = 'all';
	moduleSelected: string = 'all';
	categorySelected: string = 'all';
	startDate: any = '';
	endDate: any = '';
	incidentId: string = '';
	// Tabla
	contentToShow: number = -1;
	configTabla: {} = { status: -1, data: [] };

	// Paginador
	paginador: object = {
		count: 10,
		page: 1,
		total: 0,
		maxPages: 5
	};

	rutasMenu = [
		{
			label: 'Buscar Incidentes',
			icon: 'smart-icon-asociar-dispositivos',
			ruta: `${environment.folders.routeAdmin}incidentes/buscar`
		},
		{
			label: 'Crear Incidente',
			icon: 'smart-icon-asociar-dispositivos',
			ruta: `${environment.folders.routeAdmin}incidentes/crear`
		}
	];

	conditionalLoader: boolean;
	constructor(
		private http: HttpService,
		private uiService: UIService,
		private cdr: ChangeDetectorRef,
		private layoutUtilsService: LayoutUtilsService,
		private router: Router
	) {}

	ngOnInit() {
		const pastYear = moment()
			.subtract(1, 'month')
			.toDate();
		this.startDate = {
			year: pastYear.getFullYear(),
			month: pastYear.getMonth() + 1,
			day: pastYear.getDate()
		};
		this.endDate = {
			year: new Date().getFullYear(),
			month: new Date().getMonth() + 1,
			day: new Date().getUTCDate()
		};

		this.options = {
			language: 'es',
			closeOnSelect: true,
			dropdownCssClass: 'select2-dropdown-forms'
		};
		this.buscarIncidentes(1);
		let urlIncidentecategorias = environment.endpoints.Mesacategorias;
		this.http.ejecutarServicioGet(urlIncidentecategorias).subscribe(
			res => {
				if (res.json().length > 0) {
					let data = res.json();
					let selectCategoria = data.map(i => {
						return { id: i.value, text: i.text };
					});
					selectCategoria.unshift({ id: 'all', text: 'Todos' });
					console.log(selectCategoria)
					this.selectCategorias = selectCategoria;
				}
				this.cdr.markForCheck();
			},
			(error: Response) => {
				if (this._actualDialog) {
					this._actualDialog.dismiss();
				}
				const message = 'Error cargando datos ';
				this._actualDialog = this.layoutUtilsService.showActionNotification(
					message,
					MessageType.Update,
					10000,
					true,
					false,
					0,
					'top'
				);
			}
		);

		let urlIncidentemodulos = environment.endpoints.Mesamodulos;
		this.http.ejecutarServicioGet(urlIncidentemodulos).subscribe(
			res => {
				if (res.json().length > 0) {
					let data = res.json();
					let selectModulo = data.map(i => {
						return { id: i.value, text: i.text };
					});
					selectModulo.unshift({ id: 'all', text: 'Todos' });
					this.selectModule = selectModulo;
				}
				this.cdr.markForCheck();
			},
			(error: Response) => {
				if (this._actualDialog) {
					this._actualDialog.dismiss();
				}
				const message = 'Error cargando datos ';
				this._actualDialog = this.layoutUtilsService.showActionNotification(
					message,
					MessageType.Update,
					10000,
					true,
					false,
					0,
					'top'
				);
			}
		);

		let urlIncidenteprioridades = environment.endpoints.Mesaprioridades;
		this.http.ejecutarServicioGet(urlIncidenteprioridades).subscribe(
			res => {
				if (res.json().length > 0) {
					let data = res.json();
					let selectPrioridad = data.map(i => {
						return { id: i.value, text: i.text };
					});
					selectPrioridad.unshift({ id: 'all', text: 'Todos' });
					this.selectPrioridad = selectPrioridad;
				}
				this.cdr.markForCheck();
			},
			(error: Response) => {
				if (this._actualDialog) {
					this._actualDialog.dismiss();
				}
				const message = 'Error cargando datos ';
				this._actualDialog = this.layoutUtilsService.showActionNotification(
					message,
					MessageType.Update,
					10000,
					true,
					false,
					0,
					'top'
				);
			}
		);

		let urlIncidenteestado = environment.endpoints.Mesaestado;
		this.http.ejecutarServicioGet(urlIncidenteestado).subscribe(
			res => {
				if (res.json().length > 0) {
					let data = res.json();
					let selectEstados = data.map(i => {
						return { id: i.value, text: i.text };
					});
					selectEstados.unshift({ id: 'all', text: 'Todos' });
					this.selectEstados = selectEstados;
				}
				this.cdr.markForCheck();
			},
			(error: Response) => {
				if (this._actualDialog) {
					this._actualDialog.dismiss();
				}
				const message = 'Error cargando datos ';
				this._actualDialog = this.layoutUtilsService.showActionNotification(
					message,
					MessageType.Update,
					10000,
					true,
					false,
					0,
					'top'
				);
			}
		);
	}

	buscadorPagina(event) {
		if (this.paginador['total'] > this.paginador['count']) {
			this.buscarIncidentes(event);
		}
	}

	editarElemento(event: string) {
		let item: any = this.data.find(
			itm => itm['id'] === parseInt(event, 10)
		);
		this.router
			.navigate([`${environment.folders.routeAdmin}incidente/${item.id}`])
			.catch(e => {
				console.log(e);
			});
	}

	buscarIncidentes(page) {
		this.paginador['page'] = page;
		this.conditionalLoader = true;
		let count = this.paginador['count'];
		console.log(count)
		let state = this.stateSelected;
		if (state === 'all') {
			state = '';
		}
		let priority = this.prioritySelected;
		if (priority === 'all') {
			priority = '';
		}
		let category = this.categorySelected;
		if (category === 'all') {
			category = '';
		}
		let moduleSelected = this.moduleSelected;
		if (moduleSelected === 'all') {
			moduleSelected = '';
		}
		let startDate = '';
		if (this.startDate) {
			startDate =
				this.startDate['year'] +
				'-' +
				this.startDate['month'] +
				'-' +
				this.startDate['day'];
		}
		let endDate = '';
		if (this.endDate) {
			endDate =
				this.endDate['year'] +
				'-' +
				this.endDate['month'] +
				'-' +
				this.endDate['day'];
		}

		this.params = `?id=${
			this.incidentId
		}&count=${count}&page=${page}&state=${state}&priority=${priority}&module=${moduleSelected}&endDate=${endDate}&startDate=${startDate}&category=${category}`;
		this.http
			.ejecutarServicioGet(
				environment.endpoints.adminIncidents + 'count' + this.params
			)
			.subscribe(
				(res: Response) => {
					this.paginador['total'] = res.json();
					console.log(this.paginador['total'])
					this.http
						.ejecutarServicioGet(
							`${environment.endpoints.adminIncidents}search${
								this.params
							}`
						)
						.subscribe(res2 => {
							let data = res2.json();
							this.data = data;
							this.conditionalLoader = false;
							this.procesarJson(data);
						});
				},
				(error: Response) => {
					// this.showError(error);
				}
			);
	}

	procesarJson(data) {
		let dataArray = [];
		for (let index = 0; index < data.length; index++) {
			dataArray.push([
				data[index]['id'].toString(),
				data[index]['id'].toString(),
				new Date(data[index]['openingDate'].toString()).toLocaleString(),
				data[index]['module'].toString(),
				data[index]['category'].toString(),
				data[index]['priority'].toString(),
				data[index]['title'].toString(),
				data[index]['state'].toString(),
				''
			]);
		}
		this.armarTabla(dataArray);
	}

	showError(response) {
		if (response['_body'] && response.json()['message']) {
			this.uiService.showMessage(response.json()['message']);
		} else {
			this.uiService.showMessage('Error del servidor');
		}
		this.conditionalLoader = false;
		this.cdr.markForCheck();
	}

	exportData(event) {
		console.log('--even--', this.params);
		console.log(event);

		this.conditionalLoader = true;
		let urlGetReport = this.baseUrl + 'export/';
		let url = `${urlGetReport}${event}${this.params}`;
		console.log('--url--', this.params);
		console.log(url);
		this.http
			.ejecutarServicioGet(`${urlGetReport}${event}${this.params}`)
			.subscribe(
				res => {
					this.conditionalLoader = false;
					this.uiService.downloadFileBase64(
						res['_body'],
						event,
						'reporte'
					);
					this.cdr.markForCheck();
				},
				(error: Response) => {
					this.showError(error);
				}
			); // END GET
	}

	armarTabla(data) {
		console.log(data)
		this.contentToShow = 1;
		this.configTabla = {
			origin: 'incidents',
			status: 1,
			header: [
				'ID',
				'No Incidente',
				'Fecha Apertura',
				'Módulo',
				'Categoría',
				'Prioridad',
				'Título',
				'Estado',
				'Acciones'
			],
			typeColumn: [
				,
				,
				,
				,
				,
				,
				,
				,
				{ tipo: 'acciones', acciones: ['editar'] }
			],
			showSearch: false,
			searchColumn: [1],
			searchTitle: 'Buscar Incidente',
			hideColumns: [0],
			showPag: true,
			showExport: true,
			leftsColumns: [1],
			configColumns: {
				id: 0,
			},
			data: data,
			paginador: this.paginador
		};
		// this.loading = false;
		this.cdr.markForCheck();
	}
}
