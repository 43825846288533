import { UserDTO } from './../../../views/shared/dto/user-dto';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UIService } from './../../../../app/views/trans/_services/UI.service';
import { Http, RequestOptionsArgs, Headers, Response } from '@angular/http';
// import { HttpService } from '@app/views/shared/services/http.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private skipRedirect: boolean = false;
	private _user: UserDTO;
	private userServiceSource = new Subject<UserDTO>();
	public userService$ = this.userServiceSource.asObservable();

	constructor(
		private http: Http, 
		private uiService: UIService
	) {}

	login(username: string, password: string): Observable<any> {
		return this.http.post(environment.endpoints.login, {
			username: username,
			password: password
		});
	}

	refreshToken(): Observable<any> {
		let token = localStorage.getItem('refresh_token');
		return this.http.post(environment.endpoints.refreshToken, {
			refresh_token: token,
			grant_type: 'refresh_token'
		});
	}

	logout(): Observable<any> {
		// Limpiar
		this.clearAll();

		let hds = new Headers();
		hds.append(
			'Authorization',
			'Bearer ' + localStorage.getItem(environment.authTokenKey)
		);
		let params: RequestOptionsArgs = {
			headers: hds
		};
		return this.http.post(environment.endpoints.logout, {}, params);
		// return this.http.ejecutarServicioPost(environment.endpoints.logout);
	}

	clearAll(){
		console.log('clearAll');
		localStorage.setItem( "breadcrumbsSH", JSON.stringify( [] ) );
		localStorage.setItem( environment.authUserKey, undefined );
		localStorage.setItem( 'refresh_token', undefined );
		localStorage.removeItem(environment.authUserKey);
		localStorage.removeItem('refresh_token');

		// Limpiar Usuario
		this.setUser(null);
	}

	getUser(): UserDTO {
		if (localStorage.getItem(environment.authUserKey)) {
			const user = new UserDTO(
				JSON.parse(localStorage.getItem(environment.authUserKey))
			);
			this._user = user;
		}
		return this._user;
	}

	setUser(user: UserDTO) {
		if (user !== null) {
			localStorage.setItem(environment.authUserKey, user.toJSON());
		} else {
			localStorage.removeItem(environment.authUserKey);
			localStorage.removeItem('refresh_token');
		}
		this._user = user;
		this.userServiceSource.next(this._user);
	}

	redirectHome(router: Router, incidentes?: string) {

		if (this.getUser() && this.getUser().roles && this.getUser().roles[0]) {
			switch (this.getUser().roles[0]) {
				case environment.rol.mapa:
					if (incidentes) {
						router.navigate(['/map/dashboard?incidentes=true']);
					} else {
						router.navigate(['/map/dashboard']);
					}
					break;
				case environment.rol.operario:
				case environment.rol.administrador:
				case environment.rol.coordinadorDts:
				case environment.rol.tecnicoSeguridad:
					router.navigate(['/admin/dashboard']);
					break;
				default:
					this.showMessage(router);
					break;
			}
		} else {
			this.showMessage(router);
		}
	}

	showMessage(router: Router) {
		if (!this.skipRedirect) {
			this.skipRedirect = true;

			setTimeout(() => {
				this.uiService
					.showMessage('No está autorizado a ingresar')
					.afterDismissed()
					.subscribe(() => {
						this.skipRedirect = false;
					});
			}, 300);

			router.navigate(['auth/login']);
		}
	}
}
