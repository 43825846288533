import { environment } from './../../../../environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { filter } from 'rxjs/operators';

// Prueba Rol
@Component({
  selector: 'kt-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['../../../../assets/css/style.bundle.css', './administrador.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdministradorComponent implements OnInit {
  rol: boolean;

  constructor(private router: Router, private route: ActivatedRoute,) {
    if ( localStorage.getItem( 'adm_smhe_rol') ) {
      this.rol = JSON.parse( localStorage.getItem( 'adm_smhe_rol') );
    }

    /*
    this.router.events
    .pipe(filter(e => e instanceof NavigationEnd))
    .subscribe((ruta:NavigationEnd) => {
      if(ruta.id == 1){
        console.log("LIMIPIAR",ruta);
      }else{
        console.log("RUTA=>",ruta);
      }
    });
    */
  }

  ngOnInit() {
    if ( !environment.production ) {
      // TODO :: ONLY IN DEVELOPMENT MODE
      // this.router.navigate(['../../registry/tipo2/groups/hermes_registry_2/1548/editar'], { relativeTo: this.route });
    }
  }

  changeSwitch() {
    localStorage.setItem( 'adm_smhe_rol', JSON.stringify( this.rol ) );
  }

}
